import React from 'react';
import {Box, Select} from '@chakra-ui/react';
import FieldBase from './field-base';
import {ChevronDownIcon} from './icons';

function Dropdown({
  title,
  placeholder = 'Valitse...',
  description = undefined,
  value = undefined,
  onChange = () => {},
  children,
}) {
  return (
    <FieldBase title={title} icon={<ChevronDownIcon />} description={description}>
      <Box marginRight="-8">
        <Select
          fontSize="sm"
          isTruncated
          maxWidth="full"
          variant="unstyled"
          height="5"
          placeholder={placeholder}
          icon=""
          value={value}
          onChange={onChange}
        >
          {children}
        </Select>
      </Box>
    </FieldBase>
  );
}

export default Dropdown;
