/* eslint-disable max-len */
import React from 'react';
import {Icon} from '@chakra-ui/react';

const FilterIcon = (props) => (
  <Icon
    viewBox="0 0 16 16"
    stroke="currentColor"
    fill="currentColor"
    strokeWidth="0"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.7181593,2.385845 C15.051675,1.92991492 15.1007936,1.32528513 14.8452427,0.821500764 C14.5896917,0.317716396 14.0728033,0.000200788848 13.5079096,1.03802879e-07 L1.500046,1.03802879e-07 C0.93748939,-0.0002086553 0.422142705,0.31447513 0.165347482,0.815001049 C-0.091447741,1.31552697 -0.0464841041,1.91767841 0.281798992,2.37451557 L5.01350236,9.15284725 L5.01350236,14.4943406 C5.01345388,14.91601 5.19024895,15.3183724 5.50087392,15.6035342 C5.81149888,15.888696 6.22747842,16.0305162 6.64760611,15.9944905 C7.01581365,16.0056132 7.37953074,15.9112733 7.69591166,15.7225841 C9.51728424,14.5896411 9.32535035,14.706934 9.34267772,14.6942717 C9.71935121,14.4103331 9.94132436,13.9663868 9.9424711,13.4946849 L9.9424711,9.15484656 L14.7181593,2.385845 Z M8.42899246,8.41043633 C8.32971272,8.55099579 8.27639918,8.71885195 8.27637837,8.89093747 L8.27637837,13.2101163 C8.27649755,13.3276296 8.21471004,13.4365174 8.11376772,13.4966842 C7.89517635,13.625973 7.49864628,13.8612253 7.1774236,14.0478277 C7.07438894,14.1076328 6.94726452,14.1078807 6.84399736,14.048478 C6.7407302,13.9890753 6.67703268,13.8790603 6.67692934,13.7599269 L6.67692934,8.89093747 C6.67721259,8.72045108 6.62484424,8.55403609 6.526981,8.41443495 L2.18381046,2.19057893 C2.11280978,2.08876328 2.10428687,1.95592219 2.16169425,1.84586803 C2.21910163,1.73581388 2.33292262,1.66675933 2.45704967,1.66675933 L12.5435751,1.66675933 C12.6680428,1.66675933 12.7821726,1.73599772 12.8395123,1.84647116 C12.8968519,1.9569446 12.8878413,2.09016572 12.8161478,2.19191181 L8.42899246,8.41043633 Z"
    />
  </Icon>
);

export default FilterIcon;
