import React from 'react';
import {
  Box,
  Button,
  Stack,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
} from '@chakra-ui/react';
import {useForm} from 'react-hook-form';

const ChangePasswordForm = ({onSubmit, processing}) => {
  const {handleSubmit, register, errors, watch, reset} = useForm();
  const password = watch('password');

  const submit = (data) => {
    onSubmit(data);
    reset();
  };

  return (
    <form id="change-password-form" onSubmit={handleSubmit(submit)} autoComplete="off">
      <Stack padding="5" spacing="2">
        <FormControl isInvalid={errors.password || errors.password_confirmation}>
          <FormLabel>Salasana</FormLabel>
          <Input
            autoComplete="new-password"
            name="password"
            type="password"
            ref={register({required: 'Pakollinen kenttä'})}
          />
        </FormControl>
        <FormControl isInvalid={errors.password || errors.password_confirmation}>
          <FormLabel>Salasana uudelleen</FormLabel>
          <Input
            autoComplete="new-password"
            name="password_confirmation"
            type="password"
            ref={register({
              validate: (value) => (password ? value === password : true),
            })}
          />
          <FormErrorMessage>
            {errors.password && errors.password.message}
          </FormErrorMessage>
          <FormErrorMessage>
            {errors.password_confirmation && 'Salasanat eivät täsmää'}
          </FormErrorMessage>
        </FormControl>
        <Box textAlign="right">
          <Button
            type="submit"
            form="change-password-form"
            variant="primary"
            isLoading={processing}
          >
            <span>Vaihda salasana</span>
          </Button>
        </Box>
      </Stack>
    </form>
  );
};

export default ChangePasswordForm;
