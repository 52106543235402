import React from 'react';
import {useForm} from 'react-hook-form';
import {FormControl, FormErrorMessage, FormLabel, Input} from '@chakra-ui/react';

const ForgotPasswordForm = ({onSubmit, id}) => {
  const {handleSubmit, errors, register} = useForm();

  return (
    <form onSubmit={handleSubmit(onSubmit)} id={id}>
      <FormControl isInvalid={errors.email}>
        <FormLabel>Käyttäjätunnus</FormLabel>
        <Input
          name="email"
          type="email"
          placeholder="Sähköpostiosoite"
          ref={register({required: 'Pakollinen kenttä'})}
        />
        <FormErrorMessage>{errors.email && errors.email.message}</FormErrorMessage>
      </FormControl>
    </form>
  );
};

export default ForgotPasswordForm;
