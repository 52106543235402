/* eslint-disable max-len */
import React from 'react';
import {Icon} from '@chakra-ui/react';

const NavIcon = (props) => (
  <Icon
    viewBox="0 0 16 16"
    stroke="currentColor"
    fill="currentColor"
    strokeWidth="0"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15,12.1304348 C15.5522847,12.1304348 16,12.57815 16,13.1304348 L16,13.2173913 C16,13.7696761 15.5522847,14.2173913 15,14.2173913 L1,14.2173913 C0.44771525,14.2173913 6.76353751e-17,13.7696761 0,13.2173913 L0,13.1304348 C-6.76353751e-17,12.57815 0.44771525,12.1304348 1,12.1304348 L15,12.1304348 Z M15,6.56521739 C15.5522847,6.56521739 16,7.01293264 16,7.56521739 L16,7.65217391 C16,8.20445866 15.5522847,8.65217391 15,8.65217391 L1,8.65217391 C0.44771525,8.65217391 6.76353751e-17,8.20445866 0,7.65217391 L0,7.56521739 C-6.76353751e-17,7.01293264 0.44771525,6.56521739 1,6.56521739 L15,6.56521739 Z M15,1 C15.5522847,1 16,1.44771525 16,2 L16,2.08695652 C16,2.63924127 15.5522847,3.08695652 15,3.08695652 L1,3.08695652 C0.44771525,3.08695652 6.76353751e-17,2.63924127 0,2.08695652 L0,2 C-6.76353751e-17,1.44771525 0.44771525,1 1,1 L15,1 Z"
    />
  </Icon>
);

export default NavIcon;
