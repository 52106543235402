import {prop} from 'ramda';
import services from 'services';
import {describeThrow} from 'utils/errors';
import msgs from 'dicts/messages';
import {faultInclude, vehicleFaultsDefaultQuery} from './constants';

let httpJson = null;
services.waitFor('api').then((x) => (httpJson = x.httpJson));

export const getFault = (id) =>
  httpJson('get', `/faults/${id}`, {include: faultInclude})
    .catch(describeThrow(msgs.fetchFailed))
    .then(prop('data'));

export const putFault = (fault) =>
  httpJson('put', `/faults/${fault.id}`, {}, {body: fault})
    .catch(describeThrow(msgs.saveFailed))
    .then(prop('data'));

export const postDocument = (body) =>
  httpJson('post', '/documents', {}, {body})
    .catch(describeThrow(msgs.saveFailed))
    .then(prop('data'));

export const deleteDocument = (id) =>
  httpJson('delete', `/documents/${id}`).catch(describeThrow(msgs.deleteFailed));

export const getVehicleFaults = (query) =>
  httpJson('get', '/faults', {
    ...vehicleFaultsDefaultQuery,
    ...query,
  }).catch(describeThrow(msgs.fetchFailed));

export const postFaultLog = (body) =>
  httpJson('post', '/fault-logs', {}, {body})
    .catch(describeThrow(msgs.saveFailed))
    .then(prop('data'));

export const massUpdateFaults = (body) =>
  httpJson('put', '/faults', {}, {body})
    .catch(describeThrow(msgs.saveFailed))
    .then(prop('data'));

export const deleteFault = (id) =>
  httpJson('delete', `/faults/${id}`).catch(describeThrow(msgs.deleteFailed));

export const getTags = () =>
  httpJson('get', '/faults/tags').catch(describeThrow(msgs.fetchFailed));

export const getFaults = (query) =>
  httpJson('get', '/faults', {include: 'children', ...query})
    .catch(describeThrow(msgs.fetchFailed))
    .then(prop('data'));
