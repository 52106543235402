import React, {useEffect, useState, useMemo} from 'react';
import {
  Box,
  SimpleGrid,
  Tabs,
  TabList,
  Tab,
  TabPanel,
  TabPanels,
  Stack,
  Button,
  IconButton,
  Tag,
  TagLeftIcon,
  TagLabel,
  Text,
  Center,
  Spinner,
  useMediaQuery,
  HStack,
  Checkbox,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverBody,
  Portal,
  FormControl,
  FormLabel,
  Select,
  InputGroup,
  Input,
  InputRightElement,
  Flex,
  Icon,
} from '@chakra-ui/react';
import {Link as RouterLink} from 'react-router-dom';
import {pick, includes, all} from 'ramda';
import {connect} from 'react-redux';
import {formatISO, parseISO} from 'date-fns';
import {sort as fastSort} from 'fast-sort';
import {applyState} from 'utils/redux';
import effs from 'modules/faults/faultPage/effects';
import sels from 'modules/faults/faultPage/selectors';
import commonSels from 'modules/common/selectors';
import {initialState} from 'modules/faults/faultPage/store';
import {types, states} from 'dicts/faults';
import {getReferrerUrl} from 'utils/url';
import {useDebounce} from 'utils/react';
import {
  Card,
  Content,
  PageHeader,
  CardHeader,
  File,
  PenIcon,
  List,
  ListItem,
  BusIcon,
  PersonIcon,
  PlusIcon,
  FileList,
  TableHeader,
  Table,
  Breadcrumbs,
  RadioButtonGroup,
  ReactSelect,
  TabsHorizontalScroll,
  Paginator,
  DateRangePopover,
  FilterIcon,
  SearchIcon,
  CloseIcon,
  InfoIcon,
} from 'components';
import EditFaultModal from '../components/edit-fault-modal';
import AddAttachmentModal from '../components/add-attachment-modal';
import AddLogModal from '../components/add-log-modal';
import ActivityLog from '../components/activity-log';
import FaultRow from '../components/fault-row';
import MassEditButton from '../components/mass-edit-button';

const stateOpts = Object.entries(states).map(([value, label]) => ({value, label}));

const Fault = ({
  location,
  match: {
    params: {faultId},
  },
  loading,
  processing,
  fault,
  editFaultModalOpen,
  addAttachmentModalOpen,
  addLogModalOpen,
  vehicles,
  editFaultDefaultVals,
  vehicleFaults,
  vehicleFaultsPagination,
  vehicleFaultsLoading,
  isAdmin,
  isRepairer,
  selection,
  query,
  initialized,
  hasFilters,
  tags,
}) => {
  useEffect(() => {
    effs.initialize(Number(faultId));
    return () => {
      effs.destroy();
    };
  }, [faultId]);

  const [tabIndex, setTabIndex] = useState(0);
  const [isXs] = useMediaQuery('(max-width: 30em)');
  const [isSm] = useMediaQuery('(max-width: 48em)');

  const queryFilterTitle = query['filter[title]'];
  const [filterTitle, setFilterTitle] = useState(queryFilterTitle);
  const debouncedFilterTitle = useDebounce(filterTitle, 500);

  useEffect(() => {
    setFilterTitle(queryFilterTitle);
  }, [queryFilterTitle]);

  useEffect(() => {
    if (initialized && debouncedFilterTitle !== queryFilterTitle) {
      effs.updateQuery({
        'filter[title]': debouncedFilterTitle,
        'page[number]': 1,
      });
    }
  }, [debouncedFilterTitle]);

  const createdAtFrom = query['filter[created_at_from]'];
  const createdAtTo = query['filter[created_at_to]'];

  const tagOpts = useMemo(
    () =>
      fastSort(tags.map((t) => ({label: t, value: t}))).asc((t) =>
        t.value?.toLowerCase(),
      ),
    [tags],
  );
  const renderDateRange = () => (
    <DateRangePopover
      size="sm"
      isActive={createdAtFrom || createdAtTo ? true : false}
      selectedFrom={createdAtFrom ? parseISO(createdAtFrom) : null}
      selectedTo={createdAtTo ? parseISO(createdAtTo) : null}
      setSelectedFrom={(date) =>
        effs.updateQuery({
          'page[number]': 1,
          'filter[created_at_from]': date
            ? formatISO(date, {representation: 'date'})
            : null,
        })
      }
      setSelectedTo={(date) =>
        effs.updateQuery({
          'page[number]': 1,
          'filter[created_at_to]': date
            ? formatISO(date, {representation: 'date'})
            : null,
        })
      }
    />
  );

  const renderSelects = () => (
    <>
      <FormControl minW="12rem">
        <FormLabel>Tila</FormLabel>
        <ReactSelect
          standalone
          isMulti
          inline
          value={query['filter[state]']}
          onChange={(val) =>
            effs.updateQuery({
              'filter[state]': val,
              'page[number]': 1,
            })
          }
          options={
            isRepairer ? stateOpts.filter((opt) => opt.value !== 'notice') : stateOpts
          }
          variant="withLabel"
          placeholder="Valitse..."
          isClearable
        />
      </FormControl>

      <FormControl minW="10rem">
        <FormLabel>Kiireisyys</FormLabel>
        <Select
          value={query['filter[affects_drivability]']}
          onChange={(e) =>
            effs.updateQuery({
              'filter[affects_drivability]': e.target.value,
              'page[number]': 1,
            })
          }
          variant="withLabel"
          placeholder="Valitse..."
        >
          <option value="false">Ei vaikuta ajoon</option>
          <option value="true">Vaikuttaa ajoon</option>
        </Select>
      </FormControl>
    </>
  );

  const renderFilters = () => (
    <>
      <FormControl>
        <FormLabel>Otsikko</FormLabel>
        <InputGroup>
          <Input
            value={filterTitle}
            placeholder="Hakusana"
            onChange={(e) => setFilterTitle(e.target.value)}
          />
          <InputRightElement pointerEvents="none" color="gray.600">
            <SearchIcon />
          </InputRightElement>
        </InputGroup>
      </FormControl>
      <FormControl minW="15rem">
        <FormLabel>Tarkenne</FormLabel>
        <ReactSelect
          standalone
          inline
          value={query['filter[tags]']}
          onChange={(val) =>
            effs.updateQuery({
              'filter[tags]': val,
              'page[number]': 1,
            })
          }
          options={tagOpts}
          variant="withLabel"
          placeholder="Valitse..."
          isClearable
        />
      </FormControl>
      <FormControl>
        <FormLabel>Tyyppi</FormLabel>
        <Select
          value={query['filter[type]']}
          onChange={(e) =>
            effs.updateQuery({
              'filter[type]': e.target.value,
              'page[number]': 1,
            })
          }
          variant="withLabel"
          placeholder="Valitse..."
        >
          <option value="fault">Vikailmoitus</option>
          <option value="damage">Vahinkoilmoitus</option>
        </Select>
      </FormControl>
    </>
  );

  return (
    <Tabs index={tabIndex} onChange={(index) => setTabIndex(index)}>
      <PageHeader
        title={
          <Breadcrumbs location={location} activeCrumbName={`Ilmoitus (${faultId})`} />
        }
      >
        <TabsHorizontalScroll>
          <TabList>
            <Tab>Ilmoitus</Tab>
            <Tab whiteSpace="nowrap">{`Auton ilmoitukset (${vehicleFaultsPagination.total})`}</Tab>
            <Tab whiteSpace="nowrap">
              {`Yhdistetyt ilmoitukset (${
                fault?.parent ? 1 : fault?.children?.length || 0
              })`}
            </Tab>
          </TabList>
        </TabsHorizontalScroll>
      </PageHeader>

      <Content>
        {loading ? (
          <Center>
            <Spinner size="xl" />
          </Center>
        ) : (
          <TabPanels>
            <TabPanel>
              <SimpleGrid columns={{lg: 2}} spacing="5">
                <Stack spacing="5" marginBottom="auto">
                  <Card>
                    <CardHeader
                      isMain
                      action={
                        <IconButton
                          onClick={() => effs.openEditFaultModal()}
                          variant="primary-outline"
                          size="sm"
                          icon={<PenIcon />}
                        />
                      }
                    >
                      {fault.title}
                    </CardHeader>

                    <Box mx={5} mb={5}>
                      {fault.description}
                    </Box>

                    <List isHorizontal={!isSm} marginBottom="5">
                      <ListItem isVertical={!isSm} title="Auto">
                        <Tag>
                          <TagLeftIcon as={BusIcon} />
                          <TagLabel>{fault.vehicle?.number}</TagLabel>
                        </Tag>
                      </ListItem>

                      <ListItem isVertical={!isSm} title="Ilmoittaja">
                        {fault.user ? (
                          <Button
                            as={isAdmin ? RouterLink : undefined}
                            to={isAdmin ? `/users/${fault.user.id}` : undefined}
                            variant="primary-link"
                            leftIcon={<PersonIcon />}
                            fontSize="sm"
                          >
                            {fault.user.name}
                          </Button>
                        ) : (
                          '-'
                        )}
                      </ListItem>

                      <ListItem isVertical={!isSm} title="Tyyppi">
                        <Tag variant="outline">{types[fault.type]}</Tag>
                      </ListItem>
                    </List>
                  </Card>

                  <Card>
                    <CardHeader>Korjauksen tila</CardHeader>
                    <RadioButtonGroup
                      defaultValue={fault.state}
                      onChange={(state) => effs.updateFault({state})}
                      name="state"
                      options={
                        isRepairer
                          ? stateOpts.filter((opt) => opt.value !== 'notice')
                          : stateOpts
                      }
                      spacing={2}
                      paddingX={5}
                      paddingY={4}
                      size="sm"
                      disabled={processing}
                      direction={isXs ? 'column' : 'row'}
                    />
                  </Card>

                  <Card>
                    <CardHeader>Korjauksen kiireellisyys</CardHeader>

                    <RadioButtonGroup
                      defaultValue={fault.affects_drivability ? 'yes' : 'no'}
                      onChange={(affectsDrivability) =>
                        effs.updateFault({
                          affects_drivability:
                            affectsDrivability === 'yes' ? true : false,
                        })
                      }
                      name="affectsDrivability"
                      options={[
                        {
                          value: 'no',
                          label: 'Ei vaikuta ajoon',
                        },
                        {
                          value: 'yes',
                          label: 'Vaikuttaa ajoon',
                        },
                      ]}
                      spacing={2}
                      paddingX={5}
                      paddingY={4}
                      size="sm"
                      disabled={processing}
                    />
                  </Card>

                  <Card>
                    <CardHeader>Tarkenteet</CardHeader>
                    <Box padding="5">
                      <ReactSelect
                        standalone
                        creatable
                        isMulti
                        withLabel={false}
                        isClearable={false}
                        menuPosition="fixed"
                        formatCreateLabel={(value) => `Lisää "${value}"`}
                        noOptionsMessage={() =>
                          'Ei tarkenteita. Lisää uusi kirjoittamalla.'
                        }
                        placeholder="Valitse tai lisää uusi kirjoittamalla..."
                        onChange={(val) => effs.updateFault({tags: val || []})}
                        options={tagOpts}
                        defaultValue={
                          Array.isArray(fault.tags)
                            ? fault.tags.map((t) => ({value: t, label: t}))
                            : []
                        }
                      />
                    </Box>
                  </Card>
                </Stack>

                <Stack spacing="5" marginBottom="auto">
                  <Card>
                    <CardHeader
                      action={
                        <IconButton
                          onClick={() => effs.toggleAddAttachmentModal()}
                          variant="primary-outline"
                          size="sm"
                          icon={<PlusIcon />}
                        />
                      }
                    >
                      Liitteet
                    </CardHeader>

                    <FileList>
                      {fault.documents?.length ? (
                        fault.documents.map((doc) => (
                          <File
                            key={doc.id}
                            type={doc.filetype}
                            modified={doc.updated_at}
                            onOpen={() =>
                              doc.url ? window.open(doc.url, '_blank') : null
                            }
                            onDelete={() => effs.removeAttachment(doc.id)}
                            hideEdit
                          >
                            {doc.title}
                          </File>
                        ))
                      ) : (
                        <Text p={5}>Ei liitteitä</Text>
                      )}
                    </FileList>
                  </Card>

                  <Card>
                    <CardHeader
                      action={
                        <IconButton
                          onClick={() => effs.toggleAddLogModal()}
                          variant="primary-outline"
                          size="sm"
                          icon={<PlusIcon />}
                        />
                      }
                    >
                      Toimintaloki
                    </CardHeader>
                    <ActivityLog logs={fault.logs} />
                  </Card>
                </Stack>
              </SimpleGrid>
            </TabPanel>

            <TabPanel>
              <TableHeader>
                <HStack spacing={4}>
                  <Checkbox
                    size="lg"
                    colorScheme="gray"
                    isChecked={
                      !!vehicleFaults.length &&
                      all((f) => includes(f.id, selection), vehicleFaults)
                    }
                    onClick={(e) => e.stopPropagation()}
                    onChange={(e) =>
                      effs.selectFaults(
                        vehicleFaults.map((f) => ({
                          id: f.id,
                          selected: e.target.checked,
                        })),
                      )
                    }
                  />
                  <Text fontWeight="bold" fontSize="sm" textTransform="uppercase">
                    {`${vehicleFaultsPagination.total} ilmoitusta`}
                  </Text>
                </HStack>

                <HStack spacing={2}>
                  <Box>{renderDateRange()}</Box>
                  <Popover>
                    <PopoverTrigger>
                      <Box marginLeft="1">
                        <IconButton
                          size="sm"
                          icon={<FilterIcon />}
                          variant={hasFilters ? 'primary' : 'unselected'}
                          mr={2}
                        />
                      </Box>
                    </PopoverTrigger>
                    <Portal>
                      <PopoverContent>
                        <PopoverArrow />
                        <PopoverBody p={5}>
                          <Stack spacing={2}>
                            {renderSelects()}
                            {renderFilters()}
                          </Stack>
                          <Button
                            onClick={() => effs.updateQuery(initialState.query)}
                            size="lg"
                            leftIcon={<CloseIcon />}
                            variant="link"
                            mt={5}
                          >
                            Tyhjennä
                          </Button>
                        </PopoverBody>
                      </PopoverContent>
                    </Portal>
                  </Popover>
                  <MassEditButton
                    selection={selection}
                    updateFaults={effs.updateFaultsState}
                    removeFaults={effs.removeFaults}
                  />
                </HStack>
              </TableHeader>

              {vehicleFaultsLoading ? (
                <Center>
                  <Spinner size="xl" />
                </Center>
              ) : !vehicleFaults.length ? (
                <Card>
                  <Text p={5}>Ei ilmoituksia</Text>
                </Card>
              ) : (
                <>
                  <Table>
                    {vehicleFaults.map((f) => (
                      <FaultRow
                        key={f.id}
                        fault={f}
                        referrerUrl={getReferrerUrl(location.search)}
                        onClick={() => setTabIndex(0)}
                        isAdmin={isAdmin}
                        isSelected={includes(f.id, selection)}
                        select={effs.selectFaults}
                      />
                    ))}
                  </Table>
                  <Paginator
                    totalPages={vehicleFaultsPagination.last_page}
                    currentPage={vehicleFaultsPagination.current_page}
                    perPage={vehicleFaultsPagination.per_page}
                    onPageSelect={(pageNum) =>
                      effs.updateQuery({'page[number]': pageNum})
                    }
                    disabled={vehicleFaultsLoading}
                  />
                </>
              )}
            </TabPanel>
            <TabPanel>
              {fault.parent ? (
                <>
                  <Flex mt={{md: '-5'}} mb={3} mx={3}>
                    <Text
                      fontWeight="bold"
                      fontSize="sm"
                      textTransform="uppercase"
                      mr={4}
                    >
                      Yhdistetty pääilmoitukseen
                    </Text>
                    <Button
                      onClick={() => effs.removeParent()}
                      size="xs"
                      variant="primary"
                      leftIcon={<CloseIcon />}
                    >
                      Poista yhdistys
                    </Button>
                  </Flex>
                  <FaultRow
                    key={fault.parent.id}
                    fault={fault.parent}
                    referrerUrl={getReferrerUrl(location.search)}
                    onClick={() => setTabIndex(0)}
                    isAdmin={isAdmin}
                    showSelect={false}
                    showVehicle={false}
                    showId={true}
                  />
                </>
              ) : (
                <>
                  <FormControl mt={{md: '-5'}} mb={5}>
                    <FormLabel>Yhdistä ilmoitus</FormLabel>
                    <ReactSelect
                      value={null}
                      standalone
                      isClearable
                      getOptionValue={(opt) => opt.id}
                      getOptionLabel={(opt) => `#${opt.id} ${opt.title}`}
                      useOptionsAsValues
                      async
                      placeholder="Hae otsikolla..."
                      loadOptions={(text, callback) => {
                        if (text !== '') {
                          effs.searchFaults({text, callback});
                        } else {
                          callback([]);
                        }
                      }}
                      onChange={(val) => {
                        if (val) {
                          effs.updateParent(val);
                        }
                      }}
                    />
                    {fault.vehicle ? (
                      <Text fontSize="xs" color="gray.700" mt={1}>
                        <Icon as={InfoIcon} color="gray.700" mr={1} />
                        Hakee ilmoituksia autolta {fault.vehicle.number}
                      </Text>
                    ) : null}
                  </FormControl>
                  <Table>
                    {(fault?.children || []).map((f) => (
                      <FaultRow
                        key={f.id}
                        fault={f}
                        referrerUrl={getReferrerUrl(location.search)}
                        onClick={() => setTabIndex(0)}
                        isAdmin={isAdmin}
                        showSelect={false}
                        showVehicle={false}
                        showId={true}
                      />
                    ))}
                  </Table>
                </>
              )}
            </TabPanel>
          </TabPanels>
        )}
      </Content>

      {editFaultModalOpen && (
        <EditFaultModal
          isOpen={editFaultModalOpen}
          onClose={() => effs.closeEditFaultModal()}
          onSubmit={effs.updateFault}
          processing={processing}
          vehicles={vehicles}
          defaultValues={editFaultDefaultVals}
        />
      )}

      {addAttachmentModalOpen && (
        <AddAttachmentModal
          isOpen={addAttachmentModalOpen}
          onClose={() => effs.toggleAddAttachmentModal()}
          onSubmit={effs.addAttachments}
          processing={processing}
        />
      )}

      {addLogModalOpen && (
        <AddLogModal
          isOpen={addLogModalOpen}
          onClose={() => effs.toggleAddLogModal()}
          onSubmit={effs.addFaultLog}
          processing={processing}
        />
      )}
    </Tabs>
  );
};

export default connect(
  applyState({
    ...pick(
      [
        'fault',
        'loading',
        'processing',
        'editFaultModalOpen',
        'addAttachmentModalOpen',
        'addLogModalOpen',
        'editFaultDefaultVals',
        'vehicleFaults',
        'vehicleFaultsPagination',
        'vehicleFaultsLoading',
        'selection',
        'query',
        'initialized',
        'hasFilters',
        'tags',
      ],
      sels,
    ),
    vehicles: commonSels.vehicles,
    isAdmin: commonSels.isAdmin,
    isRepairer: commonSels.isRepairer,
  }),
)(Fault);
