/* eslint-disable max-len */
import React from 'react';
import {Icon} from '@chakra-ui/react';

const FolderIcon = (props) => (
  <Icon
    viewBox="0 0 16 16"
    stroke="currentColor"
    fill="currentColor"
    strokeWidth="0"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15,3 L7.824,3 C7.69785227,3 7.58251418,2.92879023 7.526,2.81600993 L6.89466667,1.54934326 C6.7249917,1.21116381 6.37835446,0.998325442 6,1 L1,1 C0.44771525,1 0,1.44772518 0,2 L0,14 C0,14.5522947 0.44771525,15 1,15 L15,15 C15.5522847,15 16,14.5522947 16,14 L16,4 C16,3.44772518 15.5522847,3 15,3 Z"
    />
  </Icon>
);

export default FolderIcon;
