import React from 'react';
import {Button, SimpleGrid, Stack, Text, Flex, HStack, Box} from '@chakra-ui/react';
import {parseISO, format} from 'date-fns';
import fi from 'date-fns/locale/fi';
import {Card, CardHeader, List, ListItem, PenIcon} from 'components';
import {getShiftDurationStr, getPausesStr} from 'modules/drives/utils';
import {types as faultTypes} from 'dicts/faults';
import ReportBox from './drive-report-box';

const formatDateTime = (date) => {
  if (!date) return '';

  return `${format(parseISO(date), 'EEEEEE d.M.yyyy', {
    locale: fi,
  })} klo ${format(parseISO(date), 'HH:mm')}`;
};

const DriveTabReport = ({
  shift,
  openAddFaultModal,
  openShiftDriveExceptionModal,
  openShiftExceptionModal,
  removeShiftDriveException,
  removeFault,
  processing,
  openShiftReturnModal = () => {},
  showReports,
}) => {
  const shiftStartAt = shift.shift_start_at ? parseISO(shift.shift_start_at) : null;
  const shiftEndAt = shift.shift_end_at ? parseISO(shift.shift_end_at) : null;

  const shiftExceptionStartAt = shift.exception?.shift_start_at
    ? parseISO(shift.exception.shift_start_at)
    : null;
  const shiftExceptionEndAt = shift.exception?.shift_end_at
    ? parseISO(shift.exception.shift_end_at)
    : null;

  const shiftReturnStartAt = shift.return?.shift_start_at
    ? parseISO(shift.return.shift_start_at)
    : null;
  const shiftReturnEndAt = shift.return?.shift_end_at
    ? parseISO(shift.return.shift_end_at)
    : null;

  const pausesStr = getPausesStr(shift.pauses);
  const shiftExceptionPausesStr = getPausesStr(shift.exception?.pauses);

  // reporting shift disabed until the shift has ended
  const disableReporting = !!shiftEndAt && shiftEndAt > new Date();

  // report editing disabled after shift return has been exported
  const disableEditing = !!shift.return?.exported_at;

  return (
    <SimpleGrid columns={{md: 2}} spacing="5">
      {shift.drive?.type === 'order' ? (
        <Card>
          <CardHeader isMain>Ajoraportti</CardHeader>
          {shift.return?.is_valid ? (
            <Box pb="5">
              <List mb="8">
                <ListItem title="Aloitus">
                  {shiftReturnStartAt &&
                  shiftReturnStartAt.getTime() !== shiftStartAt?.getTime() ? (
                    <HStack spacing={2}>
                      <Text fontWeight="bold">{format(shiftReturnStartAt, 'HH:mm')}</Text>
                      <Text textStyle="caption" color="gray.600" textDecor="line-through">
                        {shiftStartAt ? format(shiftStartAt, 'HH:mm') : ''}
                      </Text>
                    </HStack>
                  ) : (
                    <Text fontWeight="bold">
                      {shiftStartAt ? format(shiftStartAt, 'HH:mm') : ''}
                    </Text>
                  )}
                </ListItem>
                <ListItem title="Lopetus">
                  {shiftReturnEndAt &&
                  shiftReturnEndAt.getTime() !== shiftEndAt?.getTime() ? (
                    <HStack spacing={2}>
                      <Text fontWeight="bold">{format(shiftReturnEndAt, 'HH:mm')}</Text>
                      <Text textStyle="caption" color="gray.600" textDecor="line-through">
                        {shiftEndAt ? format(shiftEndAt, 'HH:mm') : ''}
                      </Text>
                    </HStack>
                  ) : (
                    <Text fontWeight="bold">
                      {shiftEndAt ? format(shiftEndAt, 'HH:mm') : ''}
                    </Text>
                  )}
                </ListItem>
                <ListItem title="Vuoron kesto">
                  <Text fontWeight="bold">
                    {getShiftDurationStr({
                      end: shiftReturnEndAt || shiftEndAt,
                      start: shiftReturnStartAt || shiftStartAt,
                    })}
                  </Text>
                </ListItem>
                <ListItem title="Tauot">
                  <Text fontWeight="bold">{pausesStr}</Text>
                </ListItem>
                <ListItem title="Ajon kilometrit">
                  <Text fontWeight="bold">
                    {`${
                      Number(shift.return.odometer_to) -
                      Number(shift.return.odometer_from)
                    } km`}
                  </Text>
                </ListItem>
              </List>
              <Flex px="5" justifyContent="space-between">
                <Text fontSize="xs" color="gray.700">
                  Ajo raportoitu {formatDateTime(shift.return.created_at)}
                  {disableEditing && (
                    <>
                      <br />
                      Viety Transplaniin {formatDateTime(shift.return.exported_at)}
                    </>
                  )}
                </Text>
                <Button
                  onClick={() => openShiftReturnModal()}
                  leftIcon={<PenIcon />}
                  variant="primary-link"
                  disabled={disableEditing}
                >
                  Muokkaa
                </Button>
              </Flex>
            </Box>
          ) : (
            <Box px="5" pb="5">
              <Text>Ajoa ei ole raportoitu.</Text>
              {disableReporting && shift.shift_end_at ? (
                <Text fontSize="xs" color="gray.700">
                  Ajon voi raportoida alkaen:{' '}
                  <strong>{formatDateTime(shift.shift_end_at)}</strong>
                </Text>
              ) : null}
              <Button
                mt="5"
                w="100%"
                onClick={() => openShiftReturnModal()}
                variant="primary"
                disabled={disableReporting}
              >
                Raportoi ajo
              </Button>
            </Box>
          )}
        </Card>
      ) : (
        <Card>
          <CardHeader isMain>Työvuoro</CardHeader>
          <List marginBottom="5">
            <ListItem title="Aloitus">
              {shiftExceptionStartAt &&
              shiftExceptionStartAt.getTime() !== shiftStartAt?.getTime() ? (
                <HStack spacing={2}>
                  <Text fontWeight="bold">{format(shiftExceptionStartAt, 'HH:mm')}</Text>
                  <Text textStyle="caption" color="gray.600" textDecor="line-through">
                    {shiftStartAt ? format(shiftStartAt, 'HH:mm') : ''}
                  </Text>
                </HStack>
              ) : (
                <Text fontWeight="bold">
                  {shiftStartAt ? format(shiftStartAt, 'HH:mm') : ''}
                </Text>
              )}
            </ListItem>
            <ListItem title="Lopetus">
              {shiftExceptionEndAt &&
              shiftExceptionEndAt.getTime() !== shiftEndAt?.getTime() ? (
                <HStack spacing={2}>
                  <Text fontWeight="bold">{format(shiftExceptionEndAt, 'HH:mm')}</Text>
                  <Text textStyle="caption" color="gray.600" textDecor="line-through">
                    {shiftEndAt ? format(shiftEndAt, 'HH:mm') : ''}
                  </Text>
                </HStack>
              ) : (
                <Text fontWeight="bold">
                  {shiftEndAt ? format(shiftEndAt, 'HH:mm') : ''}
                </Text>
              )}
            </ListItem>
            <ListItem title="Työaika">
              <Text fontWeight="bold">
                {getShiftDurationStr({
                  end: shiftExceptionEndAt || shiftEndAt,
                  start: shiftExceptionStartAt || shiftStartAt,
                })}
              </Text>
            </ListItem>
            <ListItem title="Tauot">
              {shift.exception?.pauses && shiftExceptionPausesStr !== pausesStr ? (
                <HStack spacing={2}>
                  <Text fontWeight="bold">{shiftExceptionPausesStr}</Text>
                  <Text textStyle="caption" color="gray.600" textDecor="line-through">
                    {pausesStr}
                  </Text>
                </HStack>
              ) : (
                <Text fontWeight="bold">{pausesStr}</Text>
              )}
            </ListItem>
            <Box paddingX="5">
              {shift.exception && (
                <Box bg="gray.50" p={4} borderRadius="md" mb={4}>
                  <Text whiteSpace="pre-wrap" fontSize="xs">
                    {shift.exception.description}
                  </Text>
                </Box>
              )}
              <Flex justifyContent="flex-end">
                <Button
                  onClick={() => openShiftExceptionModal()}
                  leftIcon={<PenIcon />}
                  variant="primary-link"
                >
                  Muokkaa
                </Button>
              </Flex>
            </Box>
          </List>
        </Card>
      )}
      {showReports && (
        <Card>
          <CardHeader>Muut raportit</CardHeader>
          <Stack spacing="2" padding="5">
            <Button onClick={() => openShiftDriveExceptionModal()} variant="primary">
              {shift.drive?.type === 'order'
                ? 'Ilmoita poikkeus'
                : 'Ilmoita muu poikkeus'}
            </Button>
            <Button onClick={() => openAddFaultModal()} variant="primary">
              Vika-/vahinkoilmoitus
            </Button>
          </Stack>
          <Stack px="5">
            {shift.drive_exceptions?.map((e) => (
              <ReportBox
                key={e.id}
                type="Poikkeus"
                description={e.description}
                documents={e.documents}
                onDelete={() => removeShiftDriveException(e.id)}
                processing={processing}
              />
            ))}
            {shift.faults?.map((f) => (
              <ReportBox
                key={f.id}
                type={faultTypes[f.type] || 'Vika-/vahinkoilmoitus'}
                title={f.title}
                description={f.description}
                documents={f.documents}
                onDelete={() => removeFault(f.id)}
                processing={processing}
              />
            ))}
          </Stack>
        </Card>
      )}
    </SimpleGrid>
  );
};

export default DriveTabReport;
