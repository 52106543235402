import AttachesBase from '@editorjs/attaches';
import Uploader from './attaches-uploader';

/**
 * Attaches-plugin doesn't have support for custom uploading by default
 * or support for readOnly mode. Monkey patch these features.
 */
export default class Attaches extends AttachesBase {
  /**
   * @param {AttachesToolData} data
   * @param {Object} config
   * @param {API} api
   * @param {boolean} tool.readOnly
   */
  constructor({data, config, api, readOnly}) {
    super({data: data, config: config, api: api});

    this.readOnly = readOnly;

    super.uploader = new Uploader({
      config: config,
      onUpload: (response) => super.onUpload(response),
      onError: (error) => super.uploadingFailed(error),
    });
  }

  /**
   * Add readOnly support
   */
  static get isReadOnlySupported() {
    return true;
  }

  showFileData() {
    super.showFileData();

    if (this.nodes.title && this.readOnly) {
      this.nodes.title.setAttribute('contenteditable', false);
    }
  }
}
