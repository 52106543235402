import React, {useState, useEffect} from 'react';
import {
  Input,
  InputGroup,
  InputRightElement,
  Text,
  Icon,
  Flex,
  Box,
  IconButton,
  Spinner,
  Stack,
  Heading,
  Tag,
  Link,
} from '@chakra-ui/react';
import Downshift from 'downshift';
import {groupBy, prop, pick} from 'ramda';
import {parseISO, format} from 'date-fns';
import {connect} from 'react-redux';
import {applyState} from 'utils/redux';
import services from 'services';
import {decorateWithNotificationsEff} from 'io/app';
import {guardHandled} from 'io/errors';
import {useDebounce} from 'utils/react';
import {search} from 'modules/common/io';
import commonSels from 'modules/common/selectors';
import {getSuggestionGroupTitle, getSuggestionItemTitle} from 'modules/common/utils';
import {getShiftDateStr} from 'modules/drives/utils';
import docEditorEffs from 'modules/documentEditor/effects';
import {types as driveTypes} from 'dicts/drives';
import roles from 'dicts/roles';
import {SearchIcon, CloseIcon} from 'components/icons';

const history = services.get('history');

const SuggestionItem = ({item, isSelected, isActive, disabled, isDriver, ...props}) => {
  const title = getSuggestionItemTitle(item);

  return (
    <Box
      px={4}
      py={3}
      borderRadius="base"
      bg={(isSelected || isActive) && !disabled ? 'gray.100' : 'gray.50'}
      cursor={disabled ? 'auto' : 'pointer'}
      _hover={{bg: disabled ? 'gray.50' : 'gray.100'}}
      {...props}
    >
      {item.groupType === 'user' ? (
        <>
          <Box
            display={{base: 'flex', md: 'grid'}}
            gridTemplateColumns="auto min-content"
            gridColumnGap="2"
            flexDirection="column"
            alignItems="flex-start"
            flexWrap="wrap"
            gridAutoFlow="column"
          >
            <Heading size="sm" overflow="hidden">
              {title}
            </Heading>
            <Tag variant="outline">{roles[item.role] || item.role}</Tag>
          </Box>
          <Text fontSize="xs">{item.email}</Text>
        </>
      ) : item.groupType === 'client' ? (
        <>
          <Heading size="sm">{title}</Heading>
          {!isDriver && (
            <>
              <Text fontSize="xs">{item.department}</Text>
              {item.phone && (
                <Box fontSize="xs">
                  <Link href={`tel:${item.phone}`}>{item.phone}</Link>
                </Box>
              )}
              <Text fontSize="xs">{item.address}</Text>
              <Text fontSize="xs">
                {item.zip ? `${item.zip} ` : ''}
                {item.city}
              </Text>
            </>
          )}
          {item.contacts?.map((c) => (
            <Stack spacing={0} key={c.id} mt={4}>
              <Heading mb={1} size="xs">
                {c.name}
              </Heading>
              {c.phone && (
                <Link href={`tel:${c.phone}`} fontSize="xs">
                  {c.phone}
                </Link>
              )}
              {c.email && !isDriver && (
                <Link href={`mailto:${c.email}`} fontSize="xs">
                  {c.email}
                </Link>
              )}
            </Stack>
          ))}
        </>
      ) : item.groupType === 'document' ? (
        <>
          <Heading size="sm">{title}</Heading>
          <Text fontSize="xs">{`Muokattu: ${format(
            parseISO(item.updated_at),
            'd.M.yyyy',
          )}`}</Text>
        </>
      ) : item.groupType === 'shift' ? (
        <>
          <Box
            display={{base: 'flex', md: 'grid'}}
            gridTemplateColumns="auto min-content"
            gridColumnGap="2"
            flexDirection="column"
            alignItems="flex-start"
            flexWrap="wrap"
            gridAutoFlow="column"
          >
            <Heading size="sm" overflow="hidden">
              {title}
            </Heading>
            {item.drive?.type && (
              <Tag variant="outline">
                {driveTypes[item.drive.type] || item.drive.type}
              </Tag>
            )}
          </Box>
          <Text fontSize="xs">{getShiftDateStr(item, false)}</Text>
        </>
      ) : null}
    </Box>
  );
};

const SuggestionGroup = ({
  group,
  prevGroups,
  getItemProps,
  selectedItem,
  highlightedIndex,
  isDriver,
}) => {
  const maxItems = 3;
  const groupItems = group.items.slice(0, maxItems);
  // for keyboard support to work, indexes must be numbers in ascending order
  // because our results are grouped, we need to get total count of items in all previous groups and add that to the index
  const indexModifier = prevGroups
    .map((g) => g.items.slice(0, maxItems).length)
    .reduce((a, b) => a + b, 0);

  return (
    <Stack px={4} pt={4} _last={{pb: 4}} spacing={2}>
      <Heading size="sm" mb={2}>
        {getSuggestionGroupTitle(group.type)}
      </Heading>
      {groupItems.map((item, i) => {
        const index = i + indexModifier;
        return (
          <SuggestionItem
            key={index}
            item={{...item, groupType: group.type}}
            {...getItemProps({
              item: {...item, groupType: group.type},
              index,
              isActive: highlightedIndex === index,
              isSelected: selectedItem === item,
              disabled: group.type === 'client',
              isDriver: isDriver,
            })}
          />
        );
      })}
    </Stack>
  );
};

const GlobalSearch = ({isDriver, isRepairer, isSubcontractor}) => {
  const [suggestions, setSuggestions] = useState([]);
  const [value, setValue] = useState('');
  const [loading, setLoading] = useState(true);

  const fetchSuggestions = guardHandled(async (str) => {
    if (str) {
      const results = await decorateWithNotificationsEff(
        {id: 'search', failureStyle: 'warning'},
        search({query: str}),
      );
      if (results.length) {
        const grouped = groupBy(prop('type'), results);
        const formatted = Object.entries(grouped).map(([type, vals]) => ({
          type,
          items: vals.map((x) => x.item),
        }));

        if (isSubcontractor) {
          // subcontractors should see shifts only
          setSuggestions(formatted.filter((g) => g.type === 'shift'));
        } else {
          setSuggestions(formatted);
        }
      } else {
        setSuggestions([]);
      }
    } else {
      setSuggestions([]);
    }
    setLoading(false);
  });

  const debouncedValue = useDebounce(value, 500);

  useEffect(() => {
    fetchSuggestions(debouncedValue);
  }, [debouncedValue]);

  const handleInputValueChange = (inputValue) => {
    if (inputValue && inputValue !== value) {
      setLoading(true);
    }
    // keep current input value in our own state too, it's used to fetch suggestions whenever it changes
    setValue(inputValue);
  };

  const handleItemSelect = (item) => {
    if (!item) return;

    if (item.groupType === 'shift') {
      history.push(`/drives/${item.id}`);
    } else if (item.groupType === 'user') {
      history.push(`/users/${item.id}`);
    } else if (item.groupType === 'document') {
      if (item.filetype === 'document') {
        docEditorEffs.openPreview({id: item.id, type: 'document'});
      } else {
        window.open(item.url, '_blank');
      }
    }
  };

  // prettier-ignore
  const placeholder = isDriver ? 'Haku: ajo, asiakas, dokumentti'
    : isRepairer ? 'Haku: dokumentti'
    : 'Haku: ajo, henkilö, asiakas, dokumentti';

  return (
    <Downshift
      itemToString={getSuggestionItemTitle}
      onInputValueChange={handleInputValueChange}
      onSelect={handleItemSelect}
    >
      {({
        getInputProps,
        getMenuProps,
        getItemProps,
        isOpen,
        highlightedIndex,
        getRootProps,
        selectedItem,
        clearSelection,
      }) => (
        <Box
          position="relative"
          minWidth={{md: '400px'}}
          marginRight="auto"
          flex={{base: 1, md: 2 / 5}}
          {...getRootProps({})}
        >
          <InputGroup size="lg">
            <Input rounded="full" bg="gray.50" pt={0} {...getInputProps({placeholder})} />
            <InputRightElement>
              {loading ? (
                <Spinner size="sm" color="gray.600" />
              ) : selectedItem ? (
                <IconButton
                  icon={<CloseIcon color="gray.600" />}
                  variant="unstyled"
                  onClick={clearSelection}
                />
              ) : (
                <Icon as={SearchIcon} color="gray.600" />
              )}
            </InputRightElement>
          </InputGroup>
          <Flex
            direction="column"
            position="absolute"
            top="100%"
            left={0}
            right={0}
            mt={2}
            maxHeight="27rem"
            overflow="auto"
            layerStyle="box"
            borderRadius="base"
            zIndex="999"
            {...getMenuProps()}
          >
            {isOpen && suggestions.length ? (
              suggestions.map((group, index) => (
                <SuggestionGroup
                  key={group.type}
                  group={group}
                  prevGroups={suggestions.slice(0, index)}
                  getItemProps={getItemProps}
                  selectedItem={selectedItem}
                  highlightedIndex={highlightedIndex}
                  isDriver={isDriver}
                />
              ))
            ) : isOpen && !suggestions.length && !loading ? (
              <Text p={5} fontSize="sm">
                Ei hakutuloksia
              </Text>
            ) : null}
          </Flex>
        </Box>
      )}
    </Downshift>
  );
};

export default connect(
  applyState(pick(['isDriver', 'isRepairer', 'isSubcontractor'], commonSels)),
)(GlobalSearch);
