import React from 'react';
import {Breadcrumb, BreadcrumbItem, BreadcrumbLink} from '@chakra-ui/react';
import {ChevronRightIcon} from 'components';

const FolderBreadcrumbs = ({crumbs = [], activeCrumb, setActive}) => {
  return (
    <Breadcrumb separator={<ChevronRightIcon w="0.7em" h="0.7em" color="violet.700" />}>
      <BreadcrumbItem>
        <BreadcrumbLink onClick={() => setActive(null)}>Dokumentit</BreadcrumbLink>
      </BreadcrumbItem>
      {crumbs.map((c) => {
        const isActive = activeCrumb && activeCrumb.id === c.id;
        return (
          <BreadcrumbItem
            isCurrentPage={isActive}
            key={`breadcrumb.${c.id}`}
            onClick={() => (isActive ? null : setActive(c))}
          >
            <BreadcrumbLink>{c.title}</BreadcrumbLink>
          </BreadcrumbItem>
        );
      })}
    </Breadcrumb>
  );
};

export default FolderBreadcrumbs;
