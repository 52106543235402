import React, {useRef} from 'react';
import {
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Button,
  HStack,
  Flex,
  Spacer,
  ModalCloseButton,
  Heading,
  Box,
} from '@chakra-ui/react';
import {parseISO, format} from 'date-fns';
import {Editor} from 'components';

const DocumentPreview = ({isOpen, onClose, document, onEdit, hideEdit}) => {
  const modifiedStr =
    document && document.updated_at
      ? format(parseISO(document.updated_at), 'd.M.yyyy HH:mm')
      : null;

  const editorRef = useRef(null);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="6xl" scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent>
        <ModalBody padding="0">
          <Flex
            bg="gray.100"
            paddingX="5"
            paddingY="6"
            alignItems="center"
            borderTopLeftRadius="md"
            borderTopRightRadius="md"
            position="sticky"
            top={0}
            left={0}
            right={0}
            zIndex={2}
          >
            <HStack spacing="5">
              <Text fontSize="sm">Muokattu: {modifiedStr}</Text>

              {!hideEdit && (
                <Button
                  variant="primary-link"
                  textDecor="underline"
                  onClick={() => onEdit()}
                >
                  Muokkaa
                </Button>
              )}
            </HStack>
            <Spacer />
            <ModalCloseButton />
          </Flex>
          <Box py={6}>
            <Heading as="h1" fontSize="2rem" px={10} pb={6}>
              {document?.title}
            </Heading>
            <Editor instanceRef={editorRef} data={document?.content} readOnly={true} />
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default DocumentPreview;
