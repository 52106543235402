export default {
  sizes: {
    lg: {
      fontSize: 'sm',
    },
    sm: {
      borderRadius: 'base',
    },
  },
  variants: {
    outline: {
      borderColor: 'gray.300',
      _hover: {
        borderColor: 'gray.400',
      },
      _focus: {
        borderColor: 'gray.600',
        boxShadow: 'md',
      },
    },
    withLabel: {
      paddingTop: 5,
      border: '1px solid',
      bg: 'gray.50',
      borderColor: 'gray.300',
      _hover: {
        borderColor: 'gray.400',
      },
      _invalid: {
        borderColor: 'red.500',
        boxShadow: '0 0 0 1px #ef4444',
      },
      _focus: {
        borderColor: 'gray.600',
        boxShadow: 'md',
      },
    },
  },
  defaultProps: {
    size: 'lg',
  },
};
