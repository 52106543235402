import React from 'react';
import {Stack, Flex, Heading} from '@chakra-ui/react';
import {headerHeight, headerHeightMobile, sidebarWidth} from 'constants/styles';

const PageHeader = ({title, actions, children, ...rest}) => {
  return (
    <Stack
      as="header"
      bg="white"
      spacing="5"
      paddingX={{base: 4, md: 8, xl: 16}}
      paddingY={{base: 3, md: 5}}
      maxW={{base: '100vw', md: `calc(100vw - ${sidebarWidth})`}}
      zIndex="8"
      position="sticky"
      top={{base: headerHeightMobile, md: headerHeight}}
      borderBottom="1px solid"
      borderColor="gray.100"
      {...rest}
    >
      <Flex alignItems="center">
        <Heading as="div" size="md" flex="1" overflow="hidden" mr={1}>
          {title}
        </Heading>

        {actions}
      </Flex>

      {children}
    </Stack>
  );
};

export default PageHeader;
