import {parseISO} from 'date-fns';
import {sort} from 'fast-sort';

export const formatFolderEditorOutput = ({title, roles, folderId}) => {
  return {
    title,
    acl: {roles: roles || []},
    folder_id: folderId,
  };
};

export const formatDocumentEditorOutput = ({
  id,
  title,
  releaseAt,
  expiresAt,
  roles,
  folderId,
  content,
  client,
  vehicleIds,
  drives,
  tags,
}) => {
  return {
    id,
    title,
    release_at: releaseAt,
    expires_at: expiresAt,
    acl: {roles: roles || []},
    folder_id: folderId,
    type: 'document',
    content: content ? JSON.stringify(content) : '',
    client_id: client ? client.id : null,
    vehicle_ids: vehicleIds || [],
    drive_ids: drives ? drives.map((d) => d.id) : [],
    tags: tags || [],
  };
};

export const formatFolderEditorDefaultVals = (folder) => {
  if (!folder) {
    return {
      title: '',
      roles: [],
    };
  }

  return {
    title: folder.title,
    roles: folder.acl?.roles || [],
  };
};

export const formatDocumentEditorDefaultVals = (document) => {
  if (!document) {
    return {
      title: '',
      roles: [],
      releaseAt: null,
      expiresAt: null,
      tags: [],
    };
  }

  return {
    title: document.title,
    roles: document.acl?.roles || [],
    releaseAt: document.release_at ? parseISO(document.release_at) : null,
    expiresAt: document.expires_at ? parseISO(document.expires_at) : null,
    client: document.client,
    vehicleIds: document.vehicles ? document.vehicles.map((v) => v.id) : [],
    drives: document.drives || [],
    tags: document.tags || [],
  };
};

export const formatDocumentUploaderDefaultVals = (document) => {
  if (!document) {
    return {
      roles: [],
      tags: [],
      releaseAt: null,
      expiresAt: null,
    };
  }

  return {
    roles: document.acl?.roles || [],
    client: document.client,
    vehicleIds: document.vehicles ? document.vehicles.map((v) => v.id) : [],
    drives: document.drives || [],
    tags: document.tags || [],
    releaseAt: document.release_at ? parseISO(document.release_at) : null,
    expiresAt: document.expires_at ? parseISO(document.expires_at) : null,
  };
};

export const formatDocumentUploaderOutput = ({
  roles,
  folderId,
  files,
  client,
  vehicleIds,
  drives,
  tags,
  releaseAt,
  expiresAt,
}) => {
  return {
    acl: {roles: roles || []},
    folder_id: folderId,
    type: 'document',
    path: files.map((f) => f.path),
    client_id: client ? client.id : null,
    vehicle_ids: vehicleIds || [],
    drive_ids: drives ? drives.map((d) => d.id) : [],
    tags: tags || [],
    release_at: releaseAt,
    expires_at: expiresAt,
  };
};

export const sortByTitle = (list) =>
  sort(list).by({
    asc: (x) => x.title,
    comparer: new Intl.Collator(undefined, {numeric: true, sensitivity: 'base'}).compare, // natural sort
  });
