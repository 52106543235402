// Register service worker to display 'Add to homescreen' prompt
// The service worker doesn't actually do anything, because this app requires API to work and thus doesn't have offline capabilities

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.0/8 are considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/,
    ),
);

export function register() {
  if (
    process.env.NODE_ENV === 'production' &&
    !isLocalhost &&
    'serviceWorker' in navigator
  ) {
    const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;
    navigator.serviceWorker.register(swUrl);
  }
}
