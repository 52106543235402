import React from 'react';
import {Divider, HStack, Stack} from '@chakra-ui/react';

function List({isHorizontal = false, children, ...other}) {
  return isHorizontal ? (
    <HStack marginX="5" spacing="10" {...other}>
      {children}
    </HStack>
  ) : (
    <Stack divider={<Divider />} spacing="5" {...other}>
      {children}
    </Stack>
  );
}

export default List;
