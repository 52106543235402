import React, {useEffect} from 'react';
import {
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalHeader,
  Stack,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Button,
  HStack,
} from '@chakra-ui/react';
import {ReactSelect} from 'components';
import {useForm} from 'react-hook-form';
import roles from 'constants/roles';

const FolderEditor = ({
  isOpen,
  toggleModal,
  onSubmit,
  processing,
  defaultValues,
  isNew,
}) => {
  const {handleSubmit, errors, register, control, reset} = useForm({
    defaultValues,
  });

  // update form default values when defaultValues prop changes
  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  return (
    <Modal isOpen={isOpen} onClose={toggleModal} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader
          display="flex"
          flexDir="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Text fontWeight="bold" fontSize="sm" textTransform="uppercase">
            {isNew ? 'Uusi kansio' : 'Muokkaa kansiota'}
          </Text>
          <HStack spacing={4}>
            <Button variant="link" textDecor="underline" onClick={() => toggleModal()}>
              Peruuta
            </Button>
            <Button
              type="submit"
              form="folder-editor-form"
              variant="primary"
              isLoading={processing}
            >
              <span>Tallenna</span>
            </Button>
          </HStack>
        </ModalHeader>
        <ModalBody>
          <form onSubmit={handleSubmit(onSubmit)} id="folder-editor-form">
            <Stack spacing={4}>
              <FormControl isInvalid={errors.title}>
                <FormLabel>Kansion nimi</FormLabel>
                <Input
                  name="title"
                  type="text"
                  ref={register({required: 'Pakollinen kenttä'})}
                />
                <FormErrorMessage>
                  {errors.title && errors.title.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl>
                <FormLabel>Oikeudet</FormLabel>
                <ReactSelect
                  name="roles"
                  control={control}
                  options={roles}
                  isMulti
                  isClearable
                  getOptionValue={(opt) => opt.name}
                  getOptionLabel={(opt) => opt.title}
                  placeholder="Näytä kaikille"
                />
                <Text fontSize="xs" color="gray.700" marginTop="1">
                  Valitse käyttäjäryhmät kenelle dokumentti näytetään
                </Text>
              </FormControl>
            </Stack>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default FolderEditor;
