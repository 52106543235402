/* eslint-disable max-len */
import React from 'react';
import {Icon} from '@chakra-ui/react';

const PlusIcon = (props) => (
  <Icon
    viewBox="0 0 16 16"
    stroke="currentColor"
    fill="currentColor"
    strokeWidth="0"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1,8 C1,11.8659932 4.13400675,15 8,15 C11.8659932,15 15,11.8659932 15,8 C15,4.13400675 11.8659932,1 8,1 C4.1358145,1.00436082 1.00436082,4.1358145 1,8 Z M4.04347826,7.69565217 C4.04347826,7.35947885 4.31600059,7.08695652 4.65217391,7.08695652 L6.93478261,7.08695652 C7.01882594,7.08695652 7.08695652,7.01882594 7.08695652,6.93478261 L7.08695652,4.65217391 C7.08695652,4.31600059 7.35947885,4.04347826 7.69565217,4.04347826 L8.30434783,4.04347826 C8.64052115,4.04347826 8.91304348,4.31600059 8.91304348,4.65217391 L8.91304348,6.93478261 C8.91304348,7.01882594 8.98117406,7.08695652 9.06521739,7.08695652 L11.3478261,7.08695652 C11.6839994,7.08695652 11.9565217,7.35947885 11.9565217,7.69565217 L11.9565217,8.30434783 C11.9565217,8.64052115 11.6839994,8.91304348 11.3478261,8.91304348 L9.06521739,8.91304348 C8.98117406,8.91304348 8.91304348,8.98117406 8.91304348,9.06521739 L8.91304348,11.3478261 C8.91304348,11.6839994 8.64052115,11.9565217 8.30434783,11.9565217 L7.69565217,11.9565217 C7.35947885,11.9565217 7.08695652,11.6839994 7.08695652,11.3478261 L7.08695652,9.06521739 C7.08695652,8.98117406 7.01882594,8.91304348 6.93478261,8.91304348 L4.65217391,8.91304348 C4.31600059,8.91304348 4.04347826,8.64052115 4.04347826,8.30434783 L4.04347826,7.69565217 Z"
    />
  </Icon>
);

export default PlusIcon;
