import {prop} from 'ramda';
import services from 'services';
import {describeThrow} from 'utils/errors';
import msgs from 'dicts/messages';
import {driverRoles} from 'modules/common/constants';
import {defaultQuery} from './constants';

let httpJson = null;
services.waitFor('api').then((x) => (httpJson = x.httpJson));

export const getShifts = (query = {}) =>
  httpJson('get', '/shifts', {...defaultQuery, ...query}).catch(
    describeThrow(msgs.fetchFailed),
  );

export const getDrivers = () =>
  httpJson('get', '/users', {
    'filter[role]': [...driverRoles, 'repairer'],
    'page[size]': 999,
    sort: 'name',
  })
    .catch(describeThrow(msgs.fetchFailed))
    .then(prop('data'));

export const putMassUpdateShifts = (body) =>
  httpJson('put', '/shifts', {}, {body})
    .catch(describeThrow(msgs.saveFailed))
    .then(prop('data'));

export const getVacations = (query = {}) =>
  httpJson('get', '/vacations', {
    'filter[type]': 'vacation,sickLeave',
    include: 'user',
    ...query,
  }).catch(describeThrow(msgs.fetchFailed));
