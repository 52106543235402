/* eslint-disable max-len */
import React from 'react';
import {Icon} from '@chakra-ui/react';

const CheckIcon = (props) => (
  <Icon
    viewBox="0 0 16 16"
    stroke="currentColor"
    fill="currentColor"
    strokeWidth="0"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.6869834,2.14051804 C13.350365,1.89918742 12.8818471,1.97641564 12.6404921,2.3130166 L5.62305048,12.0969352 L3.28056997,9.75245471 C3.09113147,9.56292688 2.81497118,9.48886019 2.55611602,9.55815483 C2.29726086,9.62744948 2.09503708,9.82957796 2.02562043,10.0884004 C1.95620377,10.3472229 2.03014029,10.6234181 2.2195788,10.8129459 L5.18705411,13.7799212 C5.3446535,13.9315737 5.55978751,14.0082483 5.77777726,13.9904571 C5.99576701,13.9726659 6.19562125,13.8621222 6.32654463,13.686922 L13.859482,3.18700933 C14.1008126,2.85039089 14.0235844,2.38187304 13.6869834,2.14051804 Z"
    />
  </Icon>
);

export default CheckIcon;
