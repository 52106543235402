import {evolve} from 'ramda';
import {replace} from 'utils/objects';
import {parseNullableNumber} from 'utils/fields';
import {initialState} from './store';
import {states} from 'dicts/faults';

export const parseUrlQuery = (query) => {
  const vals = evolve(
    {
      'page[number]': Number,
      'filter[vehicle_id]': parseNullableNumber,
      'filter[state]': (str) => (str ? str.split(',') : []),
      'filter[tags]': (str) => (str ? str.split(',') : []),
    },
    query,
  );
  return replace(vals, initialState.query);
};

export const formatCreateFaultOutput = ({
  title,
  description,
  type,
  documents,
  affectsDrivability,
  vehicle_id,
}) => {
  return {
    title: title,
    description: description,
    type: type,
    document_ids: documents.map((d) => d.id),
    affects_drivability: affectsDrivability === 'yes' ? true : false,
    vehicle_id: vehicle_id,
    tags: [],
    state: 'notice',
  };
};

export const formatFetchableQuery = (q) => {
  const {'filter[state]': state, ...rest} = q;

  return {
    'filter[state]': state || Object.keys(states).filter((s) => s !== 'archived'), // archived faults not fetched by default
    ...rest,
  };
};
