import React from 'react';
import {Text, Box, Tag, HStack, IconButton, Spacer, Heading} from '@chakra-ui/react';
import {FileTypeIcon, BinIcon} from 'components';

const ReportBox = ({
  type,
  title,
  description,
  documents = [],
  onDelete = () => {},
  processing,
}) => {
  return (
    <Box bg="gray.50" p={4} borderRadius="md" _last={{mb: 5}}>
      <HStack spacing={2} mb={2}>
        <Tag>{type}</Tag>
        {documents.map((doc) => (
          <IconButton
            key={doc.id}
            onClick={() => (doc.url ? window.open(doc.url, '_blank') : null)}
            size="sm"
            variant="ghost"
            icon={
              <FileTypeIcon type={doc.filetype} width={4} height={4} color="violet.700" />
            }
          />
        ))}
        <Spacer />
        <IconButton
          onClick={onDelete}
          disabled={processing}
          variant="ghost"
          size="sm"
          icon={<BinIcon color="gray.600" />}
        />
      </HStack>
      {title && (
        <Heading size="xs" mb={2}>
          {title}
        </Heading>
      )}
      <Text whiteSpace="pre-wrap" fontSize="xs">
        {description}
      </Text>
    </Box>
  );
};

export default ReportBox;
