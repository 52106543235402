import React, {useEffect} from 'react';
import {
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Stack,
  FormControl,
  FormLabel,
  Button,
  HStack,
  SimpleGrid,
  GridItem,
  Heading,
  Center,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import {useForm} from 'react-hook-form';
import {useDropzone} from 'react-dropzone';
import roles from 'constants/roles';
import {tags} from 'dicts/docs';
import {getClientName} from 'utils/clients';
import {ReactSelect, FileTypeIcon, ReactDatepicker} from 'components';
import {UploadIcon} from 'components/icons';

const DocumentUploader = ({
  isOpen,
  onClose,
  onSubmit,
  processing,
  document,
  defaultValues,
  vehicles,
  searchClients,
  searchDrives,
}) => {
  const {handleSubmit, control, reset} = useForm({
    defaultValues,
  });

  useEffect(() => {
    // update form default values when defaultValues prop changes
    reset(defaultValues);
  }, [defaultValues, reset]);

  const {acceptedFiles, getRootProps, getInputProps} = useDropzone({
    disabled: !!document,
  });

  const submit = (data) => {
    onSubmit({...data, files: acceptedFiles});
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="6xl">
      <ModalOverlay />
      <ModalContent>
        <ModalBody padding="0">
          <form onSubmit={handleSubmit(submit)} id="document-uploader-form">
            <SimpleGrid columns={{base: 1, md: 3}}>
              <GridItem
                display={{base: 'block', md: 'none'}}
                bg="gray.100"
                borderTopRightRadius="md"
                borderTopLeftRadius="md"
              >
                <HStack paddingX="5" spacing="5" height="20" justifyContent="flex-end">
                  <Button variant="link" onClick={() => onClose()}>
                    Peruuta
                  </Button>
                  <Button
                    type="submit"
                    form="document-uploader-form"
                    variant="primary"
                    isLoading={processing}
                  >
                    <span>Tallenna</span>
                  </Button>
                </HStack>
              </GridItem>
              <GridItem
                colSpan={{base: 1, md: 2}}
                borderRight="1px solid"
                borderColor="gray.200"
                display="flex"
              >
                <Center
                  flex="1"
                  bg="gray.200"
                  p={5}
                  textAlign="center"
                  borderTopLeftRadius={{base: 'none', md: 'md'}}
                  borderBottomLeftRadius={{base: 'none', md: 'md'}}
                  {...getRootProps({className: 'dropzone'})}
                >
                  <input {...getInputProps()} />
                  {document ? (
                    <Stack align="center" spacing={4}>
                      <FileTypeIcon type={document.filetype} />
                      <Heading color="gray.600" textStyle="h2" textAlign="center">
                        {document.title}
                      </Heading>
                    </Stack>
                  ) : acceptedFiles.length ? (
                    <Wrap align="center" justify="center" spacing={8}>
                      {acceptedFiles.map((f) => (
                        <WrapItem key={f.path}>
                          <Stack align="center" spacing={4}>
                            <FileTypeIcon type={f.type} />
                            <Heading
                              color="gray.600"
                              textStyle="h2"
                              maxW="10rem"
                              overflow="hidden"
                              textOverflow="ellipsis"
                              whiteSpace="nowrap"
                              textAlign="center"
                            >
                              {f.path}
                            </Heading>
                          </Stack>
                        </WrapItem>
                      ))}
                    </Wrap>
                  ) : (
                    <Stack spacing={2} align="center" userSelect="none">
                      <UploadIcon w={16} h={16} color="gray.600" mb={4} />
                      <Heading color="gray.600" textStyle="h2">
                        Lisää tiedostoja
                      </Heading>
                      <Text fontSize="sm" color="gray.700">
                        Raahaa tiedostot koneeltasi tai avaa tiedostoselain klikkaamalla
                      </Text>
                    </Stack>
                  )}
                </Center>
              </GridItem>

              <GridItem
                bg="gray.100"
                borderTopRightRadius={{base: 'none', md: 'md'}}
                borderBottomLeftRadius={{base: 'md', md: 'none'}}
                borderBottomRightRadius="md"
              >
                <HStack
                  paddingX="5"
                  spacing="5"
                  height="20"
                  justifyContent="flex-end"
                  display={{base: 'none', md: 'flex'}}
                >
                  <Button variant="link" onClick={() => onClose()}>
                    Peruuta
                  </Button>

                  <Button
                    type="submit"
                    form="document-uploader-form"
                    variant="primary"
                    isLoading={processing}
                  >
                    <span>Tallenna</span>
                  </Button>
                </HStack>

                <Stack spacing="3" padding="5" bg="white">
                  {document ? (
                    <Heading size="sm" textTransform="uppercase">
                      Muokkaa tiedostoa
                    </Heading>
                  ) : (
                    <Stack spacing={2}>
                      <Heading size="sm" textTransform="uppercase">
                        Massamuokkaa tiedostoja
                      </Heading>
                      <Text fontSize="xs" color="gray.700">
                        Alla tekemäsi valinnat vaikuttavat kaikkiin tiedostoihin
                      </Text>
                    </Stack>
                  )}
                  <FormControl>
                    <FormLabel>Julkaisuajankohta</FormLabel>
                    <ReactDatepicker
                      name="releaseAt"
                      control={control}
                      showTimeSelect
                      dateFormat="dd.MM.yyyy HH:mm"
                      placeholderText="Heti"
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Vanhenemisajankohta</FormLabel>
                    <ReactDatepicker
                      name="expiresAt"
                      control={control}
                      showTimeSelect
                      dateFormat="dd.MM.yyyy HH:mm"
                      placeholderText="Ei koskaan"
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Oikeudet</FormLabel>
                    <ReactSelect
                      name="roles"
                      control={control}
                      options={roles}
                      isMulti
                      isClearable
                      getOptionValue={(opt) => opt.name}
                      getOptionLabel={(opt) => opt.title}
                      placeholder="Näytä kaikille"
                    />
                    <Text fontSize="xs" color="gray.700" marginTop="1">
                      Valitse käyttäjäryhmät kenelle dokumentti näytetään
                    </Text>
                  </FormControl>
                </Stack>

                <Stack padding="5" spacing="3">
                  <Heading size="sm" textTransform="uppercase">
                    Linkitykset ajotietoihin
                  </Heading>

                  <FormControl>
                    <FormLabel>Asiakas</FormLabel>
                    <ReactSelect
                      name="client"
                      control={control}
                      isClearable
                      getOptionValue={(opt) => opt.id}
                      getOptionLabel={(opt) => getClientName(opt, true)}
                      useOptionsAsValues
                      async
                      loadOptions={(text, callback) => {
                        if (text !== '') {
                          searchClients({text, callback});
                        } else {
                          callback([]);
                        }
                      }}
                    />
                  </FormControl>

                  <FormControl>
                    <FormLabel>Auto</FormLabel>
                    <ReactSelect
                      name="vehicleIds"
                      control={control}
                      options={vehicles}
                      isClearable
                      isMulti
                      getOptionValue={(opt) => opt.id}
                      getOptionLabel={(opt) =>
                        `${opt.number} (${opt.registration_number})`
                      }
                    />
                  </FormControl>

                  <FormControl>
                    <FormLabel>Ajo/Ajolista</FormLabel>
                    <ReactSelect
                      name="drives"
                      control={control}
                      isMulti
                      isClearable
                      getOptionValue={(opt) => opt.id}
                      getOptionLabel={(opt) =>
                        opt.type === 'order' ? opt.number : opt.title
                      }
                      useOptionsAsValues
                      async
                      loadOptions={(text, callback) => {
                        if (text !== '') {
                          searchDrives({text, callback});
                        } else {
                          callback([]);
                        }
                      }}
                    />
                  </FormControl>

                  <FormControl>
                    <FormLabel>Tarkenteet</FormLabel>
                    <ReactSelect
                      name="tags"
                      control={control}
                      options={Object.values(tags).map((t) => ({value: t, label: t}))}
                      isMulti
                      isClearable
                    />
                  </FormControl>
                </Stack>
              </GridItem>
            </SimpleGrid>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default DocumentUploader;
