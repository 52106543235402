import Embed from '@editorjs/embed';
import Table from 'editorjs-table';
import Paragraph from '@editorjs/paragraph';
import List from '@editorjs/list';
import LinkTool from '@editorjs/link';
import Image from '@editorjs/image';
import Header from '@editorjs/header';
import Underline from '@editorjs/underline';
import Marker from '@editorjs/marker';
import Attaches from './attaches';

import _sels from 'modules/common/boundSelectors';
import {apiUrl} from 'constants/app';
import {warnEff} from 'io/errors';
import {describeError, describeThrow} from 'utils/errors';
import {longDur} from 'constants/notifications';
import nEffs from 'modules/notifications/effects';
import {decorateWithNotificationsEff} from 'io/app';
import services from 'services';

let sels;
_sels.then((x) => (sels = x));

let httpJson = null;
services.waitFor('api').then((x) => (httpJson = x.httpJson));

export const uploadByFile = (file, withBody = false) => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open('POST', `${apiUrl}/uploads`);
    xhr.setRequestHeader('Authorization', `Bearer ${sels.apiToken()}`);
    xhr.setRequestHeader('Accept', 'application/json');
    const data = new FormData();
    data.append('file', file);
    xhr.send(data);
    xhr.addEventListener('load', (e) => {
      if (xhr.status < 400) {
        const response = JSON.parse(xhr.responseText);
        resolve(
          withBody
            ? {
                body: {
                  success: 1,
                  file: {url: response[0]?.url, name: response[0]?.filename},
                },
              }
            : {success: 1, file: {url: response[0]?.url, name: response[0]?.filename}},
        );
      } else {
        reject(new Error('Upload failed'));
      }
    });
    xhr.addEventListener('error', () => {
      const error = JSON.parse(xhr.responseText);
      reject(error);
    });
  }).catch((e) => {
    warnEff(
      nEffs.error,
      {id: 'upload-failed', duration: longDur},
      describeError('Tiedoston lähetys epäonnistui', e),
    );
  });
};

export const uploadByUrl = (url) => {
  return decorateWithNotificationsEff(
    {id: 'upload-by-url', failureStyle: 'error'},
    httpJson('post', '/uploads', {}, {body: {file: url}})
      .catch(describeThrow('Tiedoston lähetys epäonnistui'))
      .then((res) => ({
        success: 1,
        file: {url: res[0]?.url, name: res[0]?.filename},
      })),
  );
};

export const EDITOR_JS_TOOLS = {
  paragraph: {
    class: Paragraph,
    inlineToolbar: true,
  },
  header: {
    class: Header,
    inlineToolbar: true,
    config: {
      levels: [2, 3],
      defaultLevel: 2,
    },
  },
  list: {
    class: List,
    inlineToolbar: true,
  },
  table: {
    class: Table,
    inlineToolbar: true,
  },
  embed: {
    class: Embed,
    inlineToolbar: true,
  },
  underline: Underline,
  linkTool: {
    class: LinkTool,
    config: {
      endpoint: `${apiUrl}/links`,
    },
  },
  image: {
    class: Image,
    config: {
      uploader: {
        uploadByFile(file) {
          return uploadByFile(file, false);
        },
        uploadByUrl(url) {
          return uploadByUrl(url);
        },
      },
    },
  },
  attaches: {
    class: Attaches,
    config: {
      uploader: {
        uploadByFile(file) {
          return uploadByFile(file, true);
        },
      },
      buttonText: 'Valitse tiedosto',
      errorMessage: 'Tiedoston lähetys epäonnistui',
    },
  },
  marker: {
    class: Marker,
    inlineToolbar: true,
  },
};
