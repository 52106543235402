import namespace from './namespace';
import {scopedStore} from 'utils/redux';

export const initialState = {
  processing: false,
  configuration: [],
  configurationLoading: true,
};

export const cases = {
  setConfiguration: (st, all, payload) => {
    return {...st, configuration: payload, configurationLoading: false};
  },
  setProcessing: (st, all, payload) => {
    return {...st, processing: payload};
  },
  reset: (st) => {
    return initialState;
  },
};

export const {actions, reducer} = scopedStore(namespace, cases, initialState);
