import {warnEff, informEff, logInfo} from 'io/errors';
import nEffs from 'modules/notifications/effects';
import {shortDur, medDur, longDur} from 'constants/notifications';

export const setPageTitleMessage = (msg) => {
  document.title = `${msg} - Lehtimäki`;
};

export const decorateWithNotificationsEff = (spec, promise) => {
  const {
    id,
    loading,
    success,
    suppressFailure = false,
    failureStyle = 'warning',
    successStyle = 'success',
    failureDuration,
    successDuration,
    // this is offered for convenience - sometimes you may have a helper function and you want to disable the handling there because the parent function takes care of it already
    disableEverything = false,
  } = spec;
  if (disableEverything) {
    return promise;
  }
  if (loading) {
    nEffs.loading({id, message: loading});
  }
  return promise
    .catch((e) => {
      const suppress =
        typeof suppressFailure === 'function' ? suppressFailure(e) : suppressFailure;
      if (suppress) {
        logInfo(e);
        if (loading) {
          nEffs.remove(id);
        }
      } else {
        const style = typeof failureStyle === 'function' ? failureStyle(e) : failureStyle;
        const dur =
          typeof failureDuration === 'function' ? failureDuration(e) : failureDuration;
        // TODO: could consider letting the caller decide between "warn" and "inform"
        if (style === 'error') {
          warnEff(nEffs.error, {id, duration: dur || longDur}, e);
        } else if (style === 'info') {
          informEff(nEffs.info, {id, duration: dur || medDur}, e);
        } else {
          warnEff(nEffs.warning, {id, duration: dur || medDur}, e);
        }
      }
      throw e;
    })
    .then((res) => {
      const successMsg = typeof success === 'function' ? success(res) : success;
      if (successMsg) {
        const dur =
          typeof successDuration === 'function' ? successDuration(res) : successDuration;
        if (successStyle === 'info') {
          nEffs.info({id, message: successMsg, duration: dur || medDur});
        } else {
          nEffs.success({id, message: successMsg, duration: dur || shortDur});
        }
      } else if (loading) {
        nEffs.remove(id);
      }
      return res;
    });
};
