import React from 'react';
import {Input} from '@chakra-ui/react';
import FieldBase from './field-base';

function TextInput({
  title,
  value = '',
  type = 'text',
  placeholder = '',
  icon = undefined,
  width = undefined,
  onChange = () => {},
}) {
  return (
    <FieldBase title={title} icon={icon} width={width}>
      <Input
        fontSize="sm"
        isTruncated
        type={type}
        height="5"
        maxWidth="full"
        variant="unstyled"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
    </FieldBase>
  );
}

export default TextInput;
