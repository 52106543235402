import {assertTypeAndTransformError} from './assertions';
import {safeStringify} from './json';
import {curry} from 'ramda';

export const eventsConfig = {enable: false};

export const event = (namespace) => (effectFunc, payloadType = null) => {
  if (!effectFunc.name) {
    throw new Error('Effect is missing the "name" property');
  }
  const type = `${namespace.join('.')}:${effectFunc.name}`;

  const augmentError = (e) => {
    e.message = `At event "${type}": ${e.message}`;
    return e;
  };

  let creator = (payload) => {
    // type checking here because a) payload types aren't really relevant to the channel b) it's no use letting events with invalid payloads get into the channel
    if (payloadType) {
      assertTypeAndTransformError(payloadType, payload, augmentError);
    }
    // explicitly check since people aren't gonna type things otherwise
    else if (eventsConfig.enable && payload !== undefined) {
      throw new Error(`You didn't provide a type for the event "${type}"!`);
    }
    return payload === undefined
      ? {type, effect: effectFunc}
      : {type, effect: effectFunc, payload};
  };
  Object.defineProperty(creator, 'name', {value: effectFunc.name});
  creator.type = type;
  creator.payloadType = payloadType;
  return creator;
};

export const bindCreator = (dispatch) => (eventCreator) => {
  let creator = (payload) => {
    dispatch(eventCreator(payload));
  };
  Object.defineProperty(creator, 'name', {value: eventCreator.name});
  creator.type = eventCreator.type;
  creator.payloadType = eventCreator.payloadType;
  return creator;
};

export const boundEffect = (namespace, dispatch) => (effectFunc, payloadType) => {
  const creator = event(namespace)(effectFunc, payloadType);
  return bindCreator(dispatch)(creator);
};

export const createEffects = curry((namespace, dispatch, types, effectFuncs) => {
  const effect = boundEffect(namespace, dispatch);
  return Object.keys(effectFuncs).reduce((effects, k) => {
    const func = effectFuncs[k];
    Object.defineProperty(func, 'name', {value: k});
    return {...effects, [k]: effect(func, types[k])};
  }, {});
});

export const formatEvent = (evt) =>
  `${evt.type}(${evt.payload !== undefined ? safeStringify(evt.payload) : ''})`;

// prettier-ignore
export const formatEventWithTime = (evt) =>
    `${evt.time.toLocaleTimeString()} - ${evt.type}(${evt.payload !== undefined ? safeStringify(evt.payload) : ''})`;
