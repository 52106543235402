import {parseISO} from 'date-fns';
import {
  SHOW_HOLIDAY_FORM_KEY,
  HOLIDAY_RESERVE_FROM_KEY,
  HOLIDAY_RESERVE_TO_KEY,
} from './constants';

export const getShowHolidayFormConfig = (config = []) => {
  const showForm = config.find((x) => x.key === SHOW_HOLIDAY_FORM_KEY)?.value;
  return showForm === '1';
};

export const getHolidayReserveFromConfig = (config = []) => {
  const reserveFrom = config.find((x) => x.key === HOLIDAY_RESERVE_FROM_KEY)?.value;
  return reserveFrom ? parseISO(reserveFrom) : null;
};

export const getHolidayReserveToConfig = (config = []) => {
  const reserveTo = config.find((x) => x.key === HOLIDAY_RESERVE_TO_KEY)?.value;
  return reserveTo ? parseISO(reserveTo) : null;
};

export const formatHolidayConfigFormDefaultVals = (config = []) => {
  return {
    show_holiday_form: getShowHolidayFormConfig(config),
    holiday_reserve_from: getHolidayReserveFromConfig(config),
    holiday_reserve_to: getHolidayReserveToConfig(config),
  };
};
