import {parseISO} from 'date-fns';

export const formatCreateFaultOutput = ({
  title,
  description,
  type,
  documents,
  affectsDrivability,
  vehicle_id,
  shift_id,
}) => {
  return {
    title: title,
    description: description,
    type: type,
    document_ids: documents.map((d) => d.id),
    affects_drivability: affectsDrivability === 'yes' ? true : false,
    vehicle_id: vehicle_id,
    tags: [],
    state: 'notice',
    shift_id: shift_id,
  };
};

export const formatShiftExceptionDefaultVals = (shift) => {
  if (!shift) return {};

  const startAt = shift.exception?.shift_start_at || shift.shift_start_at;
  const endAt = shift.exception?.shift_end_at || shift.shift_end_at;

  const formatPause = (pause, withId) => {
    return {
      _id: withId ? pause.id : '', // add id only for shift_exception pauses to make sure original pauses are never modified
      from_date: pause.from_date ? parseISO(pause.from_date) : '',
      to_date: pause.to_date ? parseISO(pause.to_date) : '',
      deleted: false,
    };
  };

  return {
    shift_start_at: startAt ? parseISO(startAt) : '',
    shift_end_at: endAt ? parseISO(endAt) : '',
    description: shift.exception?.description || '',
    pauses: shift.exception?.pauses
      ? shift.exception.pauses.map((p) => formatPause(p, true))
      : shift.pauses
      ? shift.pauses.map((p) => formatPause(p, false))
      : [],
  };
};

export const formatCreateShiftDriveExceptionOutput = ({
  description,
  documents,
  shift_id,
}) => {
  return {
    description: description,
    document_ids: documents.map((d) => d.id),
    shift_id: shift_id,
  };
};

export const formatShiftReturnDefaultVals = (shift) => {
  if (!shift) return {};

  const startAt = shift.return?.shift_start_at || shift.shift_start_at;
  const endAt = shift.return?.shift_end_at || shift.shift_end_at;

  const formatPause = (pause) => {
    return {
      _id: pause.id,
      from_date: pause.from_date ? parseISO(pause.from_date) : '',
      to_date: pause.to_date ? parseISO(pause.to_date) : '',
      deleted: false,
    };
  };

  return {
    shift_start_at: startAt ? parseISO(startAt) : '',
    shift_end_at: endAt ? parseISO(endAt) : '',
    pauses: shift.pauses ? shift.pauses.map((p) => formatPause(p)) : [],
    odometer_from: shift.return ? shift.return.odometer_from : '',
    odometer_to: shift.return ? shift.return.odometer_to : '',
  };
};
