/* eslint-disable max-len */
import React from 'react';
import {Icon} from '@chakra-ui/react';

const InfoIcon = (props) => (
  <Icon
    viewBox="0 0 16 16"
    stroke="currentColor"
    fill="currentColor"
    strokeWidth="0"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8,0 C3.581722,0 0,3.581722 0,8 C0,12.418278 3.581722,16 8,16 C12.418278,16 16,12.418278 16,8 C15.9952234,3.58370203 12.416298,0.00477656508 8,0 Z M8.16666667,3.33333333 C8.71895142,3.33333333 9.16666667,3.78104858 9.16666667,4.33333333 C9.16666667,4.88561808 8.71895142,5.33333333 8.16666667,5.33333333 C7.61438192,5.33333333 7.16666667,4.88561808 7.16666667,4.33333333 C7.16666667,3.78104858 7.61438192,3.33333333 8.16666667,3.33333333 Z M9.66666667,12.3333333 L7,12.3333333 C6.63181017,12.3333333 6.33333333,12.0348565 6.33333333,11.6666667 C6.33333333,11.2984768 6.63181017,11 7,11 L7.5,11 C7.59204746,11 7.66666667,10.9253808 7.66666667,10.8333333 L7.66666667,7.83333333 C7.66666667,7.74128588 7.59204746,7.66666667 7.5,7.66666667 L7,7.66666667 C6.63181017,7.66666667 6.33333333,7.36818983 6.33333333,7 C6.33333333,6.63181017 6.63181017,6.33333333 7,6.33333333 L7.66666667,6.33333333 C8.40304633,6.33333333 9,6.930287 9,7.66666667 L9,10.8333333 C9,10.9253808 9.07461921,11 9.16666667,11 L9.66666667,11 C10.0348565,11 10.3333333,11.2984768 10.3333333,11.6666667 C10.3333333,12.0348565 10.0348565,12.3333333 9.66666667,12.3333333 Z"
    />
  </Icon>
);

export default InfoIcon;
