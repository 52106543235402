import {createSelector} from 'reselect';
import namespace from './namespace';
import {scopedSelectors} from 'utils/redux';
import {formatHolidayConfigFormDefaultVals} from './utils';

let sels = {};

sels.processing = (st) => st.processing;

sels.configuration = (st) => st.configuration;

sels.configurationLoading = (st) => st.configurationLoading;

sels.holidayConfigFormDefaultVals = createSelector(sels.configuration, (config) =>
  formatHolidayConfigFormDefaultVals(config),
);

export default scopedSelectors(namespace, sels);
