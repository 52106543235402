import namespace from './namespace';
import {createEffects} from 'utils/events';
import services from 'services';
import _acts from './boundActions';
import _sels from './boundSelectors';
import _commonSels from 'modules/common/boundSelectors';
import {P, Union} from 'utils/types';
import {guardHandled} from 'io/errors';
import {setPageTitleMessage, decorateWithNotificationsEff} from 'io/app';
import {uploadFiles} from 'io/files';
import confirmerEffs from 'modules/confirmer/effects';
import docEditorEffs from 'modules/documentEditor/effects';
import msgs from 'dicts/messages';
import {formatCreateFaultOutput, formatCreateShiftDriveExceptionOutput} from './utils';
import {
  getShift,
  postDocument,
  postFault,
  deleteFault,
  putDrive,
  putShift,
  postShiftPause,
  putShiftPause,
  deleteShiftPause,
  getDriveNews,
  getNews,
  postShiftDriveException,
  deleteShiftDriveException,
  getDriveTypeDocuments,
  postShiftException,
  putShiftException,
  postShiftReturn,
  putShiftReturn,
} from './io';

let acts, sels, commonSels;
_acts.then((x) => (acts = x));
_sels.then((x) => (sels = x));
_commonSels.then((x) => (commonSels = x));

let effects = {};
let types = {};

const refetchShift = () => {
  return decorateWithNotificationsEff(
    {id: 'get-shift', failureStyle: 'warning'},
    getShift(sels.shift().id),
  ).then((shift) => acts.setShift(shift));
};

const fetchShiftNews = (shift) => {
  return Promise.all([
    shift.drive ? getDriveNews(shift.drive.id) : [],
    shift.vehicle ? getNews({'filter[vehicle]': shift.vehicle.id}) : [],
  ]).then((res) => [...res[0], ...res[1]]);
};

effects.initialize = guardHandled(async (id) => {
  setPageTitleMessage('Ajot');
  const user = commonSels.user();

  await decorateWithNotificationsEff(
    {id: 'get-shift', failureStyle: 'warning'},
    getShift(id).then((shift) => {
      acts.setShift(shift);

      if (shift.drive) {
        getDriveTypeDocuments(shift.drive.type).then((docs) =>
          acts.setDriveTypeDocuments(docs),
        );
      } else {
        acts.setDriveTypeDocuments([]);
      }

      fetchShiftNews(shift).then((news) => acts.setNews(news));

      // mark drive as read if shift's user is the current user
      if (!shift.read_at && shift.user?.id === user.id) {
        putShift({id: shift.id, read_at: new Date()});
      }
    }),
  );
});
types.initialize = P.Number;

effects.destroy = async () => {
  acts.reset();
};

effects.updateShift = guardHandled(async (data) => {
  try {
    acts.setProcessing(true);
    await decorateWithNotificationsEff(
      {
        id: 'update-shift',
        failureStyle: 'error',
        success: 'Tallennettu',
      },
      putShift({id: sels.shift().id, ...data}),
    );
    await refetchShift();
    acts.setProcessing(false);
  } catch (e) {
    acts.setProcessing(false);
    throw e;
  }
});
types.updateShift = P.Object;

effects.saveShift = guardHandled(async ({pauses, ...data}) => {
  try {
    acts.setProcessing(true);
    await decorateWithNotificationsEff(
      {
        id: 'update-shift',
        failureStyle: 'error',
        success: 'Tallennettu',
      },
      putShift({id: sels.shift().id, ...data}).then(() => {
        return Promise.all(pauses.map((p) => putShiftPause(p)));
      }),
    );
    await refetchShift();
    acts.setProcessing(false);
  } catch (e) {
    acts.setProcessing(false);
    throw e;
  }
});
types.saveShift = P.Object;

effects.updateDrive = guardHandled(async (data) => {
  const shift = sels.shift();

  if (!shift.drive) return;

  try {
    acts.setProcessing(true);
    await decorateWithNotificationsEff(
      {
        id: 'update-drive',
        failureStyle: 'error',
        success: 'Tallennettu',
      },
      putDrive({id: shift.drive.id, ...data}),
    );
    await refetchShift();
    acts.setProcessing(false);
  } catch (e) {
    acts.setProcessing(false);
    throw e;
  }
});
types.updateDrive = P.Object;

effects.addFault = guardHandled(async (formData) => {
  const shiftId = sels.shift().id;

  try {
    acts.setProcessing(true);

    // Upload files -> create document for each file -> create fault and link each doc to the fault
    const {files, ...rest} = formData;
    let documents = [];

    if (files.length) {
      const uploadedFiles = await decorateWithNotificationsEff(
        {
          id: 'upload-files',
          failureStyle: 'error',
        },
        uploadFiles(files),
      );
      documents = await decorateWithNotificationsEff(
        {
          id: 'create-documents',
          failureStyle: 'error',
        },
        postDocument({path: uploadedFiles.map((f) => f.path), type: 'fault'}),
      );
    }

    await decorateWithNotificationsEff(
      {
        id: 'create-fault',
        failureStyle: 'error',
        success: 'Ilmoitus tallennettu',
      },
      postFault(formatCreateFaultOutput({...rest, documents, shift_id: shiftId})),
    );
    acts.setProcessing(false);
    acts.closeAddFaultModal();
  } catch (e) {
    acts.setProcessing(false);
    throw e;
  }

  await refetchShift();
});
types.addFault = P.Object;

effects.removeFault = (id) => {
  const onConfirm = guardHandled(async () => {
    try {
      acts.setProcessing(true);
      await decorateWithNotificationsEff(
        {
          id: 'remove-fault',
          failureStyle: 'error',
          loading: msgs.deleting,
          success: 'Poistettu',
        },
        deleteFault(id),
      );
      acts.setProcessing(false);
    } catch (e) {
      acts.setProcessing(false);
      throw e;
    }

    refetchShift();
  });

  confirmerEffs.show({
    message: 'Poistetaanko ilmoitus?',
    okText: 'Poista',
    okStyle: 'danger',
    cancelText: msgs.cancel,
    onCancel: () => {},
    onOk: onConfirm,
  });
};
types.removeFault = P.Number;

effects.addShiftPause = guardHandled(async (formData) => {
  try {
    acts.setProcessing(true);
    await decorateWithNotificationsEff(
      {
        id: 'add-shift-pause',
        failureStyle: 'error',
        success: 'Tauko lisätty',
      },
      postShiftPause({...formData, shift_id: sels.shift().id}),
    );
    acts.setProcessing(false);
    acts.toggleShiftPauseModal();
  } catch (e) {
    acts.setProcessing(false);
    throw e;
  }
  await refetchShift();
});
types.addShiftPause = P.Object;

effects.updateShiftPause = guardHandled(async (data) => {
  try {
    acts.setProcessing(true);
    await decorateWithNotificationsEff(
      {
        id: 'update-shift-pause',
        failureStyle: 'error',
      },
      putShiftPause(data),
    );
    acts.setProcessing(false);
  } catch (e) {
    acts.setProcessing(false);
    throw e;
  }
  await refetchShift();
});
types.updateShiftPause = P.Object;

effects.removeShiftPause = (id) => {
  const onConfirm = guardHandled(async () => {
    try {
      acts.setProcessing(true);
      await decorateWithNotificationsEff(
        {
          id: 'remove-shift-pause',
          failureStyle: 'error',
          loading: msgs.deleting,
          success: 'Tauko poistettu',
        },
        deleteShiftPause(id),
      );
      acts.setProcessing(false);
    } catch (e) {
      acts.setProcessing(false);
      throw e;
    }
    await refetchShift();
  });

  confirmerEffs.show({
    message: 'Poistetaanko tauko?',
    okText: 'Poista',
    okStyle: 'danger',
    cancelText: msgs.cancel,
    onCancel: () => {},
    onOk: onConfirm,
  });
};
types.removeShiftPause = P.Number;

effects.addShiftDriveException = guardHandled(async (formData) => {
  const shiftId = sels.shift().id;

  try {
    acts.setProcessing(true);

    // Upload files -> create document for each file -> create exception and link each doc to the exception
    const {files, ...rest} = formData;
    let documents = [];

    if (files.length) {
      const uploadedFiles = await decorateWithNotificationsEff(
        {
          id: 'upload-files',
          failureStyle: 'error',
        },
        uploadFiles(files),
      );
      documents = await decorateWithNotificationsEff(
        {
          id: 'create-documents',
          failureStyle: 'error',
        },
        postDocument({
          path: uploadedFiles.map((f) => f.path),
          type: 'shift_drive_exception',
        }),
      );
    }

    await decorateWithNotificationsEff(
      {
        id: 'add-shift-drive-exception',
        failureStyle: 'error',
        success: 'Tallennettu',
      },
      postShiftDriveException(
        formatCreateShiftDriveExceptionOutput({...rest, documents, shift_id: shiftId}),
      ),
    );
    acts.setProcessing(false);
    acts.toggleShiftDriveExceptionModal();
  } catch (e) {
    acts.setProcessing(false);
    throw e;
  }

  await refetchShift();
});
types.addShiftDriveException = P.Object;

effects.removeShiftDriveException = (id) => {
  const onConfirm = guardHandled(async () => {
    try {
      acts.setProcessing(true);
      await decorateWithNotificationsEff(
        {
          id: 'remove-shift-drive-exception',
          failureStyle: 'error',
          loading: msgs.deleting,
          success: 'Poistettu',
        },
        deleteShiftDriveException(id),
      );
      acts.setProcessing(false);
    } catch (e) {
      acts.setProcessing(false);
      throw e;
    }

    await refetchShift();
  });

  confirmerEffs.show({
    message: 'Poistetaanko poikkeus?',
    okText: 'Poista',
    okStyle: 'danger',
    cancelText: msgs.cancel,
    onCancel: () => {},
    onOk: onConfirm,
  });
};
types.removeShiftDriveException = P.Number;

effects.saveShiftException = guardHandled(async (formData) => {
  const shift = sels.shift();
  const {pauses, ...rest} = formData;

  try {
    acts.setProcessing(true);
    await decorateWithNotificationsEff(
      {
        id: 'save-shift-exception',
        failureStyle: 'error',
        success: 'Tallennettu',
      },
      // update existing shift_exception or create new
      shift.exception
        ? putShiftException({...rest, id: shift.exception.id}).then((ex) => {
            if (pauses && pauses.length) {
              return Promise.all(
                pauses.map((p) =>
                  p.deleted === 'true'
                    ? p._id
                      ? deleteShiftPause(p._id)
                      : Promise.resolve(null)
                    : p._id
                    ? putShiftPause({...p, id: p._id})
                    : postShiftPause({...p, shift_exception_id: ex.id}),
                ),
              );
            }
          })
        : postShiftException({...rest, shift_id: shift.id}).then((ex) => {
            if (pauses && pauses.length) {
              return Promise.all(
                pauses.map((p) =>
                  p.deleted === 'true'
                    ? Promise.resolve(null)
                    : postShiftPause({...p, shift_exception_id: ex.id}),
                ),
              );
            }
          }),
    );
    acts.setProcessing(false);
    acts.toggleShiftExceptionModal();
  } catch (e) {
    acts.setProcessing(false);
    throw e;
  }

  await refetchShift();
});
types.saveShiftException = P.Object;

const shiftPauseRequest = (p, shift) => {
  return p.deleted === 'true'
    ? p._id
      ? deleteShiftPause(p._id)
      : Promise.resolve(null)
    : p._id
    ? putShiftPause({...p, id: p._id})
    : postShiftPause({...p, shift_id: shift.id});
};

effects.saveShiftReturn = guardHandled(async (formData) => {
  const shift = sels.shift();
  const {pauses, ...form} = formData;

  const hoursDiff = Math.abs(form.shift_end_at - form.shift_start_at) / 36e5;

  const shiftRet = {
    ...form,
    length: Number(form.odometer_to) - Number(form.odometer_from),
    hours: Math.round(hoursDiff * 100) / 100,
  };

  try {
    acts.setProcessing(true);
    await decorateWithNotificationsEff(
      {
        id: 'save-shift-return',
        failureStyle: 'error',
        success: 'Tallennettu',
      },
      // update existing shift_return or create new
      shift.return
        ? putShiftReturn({...shiftRet, id: shift.return.id}).then(() => {
            if (pauses && pauses.length) {
              return Promise.all(pauses.map((p) => shiftPauseRequest(p, shift)));
            }
          })
        : postShiftReturn({...shiftRet, shift_id: shift.id}).then(() => {
            if (pauses && pauses.length) {
              return Promise.all(pauses.map((p) => shiftPauseRequest(p, shift)));
            }
          }),
    );
    acts.setProcessing(false);
    acts.toggleShiftReturnModal();
  } catch (e) {
    acts.setProcessing(false);
    throw e;
  }

  await refetchShift();
});
types.saveShiftReturn = P.Object;

effects.refetchShift = guardHandled(async () => {
  refetchShift();
});

effects.refetchNews = guardHandled(async () => {
  const shift = sels.shift();
  const news = await decorateWithNotificationsEff(
    {id: 'fetch-shift-news', failureStyle: 'warning'},
    fetchShiftNews(shift),
  );
  acts.setNews(news);
});

effects.openDocumentPreview = ({document, type = 'document'}) => {
  docEditorEffs.openPreview({
    id: document.id,
    type,
    onSave: () => (type === 'news' ? effects.refetchNews() : effects.refetchShift()),
  });
};
types.openDocumentPreview = P.Object;

effects.openDocumentEditor = ({document, type = 'document'}) => {
  if (type === 'document' && document.filetype !== 'document') {
    docEditorEffs.openUploader({
      id: document.id,
      onSave: () => effects.refetchShift(),
    });
  } else {
    docEditorEffs.openEditor({
      id: document.id,
      type,
      onSave: () => (type === 'news' ? effects.refetchNews() : effects.refetchShift()),
    });
  }
};
types.openDocumentEditor = P.Object;

effects.removeDocument = (id) => {
  docEditorEffs.remove({id, onDelete: () => effects.refetchShift()});
};
types.removeDocument = Union(P.String, P.Number);

effects.openAddFaultModal = () => {
  acts.openAddFaultModal();
};

effects.closeAddFaultModal = () => {
  acts.closeAddFaultModal();
};

effects.toggleShiftPauseModal = () => {
  acts.toggleShiftPauseModal();
};

effects.toggleShiftDriveExceptionModal = () => {
  acts.toggleShiftDriveExceptionModal();
};

effects.toggleShiftExceptionModal = () => {
  acts.toggleShiftExceptionModal();
};

effects.toggleShiftReturnModal = () => {
  acts.toggleShiftReturnModal();
};

export default createEffects(namespace, services.get('channel').dispatch, types, effects);
