import {prop} from 'ramda';
import services from 'services';
import {describeThrow} from 'utils/errors';
import msgs from 'dicts/messages';
import {defaultQuery} from './constants';

let httpJson = null;
services.waitFor('api').then((x) => (httpJson = x.httpJson));

export const getNews = (query) =>
  httpJson('get', '/news', {...defaultQuery, ...query}).catch(
    describeThrow(msgs.fetchFailed),
  );

export const postNews = (body) =>
  httpJson('post', '/news', {}, {body})
    .catch(describeThrow(msgs.saveFailed))
    .then(prop('data'));

export const updateNews = (news) =>
  httpJson('put', `/news/${news.id}`, {}, {body: news})
    .catch(describeThrow(msgs.saveFailed))
    .then(prop('data'));

export const massUpdateNews = (body) =>
  httpJson('put', '/news', {}, {body})
    .catch(describeThrow(msgs.saveFailed))
    .then(prop('data'));

export const deleteNews = (id) =>
  httpJson('delete', `/news/${id}`).catch(describeThrow(msgs.deleteFailed));
