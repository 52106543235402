/* eslint-disable max-len */
import React from 'react';
import {Icon} from '@chakra-ui/react';

const NavNewsIcon = (props) => (
  <Icon
    viewBox="0 0 24 24"
    stroke="currentColor"
    fill="currentColor"
    strokeWidth="0"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.75,0 C22.9926407,0 24,1.00735931 24,2.25 L24,2.25 L24,13.629 C24,13.9382151 23.936304,14.2408735 23.8161698,14.5194738 C23.8098484,14.5332059 23.8041019,14.5468945 23.7979684,14.5603698 C23.6876548,14.8030939 23.5337255,15.0268114 23.3413301,15.2193301 L23.3413301,15.2193301 L15.2191601,23.3415 C15.026719,23.5338179 14.8030437,23.6877051 14.5600675,23.7982035 C14.5468945,23.8041019 14.5332059,23.8098484 14.5193181,23.8151935 C14.2408735,23.936304 13.9382151,24 13.629,24 L13.629,24 L2.25,24 C1.00735931,24 0,22.9926407 0,21.75 L0,21.75 L0,2.25 C0,1.00735931 1.00735931,0 2.25,0 L2.25,0 Z M21.75,1.5 L2.25,1.5 C1.83578644,1.5 1.5,1.83578644 1.5,2.25 L1.5,2.25 L1.5,21.75 C1.5,22.1642136 1.83578644,22.5 2.25,22.5 L2.25,22.5 L13.499,22.5 L13.5,15.75 C13.5,14.559136 14.4251616,13.5843551 15.5959512,13.5051908 L15.75,13.5 L22.5,13.5 L22.5,2.25 C22.5,1.87030423 22.2178461,1.55650904 21.8517706,1.50684662 L21.75,1.5 Z M21.438,15 L15.75,15 C15.3703042,15 15.056509,15.2821539 15.0068466,15.6482294 L15,15.75 L14.999,21.439 L21.438,15 Z M12,10.5 C12.4142136,10.5 12.75,10.8357864 12.75,11.25 C12.75,11.6296958 12.4678461,11.943491 12.1017706,11.9931534 L12,12 L6.75,12 C6.33578644,12 6,11.6642136 6,11.25 C6,10.8703042 6.28215388,10.556509 6.64822944,10.5068466 L6.75,10.5 L12,10.5 Z M18.75,6 C19.1642136,6 19.5,6.33578644 19.5,6.75 C19.5,7.12969577 19.2178461,7.44349096 18.8517706,7.49315338 L18.75,7.5 L6.75,7.5 C6.33578644,7.5 6,7.16421356 6,6.75 C6,6.37030423 6.28215388,6.05650904 6.64822944,6.00684662 L6.75,6 L18.75,6 Z"
    />
  </Icon>
);

export default NavNewsIcon;
