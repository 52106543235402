export default {
  body: {
    fontSize: 'md',
    color: 'gray.900',
    lineHeight: 'tall',
    bg: 'gray.100',
  },
  a: {
    color: 'violet.700',
  },
};
