/* eslint-disable max-len */
import React from 'react';
import {Icon} from '@chakra-ui/react';

const ChevronDoubleRightIcon = (props) => (
  <Icon
    viewBox="0 0 16 16"
    stroke="currentColor"
    fill="currentColor"
    strokeWidth="0"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.50970828,2.25065385 L8.31476361,7.05570918 C8.56530742,7.30601496 8.70577113,7.6458465 8.70507309,8 C8.70577113,8.3541535 8.56530742,8.69398504 8.31476361,8.94429082 L3.50970828,13.7493462 C3.1604341,14.0874584 2.60453178,14.0829453 2.26079326,13.7392067 C1.91705474,13.3954682 1.91254161,12.8395659 2.25065385,12.4902917 L6.65180452,8.08914105 C6.67548524,8.06552012 6.68879397,8.03344735 6.68879397,8 C6.68879397,7.96655265 6.67548524,7.93447988 6.65180452,7.91085895 L2.25065385,3.50970828 C1.91254161,3.1604341 1.91705474,2.60453178 2.26079326,2.26079326 C2.60453178,1.91705474 3.1604341,1.91254161 3.50970828,2.25065385 Z M8.62952722,2.25065385 L13.4345825,7.05570918 C13.6851264,7.30601496 13.8255901,7.6458465 13.824892,8 C13.8255901,8.3541535 13.6851264,8.69398504 13.4345825,8.94429082 L8.62952722,13.7493462 C8.28025303,14.0874584 7.72435072,14.0829453 7.3806122,13.7392067 C7.03687368,13.3954682 7.03236054,12.8395659 7.37047278,12.4902917 L11.7716235,8.08914105 C11.7953042,8.06552012 11.8086129,8.03344735 11.8086129,8 C11.8086129,7.96655265 11.7953042,7.93447988 11.7716235,7.91085895 L7.37047278,3.50970828 C7.03236054,3.1604341 7.03687368,2.60453178 7.3806122,2.26079326 C7.72435072,1.91705474 8.28025303,1.91254161 8.62952722,2.25065385 Z"
    />
  </Icon>
);

export default ChevronDoubleRightIcon;
