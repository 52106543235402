import React, {memo} from 'react';
import {parseISO, format} from 'date-fns';
import {
  Tag,
  Text,
  Link,
  Menu,
  MenuList,
  MenuButton,
  MenuItem,
  MenuDivider,
  IconButton,
  HStack,
  Portal,
  Checkbox,
} from '@chakra-ui/react';
import {TableRow, TableCell, TruncatedText} from 'components';
import {DotsIcon} from 'components/icons';
import {releaseStates} from 'dicts/news';

const NewsRow = memo(function NewsRow({
  news,
  onOpen = () => {},
  onEdit = () => {},
  onDelete = () => {},
  onArchive = () => {},
  isSelected,
  select = () => {},
  hideActions,
  hideSelect,
  hideTag,
}) {
  return (
    <TableRow
      position="relative"
      flexDirection="row"
      flexWrap="nowrap"
      overflow="hidden"
      paddingRight={{base: 1, md: 6}}
      gridTemplateColumns={hideSelect ? 'auto auto' : 'min-content auto auto'}
    >
      {!hideSelect && (
        <TableCell>
          <Checkbox
            size="lg"
            colorScheme="gray"
            isChecked={isSelected}
            onClick={(e) => e.stopPropagation()}
            onChange={(e) => select([{id: news.id, selected: e.target.checked}])}
          />
        </TableCell>
      )}
      <TableCell flex={{base: '1', md: '0 1 auto'}} overflow="hidden">
        <Text fontSize="xs" color="gray.500">
          {news.release_at ? format(parseISO(news.release_at), 'd.M.yyyy HH:mm') : null}
        </Text>
        <Link onClick={() => onOpen(news)}>
          <TruncatedText fontWeight="bold">{news.title}</TruncatedText>
        </Link>
      </TableCell>
      <TableCell>
        <HStack spacing={2} justifyContent="flex-end">
          {!hideTag && releaseStates[news.release_state] && (
            <Tag marginRight={{base: 'auto', md: 0}}>
              {releaseStates[news.release_state]}
            </Tag>
          )}
          {!hideActions && (
            <Menu>
              <MenuButton
                as={IconButton}
                icon={<DotsIcon />}
                variant="ghost"
                onClick={(e) => e.stopPropagation()}
              />
              <Portal>
                <MenuList>
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      onEdit(news);
                    }}
                  >
                    <Text fontSize="sm" fontWeight="bold">
                      Muokkaa
                    </Text>
                  </MenuItem>
                  {news.state !== 'archived' && (
                    <MenuItem
                      onClick={(e) => {
                        e.stopPropagation();
                        onArchive([news.id]);
                      }}
                    >
                      <Text fontSize="sm" fontWeight="bold">
                        Arkistoi
                      </Text>
                    </MenuItem>
                  )}
                  <MenuDivider />
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      onDelete([news.id]);
                    }}
                  >
                    <Text fontSize="sm" fontWeight="bold" color="red.600">
                      Poista
                    </Text>
                  </MenuItem>
                </MenuList>
              </Portal>
            </Menu>
          )}
        </HStack>
      </TableCell>
    </TableRow>
  );
});

export default NewsRow;
