import React, {useEffect, useState} from 'react';
import {
  Button,
  HStack,
  Text,
  Box,
  Flex,
  Spacer,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  Checkbox,
  Center,
  Spinner,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Stack,
  MenuDivider,
} from '@chakra-ui/react';
import {pick, includes, all} from 'ramda';
import {connect} from 'react-redux';
import {applyState} from 'utils/redux';
import {useDebounce} from 'utils/react';
import {createReferrerUrl} from 'utils/url';
import roles from 'constants/roles';
import effs from 'modules/users/usersPage/effects';
import sels from 'modules/users/usersPage/selectors';
import {initialState} from 'modules/users/usersPage/store';
import {PageHeader, Table, Content, TableHeader, Paginator, Card} from 'components';
import {PlusIcon, CloseIcon, SearchIcon} from 'components/icons';
import UserRow from './components/user-row';
import AddUserModal from './components/user-editor';
import RoleMassEditor from './components/role-mass-editor';

const Users = ({
  initialized,
  processing,
  loading,
  query,
  users,
  pagination,
  addUserModalOpen,
  selection,
  location,
  roleMassEditorOpen,
}) => {
  useEffect(() => {
    effs.initialize();
    return () => {
      effs.destroy();
    };
  }, []);

  const referrerUrl = createReferrerUrl(location);

  /* keep name filter in local state too and use it to update query debounced */
  const queryFilterName = query['filter[name]'];
  const [filterName, setFilterName] = useState(queryFilterName);
  const debouncedFilterName = useDebounce(filterName, 500);

  useEffect(() => {
    setFilterName(queryFilterName);
  }, [queryFilterName]);

  useEffect(() => {
    if (initialized && debouncedFilterName !== queryFilterName) {
      effs.updateQuery({
        'filter[name]': debouncedFilterName,
        'page[number]': 1,
      });
    }
  }, [debouncedFilterName]);

  return (
    <>
      <PageHeader
        title="Käyttäjät"
        actions={
          <Button
            onClick={() => effs.toggleAddUserModal()}
            leftIcon={<PlusIcon />}
            variant="primary-link"
          >
            Lisää uusi
          </Button>
        }
      >
        <Flex>
          <HStack spacing="1">
            <FormControl>
              <FormLabel>Hae käyttäjää</FormLabel>
              <InputGroup>
                <Input
                  value={filterName}
                  placeholder="Hakusana"
                  onChange={(e) => setFilterName(e.target.value)}
                />
                <InputRightElement pointerEvents="none" color="gray.600">
                  <SearchIcon />
                </InputRightElement>
              </InputGroup>
            </FormControl>

            <FormControl>
              <FormLabel>Rooli</FormLabel>
              <Select
                value={query['filter[role]']}
                onChange={(e) =>
                  effs.updateQuery({
                    'filter[role]': e.target.value,
                    'page[number]': 1,
                  })
                }
                variant="withLabel"
                placeholder="Valitse..."
              >
                {roles.map((role) => (
                  <option key={role.name} value={role.name}>
                    {role.title}
                  </option>
                ))}
              </Select>
            </FormControl>
          </HStack>

          <Spacer />

          <Flex marginLeft="3">
            <Button
              onClick={() => effs.updateQuery(initialState.query)}
              size="lg"
              leftIcon={<CloseIcon />}
              variant="link"
            >
              Tyhjennä
            </Button>
          </Flex>
        </Flex>
      </PageHeader>

      <Content>
        <TableHeader
          actions={
            <>
              <Box>
                <Menu>
                  <MenuButton
                    as={Button}
                    variant={selection.length ? 'primary' : 'unselected'}
                    disabled={!selection.length}
                    size="sm"
                  >
                    Muokkaa
                  </MenuButton>
                  <MenuList>
                    <MenuItem onClick={() => effs.toggleRoleMassEditor()}>
                      <Text fontSize="sm" fontWeight="bold">
                        Aseta rooli
                      </Text>
                    </MenuItem>
                    <MenuDivider />
                    <MenuItem onClick={() => effs.removeUsers(selection)}>
                      <Text fontSize="sm" fontWeight="bold" color="red.600">
                        Poista
                      </Text>
                    </MenuItem>
                  </MenuList>
                </Menu>
              </Box>
            </>
          }
        >
          <HStack spacing={4}>
            <Checkbox
              size="lg"
              colorScheme="gray"
              isChecked={!!users.length && all((u) => includes(u.id, selection), users)}
              onClick={(e) => e.stopPropagation()}
              onChange={(e) =>
                effs.selectUsers(
                  users.map((u) => ({
                    id: u.id,
                    selected: e.target.checked,
                  })),
                )
              }
            />
            <Text fontWeight="bold" fontSize="sm" textTransform="uppercase">
              {`${pagination.total} käyttäjää`}
            </Text>
          </HStack>
        </TableHeader>

        {loading ? (
          <Center>
            <Spinner size="xl" />
          </Center>
        ) : !users.length ? (
          <Card>
            <Text p={5}>Ei käyttäjiä</Text>
          </Card>
        ) : (
          <>
            <Table>
              {users.map((u) => (
                <UserRow
                  key={u.id}
                  user={u}
                  isSelected={includes(u.id, selection)}
                  select={effs.selectUsers}
                  onDelete={effs.removeUsers}
                  referrerUrl={referrerUrl}
                />
              ))}
            </Table>
            <Stack
              spacing={2}
              alignItems="center"
              justifyContent={{base: 'center', md: 'flex-end'}}
              direction={{base: 'column', md: 'row'}}
              marginTop="5"
            >
              <HStack spacing={2}>
                <Text textStyle="caption">Per sivu:</Text>
                <Select
                  value={query['page[size]']}
                  onChange={(e) =>
                    effs.updateQuery({
                      'page[size]': e.target.value,
                      'page[number]': 1,
                    })
                  }
                  variant="filled"
                  size="sm"
                  width="auto"
                >
                  <option value={15}>15</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                </Select>
              </HStack>
              <Paginator
                totalPages={pagination.last_page}
                currentPage={pagination.current_page}
                perPage={pagination.per_page}
                onPageSelect={(pageNum) => effs.updateQuery({'page[number]': pageNum})}
                disabled={loading}
              />
            </Stack>
          </>
        )}
      </Content>

      {addUserModalOpen && (
        <AddUserModal
          isOpen={addUserModalOpen}
          onClose={() => effs.toggleAddUserModal()}
          onSubmit={effs.createUser}
          processing={processing}
        />
      )}

      {roleMassEditorOpen && (
        <RoleMassEditor
          isOpen={roleMassEditorOpen}
          onClose={() => effs.toggleRoleMassEditor()}
          onSubmit={({role}) => effs.updateUsersRole({ids: selection, role})}
          processing={processing}
          selection={selection}
        />
      )}
    </>
  );
};

export default connect(
  applyState(
    pick(
      [
        'initialized',
        'processing',
        'loading',
        'query',
        'users',
        'pagination',
        'addUserModalOpen',
        'selection',
        'roleMassEditorOpen',
      ],
      sels,
    ),
  ),
)(Users);
