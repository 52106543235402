import React from 'react';
import {
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalHeader,
  Stack,
  FormControl,
  FormLabel,
  Button,
  HStack,
  FormErrorMessage,
} from '@chakra-ui/react';
import {parseISO} from 'date-fns';
import {ReactDatepicker} from 'components';
import {useForm} from 'react-hook-form';

const ShiftPauseModal = ({isOpen, onClose, onSubmit, processing, shift}) => {
  const {handleSubmit, errors, control} = useForm({
    defaultValues: {from_date: '', to_date: ''},
  });

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader
          display="flex"
          flexDir="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Text fontWeight="bold" fontSize="sm" textTransform="uppercase">
            Lisää tauko
          </Text>
          <HStack spacing={4}>
            <Button variant="link" textDecor="underline" onClick={() => onClose()}>
              Peruuta
            </Button>
            <Button
              type="submit"
              form="add-pause-form"
              variant="primary"
              isLoading={processing}
            >
              <span>Lisää</span>
            </Button>
          </HStack>
        </ModalHeader>
        <ModalBody>
          <form onSubmit={handleSubmit(onSubmit)} id="add-pause-form">
            <Stack spacing={2}>
              <FormControl isInvalid={errors.from_date}>
                <FormLabel>Aloitusaika</FormLabel>
                <ReactDatepicker
                  name="from_date"
                  control={control}
                  showTimeSelect
                  dateFormat="dd.MM.yyyy HH:mm"
                  openToDate={
                    shift.shift_start_at ? parseISO(shift.shift_start_at) : new Date()
                  }
                  rules={{required: 'Pakollinen kenttä'}}
                />
                <FormErrorMessage>
                  {errors.from_date && errors.from_date.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors.to_date}>
                <FormLabel>Lopetusaika</FormLabel>
                <ReactDatepicker
                  name="to_date"
                  control={control}
                  showTimeSelect
                  dateFormat="dd.MM.yyyy HH:mm"
                  openToDate={
                    shift.shift_start_at ? parseISO(shift.shift_start_at) : new Date()
                  }
                  rules={{required: 'Pakollinen kenttä'}}
                />
                <FormErrorMessage>
                  {errors.to_date && errors.to_date.message}
                </FormErrorMessage>
              </FormControl>
            </Stack>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ShiftPauseModal;
