/* eslint-disable max-len */
import React from 'react';
import {Icon} from '@chakra-ui/react';

const NavDocsIcon = (props) => (
  <Icon
    viewBox="0 0 24 24"
    stroke="currentColor"
    fill="currentColor"
    strokeWidth="0"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.5,1.5 L2.25,1.5 C1.00735931,1.5 0,2.50735931 0,3.75 L0,20.25 C0,21.4926407 1.00735931,22.5 2.25,22.5 L21.75,22.5 C22.9926407,22.5 24,21.4926407 24,20.25 L24,6.75 L23.9948092,6.59595119 C23.9156449,5.42516159 22.940864,4.5 21.75,4.5 L10.875,4.5 L9.3,2.4 C8.87507764,1.83343685 8.20820393,1.5 7.5,1.5 Z M2.25,3 L7.5,3 C7.73606798,3 7.95835921,3.11114562 8.1,3.3 L9.9,5.7 C10.0416408,5.88885438 10.263932,6 10.5,6 L21.75,6 C22.1642136,6 22.5,6.33578644 22.5,6.75 L22.5,20.25 C22.5,20.6642136 22.1642136,21 21.75,21 L2.25,21 C1.83578644,21 1.5,20.6642136 1.5,20.25 L1.5,3.75 C1.5,3.33578644 1.83578644,3 2.25,3 Z"
    />
  </Icon>
);

export default NavDocsIcon;
