import cookies from 'js-cookie';
import * as Sentry from '@sentry/react';
import _acts from './boundActions';
import {createEffects} from 'utils/events';
import {P} from 'utils/types';
import {isApiTokenError} from 'utils/app';
import services from 'services';
import {guardHandled, logInfo, handleAsFatal} from 'io/errors';
import {decorateWithNotificationsEff} from 'io/app';
import {getUser, logout, search, getVehicles} from './io';
import namespace from './namespace';

let acts;
_acts.then((x) => (acts = x));

let effects = {};
let types = {};

const clearStoredLogin = () => {
  cookies.remove('api_token', {domain: window.location.hostname});
};

const afterApiTokenReceivalEff = guardHandled(async (apiToken) => {
  try {
    const user = await getUser();
    acts.setUser(user);
    Sentry.setUser({id: user.id, username: user.name, email: user.email});
  } catch (e) {
    // keep from crashing on invalid api token, handling is done in an api hook
    if (isApiTokenError(e)) {
      logInfo(e);
      throw e;
    }
    return handleAsFatal(e);
  }

  // get vehicles in common since we need them in every module anyways
  const vehicles = await decorateWithNotificationsEff(
    {id: 'get-vehicles', failureStyle: 'warning'},
    getVehicles(),
  );
  acts.setVehicles(vehicles);
});

effects.initialize = guardHandled(async () => {
  const apiToken = cookies.get('api_token');
  acts.setApiToken(apiToken);

  if (apiToken) {
    afterApiTokenReceivalEff(apiToken);
  }
});

effects.afterLogin = async (apiToken) => {
  acts.setApiToken(apiToken);
  cookies.set('api_token', apiToken, {
    domain: window.location.hostname,
    expires: 365,
  });
  afterApiTokenReceivalEff(apiToken);
};
types.afterLogin = P.String;

effects.logout = guardHandled(async () => {
  try {
    await logout();
  } catch (e) {
    // fail silently
    logInfo(e);
  }
  acts.clearLogin();
  clearStoredLogin();
});

effects.searchClients = guardHandled(async ({text, callback}) => {
  const clients = await decorateWithNotificationsEff(
    {
      id: 'search-clients',
      failureStyle: 'warning',
    },
    search({query: text, type: 'client'}),
  );
  callback(clients?.map((c) => c.item) || []);
});
types.searchClients = P.Object;

effects.searchDrives = guardHandled(async ({text, callback}) => {
  const drives = await decorateWithNotificationsEff(
    {
      id: 'search-drives',
      failureStyle: 'warning',
    },
    search({query: text, type: 'drive'}),
  );
  callback(drives?.map((d) => d.item) || []);
});
types.searchDrives = P.Object;

export default createEffects(namespace, services.get('channel').dispatch, types, effects);
