import React from 'react';
import {Table as ChakraTable, Tbody} from '@chakra-ui/react';

const Table = ({children, ...rest}) => {
  return (
    <ChakraTable as="div" variant="unstyled">
      <Tbody as="div" display="grid" gridGap={{base: '1px', md: 2}} {...rest}>
        {children}
      </Tbody>
    </ChakraTable>
  );
};

export default Table;
