/* eslint-disable max-len */
import React from 'react';
import {Icon} from '@chakra-ui/react';

const GroupIcon = (props) => (
  <Icon
    viewBox="0 0 16 16"
    stroke="currentColor"
    fill="currentColor"
    strokeWidth="0"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.5,9.70833333 C13.4329966,9.70833333 15,11.2753367 15,13.2083333 C15,13.3694164 14.8694164,13.5 14.7083333,13.5 L14.7083333,13.5 L10.9166667,13.5 C10.7555836,13.5 10.625,13.3694164 10.625,13.2083333 C10.6249111,12.2441652 10.3573515,11.2989225 9.85208333,10.47775 C9.80900806,10.4077155 9.79749358,10.322753 9.8203747,10.2437799 C9.84325581,10.1648067 9.89840688,10.0991594 9.97225,10.063 C10.447769,9.82976093 10.9703597,9.70844167 11.5,9.70833333 Z M5.375,8.83333333 C7.79124578,8.83333333 9.75,10.7920876 9.75,13.2083333 C9.75,13.3694164 9.61941639,13.5 9.45833333,13.5 L9.45833333,13.5 L1.29166667,13.5 C1.13058361,13.5 1,13.3694164 1,13.2083333 C1,10.7920876 2.95875422,8.83333333 5.375,8.83333333 Z M11.4673333,5.04166667 C12.6754562,5.04166667 13.6548333,6.02104378 13.6548333,7.22916667 C13.6548333,8.43728956 12.6754562,9.41666667 11.4673333,9.41666667 C10.2592104,9.41666667 9.27983333,8.43728956 9.27983333,7.22916667 C9.27983333,6.02104378 10.2592104,5.04166667 11.4673333,5.04166667 Z M5.375,3 C6.90528899,3 8.14583333,4.24054434 8.14583333,5.77083333 C8.14583333,7.30112233 6.90528899,8.54166667 5.375,8.54166667 C3.84471101,8.54166667 2.60416667,7.30112233 2.60416667,5.77083333 C2.60416667,4.24054434 3.84471101,3 5.375,3 Z"
    />
  </Icon>
);

export default GroupIcon;
