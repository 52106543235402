/* eslint-disable max-len */
import React from 'react';
import {Icon} from '@chakra-ui/react';

const ChevronLeftIcon = (props) => (
  <Icon
    viewBox="0 0 16 16"
    stroke="currentColor"
    fill="currentColor"
    strokeWidth="0"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4,7.98580912 C3.99959141,7.6549727 4.14169013,7.33998722 4.38998353,7.12134758 L9.96573544,2.21606584 C10.3500138,1.89361555 10.9214295,1.93691376 11.2527329,2.31358624 C11.5840362,2.69025872 11.5540439,3.2625271 11.1851812,3.60250415 L6.30939813,7.8918133 C6.28232196,7.91554683 6.26679541,7.94980358 6.26679541,7.98580912 C6.26679541,8.02181467 6.28232196,8.05607141 6.30939813,8.07980494 L11.1851812,12.3691141 C11.4418382,12.5844765 11.5617145,12.921959 11.4984227,13.2509696 C11.435131,13.5799802 11.1986011,13.8489009 10.8803603,13.9536703 C10.5621196,14.0584398 10.2120964,13.9826209 9.96573544,13.7555524 L4.39198344,8.85177059 C4.143089,8.63275659 4.0003157,8.31734415 4,7.98580912 Z"
    />
  </Icon>
);

export default ChevronLeftIcon;
