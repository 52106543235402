import {sortWith, ascend, prop} from 'ramda';
import namespace from './namespace';
import {scopedStore} from 'utils/redux';

export const initialState = {
  initialized: false,
  apiToken: null,
  user: null,
  vehicles: [],
};

export const cases = {
  // the app is marked initialized as soon as the possible apiToken has been read and we know whether to show a login screen or not
  setApiToken: (st, all, apiToken) => {
    return {...st, apiToken, initialized: true};
  },

  setUser: (st, all, user) => {
    return {...st, user};
  },

  clearLogin: (st) => {
    return {...st, apiToken: null, user: null};
  },

  setVehicles: (st, all, payload) => {
    return {
      ...st,
      vehicles: sortWith(
        [ascend(prop('number')), ascend(prop('registration_number'))],
        payload,
      ),
    };
  },
};

export const {actions, reducer} = scopedStore(namespace, cases, initialState);
