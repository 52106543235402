import React, {useEffect, useState, useMemo} from 'react';
import {IconButton, HStack, Spinner, Text, Center} from '@chakra-ui/react';
import {pick} from 'ramda';
import {connect} from 'react-redux';
import {applyState} from 'utils/redux';
import commonEffs from 'modules/common/effects';
import effs from 'modules/docs/effects';
import sels from 'modules/docs/selectors';
import commonSels from 'modules/common/selectors';
import {sortByTitle} from 'modules/docs/utils';
import {
  PageHeader,
  Content,
  File,
  FileList,
  Card,
  Folder,
  DocumentEditor,
  DocumentPreview,
  DocumentUploader,
} from 'components';
import {AddFileIcon, UploadIcon, AddFolderIcon} from 'components/icons';
import {tags} from 'dicts/docs';
import FolderEditor from './components/folder-editor';
import FolderBreadcrumbs from './components/folder-breadcrumbs';

const tagOpts = Object.values(tags).map((t) => ({value: t, label: t}));

const Docs = ({
  processing,
  loading,
  activeFolder,
  rootFolders,
  rootDocuments,
  folderEditorOpen,
  folderInEdit,
  folderEditorDefaultVals,
  documentEditorOpen,
  documentEditorDefaultVals,
  documentInEdit,
  documentPreviewOpen,
  documentInPreview,
  documentUploaderOpen,
  documentInUploader,
  documentUploaderDefaultVals,
  vehicles,
  isAdmin,
}) => {
  useEffect(() => {
    effs.initialize();
    return () => {
      effs.destroy();
    };
  }, []);

  const folders = useMemo(
    () => sortByTitle(activeFolder ? activeFolder.folders : rootFolders),
    [activeFolder, rootFolders],
  );

  const documents = useMemo(
    () => sortByTitle(activeFolder ? activeFolder.documents : rootDocuments),
    [activeFolder, rootDocuments],
  );

  const [breadcrumbs, setBreadCrumbs] = useState([]);

  const setActiveFolder = (folder) => {
    effs.setActiveFolder(folder);
    if (folder) {
      // navigating to parent folder if index found
      const idx = breadcrumbs.findIndex((x) => x.id === folder.id);
      const newBreadcrumbs =
        idx > -1 ? breadcrumbs.slice(0, idx + 1) : [...breadcrumbs, folder];
      setBreadCrumbs(newBreadcrumbs);
    } else {
      // navigating to root, clear breadcrumbs
      setBreadCrumbs([]);
    }
  };

  return (
    <>
      <PageHeader
        title={
          <FolderBreadcrumbs
            crumbs={breadcrumbs}
            activeCrumb={activeFolder}
            setActive={setActiveFolder}
          />
        }
        actions={
          isAdmin && (
            <HStack spacing={2}>
              <IconButton
                onClick={() => effs.toggleDocumentEditor()}
                icon={<AddFileIcon />}
                variant="primary"
              />
              <IconButton
                onClick={() => effs.toggleDocumentUploader()}
                icon={<UploadIcon />}
                variant="primary"
              />
              <IconButton
                onClick={() => effs.toggleFolderEditor()}
                icon={<AddFolderIcon />}
                variant="primary"
              />
            </HStack>
          )
        }
      />

      <Content>
        {loading ? (
          <Center>
            <Spinner size="xl" />
          </Center>
        ) : (
          <Card>
            {!folders.length && !documents.length ? (
              <Text p={5}>Ei dokumentteja</Text>
            ) : (
              <FileList>
                {folders.map((folder) => (
                  <Folder
                    key={folder.id}
                    onOpen={() => setActiveFolder(folder)}
                    onEdit={() => effs.toggleFolderEditor(folder)}
                    onDelete={() => effs.removeFolder(folder.id)}
                    hideActions={!isAdmin}
                  >
                    {folder.title}
                  </Folder>
                ))}
                {documents.map((doc) => (
                  <File
                    key={doc.id}
                    type={doc.filetype}
                    modified={doc.updated_at}
                    onOpen={() =>
                      doc.filetype === 'document'
                        ? effs.toggleDocumentPreview(doc)
                        : window.open(doc.url, '_blank')
                    }
                    onEdit={() =>
                      doc.filetype === 'document'
                        ? effs.toggleDocumentEditor(doc)
                        : effs.toggleDocumentUploader(doc)
                    }
                    onDelete={() => effs.removeDocument(doc.id)}
                    hideActions={!isAdmin}
                  >
                    {doc.title}
                  </File>
                ))}
              </FileList>
            )}
          </Card>
        )}
      </Content>

      {folderEditorOpen && (
        <FolderEditor
          isOpen={folderEditorOpen}
          toggleModal={() => effs.toggleFolderEditor()}
          onSubmit={(data) =>
            folderInEdit ? effs.updateFolder(data) : effs.createFolder(data)
          }
          processing={processing}
          isNew={!folderInEdit}
          defaultValues={folderEditorDefaultVals}
        />
      )}

      {documentEditorOpen && (
        <DocumentEditor
          isOpen={documentEditorOpen}
          onClose={() => effs.toggleDocumentEditor()}
          onSubmit={(data) =>
            documentInEdit
              ? effs.updateDocument({id: documentInEdit.id, ...data})
              : effs.createDocument(data)
          }
          processing={processing}
          document={documentInEdit}
          defaultValues={documentEditorDefaultVals}
          vehicles={vehicles}
          searchClients={commonEffs.searchClients}
          searchDrives={commonEffs.searchDrives}
          tags={tagOpts}
        />
      )}

      {documentPreviewOpen && (
        <DocumentPreview
          isOpen={documentPreviewOpen}
          document={documentInPreview}
          onClose={() => effs.toggleDocumentPreview()}
          onEdit={() => effs.toggleDocumentEditor(documentInPreview)}
          hideEdit={!isAdmin}
        />
      )}

      {documentUploaderOpen && (
        <DocumentUploader
          isOpen={documentUploaderOpen}
          onClose={() => effs.toggleDocumentUploader()}
          onSubmit={(data) =>
            documentInUploader
              ? effs.updateDocument({id: documentInUploader.id, ...data})
              : effs.uploadDocuments(data)
          }
          processing={processing}
          document={documentInUploader}
          defaultValues={documentUploaderDefaultVals}
          vehicles={vehicles}
          searchClients={commonEffs.searchClients}
          searchDrives={commonEffs.searchDrives}
        />
      )}
    </>
  );
};

export default connect(
  applyState({
    ...pick(
      [
        'processing',
        'loading',
        'activeFolder',
        'rootFolders',
        'rootDocuments',
        'folderEditorOpen',
        'folderInEdit',
        'folderEditorDefaultVals',
        'documentEditorOpen',
        'documentEditorDefaultVals',
        'documentInEdit',
        'documentPreviewOpen',
        'documentInPreview',
        'documentUploaderOpen',
        'documentInUploader',
        'documentUploaderDefaultVals',
      ],
      sels,
    ),
    vehicles: commonSels.vehicles,
    isAdmin: commonSels.isAdmin,
  }),
)(Docs);
