import React from 'react';
import {
  VStack,
  Link,
  Box,
  DrawerOverlay,
  DrawerContent,
  DrawerBody,
  Drawer,
  Stack,
} from '@chakra-ui/react';
import {Link as RouterLink, NavLink as RouterNavLink} from 'react-router-dom';
import {Symbol, NavBusIcon, NavWarningIcon, NavDocsIcon, NavNewsIcon} from './icons';
import shadows from '../theme/foundations/shadows';
import {sidebarWidth} from 'constants/styles';

const navStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  boxSize: 16,
  color: 'violet.300',
  borderRadius: 'md',
  _hover: {
    bg: 'violet.800',
  },
  _focus: {
    bg: 'violet.800',
    boxShadow: shadows.outline,
  },
};

const NavLink = ({to, exact = false, onClick = () => {}, children}) => (
  <Link
    to={to}
    exact={exact}
    as={RouterNavLink}
    onClick={onClick}
    {...navStyles}
    activeStyle={{
      color: '#fff',
      background: '#2e1471',
    }}
  >
    {children}
  </Link>
);

const Nav = ({showDrives, showFaults, showNews, showDocs, closeDrawer}) => (
  <>
    {showDrives && (
      <NavLink to="/drives" key="drives" onClick={closeDrawer}>
        <NavBusIcon boxSize="6" />
      </NavLink>
    )}
    {showFaults && (
      <NavLink to="/faults" key="faults" onClick={closeDrawer}>
        <NavWarningIcon boxSize="6" />
      </NavLink>
    )}
    {showNews && (
      <NavLink to="/news" key="news" onClick={closeDrawer}>
        <NavNewsIcon boxSize="6" />
      </NavLink>
    )}
    {showDocs && (
      <NavLink to="/docs" key="docs" onClick={closeDrawer}>
        <NavDocsIcon boxSize="6" />
      </NavLink>
    )}
  </>
);

const Logo = ({closeDrawer}) => (
  <Box boxSize="16" bg="white" borderRadius="lg">
    <RouterLink to="/" onClick={closeDrawer}>
      <Symbol boxSize="12" margin="2" />
    </RouterLink>
  </Box>
);

const Sidebar = ({
  showDrives,
  showFaults,
  showNews,
  showDocs,
  drawerOpen,
  closeDrawer,
}) => {
  const navProps = {showDrives, showFaults, showNews, showDocs, closeDrawer};
  const logoProps = {closeDrawer};

  return (
    <>
      <VStack
        as="aside"
        display={{base: 'none', md: 'flex'}}
        width={sidebarWidth}
        bg="violet.700"
        color="white"
        height="100vh"
        spacing="10"
        position="sticky"
        paddingY="6"
        top={0}
        flexShrink="0"
      >
        <Logo {...logoProps} />
        <VStack marginTop="10" spacing="2">
          <Nav {...navProps} />
        </VStack>
      </VStack>

      <Drawer placement="left" isOpen={drawerOpen} onClose={closeDrawer}>
        <DrawerOverlay>
          <DrawerContent bg="violet.700" color="white" maxW={sidebarWidth}>
            <DrawerBody paddingY="6">
              <Stack spacing="10" justify="flex-start">
                <Logo {...logoProps} />
                <Stack spacing="2">
                  <Nav {...navProps} />
                </Stack>
              </Stack>
            </DrawerBody>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </>
  );
};

export default Sidebar;
