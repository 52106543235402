import {evolve} from 'ramda';
import {replace} from 'utils/objects';
import {initialState} from './store';

export const parseUrlQuery = (query) => {
  const vals = evolve(
    {
      tabIndex: Number,
    },
    query,
  );
  return replace(vals, initialState.query);
};

export const formatUserEditorDefaultVals = (user) => {
  if (!user) return {};

  return {
    name: user.name,
    email: user.email,
    phone: user.phone,
    role: user.role,
  };
};
