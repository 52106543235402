export default {
  variants: {
    filled: {
      field: {
        bg: 'gray.50',
        borderRadius: 'md',
        border: '1px solid',
        borderColor: 'gray.300',
        _hover: {
          bg: 'gray.50',
          borderColor: 'gray.400',
        },
        _focus: {
          bg: 'gray.50',
          borderColor: 'gray.600',
          boxShadow: 'md',
        },
      },
    },
    withLabel: {
      field: {
        paddingTop: 4,
        bg: 'gray.50',
        fontSize: 'sm',
        border: '1px solid',
        borderColor: 'gray.300',
        _hover: {
          borderColor: 'gray.400',
        },
        _focus: {
          borderColor: 'gray.600',
          boxShadow: 'md',
        },
        _invalid: {
          borderColor: 'red.500',
          boxShadow: '0 0 0 1px #ef4444',
        },
      },
    },
  },
  defaultProps: {
    size: 'lg',
  },
};
