import React, {useRef} from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  Button,
  Text,
} from '@chakra-ui/react';
import {connect} from 'react-redux';
import {pick} from 'ramda';
import {applyState} from 'utils/redux';
import sels from 'modules/confirmer/selectors';
import effs from 'modules/confirmer/effects';

const Confirmer = ({opts}) => {
  const cancelRef = useRef(null);

  const {
    show,
    showCancel,
    showOk,
    cancelText,
    okText,
    title,
    message,
    okStyle,
    okDisabled,
    dangerText,
  } = opts;

  if (!show) return null;

  return (
    <AlertDialog
      isOpen={show}
      leastDestructiveRef={cancelRef}
      onClose={() => effs.cancel()}
      preserveScrollBarGap
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader>{title}</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            {message}
            {dangerText ? (
              <Text fontSize="sm" fontWeight="bold" color="red.600" mt={2}>
                {dangerText}
              </Text>
            ) : null}
          </AlertDialogBody>
          <AlertDialogFooter>
            {showCancel && (
              <Button size="sm" ref={cancelRef} onClick={() => effs.cancel()}>
                {cancelText}
              </Button>
            )}
            {showOk && (
              <Button
                size="sm"
                disabled={okDisabled}
                variant={okStyle || 'primary'}
                onClick={() => effs.ok()}
                ml={3}
              >
                {okText}
              </Button>
            )}
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default connect(applyState(pick(['opts'], sels)))(Confirmer);
