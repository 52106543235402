import React from 'react';
import {Box, Stack, Text} from '@chakra-ui/react';
import {Card, CardHeader} from 'components';

const DriveProgram = ({program}) => {
  return (
    <Card>
      <CardHeader>Ajo-ohjelma / Reitti</CardHeader>
      {program && program.length ? (
        <Stack spacing="2" margin="5">
          {program.map((text, index) => (
            <Box
              key={index}
              bg="gray.100"
              borderRadius="md"
              paddingX="5"
              paddingY="3"
              fontSize="sm"
            >
              {text}
            </Box>
          ))}
        </Stack>
      ) : (
        <Text p={5}>Ei ajo-ohjelmaa</Text>
      )}
    </Card>
  );
};

export default DriveProgram;
