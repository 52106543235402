import React from 'react';
import {Textarea as ChakraTextarea} from '@chakra-ui/react';
import FieldBase from './field-base';

function Textarea({
  title,
  placeholder = '',
  cols = 40,
  rows = 10,
  value = undefined,
  onChange = () => {},
}) {
  return (
    <FieldBase title={title}>
      <ChakraTextarea
        fontSize="sm"
        maxWidth="full"
        variant="unstyled"
        cols={cols}
        rows={rows}
        resize="vertical"
        paddingY="0"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
    </FieldBase>
  );
}

export default Textarea;
