import React from 'react';
import {
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalHeader,
  Stack,
  Button,
  HStack,
  Heading,
  Center,
  Wrap,
  WrapItem,
  FormControl,
  FormLabel,
  Textarea,
} from '@chakra-ui/react';
import {useForm} from 'react-hook-form';
import {useDropzone} from 'react-dropzone';
import {UploadIcon} from 'components/icons';
import {FileTypeIcon} from 'components';

const ShiftDriveExceptionModal = ({isOpen, onClose, onSubmit, processing}) => {
  const {handleSubmit, register, errors} = useForm();

  const {acceptedFiles, getRootProps, getInputProps} = useDropzone();

  const submit = (data) => {
    onSubmit({...data, files: acceptedFiles});
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader
          display="flex"
          flexDir="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Text fontWeight="bold" fontSize="sm" textTransform="uppercase">
            Ilmoita poikkeus
          </Text>
          <HStack spacing={4}>
            <Button variant="link" textDecor="underline" onClick={() => onClose()}>
              Peruuta
            </Button>
            <Button
              type="submit"
              form="shift-drive-exception-form"
              variant="primary"
              isLoading={processing}
            >
              <span>Lisää</span>
            </Button>
          </HStack>
        </ModalHeader>
        <ModalBody>
          <form onSubmit={handleSubmit(submit)} id="shift-drive-exception-form">
            <Stack spacing={4}>
              <FormControl isInvalid={errors.description}>
                <FormLabel variant="textarea">Kuvaus</FormLabel>
                <Textarea
                  name="description"
                  variant="withLabel"
                  ref={register({required: 'Pakollinen kenttä'})}
                  placeholder="Kirjoita kuvaus mitä poikkeavaa ajossa tapahtui?"
                  height="8rem"
                />
              </FormControl>
              <Center
                flex="1"
                bg="gray.200"
                {...getRootProps({className: 'dropzone'})}
                borderRadius="md"
                p={12}
              >
                <input {...getInputProps()} />
                {acceptedFiles.length ? (
                  <Wrap align="center" justify="center" spacing={8}>
                    {acceptedFiles.map((f) => (
                      <WrapItem key={f.path}>
                        <Stack align="center" spacing={4}>
                          <FileTypeIcon type={f.type} />
                          <Heading
                            color="gray.600"
                            textStyle="h2"
                            maxW="10rem"
                            overflow="hidden"
                            textOverflow="ellipsis"
                            whiteSpace="nowrap"
                            textAlign="center"
                          >
                            {f.path}
                          </Heading>
                        </Stack>
                      </WrapItem>
                    ))}
                  </Wrap>
                ) : (
                  <Stack spacing={2} align="center" userSelect="none">
                    <UploadIcon w={16} h={16} color="gray.600" mb={4} />
                    <Heading color="gray.600" textStyle="h2">
                      Lisää liite
                    </Heading>
                    <Text fontSize="sm" color="gray.700">
                      Raahaa tiedostot koneeltasi tai avaa tiedostoselain klikkaamalla
                    </Text>
                  </Stack>
                )}
              </Center>
            </Stack>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ShiftDriveExceptionModal;
