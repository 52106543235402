import namespace from './namespace';
import {scopedSelectors} from 'utils/redux';
import {createSelector} from 'reselect';
import {formatFetchableQuery} from './utils';

let sels = {};

sels.initialized = (st) => st.initialized;

sels.processing = (st) => st.processing;

sels.loading = (st) => st.loading;

sels.addFaultModalOpen = (st) => st.addFaultModalOpen;

sels.query = (st) => st.query;

sels.queryFetchable = createSelector(sels.query, (q) => formatFetchableQuery(q));

sels.faults = (st) => st.faults;

sels.pagination = (st) => st.pagination;

sels.selection = (st) => st.selection;

sels.tags = (st) => st.tags;

export default scopedSelectors(namespace, sels);
