import React from 'react';
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  MenuGroup,
  Text,
  Button,
} from '@chakra-ui/react';
import {states} from 'dicts/faults';

const stateOpts = Object.entries(states).map(([value, label]) => ({value, label}));

const MassEditButton = ({
  selection = [],
  updateFaults = () => {},
  removeFaults = () => {},
}) => {
  return (
    <Menu>
      <MenuButton
        as={Button}
        variant={selection.length ? 'primary' : 'unselected'}
        disabled={!selection.length}
        size="sm"
      >
        Muokkaa
      </MenuButton>
      <MenuList>
        <MenuItem
          onClick={() =>
            updateFaults({
              ids: selection,
              state: 'archived',
            })
          }
        >
          <Text fontSize="sm" fontWeight="bold">
            Arkistoi
          </Text>
        </MenuItem>
        <MenuDivider />
        <MenuGroup title="Vaihda tila">
          {stateOpts
            .filter((s) => s.value !== 'archived')
            .map((opt) => (
              <MenuItem
                key={opt.value}
                onClick={() =>
                  updateFaults({
                    ids: selection,
                    state: opt.value,
                  })
                }
              >
                <Text fontSize="sm" fontWeight="bold">
                  {opt.label}
                </Text>
              </MenuItem>
            ))}
        </MenuGroup>
        <MenuDivider />
        <MenuItem onClick={() => removeFaults(selection)}>
          <Text fontSize="sm" fontWeight="bold" color="red.600">
            Poista
          </Text>
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

export default MassEditButton;
