import namespace from './namespace';
import {scopedStore} from 'utils/redux';

export const initialState = {
  previewOpen: false,
  editorOpen: false,
  uploaderOpen: false,
  document: null,
  processing: false,
  type: 'document',
};

export const cases = {
  openPreview: (st, all, payload) => {
    const {document, type} = payload;
    return {...st, previewOpen: true, document, type};
  },
  openEditor: (st, all, payload) => {
    const {document, type} = payload;
    return {...st, editorOpen: true, document, type};
  },
  openUploader: (st, all, payload) => {
    return {...st, uploaderOpen: true, document: payload};
  },
  setProcessing: (st, all, payload) => {
    return {...st, processing: payload};
  },
  _openEditor: (st) => {
    return {...st, editorOpen: true};
  },
  reset: (st) => {
    return initialState;
  },
};

export const {actions, reducer} = scopedStore(namespace, cases, initialState);
