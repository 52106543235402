import {createSelector} from 'reselect';
import namespace from './namespace';
import {scopedSelectors} from 'utils/redux';
import {adminRoles, driverRoles} from './constants';

let sels = {};

sels.initialized = (st) => st.initialized;

sels.apiToken = (st) => st.apiToken;

sels.loggedIn = (st, all) => !!sels.apiToken(st, all);

sels.user = (st) => st.user;

sels.userRole = createSelector(sels.user, (user) => (user ? user.role : null));

sels.isAdmin = createSelector(sels.userRole, (role) => adminRoles.includes(role));

sels.isDriver = createSelector(sels.userRole, (role) => driverRoles.includes(role));

sels.isRepairer = createSelector(sels.userRole, (role) => role === 'repairer');

sels.isSubcontractor = createSelector(sels.user, (user) => user?.name?.startsWith('X'));

sels.vehicles = (st) => st.vehicles;

export default scopedSelectors(namespace, sels);
