import React, {useEffect} from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalHeader,
  Stack,
  Button,
  HStack,
  FormControl,
  FormLabel,
  Input,
  Textarea,
} from '@chakra-ui/react';
import {useForm} from 'react-hook-form';
import {RadioButtonGroup, TruncatedText, ReactSelect} from 'components';

const EditFaultModal = ({
  isOpen,
  onClose,
  onSubmit,
  processing,
  vehicles,
  defaultValues,
}) => {
  const {handleSubmit, register, errors, reset, control} = useForm({defaultValues});

  useEffect(() => {
    // update form default values when defaultValues prop changes
    reset(defaultValues);
  }, [defaultValues, reset]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader
          display="flex"
          flexDir="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <TruncatedText fontWeight="bold" fontSize="sm" textTransform="uppercase" mr={2}>
            Muokkaa ilmoitusta
          </TruncatedText>
          <HStack spacing={4}>
            <Button variant="link" textDecor="underline" onClick={() => onClose()}>
              Peruuta
            </Button>
            <Button
              type="submit"
              form="edit-fault-form"
              variant="primary"
              isLoading={processing}
            >
              <span>Tallenna</span>
            </Button>
          </HStack>
        </ModalHeader>
        <ModalBody>
          <form onSubmit={handleSubmit(onSubmit)} id="edit-fault-form">
            <Stack spacing={4}>
              <RadioButtonGroup
                name="type"
                options={[
                  {
                    value: 'fault',
                    label: 'Vikailmoitus',
                  },
                  {
                    value: 'damage',
                    label: 'Vahinkoilmoitus',
                  },
                ]}
                ref={register}
                defaultValue={defaultValues.type}
              />
              <HStack spacing={4}>
                <FormControl isInvalid={errors.title} flex={2}>
                  <FormLabel>Otsikko</FormLabel>
                  <Input
                    name="title"
                    type="text"
                    placeholder="Lisää kuvaava otsikko"
                    ref={register({
                      required: 'Pakollinen kenttä',
                    })}
                  />
                </FormControl>
                <FormControl isInvalid={errors.vehicle_id} flex={1}>
                  <FormLabel>Auto</FormLabel>
                  <ReactSelect
                    name="vehicle_id"
                    control={control}
                    options={vehicles}
                    isClearable
                    getOptionValue={(opt) => opt.id}
                    getOptionLabel={(opt) => `${opt.number} (${opt.registration_number})`}
                  />
                </FormControl>
              </HStack>
              <FormControl>
                <FormLabel variant="textarea">Kuvaus</FormLabel>
                <Textarea
                  name="description"
                  variant="withLabel"
                  ref={register}
                  placeholder="Kirjoita kuvaus viasta tai vahingosta"
                  height="8rem"
                />
              </FormControl>
            </Stack>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default EditFaultModal;
