import {createSelector} from 'reselect';
import namespace from './namespace';
import {scopedSelectors} from 'utils/redux';
import {formatEditFaultDefaultVals, formatFetchableQuery} from './utils';

let sels = {};

sels.fault = (st) => st.fault;

sels.editFaultModalOpen = (st) => st.editFaultModalOpen;

sels.addAttachmentModalOpen = (st) => st.addAttachmentModalOpen;

sels.addLogModalOpen = (st) => st.addLogModalOpen;

sels.editFaultDefaultVals = createSelector(sels.fault, (fault) =>
  formatEditFaultDefaultVals(fault),
);

sels.vehicleFaults = (st) => st.vehicleFaults;

sels.vehicleFaultsPagination = (st) => st.vehicleFaultsPagination;

sels.vehicleFaultsLoading = (st) => st.vehicleFaultsLoading;

sels.processing = (st) => st.processing;

sels.loading = (st) => st.loading;

sels.selection = (st) => st.selection;

sels.query = (st) => st.query;

sels.queryFetchable = createSelector([sels.query, sels.fault], (query, fault) => ({
  ...formatFetchableQuery(query),
  'filter[vehicle_id]': fault?.vehicle?.id,
}));

sels.hasFilters = createSelector([sels.query], (query) => {
  if (
    query['filter[state]'] ||
    query['filter[affects_drivability]'] ||
    query['filter[type]'] ||
    query['filter[tags]'] ||
    query['filter[title]']
  ) {
    return true;
  }
  return false;
});

sels.tags = (st) => st.tags;

sels.initialized = (st) => st.initialized;

export default scopedSelectors(namespace, sels);
