/* eslint-disable max-len */
import React from 'react';
import {Icon} from '@chakra-ui/react';

const ChevronRightIcon = (props) => (
  <Icon
    viewBox="0 0 16 16"
    stroke="currentColor"
    fill="currentColor"
    strokeWidth="0"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.5005322,8 C11.5009418,8.33162075 11.3585062,8.64735299 11.1096241,8.86651096 L5.52065347,13.7834219 C5.13546406,14.1066367 4.56269367,14.0632358 4.23060489,13.6856703 C3.8985161,13.3081048 3.92857957,12.7344798 4.29831672,12.3936967 L9.18565906,8.09421866 C9.21279942,8.07042887 9.22836278,8.03609091 9.22836278,8 C9.22836278,7.96390909 9.21279942,7.92957113 9.18565906,7.90578134 L4.29831672,3.60630329 C3.92857957,3.26552024 3.8985161,2.69189515 4.23060489,2.31432968 C4.56269367,1.9367642 5.13546406,1.89336334 5.52065347,2.21657808 L11.1076195,7.13198555 C11.357104,7.35151877 11.5002158,7.66767898 11.5005322,8 Z"
    />
  </Icon>
);

export default ChevronRightIcon;
