import {prop} from 'ramda';
import services from 'services';
import {describeThrow} from 'utils/errors';
import msgs from 'dicts/messages';

let httpJson = null;
services.waitFor('api').then((x) => (httpJson = x.httpJson));

export const getDocument = (id) =>
  httpJson('get', `/documents/${id}`, {include: 'client,vehicles,drives'})
    .catch(describeThrow(msgs.fetchFailed))
    .then(prop('data'));

export const updateDocument = (document) =>
  httpJson(
    'put',
    `/documents/${document.id}`,
    {include: 'client,vehicles,drives'},
    {body: document},
  )
    .catch(describeThrow(msgs.saveFailed))
    .then(prop('data'));

export const deleteDocument = (id) =>
  httpJson('delete', `/documents/${id}`).catch(describeThrow(msgs.deleteFailed));

export const getNews = (id) =>
  httpJson('get', `/news/${id}`, {include: 'client,vehicles,drives'})
    .catch(describeThrow(msgs.fetchFailed))
    .then(prop('data'));

export const updateNews = (news) =>
  httpJson('put', `/news/${news.id}`, {include: 'client,vehicles,drives'}, {body: news})
    .catch(describeThrow(msgs.saveFailed))
    .then(prop('data'));
