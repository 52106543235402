export default {
  parts: ['field'],
  baseStyle: {
    field: {
      bg: 'gray.50',
    },
  },
  sizes: {
    lg: {
      field: {
        fontSize: 'sm',
        pl: 4,
        pr: 4,
        h: 12,
      },
    },
    sm: {
      field: {
        borderRadius: 'base',
      },
    },
  },
  variants: {
    outline: {
      field: {
        pt: 4,
        bg: 'gray.50',
        borderColor: 'gray.300',
        _hover: {
          borderColor: 'gray.400',
        },
        _focus: {
          borderColor: 'gray.600',
          boxShadow: 'md',
        },
      },
    },
  },
  defaultProps: {
    size: 'lg',
  },
};
