/* eslint-disable max-len */
import React from 'react';
import {Icon} from '@chakra-ui/react';

const CloseIcon = (props) => (
  <Icon
    viewBox="0 0 16 16"
    stroke="currentColor"
    fill="currentColor"
    strokeWidth="0"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.1494861,8.08816099 C9.12597763,8.06471187 9.1127657,8.0328724 9.1127657,7.99966835 C9.1127657,7.96646429 9.12597763,7.93462482 9.1494861,7.9111757 L13.780601,3.28056076 C14.0734256,2.98732194 14.0730899,2.51222365 13.779851,2.21939902 C13.4866122,1.92657438 13.0115139,1.92691014 12.7186893,2.22014894 L8.08807436,6.84876405 C8.06462524,6.87227252 8.03278577,6.88548444 7.99958172,6.88548444 C7.96637766,6.88548444 7.93453819,6.87227252 7.91108908,6.84876405 L3.28047414,2.22014894 C2.9876495,1.92718624 2.51277503,1.92707432 2.21981233,2.21989896 C1.92684964,2.5127236 1.92673772,2.98759807 2.21956236,3.28056076 L6.84967734,7.9111757 C6.87318581,7.93462482 6.88639773,7.96646429 6.88639773,7.99966835 C6.88639773,8.0328724 6.87318581,8.06471187 6.84967734,8.08816099 L2.21956236,12.7192759 C2.03013803,12.9087895 1.95620705,13.184964 2.02561851,13.4437671 C2.09502997,13.7025702 2.29723862,13.9046836 2.5560744,13.973973 C2.81491019,14.0432625 3.09104981,13.9692013 3.28047414,13.7796877 L7.91108908,9.14857281 C7.93453819,9.12506434 7.96637766,9.11185242 7.99958172,9.11185242 C8.03278577,9.11185242 8.06462524,9.12506434 8.08807436,9.14857281 L12.7186893,13.7796877 C13.0115139,14.0726504 13.4863884,14.0727623 13.7793511,13.7799377 C14.0723138,13.487113 14.0724257,13.0122386 13.7796011,12.7192759 L9.1494861,8.08816099 Z"
    />
  </Icon>
);

export default CloseIcon;
