import React, {useEffect, useRef} from 'react';
import {
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Stack,
  FormControl,
  FormLabel,
  Input,
  Button,
  HStack,
  SimpleGrid,
  GridItem,
  Flex,
  Heading,
} from '@chakra-ui/react';
import {useForm} from 'react-hook-form';
import roles from 'constants/roles';
import {getClientName} from 'utils/clients';
import {ReactSelect, ReactDatepicker, Editor} from 'components';

const DocumentEditor = ({
  isOpen,
  onClose,
  onSubmit,
  processing,
  defaultValues,
  document,
  vehicles,
  searchClients,
  searchDrives,
  tags,
}) => {
  const {handleSubmit, errors, register, control, reset} = useForm({
    defaultValues,
  });

  const editorRef = useRef(null);

  useEffect(() => {
    // update form default values when defaultValues prop changes
    reset(defaultValues);
  }, [defaultValues, reset]);

  const submit = async (data) => {
    const savedData = await editorRef.current.save();
    onSubmit({...data, content: savedData});
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="6xl">
      <ModalOverlay />
      <ModalContent>
        <ModalBody padding="0">
          <form onSubmit={handleSubmit(submit)} id="document-editor-form">
            <SimpleGrid columns={{base: 1, md: 3}}>
              <GridItem
                display={{base: 'block', md: 'none'}}
                bg="gray.100"
                borderTopRightRadius="md"
                borderTopLeftRadius="md"
              >
                <HStack paddingX="5" spacing="5" height="20" justifyContent="flex-end">
                  <Button variant="link" onClick={() => onClose()}>
                    Peruuta
                  </Button>
                  <Button
                    type="submit"
                    form="document-editor-form"
                    variant="primary"
                    isLoading={processing}
                  >
                    <span>Tallenna</span>
                  </Button>
                </HStack>
              </GridItem>
              <GridItem
                colSpan={{base: 1, md: 2}}
                borderRight={{base: 'none', md: '1px solid rgb(222, 225, 226)'}}
                borderBottom={{base: '1px solid rgb(222, 225, 226)', md: 'none'}}
                overflow="hidden"
              >
                <Flex
                  bg={{base: 'white', md: 'gray.100'}}
                  paddingX="5"
                  height="20"
                  alignItems="center"
                  borderTopLeftRadius={{base: 'none', md: 'md'}}
                >
                  <FormControl isInvalid={errors.title}>
                    <FormLabel>Otsikko</FormLabel>
                    <Input
                      name="title"
                      type="text"
                      ref={register({
                        required: 'Pakollinen kenttä',
                      })}
                    />
                  </FormControl>
                </Flex>
                <Editor instanceRef={editorRef} data={document?.content} />
              </GridItem>

              <GridItem
                bg="gray.100"
                borderTopRightRadius={{base: 'none', md: 'md'}}
                borderBottomLeftRadius={{base: 'md', md: 'none'}}
                borderBottomRightRadius="md"
              >
                <HStack
                  paddingX="5"
                  spacing="5"
                  height="20"
                  justifyContent="flex-end"
                  display={{base: 'none', md: 'flex'}}
                >
                  <Button variant="link" onClick={() => onClose()}>
                    Peruuta
                  </Button>
                  <Button
                    type="submit"
                    form="document-editor-form"
                    variant="primary"
                    isLoading={processing}
                  >
                    <span>Tallenna</span>
                  </Button>
                </HStack>

                <Stack spacing="3" padding="5" bg="white">
                  <FormControl isInvalid={errors.releaseAt}>
                    <FormLabel>Julkaisuajankohta</FormLabel>
                    <ReactDatepicker
                      name="releaseAt"
                      control={control}
                      showTimeSelect
                      dateFormat="dd.MM.yyyy HH:mm"
                      placeholderText="Heti"
                    />
                  </FormControl>

                  <FormControl isInvalid={errors.expiresAt}>
                    <FormLabel>Vanhenemisajankohta</FormLabel>
                    <ReactDatepicker
                      name="expiresAt"
                      control={control}
                      showTimeSelect
                      dateFormat="dd.MM.yyyy HH:mm"
                      placeholderText="Ei koskaan"
                    />
                  </FormControl>

                  <FormControl>
                    <FormLabel>Oikeudet</FormLabel>
                    <ReactSelect
                      name="roles"
                      control={control}
                      options={roles}
                      isMulti
                      isClearable
                      getOptionValue={(opt) => opt.name}
                      getOptionLabel={(opt) => opt.title}
                      placeholder="Näytä kaikille"
                    />
                    <Text fontSize="xs" color="gray.700" marginTop="1">
                      Valitse käyttäjäryhmät kenelle dokumentti näytetään
                    </Text>
                  </FormControl>
                </Stack>

                <Stack padding="5" spacing="3">
                  <Heading size="sm" textTransform="uppercase">
                    Linkitykset ajotietoihin
                  </Heading>

                  <FormControl>
                    <FormLabel>Asiakas</FormLabel>
                    <ReactSelect
                      name="client"
                      control={control}
                      isClearable
                      getOptionValue={(opt) => opt.id}
                      getOptionLabel={(opt) => getClientName(opt, true)}
                      useOptionsAsValues
                      async
                      loadOptions={(text, callback) => {
                        if (text !== '') {
                          searchClients({text, callback});
                        } else {
                          callback([]);
                        }
                      }}
                    />
                  </FormControl>

                  <FormControl>
                    <FormLabel>Auto</FormLabel>
                    <ReactSelect
                      name="vehicleIds"
                      control={control}
                      options={vehicles}
                      isClearable
                      isMulti
                      getOptionValue={(opt) => opt.id}
                      getOptionLabel={(opt) =>
                        `${opt.number} (${opt.registration_number})`
                      }
                    />
                  </FormControl>

                  <FormControl>
                    <FormLabel>Ajo/Ajolista</FormLabel>
                    <ReactSelect
                      name="drives"
                      control={control}
                      isMulti
                      isClearable
                      getOptionValue={(opt) => opt.id}
                      getOptionLabel={(opt) =>
                        opt.type === 'order' ? opt.number : opt.title
                      }
                      useOptionsAsValues
                      async
                      loadOptions={(text, callback) => {
                        if (text !== '') {
                          searchDrives({text, callback});
                        } else {
                          callback([]);
                        }
                      }}
                    />
                  </FormControl>

                  {tags ? (
                    <FormControl>
                      <FormLabel>Tarkenteet</FormLabel>
                      <ReactSelect
                        name="tags"
                        control={control}
                        options={tags}
                        isMulti
                        isClearable
                      />
                    </FormControl>
                  ) : null}
                </Stack>
              </GridItem>
            </SimpleGrid>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default DocumentEditor;
