import React from 'react';
import {
  Tag,
  TagLeftIcon,
  TagLabel,
  Heading,
  Text,
  Flex,
  Button,
  HStack,
  Stack,
  Box,
  Checkbox,
} from '@chakra-ui/react';
import {Link as RouterLink} from 'react-router-dom';
import {includes, all} from 'ramda';
import {List, WindowScroller, AutoSizer} from 'react-virtualized';

import {getShiftDateStr} from 'modules/drives/utils';
import {types} from 'dicts/drives';

import {Table, TableRow, TableCell, Card, BusIcon, PersonIcon} from 'components';

const getDriveTitle = (drive) => {
  const title = drive ? (drive.type === 'order' ? drive.number : drive.title) : null;
  return title || '000000';
};

const ShiftsMassEditor = ({
  shifts,
  selection = [],
  selectShifts = () => {},
  massUpdateShifts = () => {},
  clearSelection = () => {},
}) => {
  const handleSelectAll = (selected) => {
    if (selected) {
      selectShifts(shifts.map((shift) => ({id: shift.id, selected})));
    } else {
      clearSelection();
    }
  };

  const renderRow = ({key, index, style}) => {
    const shift = shifts[index];
    const isSelected = includes(shift.id, selection);

    return (
      <TableRow
        key={key}
        boxShadow="none"
        borderRadius="none"
        borderBottom="1px solid"
        borderColor="gray.100"
        py={5}
        _last={{borderBottom: 'none'}}
        gridTemplateColumns="min-content minmax(0, 2fr) repeat(4, minmax(0, 1fr))"
        style={style}
      >
        <TableCell>
          <Checkbox
            size="lg"
            colorScheme="gray"
            isChecked={isSelected}
            onClick={(e) => e.stopPropagation()}
            onChange={(e) => selectShifts([{id: shift.id, selected: e.target.checked}])}
          />
        </TableCell>
        <TableCell width={{base: '100%', md: 'auto'}} marginBottom={{base: 2, md: 0}}>
          <Text
            fontWeight="bold"
            fontSize="sm"
            overflow="hidden"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
          >
            {getShiftDateStr(shift, false) || 'Ei ajankohtaa'}
          </Text>
        </TableCell>
        <TableCell>
          <Tag>
            <TagLabel>{getDriveTitle(shift.drive)}</TagLabel>
          </Tag>
        </TableCell>
        <TableCell>
          <HStack spacing="2">
            {shift.vehicle && (
              <Tag>
                <TagLeftIcon as={BusIcon} />
                <TagLabel>{`${shift.vehicle.number} (${shift.vehicle.registration_number})`}</TagLabel>
              </Tag>
            )}
          </HStack>
        </TableCell>
        <TableCell>
          {shift.drive?.type && (
            <Tag variant="outline">
              <TagLabel>{types[shift.drive.type] || shift.drive.type}</TagLabel>
            </Tag>
          )}
        </TableCell>
        <TableCell width={{base: '100%', md: 'auto'}} marginTop={{base: 3, md: 0}}>
          <Stack spacing={2} overflow="hidden">
            {shift.user && (
              <Button
                as={RouterLink}
                to={`/users/${shift.user.id}`}
                variant="primary-link"
                leftIcon={<PersonIcon />}
                fontSize="sm"
                width="100%"
                textAlign="left"
                justifyContent="flex-start"
              >
                <Box
                  as="span"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  whiteSpace="nowrap"
                >
                  {shift.user.name}
                </Box>
              </Button>
            )}
          </Stack>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <Card>
      <Flex
        bg="gray.50"
        px={{base: 1, md: 6}}
        py={{base: 3, md: 5}}
        alignItems="center"
        justifyContent="space-between"
      >
        <HStack spacing={3}>
          <Checkbox
            size="lg"
            colorScheme="gray"
            isChecked={
              !!shifts.length && all((shift) => includes(shift.id, selection), shifts)
            }
            onClick={(e) => e.stopPropagation()}
            onChange={(e) => handleSelectAll(e.target.checked)}
          />
          <Heading size="xs">AJOJEN MASSAMUOKKAUS</Heading>
        </HStack>
        <HStack spacing={2}>
          <Button
            onClick={() =>
              massUpdateShifts({
                ids: selection,
                is_published: false,
              })
            }
            size="sm"
            variant="primary-outline"
            disabled={!selection.length}
          >
            Muuta luonnokseksi
          </Button>
          <Button
            onClick={() =>
              massUpdateShifts({
                ids: selection,
                is_published: true,
              })
            }
            size="sm"
            variant="primary-outline"
            disabled={!selection.length}
          >
            Julkaise
          </Button>
        </HStack>
      </Flex>
      <Table>
        <WindowScroller>
          {({height, isScrolling, scrollTop}) => (
            <AutoSizer disableHeight>
              {({width}) => (
                <List
                  autoHeight
                  height={height}
                  width={width}
                  isScrolling={isScrolling}
                  scrollTop={scrollTop}
                  rowCount={shifts.length}
                  rowHeight={65}
                  rowRenderer={renderRow}
                />
              )}
            </AutoSizer>
          )}
        </WindowScroller>
      </Table>
    </Card>
  );
};

export default ShiftsMassEditor;
