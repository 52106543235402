/* eslint-disable max-len */
import React from 'react';
import {Icon} from '@chakra-ui/react';

const DotsIcon = (props) => (
  <Icon
    viewBox="0 0 16 16"
    stroke="currentColor"
    fill="currentColor"
    strokeWidth="0"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.16666667,5.75 C3.36328362,5.75 4.33333333,6.72004971 4.33333333,7.91666667 C4.33333333,9.11328362 3.36328362,10.0833333 2.16666667,10.0833333 C0.970049709,10.0833333 0,9.11328362 0,7.91666667 C0,6.72004971 0.970049709,5.75 2.16666667,5.75 Z M8,5.75 C9.19661696,5.75 10.1666667,6.72004971 10.1666667,7.91666667 C10.1666667,9.11328362 9.19661696,10.0833333 8,10.0833333 C6.80338304,10.0833333 5.83333333,9.11328362 5.83333333,7.91666667 C5.83333333,6.72004971 6.80338304,5.75 8,5.75 Z M13.8333333,5.75 C15.0299503,5.75 16,6.72004971 16,7.91666667 C16,9.11328362 15.0299503,10.0833333 13.8333333,10.0833333 C12.6367164,10.0833333 11.6666667,9.11328362 11.6666667,7.91666667 C11.6666667,6.72004971 12.6367164,5.75 13.8333333,5.75 Z"
    />
  </Icon>
);

export default DotsIcon;
