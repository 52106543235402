import {createSelector} from 'reselect';
import namespace from './namespace';
import {scopedSelectors} from 'utils/redux';
import {formatShiftExceptionDefaultVals, formatShiftReturnDefaultVals} from './utils';

let sels = {};

sels.shift = (st) => st.shift;

sels.addFaultModalOpen = (st) => st.addFaultModalOpen;

sels.news = (st) => st.news;

sels.shiftPauseModalOpen = (st) => st.shiftPauseModalOpen;

sels.shiftDriveExceptionModalOpen = (st) => st.shiftDriveExceptionModalOpen;

sels.shiftExceptionModalOpen = (st) => st.shiftExceptionModalOpen;

sels.shiftExceptionDefaultVals = createSelector(sels.shift, (shift) =>
  formatShiftExceptionDefaultVals(shift),
);

sels.driveTypeDocuments = (st) => st.driveTypeDocuments;

sels.driveTypeDocumentsLoading = (st) => st.driveTypeDocumentsLoading;

sels.shiftReturnModalOpen = (st) => st.shiftReturnModalOpen;

sels.shiftReturnDefaultVals = createSelector(sels.shift, (shift) =>
  formatShiftReturnDefaultVals(shift),
);

sels.processing = (st) => st.processing;

sels.loading = (st) => st.loading;

export default scopedSelectors(namespace, sels);
