// constants

const recordBasename = 'Record';
const shapeBasename = 'Shape';

// utils

export const safeStringify = (value) => {
  try {
    return JSON.stringify(value);
  } catch (e) {
    return value;
  }
};

export const assertionToTest = (assertion) => (value) => {
  try {
    assertion(value);
    return true;
  } catch (e) {
    return false;
  }
};

export const formatError = (er, msg = undefined) => (msg ? `${msg}\n\n${er}` : er);

export const assert = (type, value, name = 'value', context = null, msg = null) => {
  const message =
    `Expected ${name}${context ? ' in ' + context : ''} ` +
    `to be a member of ${type.toString()}. ` +
    `Got: ${safeStringify(value)}`;

  return type.assert(value, formatError(message, msg));
};

export const createType = (name, predicate, assertion) => ({
  '@@name': name,

  toString: () => name,

  test: (value) => predicate(value),

  assert: (value, msg) => {
    if (assertion) {
      assertion(value, msg);
      return;
    }

    if (!predicate(value)) {
      throw new TypeError(msg || `${safeStringify(value)} is not a member of ${name}`);
    }
  },
});

export const createTypeWithAssertion = (name, assertion) => {
  return createType(name, assertionToTest(assertion), assertion);
};

// primitive types

export const Function_ = createType('Function', (x) => typeof x === 'function');

export const Array_ = createType('Array', (x) => Array.isArray(x));

export const Object_ = createType(
  'Object',
  (x) => Object.prototype.toString.call(x) === '[object Object]' && !!x,
);

export const Boolean_ = createType(
  'Boolean',
  (x) => Object.prototype.toString.call(x) === '[object Boolean]',
);

export const String_ = createType(
  'String',
  (x) => Object.prototype.toString.call(x) === '[object String]',
);

export const Number_ = createType(
  'Number',
  (x) => Object.prototype.toString.call(x) === '[object Number]',
);

export const Date_ = createType('Date', (x) => (x) =>
  Object.prototype.toString.call(x) === '[object Date]',
);

export const RegExp_ = createType('RegExp', (x) => (x) =>
  Object.prototype.toString.call(x) === '[object RegExp]',
);

export const File_ = createType(
  'File',
  (x) => Object.prototype.toString.call(x) === '[object File]',
);

export const Promise_ = createType('Promise', (x) => (x) =>
  Object.prototype.toString.call(x) === '[object Promise]',
);

export const Error_ = createType(
  'Error',
  (x) => Object.prototype.toString.call(x) === '[object Error]',
);

export const primitives = {
  Boolean: Boolean_,
  String: String_,
  Number: Number_,
  Function: Function_,
  Object: Object_,
  Array: Array_,
  Date: Date_,
  RegExp: RegExp_,
  Promise: Promise_,
  File: File_,
  Error: Error_,
};
export const P = primitives;

// simple types

export const Any = createType('Any', () => true);

export const Null = createType('Null', (x) => x === null);

export const Undefined = createType('Undefined', (x) => typeof x === 'undefined');

export const Nullish = createType('Nullish', (x) => Null.test(x) || Undefined.test(x));

export const Defined = createType('Defined', (x) => !Nullish.test(x));

export const ObjectLike = createType('ObjectLike', (x) => typeof x === 'object' && !!x);

export const ValidDate = createType(
  'ValidDate',
  (x) => Date_.test(x) && x.toString() !== 'Invalid Date',
);

// type constructors

export const Maybe = (type) =>
  createType(
    `Maybe:${type.toString()}`,
    (x) => Null.test(x) || Undefined.test(x) || type.test(x),
  );

export const Nullable = (type) =>
  createType(`Nullable:${type.toString()}`, (x) => Null.test(x) || type.test(x));

export const Union = (...types) => {
  const typeNames = types.map((t) => t.toString()).join(',');
  const name = `Union:${typeNames}`;

  const assertion = (x, msg) => {
    if (!types.reduce((acc, t) => acc || t.test(x), false)) {
      const ts = `[${typeNames}]`;
      // prettier-ignore
      const er = `Value '${safeStringify(x)}' is not a member of any of the types in ${name}: ${ts}`;
      throw new TypeError(formatError(er, msg));
    }
  };
  const pred = assertionToTest(assertion);

  return createType(name, pred, assertion);
};

export const ObjectOf = (type) => {
  const name = `StrMap:${type.toString()}`;

  let assertion = function (x, msg) {
    assert(Object_, x, 'value', name);
    Object.keys(x).forEach((k) => assert(type, x[k], `property ${k}`, name, msg));
  };

  let test = assertionToTest(assertion);

  return createType(name, test, assertion);
};

export const NamedShape = (name, fields) => {
  const _name = name ? `${shapeBasename}:${name}` : shapeBasename;

  let assertion = function (x, msg) {
    assert(Object_, x, 'value', _name, msg);
    Object.keys(fields).forEach((k) =>
      assert(fields[k], x[k], `property '${k}'`, _name, msg),
    );
  };

  let test = assertionToTest(assertion);

  const type = createType(_name, test, assertion);
  Object.assign(type, {fields});
  return type;
};

export const Shape = (fields) => NamedShape(null, fields);

export const NamedRecord = (name, fields) => {
  const _name = name ? `${recordBasename}:${name}` : recordBasename;

  let assertion = function (x, msg) {
    assert(Object_, x, 'value', _name, msg);
    Object.keys(fields).forEach((k) => {
      if (!fields[k]) {
        const er = `Field ${k} is not allowed in ${_name}`;
        throw new TypeError(formatError(er, msg));
      }
      assert(fields[k], x[k], `property '${k}'`, _name, msg);
    });
  };

  let test = assertionToTest(assertion);

  const type = createType(_name, test, assertion);
  Object.assign(type, {fields});
  return type;
};

export const Record = (fields) => NamedRecord(null, fields);

export const ArrayOf = (type) => {
  const name = `ArrayOf:${type.toString()}`;

  let assertion = function (xs, msg) {
    assert(Array_, xs, 'value', name, msg);
    xs.forEach((x, i) => assert(type, x, `value at index ${i}`, name, msg));
  };

  let test = assertionToTest(assertion);

  return createType(name, test, assertion);
};

export const NamedTuple = (name, typeList) => {
  const len = typeList.length;
  const basename = name ? name : `${len}Tuple`;
  const _name = `${basename}:${typeList.map((t) => t.toString()).join(',')}`;

  let assertion = function (xs, msg) {
    assert(Array_, xs, 'value', _name, msg);

    if (xs.length !== len) {
      let er = `Expected ${len} value${len === 1 ? '' : 's'} in ${_name}. Got ${
        xs.length
      }: ${xs.toString()}`;
      throw new TypeError(formatError(er, msg));
    }

    xs.forEach((x, i) => assert(typeList[i], x, `value at index ${i}`, _name, msg));
  };

  let test = assertionToTest(assertion);

  return createType(_name, test, assertion);
};

export const Tuple = (typeList) => NamedTuple(null, typeList);

export const Enum = (possibilities) => {
  const type = createType(`Enum:${JSON.stringify(possibilities)}`, (x) =>
    possibilities.some((y) => x === y),
  );
  Object.assign(type, {members: possibilities});
  return type;
};

// custom types

export const Action = NamedShape('Action', {type: String_});

export const PaginatedData = (dataType) =>
  NamedShape('PaginatedData', {data: dataType, pagination: Object_});
