import React from 'react';
import {
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalHeader,
  Stack,
  Button,
  HStack,
  FormControl,
  FormLabel,
  Textarea,
} from '@chakra-ui/react';
import {useForm} from 'react-hook-form';

const AddLogModal = ({isOpen, onClose, onSubmit, processing}) => {
  const {handleSubmit, register, errors} = useForm();

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader
          display="flex"
          flexDir="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Text fontWeight="bold" fontSize="sm" textTransform="uppercase">
            Lisää merkintä
          </Text>
          <HStack spacing={4}>
            <Button variant="link" textDecor="underline" onClick={() => onClose()}>
              Peruuta
            </Button>
            <Button
              type="submit"
              form="add-log-form"
              variant="primary"
              isLoading={processing}
            >
              <span>Lisää</span>
            </Button>
          </HStack>
        </ModalHeader>
        <ModalBody>
          <form onSubmit={handleSubmit(onSubmit)} id="add-log-form">
            <Stack spacing={4}>
              <FormControl isInvalid={errors.comment}>
                <FormLabel variant="textarea">Kommentti</FormLabel>
                <Textarea
                  name="comment"
                  variant="withLabel"
                  ref={register({required: 'Pakollinen kenttä'})}
                  height="8rem"
                />
              </FormControl>
            </Stack>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default AddLogModal;
