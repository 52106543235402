import React, {useEffect, useState} from 'react';
import {pick, includes, all} from 'ramda';
import {connect} from 'react-redux';
import {
  Button,
  HStack,
  Text,
  Box,
  Flex,
  Spacer,
  Spinner,
  Center,
  Input,
  InputGroup,
  InputRightElement,
  FormControl,
  FormLabel,
  Select,
  Checkbox,
  Menu,
  MenuList,
  MenuButton,
  MenuItem,
  MenuDivider,
} from '@chakra-ui/react';
import {parseISO, formatISO, format} from 'date-fns';
import {applyState} from 'utils/redux';
import {useDebounce} from 'utils/react';
import commonEffs from 'modules/common/effects';
import effs from 'modules/news/effects';
import sels from 'modules/news/selectors';
import commonSels from 'modules/common/selectors';
import {initialState} from 'modules/news/store';
import {
  PageHeader,
  Table,
  PlusIcon,
  CloseIcon,
  Content,
  SearchIcon,
  TableHeader,
  Paginator,
  Card,
  DocumentEditor as NewsEditor,
  DocumentPreview as NewsPreview,
  DateRangePopover,
} from 'components';
import {releaseStates} from 'dicts/news';
import NewsRow from './components/news-row';

const News = ({
  initialized,
  processing,
  loading,
  news,
  pagination,
  newsEditorOpen,
  newsEditorDefaultVals,
  newsInEdit,
  newsPreviewOpen,
  newsInPreview,
  query,
  selection,
  vehicles,
  isAdmin,
}) => {
  useEffect(() => {
    effs.initialize();
    return () => {
      effs.destroy();
    };
  }, []);

  /* keep title filter in local state too and use it to update query debounced */
  const queryFilterTitle = query['filter[title]'];
  const [filterTitle, setFilterTitle] = useState(queryFilterTitle);
  const debouncedFilterTitle = useDebounce(filterTitle, 500);

  useEffect(() => {
    setFilterTitle(queryFilterTitle);
  }, [queryFilterTitle]);

  useEffect(() => {
    if (initialized && debouncedFilterTitle !== queryFilterTitle) {
      effs.updateQuery({
        'filter[title]': debouncedFilterTitle,
        'page[number]': 1,
      });
    }
  }, [debouncedFilterTitle]);

  const releaseAtFrom = query['filter[release_at_from]'];
  const releaseAtTo = query['filter[release_at_to]'];

  return (
    <>
      <PageHeader
        title="Tiedotteet"
        actions={
          isAdmin && (
            <Button
              onClick={() => effs.toggleNewsEditor()}
              leftIcon={<PlusIcon />}
              variant="primary-link"
            >
              Lisää uusi
            </Button>
          )
        }
      >
        <Flex>
          <HStack spacing={2}>
            <FormControl>
              <FormLabel>Hae tiedotteita</FormLabel>
              <InputGroup>
                <Input
                  value={filterTitle}
                  placeholder="Hakusana"
                  onChange={(e) => setFilterTitle(e.target.value)}
                />
                <InputRightElement pointerEvents="none" color="gray.600">
                  <SearchIcon />
                </InputRightElement>
              </InputGroup>
            </FormControl>

            <DateRangePopover
              isActive={releaseAtFrom || releaseAtTo ? true : false}
              selectedFrom={releaseAtFrom ? parseISO(releaseAtFrom) : null}
              selectedTo={releaseAtTo ? parseISO(releaseAtTo) : null}
              setSelectedFrom={(date) =>
                effs.updateQuery({
                  'page[number]': 1,
                  'filter[release_at_from]': date
                    ? formatISO(date, {representation: 'date'})
                    : null,
                })
              }
              setSelectedTo={(date) =>
                effs.updateQuery({
                  'page[number]': 1,
                  'filter[release_at_to]': date
                    ? formatISO(date, {representation: 'date'})
                    : null,
                })
              }
            />
            {isAdmin && (
              <FormControl display={{base: 'none', md: 'block'}}>
                <FormLabel>Tila</FormLabel>
                <Select
                  value={query['filter[releaseState]']}
                  onChange={(e) =>
                    effs.updateQuery({
                      'filter[releaseState]': e.target.value,
                      'page[number]': 1,
                    })
                  }
                  variant="withLabel"
                  placeholder="Valitse..."
                >
                  {Object.entries(releaseStates).map(([value, label]) => (
                    <option key={value} value={value}>
                      {label}
                    </option>
                  ))}
                </Select>
              </FormControl>
            )}
          </HStack>

          <Spacer />

          <Flex marginLeft="3">
            <Button
              onClick={() => effs.updateQuery(initialState.query)}
              size="lg"
              leftIcon={<CloseIcon />}
              variant="link"
            >
              Tyhjennä
            </Button>
          </Flex>
        </Flex>
      </PageHeader>

      <Content>
        <TableHeader
          actions={
            isAdmin && (
              <Box>
                <Menu>
                  <MenuButton
                    as={Button}
                    variant={selection.length ? 'primary' : 'unselected'}
                    disabled={!selection.length}
                    size="sm"
                  >
                    Muokkaa
                  </MenuButton>
                  <MenuList>
                    <MenuItem onClick={() => effs.archiveNews(selection)}>
                      <Text fontSize="sm" fontWeight="bold">
                        Arkistoi
                      </Text>
                    </MenuItem>
                    <MenuDivider />
                    <MenuItem onClick={() => effs.removeNews(selection)}>
                      <Text fontSize="sm" fontWeight="bold" color="red.600">
                        Poista
                      </Text>
                    </MenuItem>
                  </MenuList>
                </Menu>
              </Box>
            )
          }
        >
          <HStack spacing={4}>
            {isAdmin && (
              <Checkbox
                size="lg"
                colorScheme="gray"
                isChecked={!!news.length && all((n) => includes(n.id, selection), news)}
                onClick={(e) => e.stopPropagation()}
                onChange={(e) =>
                  effs.selectNews(
                    news.map((n) => ({
                      id: n.id,
                      selected: e.target.checked,
                    })),
                  )
                }
              />
            )}
            <Text fontWeight="bold" fontSize="sm" textTransform="uppercase">
              <span>{`${pagination.total} tiedotetta`}</span>
              <span>
                {releaseAtFrom || releaseAtTo
                  ? ` / ${
                      releaseAtFrom ? format(parseISO(releaseAtFrom), 'd.M.yyyy') : ''
                    } - ${releaseAtTo ? format(parseISO(releaseAtTo), 'd.M.yyyy') : ''}`
                  : null}
              </span>
            </Text>
          </HStack>
        </TableHeader>

        {loading ? (
          <Center>
            <Spinner size="xl" />
          </Center>
        ) : !news.length ? (
          <Card>
            <Text p={5}>Ei tiedotteita</Text>
          </Card>
        ) : (
          <>
            <Table>
              {news.map((n) => (
                <NewsRow
                  key={n.id}
                  news={n}
                  onOpen={effs.toggleNewsPreview}
                  onEdit={effs.toggleNewsEditor}
                  onDelete={effs.removeNews}
                  onArchive={effs.archiveNews}
                  isSelected={includes(n.id, selection)}
                  select={effs.selectNews}
                  hideActions={!isAdmin}
                  hideSelect={!isAdmin}
                  hideTag={!isAdmin}
                />
              ))}
            </Table>
            <Paginator
              totalPages={pagination.last_page}
              currentPage={pagination.current_page}
              perPage={pagination.per_page}
              onPageSelect={(pageNum) => effs.updateQuery({'page[number]': pageNum})}
              disabled={loading}
            />
          </>
        )}
      </Content>

      {newsEditorOpen && (
        <NewsEditor
          isOpen={newsEditorOpen}
          onClose={() => effs.toggleNewsEditor()}
          onSubmit={(data) =>
            newsInEdit
              ? effs.updateNews({id: newsInEdit.id, ...data})
              : effs.createNews(data)
          }
          processing={processing}
          document={newsInEdit}
          defaultValues={newsEditorDefaultVals}
          vehicles={vehicles}
          searchClients={commonEffs.searchClients}
          searchDrives={commonEffs.searchDrives}
        />
      )}

      {newsPreviewOpen && (
        <NewsPreview
          isOpen={newsPreviewOpen}
          document={newsInPreview}
          onClose={() => effs.toggleNewsPreview()}
          onEdit={() => effs.toggleNewsEditor(newsInPreview)}
          hideEdit={!isAdmin}
        />
      )}
    </>
  );
};

export default connect(
  applyState({
    ...pick(
      [
        'initialized',
        'processing',
        'loading',
        'news',
        'pagination',
        'newsEditorOpen',
        'newsEditorDefaultVals',
        'newsInEdit',
        'newsPreviewOpen',
        'newsInPreview',
        'query',
        'selection',
      ],
      sels,
    ),
    vehicles: commonSels.vehicles,
    isAdmin: commonSels.isAdmin,
  }),
)(News);
