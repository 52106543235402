import {includes, reject, append, equals} from 'ramda';
import namespace from './namespace';
import {scopedStore} from 'utils/redux';
import {emptyPagination} from 'constants/pagination';

export const initialState = {
  query: {
    'page[number]': 1,
    'filter[title]': '',
    'filter[releaseState]': '',
    'filter[release_at_from]': '',
    'filter[release_at_to]': '',
  },
  initialized: false,
  processing: false,
  loading: false,
  news: [],
  pagination: emptyPagination,
  newsEditorOpen: false,
  newsInEdit: null,
  newsPreviewOpen: false,
  newsInPreview: null,
  selection: [],
};

export const cases = {
  updateQuery: (st, all, payload) => {
    return {
      ...st,
      query: {...st.query, ...payload},
      loading: true,
    };
  },
  setNews: (st, all, payload) => {
    return {
      ...st,
      news: payload.data,
      pagination: payload.pagination,
      loading: false,
    };
  },
  toggleNewsEditor: (st, all, payload) => {
    return {...st, newsEditorOpen: !st.newsEditorOpen, newsInEdit: payload};
  },
  toggleNewsPreview: (st, all, payload) => {
    return {
      ...st,
      newsPreviewOpen: !st.newsPreviewOpen,
      newsInPreview: payload,
    };
  },
  closeNewsModals: (st) => {
    return {
      ...st,
      newsEditorOpen: false,
      newsPreviewOpen: false,
      newsInEdit: initialState.newsInEdit,
      newsInPreview: initialState.newsInPreview,
    };
  },
  selectNews: (st, all, news) => {
    let selection = st.selection;

    news.forEach((n) => {
      if (includes(n.id, selection)) {
        selection = !n.selected ? reject(equals(n.id), selection) : selection;
      } else {
        selection = n.selected ? append(n.id, selection) : selection;
      }
    });

    return {...st, selection};
  },
  clearSelection: (st) => {
    return {...st, selection: initialState.selection};
  },
  setProcessing: (st, all, payload) => {
    return {...st, processing: payload};
  },
  setLoading: (st, all, payload) => {
    return {...st, loading: payload};
  },
  setInitialized: (st, all, payload) => {
    return {...st, initialized: payload};
  },
  reset: (st) => {
    return initialState;
  },
};

export const {actions, reducer} = scopedStore(namespace, cases, initialState);
