import {prop} from 'ramda';
import services from 'services';
import {describeThrow} from 'utils/errors';
import msgs from 'dicts/messages';
import {tags} from 'dicts/docs';
import {shiftInclude} from './constants';

let httpJson = null;
services.waitFor('api').then((x) => (httpJson = x.httpJson));

export const getShift = (id) =>
  httpJson('get', `/shifts/${id}`, {include: shiftInclude})
    .catch(describeThrow(msgs.fetchFailed))
    .then(prop('data'));

export const getDriveNews = (id) =>
  httpJson('get', '/news/forDrive', {drive_id: id})
    .catch(describeThrow(msgs.fetchFailed))
    .then(prop('data'));

export const getNews = (query) =>
  httpJson('get', '/news', query)
    .catch(describeThrow(msgs.fetchFailed))
    .then(prop('data'));

export const putDrive = (drive) =>
  httpJson('put', `/drives/${drive.id}`, {}, {body: drive})
    .catch(describeThrow(msgs.saveFailed))
    .then(prop('data'));

export const putShift = (shift) =>
  httpJson('put', `/shifts/${shift.id}`, {}, {body: shift})
    .catch(describeThrow(msgs.saveFailed))
    .then(prop('data'));

export const postDocument = (body) =>
  httpJson('post', '/documents', {}, {body})
    .catch(describeThrow(msgs.saveFailed))
    .then(prop('data'));

export const postFault = (body) =>
  httpJson('post', '/faults', {}, {body})
    .catch(describeThrow(msgs.saveFailed))
    .then(prop('data'));

export const deleteFault = (id) =>
  httpJson('delete', `/faults/${id}`).catch(describeThrow(msgs.deleteFailed));

export const postShiftPause = (body) =>
  httpJson('post', '/shift-pauses', {}, {body})
    .catch(describeThrow(msgs.saveFailed))
    .then(prop('data'));

export const putShiftPause = (pause) =>
  httpJson('put', `/shift-pauses/${pause.id}`, {}, {body: pause})
    .catch(describeThrow(msgs.saveFailed))
    .then(prop('data'));

export const deleteShiftPause = (id) =>
  httpJson('delete', `/shift-pauses/${id}`).catch(describeThrow(msgs.deleteFailed));

export const postShiftDriveException = (body) =>
  httpJson('post', '/shift-drive-exceptions', {}, {body})
    .catch(describeThrow(msgs.saveFailed))
    .then(prop('data'));

export const deleteShiftDriveException = (id) =>
  httpJson('delete', `/shift-drive-exceptions/${id}`).catch(
    describeThrow(msgs.deleteFailed),
  );

export const getDriveTypeDocuments = (driveType) =>
  httpJson('get', '/documents', {
    'filter[tags]': tags[driveType],
    'page[size]': 999,
    withoutAcl: true,
  })
    .catch(describeThrow(msgs.fetchFailed))
    .then(prop('data'));

export const postShiftException = (body) =>
  httpJson('post', '/shift-exceptions', {}, {body})
    .catch(describeThrow(msgs.saveFailed))
    .then(prop('data'));

export const putShiftException = (exception) =>
  httpJson('put', `/shift-exceptions/${exception.id}`, {}, {body: exception})
    .catch(describeThrow(msgs.saveFailed))
    .then(prop('data'));

export const postShiftReturn = (body) =>
  httpJson('post', '/shift-returns', {}, {body})
    .catch(describeThrow(msgs.saveFailed))
    .then(prop('data'));

export const putShiftReturn = (r) =>
  httpJson('put', `/shift-returns/${r.id}`, {}, {body: r})
    .catch(describeThrow(msgs.saveFailed))
    .then(prop('data'));
