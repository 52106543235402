import React from 'react';
import {SimpleGrid, Heading, GridItem, Flex, Stack} from '@chakra-ui/react';

function ListItem({isVertical = false, title, children, ...other}) {
  return isVertical ? (
    <Stack spacing="2" {...other}>
      <Heading size="xs" color="gray.700">
        {title}
      </Heading>

      <Flex alignItems="center" marginTop="auto">
        {children}
      </Flex>
    </Stack>
  ) : (
    <SimpleGrid columns="3" paddingX="5" columnGap="2" {...other}>
      <Heading size="xs" marginTop="1" color="gray.700">
        {title}
      </Heading>

      <GridItem colSpan="2">{children}</GridItem>
    </SimpleGrid>
  );
}

export default ListItem;
