import React from 'react';
import {Flex, HStack} from '@chakra-ui/react';

function TableHeader({children, actions, actionSpacing = 2, ...rest}) {
  return (
    <Flex
      justify="space-between"
      alignItems="center"
      marginTop={{base: '-3', md: '-8'}}
      marginBottom="2"
      height="10"
      marginX="6"
      {...rest}
    >
      {children}

      {actions ? <HStack spacing={actionSpacing}>{actions}</HStack> : ''}
    </Flex>
  );
}

export default TableHeader;
