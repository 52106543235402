import namespace from './namespace';
import {scopedStore} from 'utils/redux';

export const initialState = {
  query: {
    tabIndex: 0,
  },
  processing: false,
  loading: true,
  user: null,
  userEditorOpen: false,
  configuration: [],
};

export const cases = {
  setUser: (st, all, payload) => {
    return {...st, user: payload};
  },
  openUserEditor: (st) => {
    return {...st, userEditorOpen: true};
  },
  closeUserEditor: (st) => {
    return {...st, userEditorOpen: false};
  },
  updateQuery: (st, all, payload) => {
    return {...st, query: {...st.query, ...payload}};
  },
  setConfiguration: (st, all, payload) => {
    return {...st, configuration: payload};
  },
  setProcessing: (st, all, payload) => {
    return {...st, processing: payload};
  },
  setLoading: (st, all, payload) => {
    return {...st, loading: payload};
  },
  reset: (st) => {
    return initialState;
  },
};

export const {actions, reducer} = scopedStore(namespace, cases, initialState);
