import {parseISO} from 'date-fns';
import {evolve} from 'ramda';
import {replace} from 'utils/objects';
import {initialState} from './store';

export const parseUrlQuery = (query) => {
  const vals = evolve(
    {
      'page[number]': Number,
    },
    query,
  );
  return replace(vals, initialState.query);
};

export const formatNewsEditorOutput = ({
  id,
  title,
  releaseAt,
  expiresAt,
  roles,
  content,
  client,
  vehicleIds,
  drives,
}) => {
  return {
    id,
    title,
    release_at: releaseAt,
    expires_at: expiresAt,
    acl: {roles: roles || []},
    content: content ? JSON.stringify(content) : '',
    state: 'released',
    client_id: client ? client.id : null,
    vehicle_ids: vehicleIds || [],
    drive_ids: drives ? drives.map((d) => d.id) : [],
  };
};

export const formatNewsEditorDefaultVals = (news) => {
  if (!news) {
    return {
      title: '',
      roles: [],
      releaseAt: null,
      expiresAt: null,
    };
  }

  return {
    title: news.title,
    roles: news.acl?.roles || [],
    releaseAt: news.release_at ? parseISO(news.release_at) : null,
    expiresAt: news.expires_at ? parseISO(news.expires_at) : null,
    client: news.client,
    vehicleIds: news.vehicles ? news.vehicles.map((v) => v.id) : [],
    drives: news.drives || [],
  };
};

export const formatFetchableQuery = (q) => {
  const {'filter[releaseState]': releaseState, ...rest} = q;

  // if releaseState filter is not selected
  // send filter[state]: released -> archived news not fetched by default
  const stateQuery = !releaseState
    ? {'filter[state]': 'released'}
    : {'filter[releaseState]': releaseState};

  return {
    ...stateQuery,
    ...rest,
  };
};
