import {prop} from 'ramda';
import services from 'services';
import {describeThrow} from 'utils/errors';
import msgs from 'dicts/messages';
import {defaultQuery} from './constants';

let httpJson = null;
services.waitFor('api').then((x) => (httpJson = x.httpJson));

export const getUsers = (query = {}) =>
  httpJson('get', '/users', {...defaultQuery, ...query}).catch(
    describeThrow(msgs.fetchFailed),
  );

export const postUser = (body) =>
  httpJson('post', '/users', {}, {body})
    .catch(describeThrow(msgs.saveFailed))
    .then(prop('data'));

export const updateUser = (user) =>
  httpJson('put', `/users/${user.id}`, {}, {body: user})
    .catch(describeThrow(msgs.saveFailed))
    .then(prop('data'));

export const massUpdateUsers = (body) =>
  httpJson('put', '/users', {}, {body})
    .catch(describeThrow(msgs.saveFailed))
    .then(prop('data'));

export const deleteUser = (id) =>
  httpJson('delete', `/users/${id}`).catch(describeThrow(msgs.deleteFailed));
