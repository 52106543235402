import {includes, reject, append, equals} from 'ramda';
import namespace from './namespace';
import {scopedStore} from 'utils/redux';
import {emptyPagination} from 'constants/pagination';

export const initialState = {
  query: {
    'page[number]': 1,
    'filter[state]': '',
    'filter[affects_drivability]': '',
    'filter[type]': '',
    'filter[tags]': '',
    'filter[created_at_from]': '',
    'filter[created_at_to]': '',
    'filter[title]': '',
  },
  fault: null,
  processing: false,
  loading: true,
  editFaultModalOpen: false,
  addAttachmentModalOpen: false,
  vehicleFaults: [],
  vehicleFaultsPagination: emptyPagination,
  vehicleFaultsLoading: true,
  addLogModalOpen: false,
  selection: [],
  initialized: false,
  tags: [],
};

export const cases = {
  setFault: (st, all, payload) => {
    return {...st, fault: payload, loading: false};
  },
  openEditFaultModal: (st) => {
    return {...st, editFaultModalOpen: true};
  },
  closeEditFaultModal: (st) => {
    return {...st, editFaultModalOpen: false};
  },
  toggleAddAttachmentModal: (st) => {
    return {
      ...st,
      addAttachmentModalOpen: !st.addAttachmentModalOpen,
    };
  },
  toggleAddLogModal: (st) => {
    return {...st, addLogModalOpen: !st.addLogModalOpen};
  },
  setVehicleFaults: (st, all, payload) => {
    return {
      ...st,
      vehicleFaults: payload.data,
      vehicleFaultsPagination: payload.pagination,
      vehicleFaultsLoading: false,
    };
  },
  setProcessing: (st, all, payload) => {
    return {...st, processing: payload};
  },
  setVehicleFaultsLoading: (st, all, payload) => {
    return {...st, vehicleFaultsLoading: payload};
  },
  updateQuery: (st, all, payload) => {
    return {
      ...st,
      query: {...st.query, ...payload},
    };
  },
  selectFaults: (st, all, faults) => {
    let selection = st.selection;

    faults.forEach((f) => {
      if (includes(f.id, selection)) {
        selection = !f.selected ? reject(equals(f.id), selection) : selection;
      } else {
        selection = f.selected ? append(f.id, selection) : selection;
      }
    });

    return {...st, selection};
  },
  clearSelection: (st) => {
    return {...st, selection: initialState.selection};
  },
  setTags: (st, all, payload) => {
    return {...st, tags: payload};
  },
  setInitialized: (st, all, payload) => {
    return {...st, initialized: payload};
  },
  reset: (st) => {
    return initialState;
  },
};

export const {actions, reducer} = scopedStore(namespace, cases, initialState);
