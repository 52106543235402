import React, {memo} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {
  Tag,
  TagLeftIcon,
  TagLabel,
  Text,
  Link,
  HStack,
  Checkbox,
  Avatar,
  IconButton,
  Menu,
  MenuList,
  MenuItem,
  MenuButton,
  Stack,
  Portal,
  Box,
} from '@chakra-ui/react';
import {TableRow, TableCell, PersonIcon, TruncatedText} from 'components';
import {DotsIcon} from 'components/icons';
import roles from 'dicts/roles';
import {encodeQuery} from 'utils/url';

const UserRow = memo(function UserRow({
  user,
  showSelect = true,
  isSelected,
  select = () => {},
  onDelete = () => {},
  referrerUrl,
}) {
  const renderUserTags = () => (
    <HStack spacing={2}>
      {user.driver_number && (
        <Tag>
          <TagLeftIcon as={PersonIcon} />
          <TagLabel>{user.driver_number}</TagLabel>
        </Tag>
      )}
      <Tag>
        <TagLabel>{roles[user.role] || user.role}</TagLabel>
      </Tag>
    </HStack>
  );

  return (
    <TableRow
      position="relative"
      flexDirection="row"
      flexWrap="nowrap"
      overflow="hidden"
      paddingRight={{base: 1, md: 6}}
      gridTemplateColumns={showSelect ? 'min-content repeat(3, 1fr)' : 'repeat(3, 1fr)'}
    >
      {showSelect && (
        <TableCell>
          <Checkbox
            size="lg"
            colorScheme="gray"
            isChecked={isSelected}
            onClick={(e) => e.stopPropagation()}
            onChange={(e) => select([{id: user.id, selected: e.target.checked}])}
          />
        </TableCell>
      )}

      <TableCell flex={{base: '1', md: '0 1 auto'}} overflow="hidden">
        <Stack
          direction={{base: 'column', md: 'row'}}
          alignItems={{base: 'flex-start', md: 'center'}}
          spacing={{base: 1, md: 5}}
        >
          <Avatar name={user.name} size="sm" display={{base: 'none', md: 'block'}} />
          <Link
            as={RouterLink}
            to={`/users/${user.id}${encodeQuery({
              referrerUrl,
              referrerTitle: 'Käyttäjät',
              referrer: 'users',
            })}`}
          >
            <TruncatedText fontWeight="bold">{user.name}</TruncatedText>
          </Link>
          <Box display={{base: 'flex', md: 'none'}}>{renderUserTags()}</Box>
        </Stack>
      </TableCell>

      <TableCell display={{base: 'none', md: 'block'}}>{renderUserTags()}</TableCell>

      <TableCell textAlign="right">
        <Menu>
          <MenuButton
            as={IconButton}
            icon={<DotsIcon />}
            variant="ghost"
            onClick={(e) => e.stopPropagation()}
          />
          <Portal>
            <MenuList>
              <MenuItem
                onClick={(e) => {
                  e.stopPropagation();
                  onDelete([user.id]);
                }}
              >
                <Text fontSize="sm" fontWeight="bold" color="red.600">
                  Poista
                </Text>
              </MenuItem>
            </MenuList>
          </Portal>
        </Menu>
      </TableCell>
    </TableRow>
  );
});

export default UserRow;
