/* eslint-disable max-len */
import React from 'react';
import {Icon} from '@chakra-ui/react';

const BellIcon = (props) => (
  <Icon
    viewBox="0 0 16 16"
    stroke="currentColor"
    fill="currentColor"
    strokeWidth="0"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.32433983,13.9999997 C9.40928901,13.9995342 9.48076838,14.0635198 9.48967319,14.1480023 C9.49693795,14.2095106 9.50049993,14.2713999 9.50034502,14.3333356 C9.50034502,15.2538103 8.75414766,15.9999997 7.83367293,15.9999997 C6.9131982,15.9999997 6.16700083,15.2538103 6.16700083,14.3333356 C6.16684593,14.2713999 6.1704079,14.2095106 6.17767266,14.1480023 C6.18716071,14.0638195 6.25829036,14.000147 6.34300602,13.9999997 L6.34300602,13.9999997 Z M7.83367293,0 C8.38595777,0 8.83367309,0.447715322 8.83367309,1 L8.83367309,1 L8.83367309,1.75733361 C9.38772817,1.85635647 9.91661559,2.06437646 10.3896733,2.36933371 C12.038904,3.53035911 13.0045131,5.43410272 12.9670071,7.45066785 L12.9670071,7.96266794 C12.9694354,9.66037116 13.3680038,11.3340844 14.1310073,12.8506687 C14.1830498,12.9532806 14.1778408,13.0761542 14.1172442,13.1746577 C14.0566475,13.2731612 13.9493237,13.3333355 13.8336739,13.3333355 L13.8336739,13.3333355 L1.83367197,13.3333355 C1.71821457,13.3331008 1.61110061,13.2731356 1.55054411,13.1748331 C1.48998762,13.0765307 1.48461333,12.9538916 1.53633859,12.8506687 C2.29934206,11.3340844 2.69791045,9.66037116 2.70033878,7.96266794 L2.70033878,7.96266794 L2.70033878,7.45066785 C2.67003756,5.58369587 3.49185815,3.80459784 4.9330058,2.61733375 C5.49112122,2.18857917 6.14217318,1.89696453 6.83367277,1.76600028 L6.83367277,1.76600028 L6.83367277,1 C6.83367277,0.447715322 7.28138809,0 7.83367293,0 Z"
    />
  </Icon>
);

export default BellIcon;
