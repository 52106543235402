import React, {useState, useEffect, useMemo} from 'react';
import {
  Button,
  HStack,
  IconButton,
  Text,
  Box,
  Flex,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverBody,
  Center,
  Spinner,
  FormControl,
  FormLabel,
  InputGroup,
  Input,
  InputRightElement,
  Portal,
  Stack,
  useMediaQuery,
  Checkbox,
  Divider,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';
import {pick} from 'ramda';
import {connect} from 'react-redux';
import {
  parseISO,
  formatISO,
  format,
  addWeeks,
  subWeeks,
  differenceInWeeks,
} from 'date-fns';

import {applyState} from 'utils/redux';
import {createReferrerUrl} from 'utils/url';
import {useDebounce} from 'utils/react';
import effs from 'modules/drives/drivesPage/effects';
import sels from 'modules/drives/drivesPage/selectors';
import commonSels from 'modules/common/selectors';
import {initialState} from 'modules/drives/drivesPage/store';
import {
  adminDefaultQuery,
  repairerDefaultQuery,
  periodStartingPoint,
} from 'modules/drives/drivesPage/constants';
import {getPeriodEnd, getPeriodStart} from 'modules/drives/drivesPage/utils';

import {
  PageHeader,
  Table,
  CloseIcon,
  Content,
  SearchIcon,
  FilterIcon,
  TableHeader,
  List,
  ListItem,
  Card,
  Paginator,
  RadioButtonGroup,
  PageLoader,
  DateRangePopover,
  ReactSelect,
} from 'components';
import ShiftRow from './components/shift-row';
import ShiftsReport from './components/shifts-report';
import ShiftsMassEditor from './components/shifts-mass-editor';

const ShiftsTable = ({shifts, printOnMount, referrerUrl, isAdmin}) => {
  useEffect(() => {
    if (printOnMount) {
      window.print();
    }
  }, []);

  return (
    <Table
      sx={{
        '@media print': {
          display: 'block',
        },
      }}
    >
      {shifts.map((d) => (
        <ShiftRow key={d.id} shift={d} referrerUrl={referrerUrl} isAdmin={isAdmin} />
      ))}
    </Table>
  );
};

const Drives = ({
  initialized,
  loading,
  query,
  shifts,
  pagination,
  vehicles,
  location,
  drivers,
  isRepairer,
  isAdmin,
  activeTab,
  selection,
  filtersCount,
  extraFiltersCount,
  reportRows,
  fromDate,
  toDate,
  user,
  isSubcontractor,
}) => {
  const {isOpen: mobileFiltersOpen, onOpen, onClose} = useDisclosure();

  useEffect(() => {
    effs.initialize();
    return () => {
      effs.destroy();
    };
  }, []);

  const referrerUrl = createReferrerUrl(location);

  /* keep title filter in local state too and use it to update query debounced */
  const queryFilterTitle = query['filter[drive_title]'];
  const [filterTitle, setFilterTitle] = useState(queryFilterTitle);
  const debouncedFilterTitle = useDebounce(filterTitle, 500);

  useEffect(() => {
    setFilterTitle(queryFilterTitle);
  }, [queryFilterTitle]);

  useEffect(() => {
    if (initialized && debouncedFilterTitle !== queryFilterTitle) {
      effs.updateQuery({
        'filter[drive_title]': debouncedFilterTitle,
        'page[number]': 1,
      });
    }
  }, [debouncedFilterTitle]);

  const vehicleOpts = useMemo(
    () =>
      vehicles.map((v) => ({
        value: v.id,
        label: `${v.number} (${v.registration_number})`,
      })),
    [vehicles],
  );

  const driverOpts = useMemo(
    () =>
      drivers.map((d) => ({
        value: d.id,
        label: `${d.name} (${d.driver_number || '-'})`,
      })),
    [drivers],
  );

  const clearQuery = () => {
    const emptyQuery = isAdmin
      ? {...initialState.query, ...adminDefaultQuery}
      : isRepairer
      ? {...initialState.query, ...repairerDefaultQuery}
      : initialState.query;

    effs.updateQuery(emptyQuery);
  };

  const [isLg] = useMediaQuery('(min-width: 62em)');

  if (!initialized) return <PageLoader />;

  const renderFilters = (isMobile) => (
    <>
      <FormControl maxW={isMobile ? 'none' : '12.5rem'}>
        <FormLabel>Ajo</FormLabel>
        <InputGroup>
          <Input
            value={filterTitle}
            placeholder="Reitti, Ajolista"
            onChange={(e) => setFilterTitle(e.target.value)}
          />
          <InputRightElement pointerEvents="none" color="gray.600">
            <SearchIcon />
          </InputRightElement>
        </InputGroup>
      </FormControl>
      {!isSubcontractor && (
        <FormControl maxW={isMobile ? 'none' : '12.5rem'}>
          <FormLabel>Auto</FormLabel>
          <ReactSelect
            standalone
            value={query['filter[vehicle_id]']}
            onChange={(val) =>
              effs.updateQuery({
                'filter[vehicle_id]': val,
                'page[number]': 1,
              })
            }
            options={vehicleOpts}
            variant="withLabel"
            placeholder="Valitse..."
            isClearable
            menuPosition={isMobile ? 'absolute' : 'fixed'}
            isDisabled={loading}
          />
        </FormControl>
      )}
      {isAdmin && (
        <FormControl maxW={isMobile ? 'none' : '12.5rem'}>
          <FormLabel>Kuljettaja</FormLabel>
          <ReactSelect
            standalone
            value={query['filter[user_id]']}
            onChange={(val) =>
              effs.updateQuery({
                'filter[user_id]': val,
                'page[number]': 1,
              })
            }
            options={driverOpts}
            variant="withLabel"
            placeholder="Valitse..."
            isClearable
            menuPosition={isMobile ? 'absolute' : 'fixed'}
            isDisabled={loading}
          />
        </FormControl>
      )}
    </>
  );

  const renderExtraFilters = () => (
    <List marginY={{base: '0', lg: '5'}}>
      <ListItem isVertical title="Tyyppi" paddingX={{base: '0', lg: '5'}}>
        <RadioButtonGroup
          value={query['filter[drive_type]']}
          defaultValue={query['filter[drive_type]']}
          onChange={(type) =>
            effs.updateQuery({
              'filter[drive_type]': type,
              'page[number]': 1,
            })
          }
          name="drive_type"
          options={[
            {value: '', label: 'Kaikki'},
            {value: 'order', label: 'Tilaus'},
            {value: 'series', label: 'Reitti'},
          ]}
          size="sm"
          disabled={loading}
        />
      </ListItem>
      <ListItem isVertical title="Tila" paddingX={{base: '0', lg: '5'}}>
        <Checkbox
          isChecked={query.onlyUnread}
          onChange={(e) =>
            effs.updateQuery({
              onlyUnread: e.target.checked,
              'page[number]': 1,
            })
          }
          colorScheme="gray"
          size="md"
          isDisabled={loading}
        >
          Näytä vain lukemattomat
        </Checkbox>
      </ListItem>
      <ListItem isVertical title="Raportointi" paddingX={{base: '0', lg: '5'}}>
        <Checkbox
          isChecked={query.onlyUnreported}
          onChange={(e) =>
            effs.updateQuery({
              onlyUnreported: e.target.checked,
              'page[number]': 1,
            })
          }
          colorScheme="gray"
          size="md"
          isDisabled={loading}
        >
          Näytä vain raportoimattomat
        </Checkbox>
      </ListItem>
      <ListItem isVertical title="Poikkeamat" paddingX={{base: '0', lg: '5'}}>
        <Checkbox
          isChecked={query.onlyExceptions}
          onChange={(e) =>
            effs.updateQuery({
              onlyExceptions: e.target.checked,
              'page[number]': 1,
            })
          }
          colorScheme="gray"
          size="md"
          isDisabled={loading}
        >
          Näytä ajot joissa poikkeama
        </Checkbox>
      </ListItem>
      <ListItem isVertical title="Ajojen näkyvyys" paddingX={{base: '0', lg: '5'}}>
        <RadioButtonGroup
          value={query.visibility}
          defaultValue={query.visibility}
          onChange={(val) =>
            effs.updateQuery({
              visibility: val,
              'page[number]': 1,
            })
          }
          name="visibility"
          options={[
            {value: '', label: 'Kaikki'},
            {value: 'published', label: 'Julkaistu'},
            {value: 'unpublished', label: 'Ei julkaistu'},
          ]}
          size="sm"
          disabled={loading}
        />
      </ListItem>
    </List>
  );

  return (
    <>
      <PageHeader
        title="Ajot"
        sx={{
          '@media print': {
            display: 'none',
          },
        }}
      >
        <Flex justifyContent="space-between">
          <HStack spacing="1">
            <Button
              onClick={() =>
                effs.updateQuery({
                  departure: 'period',
                  shiftStartAtFrom: '',
                  shiftStartAtTo: '',
                  'page[number]': 1,
                })
              }
              size="lg"
              px={{base: 4, md: 6}}
              variant={query.departure === 'period' ? 'primary' : 'unselected'}
              isActive={query.departure === 'period'}
              disabled={loading}
            >
              Jakso
            </Button>
            <Button
              onClick={() =>
                effs.updateQuery({
                  departure: 'today',
                  shiftStartAtFrom: '',
                  shiftStartAtTo: '',
                  'page[number]': 1,
                })
              }
              size="lg"
              px={{base: 4, md: 6}}
              variant={query.departure === 'today' ? 'primary' : 'unselected'}
              isActive={query.departure === 'today'}
              disabled={loading}
            >
              Tänään
            </Button>

            <DateRangePopover
              isActive={query.departure === 'range'}
              disabled={loading}
              selectedFrom={
                query.shiftStartAtFrom ? parseISO(query.shiftStartAtFrom) : null
              }
              selectedTo={query.shiftStartAtTo ? parseISO(query.shiftStartAtTo) : null}
              setSelectedFrom={(date) =>
                effs.updateQuery({
                  departure: 'range',
                  shiftStartAtFrom: date
                    ? formatISO(date, {representation: 'date'})
                    : null,
                  'page[number]': 1,
                })
              }
              setSelectedTo={(date) =>
                effs.updateQuery({
                  departure: 'range',
                  shiftStartAtTo: date ? formatISO(date, {representation: 'date'}) : null,
                  'page[number]': 1,
                })
              }
            >
              <Stack spacing={2} mt={4}>
                <Button
                  disabled={differenceInWeeks(new Date(), periodStartingPoint) % 2 === 0}
                  onClick={() =>
                    effs.updateQuery({
                      departure: 'range',
                      shiftStartAtFrom: formatISO(addWeeks(getPeriodStart(), 2), {
                        representation: 'date',
                      }),
                      shiftStartAtTo: formatISO(addWeeks(getPeriodEnd(), 2), {
                        representation: 'date',
                      }),
                      'page[number]': 1,
                    })
                  }
                  size="sm"
                >
                  Seuraava jakso
                </Button>
                <Button
                  onClick={() =>
                    effs.updateQuery({
                      departure: 'range',
                      shiftStartAtFrom: formatISO(subWeeks(getPeriodStart(), 2), {
                        representation: 'date',
                      }),
                      shiftStartAtTo: formatISO(subWeeks(getPeriodEnd(), 2), {
                        representation: 'date',
                      }),
                      'page[number]': 1,
                    })
                  }
                  size="sm"
                >
                  Edellinen jakso
                </Button>
                <Button
                  onClick={() =>
                    effs.updateQuery({
                      departure: 'range',
                      shiftStartAtFrom: formatISO(subWeeks(getPeriodStart(), 4), {
                        representation: 'date',
                      }),
                      shiftStartAtTo: formatISO(subWeeks(getPeriodEnd(), 4), {
                        representation: 'date',
                      }),
                      'page[number]': 1,
                    })
                  }
                  size="sm"
                >
                  Toissa jakso
                </Button>
              </Stack>
            </DateRangePopover>
          </HStack>

          {isLg ? (
            <HStack flex="1" spacing="1" marginLeft="1">
              <HStack flex="1" spacing="1" justifyContent="flex-end">
                {renderFilters(false)}
              </HStack>
              <HStack spacing="3">
                {isAdmin ? (
                  <Popover>
                    <PopoverTrigger>
                      <Button variant={extraFiltersCount > 0 ? 'primary' : 'solid'}>
                        <Box as="span" display={{lg: 'none', xl: 'inline'}}>
                          Lisää suodattimia
                          <span>
                            {extraFiltersCount > 0 ? ` (${extraFiltersCount})` : null}
                          </span>
                        </Box>
                        <Box as="span" display={{lg: 'inline', xl: 'none'}}>
                          Lisää...
                          <span>
                            {extraFiltersCount > 0 ? ` (${extraFiltersCount})` : null}
                          </span>
                        </Box>
                      </Button>
                    </PopoverTrigger>
                    <Portal>
                      <PopoverContent
                        sx={{
                          '@media print': {
                            display: 'none',
                          },
                        }}
                      >
                        <PopoverArrow />
                        <PopoverBody padding="0">{renderExtraFilters()}</PopoverBody>
                      </PopoverContent>
                    </Portal>
                  </Popover>
                ) : (
                  <Box />
                )}
                <Button
                  onClick={() => clearQuery()}
                  size="lg"
                  leftIcon={<CloseIcon />}
                  variant="link"
                  disabled={loading}
                >
                  Tyhjennä
                </Button>
              </HStack>
            </HStack>
          ) : (
            <Box marginLeft="1">
              <IconButton
                onClick={onOpen}
                size="lg"
                icon={<FilterIcon />}
                variant={
                  filtersCount > 0 || extraFiltersCount > 0 ? 'primary' : 'unselected'
                }
              />
            </Box>
          )}
        </Flex>
      </PageHeader>

      <Content>
        <TableHeader
          sx={{
            '@media print': {
              display: 'none',
            },
          }}
          actionSpacing={4}
          actions={
            <>
              {!isRepairer && (
                <Button
                  onClick={() => effs.setActiveTab('list')}
                  variant={activeTab === 'list' ? 'primary-link' : 'link'}
                  textDecor={activeTab === 'list' ? 'underline' : 'none'}
                  disabled={loading}
                >
                  Työlista
                </Button>
              )}
              <Button
                onClick={() => effs.setActiveTab('report')}
                variant={activeTab === 'report' ? 'primary-link' : 'link'}
                textDecor={activeTab === 'report' ? 'underline' : 'none'}
                disabled={loading}
              >
                Ajopäiväkirja
              </Button>
              {isAdmin && (
                <Button
                  onClick={() => effs.setActiveTab('massEditor')}
                  variant={activeTab === 'massEditor' ? 'primary-link' : 'link'}
                  textDecor={activeTab === 'massEditor' ? 'underline' : 'none'}
                  disabled={loading}
                  display={{base: 'none', md: 'block'}}
                >
                  Muokkaus
                </Button>
              )}
              <Button
                onClick={() => effs.setActiveTab('print')}
                variant={activeTab === 'print' ? 'primary-link' : 'link'}
                textDecor={activeTab === 'print' ? 'underline' : 'none'}
                disabled={loading}
                display={{base: 'none', md: 'block'}}
              >
                Tulostus
              </Button>
            </>
          }
        >
          <Text fontWeight="bold" fontSize="sm" textTransform="uppercase">
            <span>{`${pagination.total} ajoa / `}</span>
            <span>
              {query.departure === 'period'
                ? `${format(getPeriodStart(), 'd.M.yyyy')} - ${format(
                    getPeriodEnd(),
                    'd.M.yyyy',
                  )}`
                : query.departure === 'today'
                ? format(new Date(), 'd.M.yyyy')
                : `${
                    query.shiftStartAtFrom
                      ? format(parseISO(query.shiftStartAtFrom), 'd.M.yyyy')
                      : ''
                  } - ${
                    query.shiftStartAtTo
                      ? format(parseISO(query.shiftStartAtTo), 'd.M.yyyy')
                      : ''
                  }`}
            </span>
          </Text>
        </TableHeader>

        {loading ? (
          <Center p={5}>
            <Spinner size="xl" />
          </Center>
        ) : !shifts.length && activeTab !== 'report' ? (
          <Card>
            <Text p={5}>Ei ajoja</Text>
          </Card>
        ) : activeTab === 'report' ? (
          <ShiftsReport
            reportRows={reportRows}
            userId={isAdmin ? query['filter[user_id]'] : user.id}
            fromDate={fromDate}
            toDate={toDate}
            referrerUrl={referrerUrl}
            showReservations={isAdmin ? query.visibility === 'published' : true}
          />
        ) : activeTab === 'massEditor' ? (
          <ShiftsMassEditor
            shifts={shifts}
            selection={selection}
            selectShifts={effs.selectShifts}
            massUpdateShifts={effs.massUpdateShifts}
            clearSelection={effs.clearSelection}
          />
        ) : (
          <>
            <ShiftsTable
              shifts={shifts}
              isAdmin={isAdmin}
              referrerUrl={referrerUrl}
              printOnMount={activeTab === 'print'}
            />
            <Paginator
              totalPages={pagination.last_page}
              currentPage={pagination.current_page}
              perPage={pagination.per_page}
              onPageSelect={(pageNum) => effs.updateQuery({'page[number]': pageNum})}
              disabled={loading}
              sx={{
                '@media print': {
                  display: 'none',
                },
              }}
            />
          </>
        )}
      </Content>

      {mobileFiltersOpen && (
        <Modal
          isOpen={mobileFiltersOpen}
          onClose={onClose}
          size="s"
          scrollBehavior="inside"
        >
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton />
            <ModalBody>
              {isAdmin && (
                <>
                  <Box>{renderExtraFilters()}</Box>
                  <Divider my={5} />
                </>
              )}
              <Stack spacing={2}>{renderFilters(true)}</Stack>
              <Button
                onClick={() => clearQuery()}
                size="lg"
                leftIcon={<CloseIcon />}
                variant="link"
                mt={5}
              >
                Tyhjennä
              </Button>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default connect(
  applyState({
    ...pick(
      [
        'initialized',
        'processing',
        'loading',
        'query',
        'shifts',
        'pagination',
        'drivers',
        'activeTab',
        'selection',
        'filtersCount',
        'extraFiltersCount',
        'reportRows',
        'fromDate',
        'toDate',
      ],
      sels,
    ),
    vehicles: commonSels.vehicles,
    isRepairer: commonSels.isRepairer,
    isAdmin: commonSels.isAdmin,
    user: commonSels.user,
    isSubcontractor: commonSels.isSubcontractor,
  }),
)(Drives);
