import {pipeReducers} from 'utils/redux';
import notifications from './notifications/reducer';
import confirmer from './confirmer/reducer';
import common from './common/reducer';
import login from './login/reducer';
import docs from './docs/reducer';
import news from './news/reducer';
import faults from './faults/reducer';
import users from './users/reducer';
import drives from './drives/reducer';
import documentEditor from './documentEditor/reducer';
import settings from './settings/reducer';

export default pipeReducers(
  notifications,
  confirmer,
  common,
  login,
  docs,
  news,
  faults,
  users,
  drives,
  documentEditor,
  settings,
);
