export default {
  baseStyle: {
    header: {
      bg: 'gray.100',
      px: '6',
      py: '4',
      borderTopLeftRadius: 'md',
      borderTopRightRadius: 'md',
    },
    body: {
      p: 6,
    },
  },
};
