import React from 'react';
import {Box} from '@chakra-ui/react';

function Content({children, ...props}) {
  return (
    <Box paddingX={{md: 8, xl: 16}} paddingY={{base: 5, md: 10}} {...props}>
      {children}
    </Box>
  );
}

export default Content;
