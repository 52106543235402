export default {
  baseStyle: {
    label: {
      fontWeight: 'extrabold',
    },
    container: {
      fontWeight: 'extrabold',
    },
  },
  variants: {
    solid: {
      container: {
        color: 'gray.900',
        bg: 'gray.100',
        borderRadius: 'base',
      },
    },
    outline: {
      container: {
        color: 'gray.600',
        borderRadius: 'base',
      },
    },
  },
  defaultProps: {
    variant: 'solid',
    size: 'md',
  },
};
