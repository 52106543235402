export const shiftInclude = [
  'user',
  'drive.client.documents',
  'vehicle.documents',
  'pauses',
  'drive.contact',
  'drive.documents',
  'drive_exceptions.documents',
  'faults.documents',
  'exception.pauses',
  'return',
];
