import {prop} from 'ramda';
import services from 'services';
import {describeThrow} from 'utils/errors';
import msgs from 'dicts/messages';

let httpJson = null;
services.waitFor('api').then((x) => (httpJson = x.httpJson));

export const updateUser = (user) =>
  httpJson('put', `/users/${user.id}`, {}, {body: user})
    .catch(describeThrow(msgs.saveFailed))
    .then(prop('data'));

export const getUser = (id) =>
  httpJson('get', `/users/${id}`, {})
    .catch(describeThrow(msgs.fetchFailed))
    .then(prop('data'));

export const postVacation = (body) =>
  httpJson('post', '/vacations', {}, {body})
    .catch(describeThrow(msgs.saveFailed))
    .then(prop('data'));

export const getConfiguration = (query) =>
  httpJson('get', '/configuration', query)
    .catch(describeThrow(msgs.fetchFailed))
    .then(prop('data'));
