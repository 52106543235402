export default {
  baseStyle: {
    tablist: {
      marginBottom: {
        base: -3,
        md: -5,
      },
    },
    tab: {
      fontWeight: 'bold',
      textTransform: 'uppercase',
      borderBottom: '4px solid',
    },
  },
  variants: {
    line: {
      tablist: {
        borderColor: 'transparent',
      },
      tab: {
        fontSize: 'sm',
        borderBottom: '4px solid',
        paddingY: 4,
        paddingX: 0,
        marginRight: 5,
        _hover: {
          borderColor: 'violet.200',
        },
        _active: {
          bg: 'none',
          borderColor: 'violet.700',
        },
        _selected: {
          color: 'inherit',
          borderColor: 'violet.700',
        },
      },
      tabpanel: {
        padding: 0,
      },
    },
  },
};
