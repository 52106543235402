import React from 'react';
import {
  Center,
  IconButton,
  Link,
  Stack,
  Text,
  Menu,
  MenuList,
  MenuButton,
  MenuItem,
  MenuDivider,
  Grid,
} from '@chakra-ui/react';
import {parseISO, format} from 'date-fns';
import {DotsIcon, FileIcon, ImageIcon, PdfIcon} from './icons';

const File = ({
  type = 'file',
  modified,
  children,
  onOpen = () => {},
  onEdit = () => {},
  onDelete = () => {},
  hideEdit,
  hideDelete,
  hideActions,
}) => {
  const icons = {
    file: <FileIcon />,
    pdf: <PdfIcon />,
    image: <ImageIcon />,
  };
  const icon = icons[type] || icons.file;
  const modifiedStr = format(parseISO(modified), 'd.M.yyyy');

  return (
    <Grid
      gridAutoFlow="column"
      templateColumns="auto 1fr auto"
      alignItems="center"
      role="button"
      onClick={() => onOpen()}
    >
      <Center color="purple.700" boxSize="14" fontSize="2xl">
        {icon}
      </Center>

      <Stack spacing="1" overflow="hidden">
        <Link
          fontWeight="bold"
          lineHeight="1"
          fontSize="sm"
          display="inline-block"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          overflow="hidden"
        >
          {children}
        </Link>

        <Text fontSize="xs" lineHeight="1" color="gray.600">
          Muokattu: {modifiedStr}
        </Text>
      </Stack>

      {!hideActions && (
        <Menu>
          <MenuButton
            as={IconButton}
            icon={<DotsIcon />}
            variant="ghost"
            marginRight="1"
            onClick={(e) => e.stopPropagation()}
          />
          <MenuList>
            {!hideEdit && (
              <>
                <MenuItem
                  onClick={(e) => {
                    e.stopPropagation();
                    onEdit();
                  }}
                >
                  <Text fontSize="sm" fontWeight="bold">
                    Muokkaa
                  </Text>
                </MenuItem>
                <MenuDivider />
              </>
            )}
            {!hideDelete && (
              <MenuItem
                onClick={(e) => {
                  e.stopPropagation();
                  onDelete();
                }}
              >
                <Text fontSize="sm" fontWeight="bold" color="red.600">
                  Poista
                </Text>
              </MenuItem>
            )}
          </MenuList>
        </Menu>
      )}
    </Grid>
  );
};

export default File;
