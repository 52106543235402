import React, {useEffect} from 'react';
import {Tabs, TabList, Tab, TabPanel, TabPanels} from '@chakra-ui/react';
import {pick} from 'ramda';
import {connect} from 'react-redux';
import {applyState} from 'utils/redux';
import effs from 'modules/drives/drivePage/effects';
import sels from 'modules/drives/drivePage/selectors';
import commonSels from 'modules/common/selectors';
import {
  Content,
  PageHeader,
  Breadcrumbs,
  AddFaultModal,
  PageLoader,
  TabsHorizontalScroll,
} from 'components';
import ShiftPauseModal from '../components/shift-pause-modal';
import DriveTabBasic from '../components/drive-tab-basic';
import DriveTabClient from '../components/drive-tab-client';
import DriveTabVehicle from '../components/drive-tab-vehicle';
import DriveTabReport from '../components/drive-tab-report';
import DriveTabAttachments from '../components/drive-tab-attachments';
import DriveNews from '../components/drive-news';
import ShiftDriveExceptionModal from '../components/shift-drive-exception-modal';
import ShiftExceptionModal from '../components/shift-exception-modal';
import ShiftReturnModal from '../components/shift-return-modal';

const Drive = ({
  location,
  match: {
    params: {driveId},
  },
  shift,
  processing,
  loading,
  addFaultModalOpen,
  vehicles,
  news,
  shiftPauseModalOpen,
  shiftDriveExceptionModalOpen,
  shiftExceptionModalOpen,
  shiftExceptionDefaultVals,
  isAdmin,
  driveTypeDocuments,
  driveTypeDocumentsLoading,
  shiftReturnModalOpen,
  shiftReturnDefaultVals,
  isSubcontractor,
}) => {
  useEffect(() => {
    effs.initialize(Number(driveId));
    return () => {
      effs.destroy();
    };
  }, [driveId]);

  if (loading) return <PageLoader />;

  const tabProps = {
    shift,
    openDocument: (document) => effs.openDocumentPreview({document, type: 'document'}),
    editDocument: (document) => effs.openDocumentEditor({document, type: 'document'}),
    removeDocument: (document) => effs.removeDocument(document.id),
    hideActions: !isAdmin,
    isAdmin,
  };

  const activeCrumbName = shift.drive
    ? shift.drive.type === 'order'
      ? shift.drive.number
      : shift.drive.title
    : null;

  return (
    <Tabs>
      <PageHeader
        title={
          <Breadcrumbs
            location={location}
            activeCrumbName={activeCrumbName ? `Ajo (${activeCrumbName})` : 'Ajo'}
          />
        }
      >
        <TabsHorizontalScroll>
          <TabList>
            <Tab>Perustiedot</Tab>
            <Tab hidden={shift.drive ? shift.drive.type !== 'order' : true}>Asiakas</Tab>
            <Tab>Auto</Tab>
            <Tab>Raportointi</Tab>
            <Tab>Liitteet</Tab>
          </TabList>
        </TabsHorizontalScroll>
      </PageHeader>

      <Content>
        <DriveNews
          news={news}
          openDocument={(document) => effs.openDocumentPreview({document, type: 'news'})}
        />
        <TabPanels>
          <TabPanel>
            <DriveTabBasic {...tabProps} />
          </TabPanel>
          <TabPanel hidden={shift.drive ? shift.drive.type !== 'order' : true}>
            <DriveTabClient {...tabProps} />
          </TabPanel>
          <TabPanel>
            <DriveTabVehicle {...tabProps} />
          </TabPanel>
          <TabPanel>
            <DriveTabReport
              shift={shift}
              openAddFaultModal={effs.openAddFaultModal}
              openShiftDriveExceptionModal={effs.toggleShiftDriveExceptionModal}
              openShiftExceptionModal={effs.toggleShiftExceptionModal}
              removeShiftDriveException={effs.removeShiftDriveException}
              removeFault={effs.removeFault}
              processing={processing}
              openShiftReturnModal={effs.toggleShiftReturnModal}
              showReports={!isSubcontractor}
            />
          </TabPanel>
          <TabPanel>
            <DriveTabAttachments
              {...tabProps}
              driveTypeDocuments={driveTypeDocuments}
              driveTypeDocumentsLoading={driveTypeDocumentsLoading}
            />
          </TabPanel>
        </TabPanels>
      </Content>

      {addFaultModalOpen && (
        <AddFaultModal
          isOpen={addFaultModalOpen}
          onClose={() => effs.closeAddFaultModal()}
          onSubmit={effs.addFault}
          processing={processing}
          vehicles={vehicles}
          defaultValues={{
            type: 'fault',
            affectsDrivability: 'no',
            vehicle_id: shift.vehicle?.id,
          }}
        />
      )}

      {shiftPauseModalOpen && (
        <ShiftPauseModal
          isOpen={shiftPauseModalOpen}
          onSubmit={effs.addShiftPause}
          onClose={effs.toggleShiftPauseModal}
          processing={processing}
          shift={shift}
        />
      )}

      {shiftDriveExceptionModalOpen && (
        <ShiftDriveExceptionModal
          isOpen={shiftDriveExceptionModalOpen}
          onSubmit={effs.addShiftDriveException}
          onClose={effs.toggleShiftDriveExceptionModal}
          processing={processing}
        />
      )}

      {shiftExceptionModalOpen && (
        <ShiftExceptionModal
          isOpen={shiftExceptionModalOpen}
          onSubmit={effs.saveShiftException}
          onClose={effs.toggleShiftExceptionModal}
          defaultValues={shiftExceptionDefaultVals}
          processing={processing}
          shift={shift}
        />
      )}

      {shiftReturnModalOpen && (
        <ShiftReturnModal
          isOpen={shiftReturnModalOpen}
          onSubmit={effs.saveShiftReturn}
          onClose={effs.toggleShiftReturnModal}
          defaultValues={shiftReturnDefaultVals}
          processing={processing}
          shift={shift}
        />
      )}
    </Tabs>
  );
};

export default connect(
  applyState({
    ...pick(
      [
        'shift',
        'processing',
        'loading',
        'addFaultModalOpen',
        'news',
        'shiftPauseModalOpen',
        'shiftDriveExceptionModalOpen',
        'shiftExceptionModalOpen',
        'shiftExceptionDefaultVals',
        'driveTypeDocuments',
        'driveTypeDocumentsLoading',
        'shiftReturnModalOpen',
        'shiftReturnDefaultVals',
      ],
      sels,
    ),
    vehicles: commonSels.vehicles,
    isAdmin: commonSels.isAdmin,
    isSubcontractor: commonSels.isSubcontractor,
  }),
)(Drive);
