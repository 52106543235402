/* eslint-disable max-len */
import React from 'react';
import {Icon} from '@chakra-ui/react';

const WarningIcon = (props) => (
  <Icon
    viewBox="0 0 16 16"
    stroke="currentColor"
    fill="currentColor"
    strokeWidth="0"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.8591462,12.6725005 L9.09312052,1.66110027 L9.09312052,1.66110027 C8.88013196,1.25465949 8.45917468,1 8.00030844,1 C7.5414422,1 7.12048492,1.25465949 6.90749636,1.66110027 L1.1408538,12.6725005 C0.940509899,13.0549826 0.954691602,13.5143682 1.17824675,13.883764 C1.40180189,14.2531599 1.80219869,14.4787403 2.23397432,14.4787403 L13.7660257,14.4787403 C14.1978013,14.4787403 14.5981981,14.2531599 14.8217533,13.883764 C15.0453084,13.5143682 15.0594901,13.0549826 14.8591462,12.6725005 Z M7.38311483,5.53082093 C7.38311483,5.19012466 7.65930373,4.91393576 8,4.91393576 C8.34069627,4.91393576 8.61688517,5.19012466 8.61688517,5.53082093 L8.61688517,9.23213195 C8.61688517,9.57282822 8.34069627,9.84901712 8,9.84901712 C7.65930373,9.84901712 7.38311483,9.57282822 7.38311483,9.23213195 L7.38311483,5.53082093 Z M8.03084426,12.6311692 L8.01357147,12.6311692 C7.50808078,12.6295485 7.09385722,12.2294771 7.07467225,11.724348 C7.06568988,11.4801132 7.15527074,11.2425208 7.3232654,11.0650126 C7.49126006,10.8875045 7.72356288,10.7849849 7.96792197,10.7805137 L7.98519476,10.7805137 C8.49060268,10.7811164 8.90546924,11.1804663 8.92532775,11.6854843 C8.93499353,11.9302896 8.84553033,12.1686491 8.67717734,12.3466388 C8.50882435,12.5246286 8.27580831,12.6272075 8.03084426,12.6311692 Z"
    />
  </Icon>
);

export default WarningIcon;
