import React from 'react';
import {
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalHeader,
  Stack,
  Button,
  HStack,
  Heading,
  Center,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import {useDropzone} from 'react-dropzone';
import {UploadIcon} from 'components/icons';
import {FileTypeIcon} from 'components';

const AddAttachmentModal = ({isOpen, onClose, onSubmit, processing}) => {
  const {acceptedFiles, getRootProps, getInputProps} = useDropzone();

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader
          display="flex"
          flexDir="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Text fontWeight="bold" fontSize="sm" textTransform="uppercase">
            Lisää liite
          </Text>
          <HStack spacing={4}>
            <Button variant="link" textDecor="underline" onClick={() => onClose()}>
              Peruuta
            </Button>
            <Button
              onClick={() => onSubmit(acceptedFiles)}
              variant="primary"
              isLoading={processing}
            >
              <span>Lisää</span>
            </Button>
          </HStack>
        </ModalHeader>
        <ModalBody>
          <Center
            flex="1"
            bg="gray.200"
            {...getRootProps({className: 'dropzone'})}
            borderRadius="md"
            p={12}
          >
            <input {...getInputProps()} />
            {acceptedFiles.length ? (
              <Wrap align="center" justify="center" spacing={8}>
                {acceptedFiles.map((f) => (
                  <WrapItem key={f.path}>
                    <Stack align="center" spacing={4}>
                      <FileTypeIcon type={f.type} />
                      <Heading
                        color="gray.600"
                        textStyle="h2"
                        maxW="10rem"
                        overflow="hidden"
                        textOverflow="ellipsis"
                        whiteSpace="nowrap"
                        textAlign="center"
                      >
                        {f.path}
                      </Heading>
                    </Stack>
                  </WrapItem>
                ))}
              </Wrap>
            ) : (
              <Stack spacing={2} align="center" userSelect="none">
                <UploadIcon w={16} h={16} color="gray.600" mb={4} />
                <Heading color="gray.600" textStyle="h2">
                  Lisää liite
                </Heading>
                <Text fontSize="sm" color="gray.700">
                  Raahaa tiedostot koneeltasi tai avaa tiedostoselain klikkaamalla
                </Text>
              </Stack>
            )}
          </Center>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default AddAttachmentModal;
