import {prop} from 'ramda';
import services from 'services';
import {describeThrow} from 'utils/errors';
import msgs from 'dicts/messages';
import {defaultQuery} from './constants';

let httpJson = null;
services.waitFor('api').then((x) => (httpJson = x.httpJson));

export const getFaults = (query = {}) =>
  httpJson('get', '/faults', {...defaultQuery, ...query}).catch(
    describeThrow(msgs.fetchFailed),
  );

export const postFault = (body) =>
  httpJson('post', '/faults', {}, {body})
    .catch(describeThrow(msgs.saveFailed))
    .then(prop('data'));

export const updateFault = (fault) =>
  httpJson('put', `/faults/${fault.id}`, {}, {body: fault})
    .catch(describeThrow(msgs.saveFailed))
    .then(prop('data'));

export const massUpdateFaults = (body) =>
  httpJson('put', '/faults', {}, {body})
    .catch(describeThrow(msgs.saveFailed))
    .then(prop('data'));

export const deleteFault = (id) =>
  httpJson('delete', `/faults/${id}`).catch(describeThrow(msgs.deleteFailed));

export const postDocument = (body) =>
  httpJson('post', '/documents', {}, {body})
    .catch(describeThrow(msgs.saveFailed))
    .then(prop('data'));

export const getTags = () =>
  httpJson('get', '/faults/tags').catch(describeThrow(msgs.fetchFailed));
