import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalHeader,
  Stack,
  Button,
  HStack,
  FormControl,
  FormLabel,
  Textarea,
  Heading,
  Spacer,
  IconButton,
  FormErrorMessage,
} from '@chakra-ui/react';
import {useForm, useFieldArray} from 'react-hook-form';
import {parseISO} from 'date-fns';
import {ReactDatepicker, PlusIcon, BinIcon, TruncatedText} from 'components';

const ShiftExceptionModal = ({
  isOpen,
  onClose,
  onSubmit,
  processing,
  defaultValues,
  shift,
}) => {
  const {handleSubmit, control, errors, register, setValue, watch} = useForm({
    defaultValues,
  });
  const {fields, append} = useFieldArray({control, name: 'pauses'});
  const pauses = watch('pauses');

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="3xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader
          display="flex"
          flexDir="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <TruncatedText fontWeight="bold" fontSize="sm" textTransform="uppercase" mr={2}>
            Työvuoro poikkeus
          </TruncatedText>
          <HStack spacing={4}>
            <Button variant="link" textDecor="underline" onClick={() => onClose()}>
              Peruuta
            </Button>
            <Button
              type="submit"
              form="shift-exception-form"
              variant="primary"
              isLoading={processing}
            >
              <span>Tallenna</span>
            </Button>
          </HStack>
        </ModalHeader>
        <ModalBody>
          <form onSubmit={handleSubmit(onSubmit)} id="shift-exception-form">
            <HStack>
              <Heading size="xs" textTransform="uppercase">
                Ajoaika
              </Heading>
              <Spacer />
              <Button
                onClick={() => append({_id: '', from_date: '', to_date: ''})}
                leftIcon={<PlusIcon />}
                variant="primary-link"
              >
                Lisää tauko
              </Button>
            </HStack>
            <Stack spacing={2} mt={4} mb={2}>
              <FormControl isInvalid={errors.shift_start_at}>
                <FormLabel>Aloitusaika</FormLabel>
                <ReactDatepicker
                  name="shift_start_at"
                  control={control}
                  showTimeSelect
                  dateFormat="dd.MM.yyyy HH:mm"
                  rules={{required: 'Pakollinen kenttä'}}
                  showPopperArrow={false}
                />
              </FormControl>
              <FormControl isInvalid={errors.shift_end_at}>
                <FormLabel>Lopetusaika</FormLabel>
                <ReactDatepicker
                  name="shift_end_at"
                  control={control}
                  showTimeSelect
                  dateFormat="dd.MM.yyyy HH:mm"
                  rules={{required: 'Pakollinen kenttä'}}
                  showPopperArrow={false}
                />
              </FormControl>
            </Stack>
            {fields.map((pause, index) => (
              <Stack
                key={pause.id}
                spacing={4}
                my={4}
                hidden={pauses[index].deleted === 'true'}
              >
                <Heading size="xs" textTransform="uppercase">
                  Tauko
                </Heading>
                <input
                  name={`pauses[${index}]._id`}
                  hidden
                  ref={register()}
                  defaultValue={pause._id}
                />
                <input
                  name={`pauses[${index}].deleted`}
                  hidden
                  ref={register()}
                  defaultValue={pause.deleted}
                />
                <HStack spacing={2}>
                  <FormControl isInvalid={errors.pauses?.[index]?.from_date}>
                    <FormLabel>Aloitusaika</FormLabel>
                    <ReactDatepicker
                      name={`pauses[${index}].from_date`}
                      control={control}
                      defaultValue={pause.from_date}
                      showTimeSelect
                      dateFormat="HH:mm"
                      showPopperArrow={false}
                      rules={{required: pauses[index].deleted === 'true' ? false : true}}
                      openToDate={
                        shift.shift_start_at ? parseISO(shift.shift_start_at) : new Date()
                      }
                    />
                  </FormControl>
                  <FormControl isInvalid={errors.pauses?.[index]?.to_date}>
                    <FormLabel>Lopetusaika</FormLabel>
                    <ReactDatepicker
                      name={`pauses[${index}].to_date`}
                      control={control}
                      defaultValue={pause.to_date}
                      showTimeSelect
                      dateFormat="HH:mm"
                      showPopperArrow={false}
                      rules={{required: pauses[index].deleted === 'true' ? false : true}}
                      openToDate={
                        shift.shift_start_at ? parseISO(shift.shift_start_at) : new Date()
                      }
                    />
                  </FormControl>
                  <IconButton
                    icon={<BinIcon color="gray.600" />}
                    variant="ghost"
                    disabled={processing}
                    onClick={() => {
                      // flag as deleted instead of actually deleting item from FieldArray
                      // DELETE request is sent to API for each flagged pause
                      setValue(`pauses[${index}].deleted`, true);
                    }}
                  />
                </HStack>
              </Stack>
            ))}
            <FormControl isInvalid={errors.description}>
              <FormLabel variant="textarea">Kuvaus</FormLabel>
              <Textarea
                name="description"
                variant="withLabel"
                ref={register({required: 'Pakollinen kenttä'})}
                placeholder="Syy aikataulu poikkeukselle"
                height="8rem"
              />
              <FormErrorMessage mt={0}>
                {errors.description && errors.description.message}
              </FormErrorMessage>
            </FormControl>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ShiftExceptionModal;
