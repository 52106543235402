import React from 'react';
import {
  Button,
  Checkbox,
  HStack,
  FormControl,
  FormLabel,
  Flex,
  FormErrorMessage,
} from '@chakra-ui/react';
import {useForm} from 'react-hook-form';
import {List, ListItem, ReactDatepicker} from 'components';

const HolidayConfigurationForm = ({onSubmit, processing, defaultValues}) => {
  const {handleSubmit, register, control, errors} = useForm({defaultValues});

  return (
    <form onSubmit={handleSubmit(onSubmit)} id="holiday-configuration-form">
      <List pt={5}>
        <ListItem title="Lomake">
          <Checkbox name="show_holiday_form" ref={register} colorScheme="gray" size="md">
            Näytä lomake käyttäjille
          </Checkbox>
        </ListItem>
        <ListItem title="Lomien varausväli">
          <HStack spacing={2} alignItems="flex-start">
            <FormControl isInvalid={errors.holiday_reserve_from}>
              <FormLabel>Alkaen</FormLabel>
              <ReactDatepicker
                name="holiday_reserve_from"
                control={control}
                dateFormat="dd.MM.yyyy"
                portalId="root"
                rules={{required: 'Pakollinen kenttä'}}
              />
              <FormErrorMessage>
                {errors.holiday_reserve_from && errors.holiday_reserve_from.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors.holiday_reserve_to}>
              <FormLabel>Päättyen</FormLabel>
              <ReactDatepicker
                name="holiday_reserve_to"
                control={control}
                dateFormat="dd.MM.yyyy"
                portalId="root"
                rules={{required: 'Pakollinen kenttä'}}
              />
              <FormErrorMessage>
                {errors.holiday_reserve_to && errors.holiday_reserve_to.message}
              </FormErrorMessage>
            </FormControl>
          </HStack>
        </ListItem>
      </List>
      <Flex justifyContent="flex-end" p={5}>
        <Button
          type="submit"
          form="holiday-configuration-form"
          variant="primary"
          isLoading={processing}
        >
          <span>Tallenna</span>
        </Button>
      </Flex>
    </form>
  );
};

export default HolidayConfigurationForm;
