import React from 'react';
import {useForm} from 'react-hook-form';
import {
  Box,
  Button,
  GridItem,
  SimpleGrid,
  Stack,
  Text,
  FormControl,
  FormLabel,
  Textarea,
  FormErrorMessage,
} from '@chakra-ui/react';
import {Card, CardHeader, ReactDatepicker} from 'components';

const UserTabHolidays = ({sendRequest, processing}) => {
  const {handleSubmit, register, control, errors} = useForm({
    defaultValues: {starts_at: '', time: '', comment: ''},
  });

  const submit = (data) => {
    const {starts_at, time, ...rest} = data;
    const ends_at = starts_at; // set end date same as start date for holidays

    if (time) {
      const hours = time.getHours();
      const minutes = time.getMinutes();
      starts_at.setHours(hours);
      starts_at.setMinutes(minutes);
    }

    sendRequest({type: 'holiday', starts_at, ends_at, ...rest});
  };

  return (
    <SimpleGrid columns={{lg: 3}} spacing="5">
      <Card>
        <CardHeader>Vapaapäivätoiveet</CardHeader>
        <form
          id="holiday-request-form"
          onSubmit={handleSubmit(submit)}
          autoComplete="off"
        >
          <Stack padding="5" spacing="2">
            <FormControl isInvalid={errors.starts_at}>
              <FormLabel>Päivämäärä (*)</FormLabel>
              <ReactDatepicker
                name="starts_at"
                control={control}
                dateFormat="dd.MM.yyyy"
                rules={{required: 'Pakollinen kenttä'}}
                portalId="root"
              />
              <FormErrorMessage>
                {errors.starts_at && errors.starts_at.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl>
              <FormLabel>Kellonaikatoive tiettyyn päivään</FormLabel>
              <ReactDatepicker
                name="time"
                control={control}
                dateFormat="HH:mm"
                showTimeSelect
                showTimeSelectOnly
                portalId="root"
              />
            </FormControl>

            <FormControl isInvalid={errors.comment}>
              <FormLabel variant="textarea">
                Miksi toivot tätä kohtaa vapaaksi (*)
              </FormLabel>
              <Textarea
                name="comment"
                variant="withLabel"
                ref={register({required: 'Pakollinen kenttä'})}
                height="8rem"
              />
              <FormErrorMessage>
                {errors.comment && errors.comment.message}
              </FormErrorMessage>
            </FormControl>

            <Box textAlign="right">
              <Button
                variant="primary"
                type="submit"
                form="holiday-request-form"
                isLoading={processing}
              >
                <span>Lähetä toive</span>
              </Button>
            </Box>
          </Stack>
        </form>
      </Card>

      <GridItem colSpan={{lg: 2}}>
        <Stack spacing="5" padding="5">
          <Text fontWeight="bold">Ohjeet</Text>

          <Text>
            Lomakkeella lähetetään vapaapäivät, joiden ajankohta on työvuorolistojen
            päivissä.
          </Text>

          <Text>
            Vapaapäivätoiveet täytetään tällä lomakkeella. Vuosilomatoiveet tulevat eri
            kyselyillä. Lomakkeella toivotaan tuleviin listoihin sijoittuvia
            vapaatoiveita. Toiveet tulee täyttää viimeistään listan julkaisua edeltävänä
            SUNNUNTAINA klo. 23:59 mennessä. Ilmoita toiveesi mahdollisimman ajoissa.
          </Text>

          <Text>HUOM! Vain yksi vapaatoive per 2 viikkojakso.</Text>

          <Text>
            Kaikki toiveet käsitellään mutta valitettavasti kaikkia toiveita ei pystytä
            aina toteuttamaan työtilanteesta johtuen. Vapaan onnistumisen näet
            julkaistulta työvuorolistalta.
          </Text>
        </Stack>
      </GridItem>
    </SimpleGrid>
  );
};

export default UserTabHolidays;
