/* eslint-disable max-len */
import React from 'react';
import {Icon} from '@chakra-ui/react';

const NavWarningIcon = (props) => (
  <Icon
    viewBox="0 0 24 24"
    stroke="currentColor"
    fill="currentColor"
    strokeWidth="0"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12,0.000238029737 C12.9746483,0.000238029737 13.8644651,0.554527475 14.2942585,1.42951095 L14.2942585,1.42951095 L23.7670723,20.727132 C24.113211,21.4312688 24.0713635,22.2642 23.6563925,22.9300977 C23.2414217,23.5959953 22.5121174,24.0005203 21.728,24.0000008 L21.728,24.0000008 L2.27249721,24.0000008 C1.48788257,24.0005203 0.758578277,23.5959953 0.343607461,22.9300977 C-0.0713634631,22.2642 -0.113210982,21.4312688 0.232741514,20.727511 L0.232741514,20.727511 L9.70582724,1.42933637 C10.1355349,0.554527475 11.0253517,0.000238029737 12,0.000238029737 Z M12,1.50023803 C11.5973284,1.50023803 11.2297046,1.72924024 11.0522585,2.09048905 L11.0522585,2.09048905 L1.57907233,21.388868 C1.46155894,21.6279211 1.47576609,21.9106996 1.61664789,22.1367705 C1.75752972,22.3628415 2.00512732,22.5000007 2.272,22.5000007 L2.272,22.5000007 L21.7284972,22.5000007 C21.9948727,22.5000007 22.2424703,22.3628415 22.3833521,22.1367705 C22.5242339,21.9106996 22.5384411,21.6279211 22.4207415,21.388489 L22.4207415,21.388489 L12.9478272,2.09066363 C12.7702954,1.72924024 12.4026716,1.50023803 12,1.50023803 Z M12,18 L12.1225811,18.0066013 C12.6862391,18.0676801 13.125,18.545101 13.125,19.125 C13.125,19.7463203 12.6213203,20.25 12,20.25 C11.3786797,20.25 10.875,19.7463203 10.875,19.125 C10.875,18.545101 11.3137609,18.0676801 11.8774189,18.0066013 L12,18 Z M12,7.5 C12.3796958,7.5 12.693491,7.78215388 12.7431534,8.14822944 L12.75,8.25 L12.75,15.75 C12.75,16.1642136 12.4142136,16.5 12,16.5 C11.6203042,16.5 11.306509,16.2178461 11.2568466,15.8517706 L11.25,15.75 L11.25,8.25 C11.25,7.83578644 11.5857864,7.5 12,7.5 Z"
    />
  </Icon>
);

export default NavWarningIcon;
