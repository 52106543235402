import React from 'react';
import {
  Box,
  ChakraProvider,
  CSSReset,
  PortalManager,
  useDisclosure,
} from '@chakra-ui/react';
import 'focus-visible/dist/focus-visible'; // disable border for non-keyboard interactions
import {Switch, Route, Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import {pick} from 'ramda';
import 'react-virtualized/styles.css'; // only needs to be imported once

import {applyState} from 'utils/redux';
import commonSels from 'modules/common/selectors';
import theme from '../theme/theme';

import {Sidebar, Header, PageLoader} from '../components';
//import Dashboard from './dashboard';
import Drives from './drives';
import Drive from './drives/drive';
import Faults from './faults';
import Fault from './faults/fault';
import News from './news';
import Docs from './docs';
import Settings from './settings';
import Users from './users';
import User from './users/user';
import Notifications from './notifications';
import Confirmer from './confirmer';
import Login from './login';
import GlobalDocumentEditor from './documentEditor';
import ForgotPassword from './forgotPassword';
import ResetPassword from './resetPassword';

const App = ({
  initialized,
  loggedIn,
  user,
  isAdmin,
  isDriver,
  isRepairer,
  isSubcontractor,
}) => {
  const redirectTo = isRepairer ? '/faults' : '/drives';
  const showDrives = isAdmin || isDriver || isRepairer;
  const showFaults = isAdmin || isRepairer;
  const showUsers = isAdmin;
  // isAdmin = user role is admin or driveMaster, show settings only for actual admin
  const showSettings = user?.role === 'admin';
  const showNews = !isSubcontractor;
  const showDocs = !isSubcontractor;
  const showVacations = !isSubcontractor;

  // mobile drawer
  const {isOpen, onOpen, onClose} = useDisclosure();

  return (
    <ChakraProvider theme={theme}>
      <CSSReset />
      <Notifications />
      <Confirmer />
      <GlobalDocumentEditor />
      <PortalManager>
        {!initialized ? null : !loggedIn ? (
          <Switch>
            <Route exact path="/forgot-password" component={ForgotPassword} />
            <Route exact path="/reset-password" component={ResetPassword} />
            <Route component={Login} />
          </Switch>
        ) : !user ? (
          <PageLoader />
        ) : (
          <Box bg="gray.100" display="flex">
            <Sidebar
              showDrives={showDrives}
              showFaults={showFaults}
              showNews={showNews}
              showDocs={showDocs}
              drawerOpen={isOpen}
              closeDrawer={onClose}
            />
            <Box flex="1">
              <Header
                openDrawer={onOpen}
                user={user}
                showUsers={showUsers}
                showSettings={showSettings}
                showVacations={showVacations}
              />
              <Switch>
                <Redirect exact path="/" to={redirectTo} />
                {showDrives && <Route path="/drives" exact component={Drives} />}
                {showDrives && <Route path="/drives/:driveId" exact component={Drive} />}
                {showFaults && <Route path="/faults" exact component={Faults} />}
                {showFaults && <Route path="/faults/:faultId" exact component={Fault} />}
                {showNews && <Route path="/news" exact component={News} />}
                {showDocs && <Route path="/docs" exact component={Docs} />}
                <Route path="/users/:userId" exact component={User} />
                {showUsers && <Route path="/users" exact component={Users} />}
                {showSettings && <Route path="/settings" exact component={Settings} />}
              </Switch>
            </Box>
          </Box>
        )}
      </PortalManager>
    </ChakraProvider>
  );
};

export default connect(
  applyState({
    ...pick(
      [
        'initialized',
        'loggedIn',
        'user',
        'isAdmin',
        'isDriver',
        'isRepairer',
        'isSubcontractor',
      ],
      commonSels,
    ),
  }),
)(App);
