import React from 'react';
import {
  Wrap,
  WrapItem,
  Box,
  useRadio,
  useRadioGroup,
  useMediaQuery,
} from '@chakra-ui/react';

const RadioButton = ({size, innerRef, children, ...props}) => {
  const {getInputProps, getCheckboxProps} = useRadio(props);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box as="label" flex={1}>
      <input {...input} ref={innerRef} />
      <Box
        {...checkbox}
        cursor="pointer"
        borderRadius="base"
        boxShadow="base"
        bg="gray.200"
        color="gray.600"
        fontSize="sm"
        fontWeight="extrabold"
        _hover={{
          color: 'gray.900',
        }}
        _checked={{
          bg: 'violet.700',
          color: 'white',
        }}
        px={size === 'sm' ? 3 : 6}
        py={1}
        textAlign="center"
        whiteSpace="nowrap"
      >
        {children}
      </Box>
    </Box>
  );
};

const RadioButtonGroup = React.forwardRef((props, ref) => {
  const {
    name,
    options,
    value,
    defaultValue,
    onChange,
    size,
    disabled,
    direction = 'row',
    ...rest
  } = props;

  const {getRootProps, getRadioProps} = useRadioGroup({
    name: name,
    value: value,
    defaultValue: defaultValue,
    onChange: onChange,
  });

  const group = getRootProps();

  const [isXs] = useMediaQuery('(max-width: 30em)');

  return (
    <Wrap direction={direction} {...group} {...rest}>
      {options.map((o) => {
        const radio = getRadioProps({value: o.value});
        return (
          <WrapItem key={o.value} flex={isXs ? '1' : '0 1 auto'}>
            <RadioButton innerRef={ref} size={size} disabled={disabled} {...radio}>
              {o.label}
            </RadioButton>
          </WrapItem>
        );
      })}
    </Wrap>
  );
});

export default RadioButtonGroup;
