import {createSelector} from 'reselect';
import namespace from './namespace';
import {scopedSelectors} from 'utils/redux';
import {formatNewsEditorDefaultVals, formatFetchableQuery} from './utils';

let sels = {};

sels.initialized = (st) => st.initialized;

sels.processing = (st) => st.processing;

sels.loading = (st) => st.loading;

sels.query = (st) => st.query;

sels.queryFetchable = createSelector(sels.query, (q) => formatFetchableQuery(q));

sels.news = (st) => st.news;

sels.pagination = (st) => st.pagination;

sels.newsEditorOpen = (st) => st.newsEditorOpen;

sels.newsInEdit = (st) => st.newsInEdit;

sels.newsEditorDefaultVals = createSelector(sels.newsInEdit, (news) =>
  formatNewsEditorDefaultVals(news),
);

sels.newsPreviewOpen = (st) => st.newsPreviewOpen;

sels.newsInPreview = (st) => st.newsInPreview;

sels.selection = (st) => st.selection;

export default scopedSelectors(namespace, sels);
