/* eslint-disable max-len */
import React from 'react';
import {Icon} from '@chakra-ui/react';

const PersonIcon = (props) => (
  <Icon
    viewBox="0 0 16 16"
    stroke="currentColor"
    fill="currentColor"
    strokeWidth="0"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.67567568,8.94594595 C10.8088816,8.9492827 13.3480146,11.4884157 13.3513514,14.6216216 C13.3513514,14.8305942 13.1819456,15 12.972973,15 L12.972973,15 L2.37837838,15 C2.16940577,15 2,14.8305942 2,14.6216216 C2.00333675,11.4884157 4.54246972,8.9492827 7.67567568,8.94594595 Z M7.67567568,1 C9.66091545,1 11.2702703,2.60935482 11.2702703,4.59459459 C11.2702703,6.57983437 9.66091545,8.18918919 7.67567568,8.18918919 C5.6904359,8.18918919 4.08108108,6.57983437 4.08108108,4.59459459 C4.08108108,2.60935482 5.6904359,1 7.67567568,1 Z"
    />
  </Icon>
);

export default PersonIcon;
