import React from 'react';
import {Button, SimpleGrid, Tag, Text, TagLeftIcon, Link, Box} from '@chakra-ui/react';
import {BlobProvider} from '@react-pdf/renderer';
import {tags} from 'dicts/docs';
import {getClientName} from 'utils/clients';
import {Card, List, ListItem, GroupIcon, CardHeader, File, FileList} from 'components';
import DriveSignPdf from './drive-sign-pdf';

const DriveTabClient = ({
  shift,
  openDocument,
  editDocument,
  removeDocument,
  hideActions,
  isAdmin,
}) => {
  const clientSign = shift.drive?.client?.documents?.find(
    (d) => d.tags && d.tags.includes(tags.sign) && d.url,
  );

  return (
    <SimpleGrid columns={{md: 2}} spacing="5">
      <Card>
        <CardHeader isMain>{getClientName(shift.drive?.client)}</CardHeader>

        <List marginBottom="5">
          {isAdmin && (
            <ListItem title="Tilaaja">
              <Text fontWeight="bold">{getClientName(shift.drive?.client)}</Text>
              <Text fontSize="sm">{shift.drive?.client?.department}</Text>
              {shift.drive?.client?.phone && (
                <Box fontSize="sm">
                  <Link href={`tel:${shift.drive.client.phone}`}>
                    {shift.drive.client.phone}
                  </Link>
                </Box>
              )}
              <Text fontSize="sm">{shift.drive?.client?.address}</Text>
              <Text fontSize="sm">
                {shift.drive?.client?.zip ? `${shift.drive.client.zip} ` : ''}
                {shift.drive?.client?.city}
              </Text>
            </ListItem>
          )}

          <ListItem title="Yhteyshenkilö">
            <Text fontWeight="bold">{shift.drive?.contact?.name}</Text>
            {shift.drive?.contact?.phone && (
              <Box fontSize="sm">
                <Link href={`tel:${shift.drive.contact.phone}`}>
                  {shift.drive.contact.phone}
                </Link>
              </Box>
            )}
            {shift.drive?.contact?.email && isAdmin ? (
              <Box fontSize="sm">
                <Link href={`mailto:${shift.drive.contact.email}`}>
                  {shift.drive.contact.email}
                </Link>
              </Box>
            ) : null}
          </ListItem>

          <ListItem title="Viite">
            <Text fontWeight="bold">{shift.drive?.reference}</Text>
          </ListItem>

          <ListItem title="Matkustajat">
            {shift.drive?.person_count ? (
              <Tag>
                <TagLeftIcon as={GroupIcon} />
                {shift.drive.person_count}
              </Tag>
            ) : (
              <Text fontWeight="bold">-</Text>
            )}
          </ListItem>

          <ListItem title="Eväät / Vedet">
            <Text fontWeight="bold">{shift.drive?.has_snacks ? 'Kyllä' : 'Ei'}</Text>
          </ListItem>

          {!!shift.drive?.banner && (
            <ListItem title="Kyltti" alignItems="center">
              <BlobProvider
                document={
                  <DriveSignPdf text={shift.drive.banner} clientLogo={clientSign?.url} />
                }
              >
                {({url, loading}) => (
                  <Button
                    as={Link}
                    href={url}
                    isExternal
                    variant="primary"
                    isLoading={loading}
                  >
                    Tulosta kyltti
                  </Button>
                )}
              </BlobProvider>
            </ListItem>
          )}

          {!!shift.drive?.banner && (
            <ListItem title="Kyltin teksti">
              <Text fontWeight="bold">{shift.drive.banner}</Text>
            </ListItem>
          )}
        </List>
      </Card>

      <Card>
        <CardHeader>Asiakaskohtainen ohjeistus</CardHeader>
        {shift.drive?.client?.documents?.length ? (
          <FileList>
            {shift.drive.client.documents.map((doc) => (
              <File
                key={doc.id}
                type={doc.filetype}
                modified={doc.updated_at}
                hideActions={hideActions}
                onOpen={() =>
                  doc.filetype === 'document'
                    ? openDocument(doc)
                    : window.open(doc.url, '_blank')
                }
                onEdit={() => editDocument(doc)}
                onDelete={() => removeDocument(doc)}
              >
                {doc.title}
              </File>
            ))}
          </FileList>
        ) : (
          <Text p={5}>Ei dokumentteja</Text>
        )}
      </Card>
    </SimpleGrid>
  );
};

export default DriveTabClient;
