import React from 'react';
import {
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalHeader,
  Stack,
  Button,
  HStack,
  Heading,
  Center,
  Wrap,
  WrapItem,
  FormControl,
  FormLabel,
  Input,
  Textarea,
} from '@chakra-ui/react';
import {useForm} from 'react-hook-form';
import {useDropzone} from 'react-dropzone';
import {UploadIcon} from 'components/icons';
import {FileTypeIcon, RadioButtonGroup, TruncatedText, ReactSelect} from 'components';

const AddFaultModal = ({
  isOpen,
  onClose,
  onSubmit,
  processing,
  vehicles,
  defaultValues,
}) => {
  const {handleSubmit, register, errors, control} = useForm({defaultValues});

  const {acceptedFiles, getRootProps, getInputProps} = useDropzone();

  const submit = (data) => {
    onSubmit({...data, files: acceptedFiles});
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl" scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader
          display="flex"
          flexDir="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <TruncatedText fontWeight="bold" fontSize="sm" textTransform="uppercase" mr={2}>
            Vika- / Vahinkoilmoitus
          </TruncatedText>
          <HStack spacing={4}>
            <Button variant="link" textDecor="underline" onClick={() => onClose()}>
              Peruuta
            </Button>
            <Button
              type="submit"
              form="add-fault-form"
              variant="primary"
              isLoading={processing}
            >
              <span>Lisää</span>
            </Button>
          </HStack>
        </ModalHeader>
        <ModalBody>
          <form onSubmit={handleSubmit(submit)} id="add-fault-form">
            <Stack spacing={4}>
              <RadioButtonGroup
                name="type"
                options={[
                  {
                    value: 'fault',
                    label: 'Vikailmoitus',
                  },
                  {
                    value: 'damage',
                    label: 'Vahinkoilmoitus',
                  },
                ]}
                ref={register}
                defaultValue={defaultValues.type}
              />
              <HStack spacing={4}>
                <FormControl isInvalid={errors.title} flex={2}>
                  <FormLabel>Otsikko</FormLabel>
                  <Input
                    name="title"
                    type="text"
                    placeholder="Lisää kuvaava otsikko"
                    ref={register({
                      required: 'Pakollinen kenttä',
                    })}
                  />
                </FormControl>
                <FormControl isInvalid={errors.vehicle_id} flex={1}>
                  <FormLabel>Auto</FormLabel>
                  <ReactSelect
                    name="vehicle_id"
                    control={control}
                    options={vehicles}
                    isClearable
                    getOptionValue={(opt) => opt.id}
                    getOptionLabel={(opt) => `${opt.number} (${opt.registration_number})`}
                  />
                </FormControl>
              </HStack>
              <Text fontWeight="bold" fontSize="sm" textTransform="uppercase">
                Korjauksen kiireellisyys
              </Text>
              <RadioButtonGroup
                name="affectsDrivability"
                options={[
                  {
                    value: 'no',
                    label: 'Ei vaikuta ajoon',
                  },
                  {
                    value: 'yes',
                    label: 'Vaikuttaa ajoon',
                  },
                ]}
                ref={register}
                defaultValue={defaultValues.affectsDrivability}
              />

              <FormControl>
                <FormLabel variant="textarea">Kuvaus</FormLabel>
                <Textarea
                  name="description"
                  variant="withLabel"
                  ref={register}
                  placeholder="Kirjoita kuvaus viasta tai vahingosta"
                  height="8rem"
                />
              </FormControl>
              <Center
                flex="1"
                bg="gray.200"
                {...getRootProps({className: 'dropzone'})}
                borderRadius="md"
                p={12}
              >
                <input {...getInputProps()} />
                {acceptedFiles.length ? (
                  <Wrap align="center" justify="center" spacing={8}>
                    {acceptedFiles.map((f) => (
                      <WrapItem key={f.path}>
                        <Stack align="center" spacing={4}>
                          <FileTypeIcon type={f.type} />
                          <Heading
                            color="gray.600"
                            textStyle="h2"
                            maxW="10rem"
                            overflow="hidden"
                            textOverflow="ellipsis"
                            whiteSpace="nowrap"
                            textAlign="center"
                          >
                            {f.path}
                          </Heading>
                        </Stack>
                      </WrapItem>
                    ))}
                  </Wrap>
                ) : (
                  <Stack spacing={2} align="center" userSelect="none">
                    <UploadIcon w={16} h={16} color="gray.600" mb={4} />
                    <Heading color="gray.600" textStyle="h2">
                      Lisää liite
                    </Heading>
                    <Text fontSize="sm" color="gray.700">
                      Raahaa tiedostot koneeltasi tai avaa tiedostoselain klikkaamalla
                    </Text>
                  </Stack>
                )}
              </Center>
            </Stack>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default AddFaultModal;
