import {includes, reject, append, equals} from 'ramda';
import namespace from './namespace';
import {scopedStore} from 'utils/redux';
import {emptyPagination} from 'constants/pagination';

export const initialState = {
  query: {
    'page[number]': 1,
    'filter[vehicle_id]': '',
    'filter[state]': '',
    'filter[affects_drivability]': '',
    'filter[type]': '',
    'filter[tags]': '',
    'filter[created_at_from]': '',
    'filter[created_at_to]': '',
    'filter[title]': '',
    sort: '-created_at',
  },
  initialized: false,
  processing: false,
  loading: false,
  addFaultModalOpen: false,
  faults: [],
  pagination: emptyPagination,
  selection: [],
  tags: [],
};

export const cases = {
  updateQuery: (st, all, payload) => {
    return {
      ...st,
      query: {...st.query, ...payload},
      loading: true,
    };
  },
  setFaults: (st, all, payload) => {
    return {
      ...st,
      faults: payload.data,
      pagination: payload.pagination,
      loading: false,
    };
  },
  toggleAddFaultModal: (st) => {
    return {
      ...st,
      addFaultModalOpen: !st.addFaultModalOpen,
    };
  },
  selectFaults: (st, all, faults) => {
    let selection = st.selection;

    faults.forEach((f) => {
      if (includes(f.id, selection)) {
        selection = !f.selected ? reject(equals(f.id), selection) : selection;
      } else {
        selection = f.selected ? append(f.id, selection) : selection;
      }
    });

    return {...st, selection};
  },
  clearSelection: (st) => {
    return {...st, selection: initialState.selection};
  },
  setTags: (st, all, payload) => {
    return {...st, tags: payload};
  },
  setProcessing: (st, all, payload) => {
    return {...st, processing: payload};
  },
  setLoading: (st, all, payload) => {
    return {...st, loading: payload};
  },
  setInitialized: (st, all, payload) => {
    return {...st, initialized: payload};
  },
  reset: (st) => {
    return initialState;
  },
};

export const {actions, reducer} = scopedStore(namespace, cases, initialState);
