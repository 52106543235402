import {states} from 'dicts/faults';

export const formatEditFaultDefaultVals = (fault) => {
  if (!fault) return {};

  return {
    title: fault.title,
    description: fault.description,
    type: fault.type,
    vehicle_id: fault.vehicle?.id,
  };
};

export const formatFetchableQuery = (q) => {
  const {'filter[state]': state, ...rest} = q;

  return {
    'filter[state]': state || Object.keys(states).filter((s) => s !== 'archived'), // archived faults not fetched by default
    ...rest,
  };
};
