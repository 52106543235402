import {mergeLeft, equals} from 'ramda';
import namespace from './namespace';
import {createEffects} from 'utils/events';
import services from 'services';
import _acts from './boundActions';
import _sels from './boundSelectors';
import {P} from 'utils/types';
import {guardHandled} from 'io/errors';
import {setPageTitleMessage, decorateWithNotificationsEff} from 'io/app';
import {getQuery, pushQuery} from 'io/history';
import _commonActs from 'modules/common/boundActions';
import _commonSels from 'modules/common/boundSelectors';
import {updateUser, getUser, postVacation, getConfiguration} from './io';
import {parseUrlQuery} from './utils';

const history = services.get('history');

let acts, sels, commonActs, commonSels;
_acts.then((x) => (acts = x));
_sels.then((x) => (sels = x));
_commonActs.then((x) => (commonActs = x));
_commonSels.then((x) => (commonSels = x));

let effects = {};
let types = {};

effects.initialize = guardHandled(async (id) => {
  const currUser = commonSels.user();
  const isAdmin = commonSels.isAdmin();

  // non-admin user can only view self
  if (!isAdmin && currUser.id !== id) {
    history.push('/');
    return;
  }

  const query = parseUrlQuery(getQuery());
  acts.updateQuery(query);

  if (currUser.id === id) {
    setPageTitleMessage('Omat tiedot');
    acts.setUser(currUser);
  } else {
    setPageTitleMessage('Käyttäjät');
    const user = await decorateWithNotificationsEff(
      {id: 'get-user', failureStyle: 'warning'},
      getUser(id),
    );
    acts.setUser(user);
  }

  // get configuration to determine if annual holidays should be shown
  const config = await decorateWithNotificationsEff(
    {id: 'get-configuration', failureStyle: 'warning'},
    getConfiguration(),
  );
  acts.setConfiguration(config);
  acts.setLoading(false);
});
types.initialize = P.Number;

effects.destroy = async () => {
  acts.reset();
};

effects.updateUser = guardHandled(async (formData) => {
  const user = sels.user();
  const currUser = commonSels.user();

  try {
    acts.setProcessing(true);
    const updatedUser = await decorateWithNotificationsEff(
      {
        id: 'update-user',
        failureStyle: 'error',
        success: 'Tallennettu',
      },
      updateUser({id: user.id, ...formData}),
    );
    acts.setProcessing(false);
    acts.closeUserEditor();
    acts.setUser(updatedUser);

    // if updated self, update logged in user data in common too
    if (currUser.id === user.id) {
      commonActs.setUser(updatedUser);
    }
  } catch (e) {
    acts.setProcessing(false);
    throw e;
  }
});
types.updateUser = P.Object;

effects.updateQuery = (query) => {
  acts.updateQuery(query);
  pushQuery(mergeLeft(sels.query()));
};
types.updateQuery = P.Object;

effects.recheckQuery = () => {
  const urlQuery = parseUrlQuery(getQuery());
  const query = sels.query();
  if (!equals(query, urlQuery)) {
    acts.updateQuery(urlQuery);
  }
};

effects.addVacation = guardHandled(async (data) => {
  try {
    acts.setProcessing(true);
    await decorateWithNotificationsEff(
      {
        id: 'add-vacation',
        failureStyle: 'error',
        success: 'Tallennettu',
      },
      postVacation(data),
    );
    acts.setProcessing(false);
  } catch (e) {
    acts.setProcessing(false);
    throw e;
  }
});
types.addVacation = P.Object;

effects.openUserEditor = () => {
  acts.openUserEditor();
};

effects.closeUserEditor = () => {
  acts.closeUserEditor();
};

export default createEffects(namespace, services.get('channel').dispatch, types, effects);
