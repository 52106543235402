/* eslint-disable max-len */
import React from 'react';
import {Icon} from '@chakra-ui/react';

const PauseIcon = (props) => (
  <Icon
    viewBox="0 0 16 16"
    stroke="currentColor"
    fill="currentColor"
    strokeWidth="0"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8,0 C3.581722,0 0,3.581722 0,8 C0,12.418278 3.581722,16 8,16 C12.418278,16 16,12.418278 16,8 C15.9952234,3.58370203 12.416298,0.00477656508 8,0 Z M11.3333333,10.6666667 C11.3333333,11.0348565 11.0348565,11.3333333 10.6666667,11.3333333 L9.33333333,11.3333333 C8.9651435,11.3333333 8.66666667,11.0348565 8.66666667,10.6666667 L8.66666667,5.33333333 C8.66666667,4.9651435 8.9651435,4.66666667 9.33333333,4.66666667 L10.6666667,4.66666667 C11.0348565,4.66666667 11.3333333,4.9651435 11.3333333,5.33333333 L11.3333333,10.6666667 Z M7.33333333,5.33333333 L7.33333333,10.6666667 C7.33333333,11.0348565 7.0348565,11.3333333 6.66666667,11.3333333 L5.33333333,11.3333333 C4.9651435,11.3333333 4.66666667,11.0348565 4.66666667,10.6666667 L4.66666667,5.33333333 C4.66666667,4.9651435 4.9651435,4.66666667 5.33333333,4.66666667 L6.66666667,4.66666667 C7.0348565,4.66666667 7.33333333,4.9651435 7.33333333,5.33333333 Z"
    />
  </Icon>
);

export default PauseIcon;
