import React from 'react';
import {Stack, Button, SimpleGrid, Tag, Text} from '@chakra-ui/react';
import {Link as RouterLink} from 'react-router-dom';
import {format, parseISO} from 'date-fns';
import fi from 'date-fns/locale/fi';
import {types} from 'dicts/drives';
import {tags} from 'dicts/docs';
import {getDriveNote, getPausesStr} from 'modules/drives/utils';
import {Card, List, ListItem, PersonIcon, CardHeader, File, FileList} from 'components';
import DriveProgram from './drive-program';

const DriveTabBasic = ({
  shift,
  openDocument,
  editDocument,
  removeDocument,
  hideActions,
  isAdmin,
}) => {
  const startDate =
    shift.drive?.type === 'order' ? shift.drive.departure_at : shift.shift_start_at;
  const endDate =
    shift.drive?.type === 'order' ? shift.drive.arrival_at : shift.shift_end_at;

  const startStr = startDate
    ? `${format(parseISO(startDate), 'EEEEEE d.M.yyyy', {
        locale: fi,
      })} klo ${format(parseISO(startDate), 'HH:mm')}`
    : '';

  const endStr = endDate
    ? `${format(parseISO(endDate), 'EEEEEE d.M.yyyy', {
        locale: fi,
      })} klo ${format(parseISO(endDate), 'HH:mm')}`
    : '';

  const presenceStr = shift.drive?.presence_at
    ? ` (Paikalla ${format(parseISO(shift.drive.presence_at), 'HH:mm')})`
    : '';

  const driveLists =
    shift.drive?.documents?.filter((d) => d.tags && d.tags.includes(tags.driveList)) ||
    [];

  return (
    <SimpleGrid columns={{md: 2}} spacing="5">
      <Card>
        <CardHeader isMain>{shift.drive?.title}</CardHeader>

        <List marginBottom="5">
          <ListItem title="Aloitus">
            <Text fontWeight="bold">{shift.drive?.departure_location}</Text>
            <Text fontSize="sm">
              {startStr}
              {presenceStr}
            </Text>
          </ListItem>

          <ListItem title="Päättyminen">
            <Text fontWeight="bold">{shift.drive?.arrival_location}</Text>
            <Text fontSize="sm">{endStr}</Text>
          </ListItem>

          <ListItem title="Tauot">
            <Text fontSize="sm">{getPausesStr(shift.pauses)}</Text>
          </ListItem>

          <ListItem title="Kuljettaja">
            <Stack spacing={2} alignItems="flex-start">
              {shift.user && (
                <Button
                  as={isAdmin ? RouterLink : undefined}
                  to={isAdmin ? `/users/${shift.user.id}` : undefined}
                  variant="primary-link"
                  leftIcon={<PersonIcon />}
                  fontSize="sm"
                >
                  {shift.user.name}
                </Button>
              )}
            </Stack>
          </ListItem>

          <ListItem title="Tyyppi">
            {shift.drive && (
              <Tag variant="outline">{types[shift.drive.type] || shift.drive.type}</Tag>
            )}
          </ListItem>

          <ListItem title="Huomautus">
            <Text>{getDriveNote(shift.drive)}</Text>
          </ListItem>
        </List>
      </Card>

      {shift.drive?.type === 'order' ? (
        <DriveProgram program={shift.drive.program} />
      ) : (
        <Card>
          <CardHeader>Ajolista</CardHeader>
          {driveLists.length ? (
            <FileList>
              {driveLists.map((doc) => (
                <File
                  key={doc.id}
                  type={doc.filetype}
                  modified={doc.updated_at}
                  hideActions={hideActions}
                  onOpen={() =>
                    doc.filetype === 'document'
                      ? openDocument(doc)
                      : window.open(doc.url, '_blank')
                  }
                  onEdit={() => editDocument(doc)}
                  onDelete={() => removeDocument(doc)}
                >
                  {doc.title}
                </File>
              ))}
            </FileList>
          ) : (
            <Text p={5}>Ei ajolistaa</Text>
          )}
        </Card>
      )}
    </SimpleGrid>
  );
};

export default DriveTabBasic;
