import namespace from './namespace';
import {scopedSelectors} from 'utils/redux';

let sels = {};

sels.initialized = (st) => st.initialized;

sels.processing = (st) => st.processing;

sels.loading = (st) => st.loading;

sels.addUserModalOpen = (st) => st.addUserModalOpen;

sels.query = (st) => st.query;

sels.users = (st) => st.users;

sels.roleMassEditorOpen = (st) => st.roleMassEditorOpen;

sels.pagination = (st) => st.pagination;

sels.selection = (st) => st.selection;

export default scopedSelectors(namespace, sels);
