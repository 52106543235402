import {evolve, sort, omit} from 'ramda';
import {sort as fastSort} from 'fast-sort';
import {
  eachDayOfInterval,
  parseISO,
  formatISO,
  isBefore,
  isAfter,
  differenceInWeeks,
  startOfWeek,
  endOfWeek,
  subWeeks,
  addWeeks,
} from 'date-fns';
import {replace} from 'utils/objects';
import {parseNullableNumber, parseNullableJson} from 'utils/fields';
import {dateIsBetween} from 'utils/time';
import {initialState} from './store';
import {periodStartingPoint, defaultQuery} from './constants';

export const parseUrlQuery = (query) => {
  const vals = evolve(
    {
      'page[number]': Number,
      'page[size]': Number,
      'filter[vehicle_id]': parseNullableNumber,
      'filter[user_id]': parseNullableNumber,
      onlyUnread: parseNullableJson(false),
      onlyUnreported: parseNullableJson(false),
      onlyExceptions: parseNullableJson(false),
    },
    query,
  );
  return replace(vals, initialState.query);
};

export const getPeriodStart = () => {
  const currDate = new Date();
  const diff = differenceInWeeks(currDate, periodStartingPoint);
  const isPeriodSecondHalf = diff % 2 !== 0;

  const start = isPeriodSecondHalf
    ? startOfWeek(subWeeks(currDate, 1), {weekStartsOn: 1})
    : startOfWeek(currDate, {weekStartsOn: 1});

  return start;
};

export const getPeriodEnd = () => {
  const currDate = new Date();
  const diff = differenceInWeeks(currDate, periodStartingPoint);
  const isPeriodSecondHalf = diff % 2 !== 0;

  const end = isPeriodSecondHalf
    ? endOfWeek(currDate, {weekStartsOn: 1})
    : endOfWeek(addWeeks(currDate, 1), {weekStartsOn: 1});

  return end;
};

export const formatFetchableQuery = (q, isAdmin) => {
  const {
    shiftStartAtFrom,
    shiftStartAtTo,
    departure,
    activeTab,
    onlyUnread,
    onlyUnreported,
    onlyExceptions,
    visibility,
    ...rest
  } = q;

  // prettier-ignore
  let query = {
    'filter[shift_start_at_from]': departure === 'period' ? formatISO(getPeriodStart(), {representation: 'date'})
      : departure === 'today' ? new Date().toISOString().split('T')[0]
      : shiftStartAtFrom,
    'filter[shift_start_at_to]': departure === 'period' ? formatISO(getPeriodEnd(), {representation: 'date'})
      : departure === 'today' ? new Date().toISOString().split('T')[0]
      : shiftStartAtTo,
    ...rest,
  };

  if (onlyUnread) {
    query = {...query, 'filter[is_read]': 0};
  }

  if (onlyUnreported) {
    query = {...query, 'filter[is_returned]': 0};
  }

  if (onlyExceptions) {
    query = {...query, 'filter[has_exceptions]': 1};
  }

  if (visibility) {
    query = {...query, 'filter[is_published]': visibility === 'published' ? 1 : 0};
  }

  if (!isAdmin) {
    // show only published shifts if user isn't admin
    query = {...query, 'filter[is_published]': 1};
  }

  if (activeTab === 'report') {
    query = {
      ...omit(['filter[is_published]'], query), // show all published/unpublished shifts in report. for non-admin users, unpublished shifts are shown as "reservations", meaning all drive details are hidden until published.
      'filter[trashed]': 'with', // show deleted shifts in report
      include: defaultQuery.include + ',return',
    };
  }

  if (activeTab === 'massEditor') {
    // show only series type drives in massEditor
    query = {...query, 'filter[drive_type]': 'series'};
  }

  if (activeTab === 'list') {
    query = {...query, append: 'previous_drive,is_returned'};
  }

  return query;
};

export const groupShiftsByUser = (shifts, drivers) => {
  const shiftsByUser = Object.values(
    shifts.reduce((acc, cur) => {
      if (cur.user) {
        const u = cur.user;
        if (acc[u.id]) {
          acc[u.id].shifts = [...acc[u.id].shifts, cur];
        } else {
          acc[u.id] = {...u, shifts: [cur]};
        }
      }
      return acc;
    }, {}),
  );

  const usersWithoutShift = drivers
    .filter((d) => !shiftsByUser.find((u) => u.id === d.id))
    .map((d) => ({...d, shifts: []}));

  return fastSort([...shiftsByUser, ...usersWithoutShift]).asc((u) => u.name);
};

// generate virtual shifts for vacation days and off days
export const fillVirtualShifts = (
  shiftsGrouped = [],
  vacations = [],
  startDate = '',
  endDate = '',
) => {
  return shiftsGrouped.map((u) => {
    let virtualShifts = [];

    if (startDate && endDate) {
      const userShifts = u.shifts.filter((x) => !!x.shift_start_at);
      const userVacations = vacations.filter(
        (v) => v.user?.id === u.id && !!v.starts_at && !!v.ends_at,
      );

      const start = parseISO(startDate);
      const end = parseISO(endDate);
      const dateRange =
        start.getTime() <= end.getTime() ? eachDayOfInterval({start, end}) : [];

      dateRange.forEach((day) => {
        if (
          // create virtual shift if day is during vacation
          userVacations.find((v) =>
            dateIsBetween({
              date: day,
              from: parseISO(v.starts_at),
              to: parseISO(v.ends_at),
            }),
          )
        ) {
          virtualShifts = [
            ...virtualShifts,
            {
              id: `vacation.${day.getTime()}`,
              shift_start_at: day.toISOString(),
              virtual: true,
              virtualType: 'vacation',
            },
          ];
        } else if (
          // create virtual shift if no actual shift (or vacation) found for the day to fill in off days during the period
          ![...userShifts, ...virtualShifts].find(
            (s) => parseISO(s.shift_start_at).setHours(0, 0, 0, 0) === day.getTime(),
          )
        ) {
          virtualShifts = [
            ...virtualShifts,
            {
              id: `offDay.${day.getTime()}`,
              shift_start_at: day.toISOString(),
              virtual: true,
              virtualType: 'offDay',
            },
          ];
        }
      });

      // create virtual shifts for cancelled off days
      // if shift was created after the period strated and there are no other shifts on that day created before the period started, we assume that day was off day originally
      const cancelledOffDays = userShifts.filter(
        (x) =>
          isAfter(parseISO(x.created_at), start) &&
          !userShifts.find(
            (y) =>
              y.id !== x.id &&
              isBefore(parseISO(y.created_at), start) &&
              parseISO(y.shift_start_at).setHours(0, 0, 0, 0) ===
                parseISO(x.shift_start_at).setHours(0, 0, 0, 0),
          ),
      );

      cancelledOffDays.forEach((c) => {
        const startAt = parseISO(c.shift_start_at);

        if (
          !virtualShifts.find(
            (x) =>
              parseISO(x.shift_start_at).setHours(0, 0, 0, 0) ===
              startAt.setHours(0, 0, 0, 0),
          )
        ) {
          virtualShifts = [
            ...virtualShifts,
            {
              id: `offDayCancelled.${c.id}`,
              shift_start_at: startAt.toISOString(),
              virtual: true,
              virtualType: 'offDayCancelled',
            },
          ];
        }
      });
    }

    const shifts = sort(
      (a, b) => parseISO(a.shift_start_at) - parseISO(b.shift_start_at),
      [...virtualShifts, ...u.shifts],
    );

    return {...u, shifts};
  });
};

const _getFiltersCount = (query, filters) => {
  let count = 0;

  for (const [key, value] of Object.entries(query)) {
    if (filters.includes(key) && value !== initialState.query[key]) {
      count++;
    }
  }

  return count;
};

export const getFiltersCount = (query) => {
  const filters = ['filter[drive_title]', 'filter[vehicle_id]', 'filter[user_id]'];

  return _getFiltersCount(query, filters);
};

export const getExtraFiltersCount = (query) => {
  const extraFilters = [
    'filter[drive_type]',
    'onlyUnread',
    'onlyUnreported',
    'onlyExceptions',
    'visibility',
  ];

  return _getFiltersCount(query, extraFilters);
};

export const filterUnpublishedTrashed = (data = []) => {
  return data.filter((x) => (!!x.deleted_at && !x.is_published ? false : true));
};
