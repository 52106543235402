import {includes, reject, append, equals} from 'ramda';
import namespace from './namespace';
import {scopedStore} from 'utils/redux';
import {emptyPagination} from 'constants/pagination';

export const initialState = {
  query: {
    'page[number]': 1,
    'page[size]': 15,
    'filter[drive_title]': '',
    'filter[drive_type]': '',
    'filter[vehicle_id]': null,
    'filter[user_id]': null,
    shiftStartAtFrom: '',
    shiftStartAtTo: '',
    departure: 'today',
    activeTab: 'list',
    onlyUnread: false,
    onlyUnreported: false,
    onlyExceptions: false,
    visibility: '',
  },
  initialized: false,
  processing: false,
  loading: false,
  shifts: [],
  pagination: emptyPagination,
  drivers: [],
  selection: [],
  vacations: [],
};

export const cases = {
  updateQuery: (st, all, payload) => {
    return {
      ...st,
      query: {...st.query, ...payload},
      loading: true,
    };
  },
  setShifts: (st, all, payload) => {
    return {
      ...st,
      shifts: payload.data,
      pagination: payload.pagination,
      loading: false,
    };
  },
  setDrivers: (st, all, payload) => {
    return {...st, drivers: payload};
  },
  selectShifts: (st, all, shifts) => {
    let selection = st.selection;

    shifts.forEach((s) => {
      if (includes(s.id, selection)) {
        selection = !s.selected ? reject(equals(s.id), selection) : selection;
      } else {
        selection = s.selected ? append(s.id, selection) : selection;
      }
    });

    return {...st, selection};
  },
  clearSelection: (st) => {
    return {...st, selection: initialState.selection};
  },
  setProcessing: (st, all, payload) => {
    return {...st, processing: payload};
  },
  setLoading: (st, all, payload) => {
    return {...st, loading: payload};
  },
  setInitialized: (st, all, payload) => {
    return {...st, initialized: payload};
  },
  setVacations: (st, all, vacations) => {
    return {...st, vacations};
  },
  reset: (st) => {
    return initialState;
  },
};

export const {actions, reducer} = scopedStore(namespace, cases, initialState);
