import {mergeLeft} from 'ramda';
import namespace from './namespace';
import {createEffects} from 'utils/events';
import services from 'services';
import _acts from './boundActions';
import _sels from './boundSelectors';
import {setPageTitleMessage, decorateWithNotificationsEff} from 'io/app';
import {guardHandled} from 'io/errors';
import {P, Record} from 'utils/types';
import {getQuery, pushQuery} from 'io/history';
import confirmerEffs from 'modules/confirmer/effects';
import msgs from 'dicts/messages';
import {parseUrlQuery} from './utils';
import {getUsers, postUser, massUpdateUsers, deleteUser} from './io';

let acts, sels;
_acts.then((x) => (acts = x));
_sels.then((x) => (sels = x));

let effects = {};
let types = {};

effects.initialize = guardHandled(async () => {
  setPageTitleMessage('Käyttäjät');

  const query = parseUrlQuery(getQuery());
  acts.updateQuery(query);

  const {data, meta} = await decorateWithNotificationsEff(
    {id: 'init-users', failureStyle: 'warning'},
    getUsers(sels.query()),
  );
  acts.setUsers({data, pagination: meta});
  acts.setInitialized(true);
});

effects.destroy = async () => {
  acts.reset();
};

effects.updateQuery = guardHandled(async (query) => {
  acts.updateQuery(query);
  pushQuery(mergeLeft(sels.query()));
  const {data, meta} = await decorateWithNotificationsEff(
    {id: 'get-users', failureStyle: 'warning'},
    getUsers(sels.query()),
  );
  acts.setUsers({data, pagination: meta});
});
types.updateQuery = P.Object;

effects.createUser = guardHandled(async (formData) => {
  try {
    acts.setProcessing(true);
    await decorateWithNotificationsEff(
      {
        id: 'post-user',
        failureStyle: 'error',
        success: 'Tallennettu',
      },
      postUser(formData),
    );
    acts.toggleAddUserModal();
    acts.setProcessing(false);
  } catch (e) {
    acts.setProcessing(false);
    throw e;
  }
  // refetch
  acts.setLoading(true);
  const {data, meta} = await decorateWithNotificationsEff(
    {id: 'get-users', failureStyle: 'warning'},
    getUsers(sels.query()),
  );
  acts.setUsers({data, pagination: meta});
});
types.createUser = P.Object;

effects.removeUsers = (ids) => {
  const onConfirm = guardHandled(async () => {
    try {
      acts.setProcessing(true);
      await decorateWithNotificationsEff(
        {
          id: 'remove-users',
          failureStyle: 'error',
          loading: msgs.deleting,
          success: ids.length > 1 ? 'Käyttäjät poistettu' : 'Käyttäjä poistettu',
        },
        Promise.all(ids.map((id) => deleteUser(id))),
      );
      acts.setProcessing(false);
      acts.clearSelection();
    } catch (e) {
      acts.setProcessing(false);
      throw e;
    }
    // refetch
    acts.setLoading(true);
    const {data, meta} = await decorateWithNotificationsEff(
      {id: 'get-users', failureStyle: 'warning'},
      getUsers(sels.query()),
    );
    acts.setUsers({data, pagination: meta});
  });

  confirmerEffs.show({
    message:
      ids.length > 1 ? `Poistetaanko ${ids.length} käyttäjää?` : 'Poistetaanko käyttäjä?',
    okText: 'Poista',
    okStyle: 'danger',
    cancelText: msgs.cancel,
    onCancel: () => {},
    onOk: onConfirm,
  });
};
types.removeUsers = P.Array;

effects.updateUsersRole = guardHandled(async ({ids, role}) => {
  try {
    acts.setProcessing(true);
    await decorateWithNotificationsEff(
      {
        id: 'update-users-role',
        failureStyle: 'error',
        success: 'Tallennettu',
      },
      massUpdateUsers({ids, role}),
    );
    acts.toggleRoleMassEditor();
    acts.setProcessing(false);
    acts.clearSelection();
  } catch (e) {
    acts.setProcessing(false);
    throw e;
  }
  // refetch
  acts.setLoading(true);
  const {data, meta} = await decorateWithNotificationsEff(
    {id: 'get-users', failureStyle: 'warning'},
    getUsers(sels.query()),
  );
  acts.setUsers({data, pagination: meta});
});
types.updateUsersRole = Record({ids: P.Array, role: P.String});

effects.toggleAddUserModal = () => {
  acts.toggleAddUserModal();
};

effects.toggleRoleMassEditor = () => {
  acts.toggleRoleMassEditor();
};

effects.selectUsers = (users) => {
  acts.selectUsers(users);
};
types.selectUsers = P.Array;

export default createEffects(namespace, services.get('channel').dispatch, types, effects);
