import React from 'react';
import {useForm} from 'react-hook-form';
import {
  Box,
  Button,
  GridItem,
  SimpleGrid,
  Stack,
  Text,
  FormControl,
  FormLabel,
  Textarea,
  FormErrorMessage,
} from '@chakra-ui/react';
import {Card, CardHeader, ReactDatepicker} from 'components';

const UserTabAnnualHolidays = ({
  sendRequest,
  processing,
  show,
  reserveFrom,
  reserveTo,
}) => {
  const {handleSubmit, register, control, errors} = useForm({
    defaultValues: {starts_at: '', ends_at: '', comment: ''},
  });

  const submit = (data) => {
    sendRequest({type: 'annualHoliday', ...data});
  };

  if (!show) return null;

  return (
    <SimpleGrid columns={{lg: 3}} spacing="5">
      <Card>
        <CardHeader>Vuosilomat</CardHeader>
        <form
          id="annual-holiday-request-form"
          onSubmit={handleSubmit(submit)}
          autoComplete="off"
        >
          <Stack padding="5" spacing="2">
            <FormControl isInvalid={errors.starts_at}>
              <FormLabel>Päivämäärä - alkaen (*)</FormLabel>
              <ReactDatepicker
                name="starts_at"
                control={control}
                dateFormat="dd.MM.yyyy"
                rules={{required: 'Pakollinen kenttä'}}
                portalId="root"
                minDate={reserveFrom}
                maxDate={reserveTo}
              />
              <FormErrorMessage>
                {errors.starts_at && errors.starts_at.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={errors.ends_at}>
              <FormLabel>Päivämäärä - päättyen (*)</FormLabel>
              <ReactDatepicker
                name="ends_at"
                control={control}
                dateFormat="dd.MM.yyyy"
                rules={{required: 'Pakollinen kenttä'}}
                portalId="root"
                minDate={reserveFrom}
                maxDate={reserveTo}
              />
              <FormErrorMessage>
                {errors.ends_at && errors.ends_at.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={errors.comment}>
              <FormLabel variant="textarea">
                Miksi toivot tätä kohtaa vapaaksi (*)
              </FormLabel>
              <Textarea
                name="comment"
                variant="withLabel"
                ref={register({required: 'Pakollinen kenttä'})}
                height="8rem"
              />
              <FormErrorMessage>
                {errors.comment && errors.comment.message}
              </FormErrorMessage>
            </FormControl>

            <Box textAlign="right">
              <Button
                variant="primary"
                type="submit"
                form="annual-holiday-request-form"
                isLoading={processing}
              >
                <span>Lähetä toive</span>
              </Button>
            </Box>
          </Stack>
        </form>
      </Card>

      <GridItem colSpan={{lg: 2}}>
        <Stack spacing="5" padding="5">
          <Text fontWeight="bold">Kesälomatoive</Text>
          <Text>
            Täytä ohessa lomatoiveesi, kesäloman voi jakaa osiin halutessaan. Halutessasi
            voit kirjoittaa lisätietoja. Huomioithan, että nämä ovat toiveita ja
            kesälomalista julkaistaan erikseen.
          </Text>
        </Stack>
      </GridItem>
    </SimpleGrid>
  );
};

export default UserTabAnnualHolidays;
