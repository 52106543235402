export default {
  h1: {
    fontSize: 'xl',
    fontWeight: 'extrabold',
    lineHeight: '6',
  },
  h2: {
    fontSize: 'lg',
    fontWeight: 'extrabold',
    lineHeight: '6',
  },
  h3: {
    fontSize: 'md',
    fontWeight: 'extrabold',
    lineHeight: '6',
  },
  body: {
    fontSize: 'md',
    fontWeight: 'regular',
    lineHeight: '6',
  },
  small: {
    fontSize: 'sm',
    fontWeight: 'regular',
    lineHeight: '4',
  },
  heading: {
    fontSize: 'sm',
    fontWeight: 'extrabold',
    lineHeight: '4',
    textTransform: 'uppercase',
    letterSpacing: 'wider',
  },
  caption: {
    fontSize: 'xs',
    fontWeight: 'regular',
    lineHeight: '4',
  },
};
