import {prop} from 'ramda';
import services from 'services';
import {describeThrow} from 'utils/errors';
import msgs from 'dicts/messages';

let httpJson = null;
services.waitFor('api').then((x) => (httpJson = x.httpJson));

export const getFolders = (query) =>
  httpJson('get', '/folders', {
    'page[number]': 1,
    'page[size]': 999,
    ...query,
  })
    .catch(describeThrow(msgs.fetchFailed))
    .then(prop('data'));

export const getDocuments = (query) =>
  httpJson('get', '/documents', {
    'page[number]': 1,
    'page[size]': 999,
    'filter[type]': 'document',
    include: 'client,vehicles,drives',
    ...query,
  })
    .catch(describeThrow(msgs.fetchFailed))
    .then(prop('data'));

export const getFolder = (folderId) =>
  httpJson('get', `/folders/${folderId}`, {
    include: 'folders,documents.client,documents.vehicles,documents.drives',
  })
    .catch(describeThrow(msgs.fetchFailed))
    .then(prop('data'));

export const postFolder = (body) =>
  httpJson('post', '/folders', {}, {body})
    .catch(describeThrow(msgs.saveFailed))
    .then(prop('data'));

export const updateFolder = (folder) =>
  httpJson('put', `/folders/${folder.id}`, {}, {body: folder})
    .catch(describeThrow(msgs.saveFailed))
    .then(prop('data'));

export const deleteFolder = (id) =>
  httpJson('delete', `/folders/${id}`).catch(describeThrow(msgs.deleteFailed));

export const postDocument = (body) =>
  httpJson('post', '/documents', {include: 'client,vehicles,drives'}, {body})
    .catch(describeThrow(msgs.saveFailed))
    .then(prop('data'));

export const updateDocument = (document) =>
  httpJson(
    'put',
    `/documents/${document.id}`,
    {include: 'client,vehicles,drives'},
    {body: document},
  )
    .catch(describeThrow(msgs.saveFailed))
    .then(prop('data'));

export const deleteDocument = (id) =>
  httpJson('delete', `/documents/${id}`).catch(describeThrow(msgs.deleteFailed));
