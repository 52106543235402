import {prop} from 'ramda';
import services from 'services';
import {describeThrow} from 'utils/errors';
import msgs from 'dicts/messages';

let httpJson = null;
services.waitFor('api').then((x) => (httpJson = x.httpJson));

export const getUser = () =>
  httpJson('get', '/users/me', {})
    .catch(describeThrow(msgs.fetchFailed))
    .then(prop('data'));

export const logout = () =>
  httpJson('post', '/logout', {}).catch(describeThrow(msgs.opFailed));

export const search = (query) =>
  httpJson('get', '/search', {...query}).catch(describeThrow(msgs.fetchFailed));

export const getVehicles = () =>
  httpJson('get', '/vehicles', {'page[size]': 999})
    .catch(describeThrow('Autojen haku epäonnistui'))
    .then(prop('data'));
