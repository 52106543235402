import {createSelector} from 'reselect';
import namespace from './namespace';
import {scopedSelectors} from 'utils/redux';
import {formatUserEditorDefaultVals} from './utils';
import {
  getShowHolidayFormConfig,
  getHolidayReserveFromConfig,
  getHolidayReserveToConfig,
} from 'modules/settings/utils';

let sels = {};

sels.processing = (st) => st.processing;

sels.loading = (st) => st.loading;

sels.user = (st) => st.user;

sels.userEditorOpen = (st) => st.userEditorOpen;

sels.userEditorDefaultVals = createSelector(sels.user, (user) =>
  formatUserEditorDefaultVals(user),
);

sels.query = (st) => st.query;

sels.configuration = (st) => st.configuration;

sels.showAnnualHolidays = createSelector(sels.configuration, (config) =>
  getShowHolidayFormConfig(config),
);

sels.annualHolidaysReserveFrom = createSelector(sels.configuration, (config) =>
  getHolidayReserveFromConfig(config),
);

sels.annualHolidaysReserveTo = createSelector(sels.configuration, (config) =>
  getHolidayReserveToConfig(config),
);

export default scopedSelectors(namespace, sels);
