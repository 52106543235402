/* eslint-disable max-len */
import React from 'react';
import {Icon} from '@chakra-ui/react';

const ChevronDoubleLeftIcon = (props) => (
  <Icon
    viewBox="0 0 16 16"
    stroke="currentColor"
    fill="currentColor"
    strokeWidth="0"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.5641014,2.26079326 C13.9078399,2.60453178 13.912353,3.1604341 13.5742408,3.50970828 L9.1730901,7.91085895 C9.14940937,7.93447988 9.13610064,7.96655265 9.13610064,8 C9.13610064,8.03344735 9.14940937,8.06552012 9.1730901,8.08914105 L13.5742408,12.4902917 C13.912353,12.8395659 13.9078399,13.3954682 13.5641014,13.7392067 C13.2203628,14.0829453 12.6644605,14.0874584 12.3151863,13.7493462 L7.51013101,8.94429082 C7.25958719,8.69398504 7.11912349,8.3541535 7.11982153,8 C7.11912349,7.6458465 7.25958719,7.30601496 7.51013101,7.05570918 L12.3151863,2.25065385 C12.6644605,1.91254161 13.2203628,1.91705474 13.5641014,2.26079326 Z M8.44428242,2.26079326 C8.78802094,2.60453178 8.79253407,3.1604341 8.45442183,3.50970828 L4.05327116,7.91085895 C4.02959044,7.93447988 4.01628171,7.96655265 4.01628171,8 C4.01628171,8.03344735 4.02959044,8.06552012 4.05327116,8.08914105 L8.45442183,12.4902917 C8.79253407,12.8395659 8.78802094,13.3954682 8.44428242,13.7392067 C8.10054389,14.0829453 7.54464158,14.0874584 7.1953674,13.7493462 L2.39031207,8.94429082 C2.13976826,8.69398504 1.99930455,8.3541535 2.00000259,8 C1.99930455,7.6458465 2.13976826,7.30601496 2.39031207,7.05570918 L7.1953674,2.25065385 C7.54464158,1.91254161 8.10054389,1.91705474 8.44428242,2.26079326 Z"
    />
  </Icon>
);

export default ChevronDoubleLeftIcon;
