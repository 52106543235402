import namespace from './namespace';
import {createEffects} from 'utils/events';
import services from 'services';
import _acts from './boundActions';
import _sels from './boundSelectors';
import {P} from 'utils/types';
import {guardHandled} from 'io/errors';
import {setPageTitleMessage, decorateWithNotificationsEff} from 'io/app';
import {uploadFiles} from 'io/files';
import confirmerEffs from 'modules/confirmer/effects';
import msgs from 'dicts/messages';
import {
  getFault,
  putFault,
  deleteDocument,
  getVehicleFaults,
  postDocument,
  postFaultLog,
  deleteFault,
  massUpdateFaults,
  getTags,
  getFaults,
} from './io';
import {states} from 'dicts/faults';
import {formatTags} from '../utils';

let acts, sels;
_acts.then((x) => (acts = x));
_sels.then((x) => (sels = x));

let effects = {};
let types = {};

effects.initialize = guardHandled(async (id) => {
  setPageTitleMessage('Vika-/vahinkoilmoitukset');
  const fault = await decorateWithNotificationsEff(
    {id: 'get-fault', failureStyle: 'warning'},
    getFault(id),
  );
  acts.setFault(fault);

  if (fault.vehicle) {
    const {data, meta} = await decorateWithNotificationsEff(
      {id: 'get-vehicle-faults', failureStyle: 'warning'},
      getVehicleFaults(sels.queryFetchable()),
    );
    acts.setVehicleFaults({data, pagination: meta});
  } else {
    acts.setVehicleFaultsLoading(false);
  }

  acts.setInitialized(true);

  const tags = await decorateWithNotificationsEff(
    {id: 'get-faults-tags', failureStyle: 'warning'},
    getTags(),
  );
  acts.setTags(formatTags(tags));
});
types.initialize = P.Number;

effects.destroy = async () => {
  acts.reset();
};

effects.updateFault = guardHandled(async (data) => {
  try {
    acts.setProcessing(true);
    const fault = sels.fault();
    await decorateWithNotificationsEff(
      {
        id: 'update-fault',
        failureStyle: 'error',
        success: 'Tallennettu',
      },
      putFault({id: fault.id, ...data}),
    );
    const updatedFault = await decorateWithNotificationsEff(
      {id: 'get-fault', failureStyle: 'warning'},
      getFault(fault.id),
    );
    acts.setFault(updatedFault);
    acts.closeEditFaultModal();
    acts.setProcessing(false);
  } catch (e) {
    acts.setProcessing(false);
    throw e;
  }
});
types.updateFault = P.Object;

effects.addAttachments = guardHandled(async (files) => {
  if (!files.length) {
    acts.toggleAddAttachmentModal();
    return;
  }

  const fault = sels.fault();

  try {
    acts.setProcessing(true);

    // Upload files -> create document for each file -> update fault
    const uploadedFiles = await decorateWithNotificationsEff(
      {
        id: 'upload-files',
        failureStyle: 'error',
      },
      uploadFiles(files),
    );
    const documents = await decorateWithNotificationsEff(
      {
        id: 'create-documents',
        failureStyle: 'error',
      },
      postDocument({path: uploadedFiles.map((f) => f.path), type: 'fault'}),
    );
    await decorateWithNotificationsEff(
      {
        id: 'update-fault',
        failureStyle: 'error',
        success: files.length > 1 ? 'Liitteet lisätty' : 'Liite lisätty',
      },
      putFault({id: fault.id, document_ids: documents.map((d) => d.id)}),
    );
    acts.setProcessing(false);
    acts.toggleAddAttachmentModal();
  } catch (e) {
    acts.setProcessing(false);
    throw e;
  }

  // refetch
  const updatedFault = await decorateWithNotificationsEff(
    {id: 'get-fault', failureStyle: 'warning'},
    getFault(fault.id),
  );
  acts.setFault(updatedFault);
});
types.addAttachments = P.Array;

effects.removeAttachment = (id) => {
  const onConfirm = guardHandled(async () => {
    try {
      acts.setProcessing(true);
      await decorateWithNotificationsEff(
        {
          id: 'remove-document',
          failureStyle: 'error',
          loading: msgs.deleting,
          success: 'Liite poistettu',
        },
        deleteDocument(id),
      );
      const fault = await decorateWithNotificationsEff(
        {id: 'get-fault', failureStyle: 'warning'},
        getFault(sels.fault().id),
      );
      acts.setFault(fault);
      acts.setProcessing(false);
    } catch (e) {
      acts.setProcessing(false);
      throw e;
    }
  });

  confirmerEffs.show({
    message: 'Poistetaanko liite?',
    okText: 'Poista',
    okStyle: 'danger',
    cancelText: msgs.cancel,
    onCancel: () => {},
    onOk: onConfirm,
  });
};
types.removeAttachment = P.Number;

effects.addFaultLog = guardHandled(async (data) => {
  try {
    acts.setProcessing(true);
    const fault = sels.fault();
    await decorateWithNotificationsEff(
      {
        id: 'post-fault-log',
        failureStyle: 'error',
        success: 'Tallennettu',
      },
      postFaultLog({fault_id: fault.id, ...data}),
    );
    const updatedFault = await decorateWithNotificationsEff(
      {id: 'get-fault', failureStyle: 'warning'},
      getFault(fault.id),
    );
    acts.setFault(updatedFault);
    acts.toggleAddLogModal();
    acts.setProcessing(false);
  } catch (e) {
    acts.setProcessing(false);
    throw e;
  }
});
types.addFaultLog = P.Object;

effects.updateQuery = guardHandled(async (query) => {
  acts.updateQuery(query);

  try {
    acts.setVehicleFaultsLoading(true);
    const {data, meta} = await decorateWithNotificationsEff(
      {id: 'get-vehicle-faults', failureStyle: 'warning'},
      getVehicleFaults(sels.queryFetchable()),
    );
    acts.setVehicleFaults({data, pagination: meta});
  } catch (e) {
    acts.setVehicleFaultsLoading(false);
    throw e;
  }
});
types.updateQuery = P.Object;

effects.openEditFaultModal = guardHandled(async () => {
  acts.openEditFaultModal();
});

effects.closeEditFaultModal = () => {
  acts.closeEditFaultModal();
};

effects.toggleAddAttachmentModal = () => {
  acts.toggleAddAttachmentModal();
};

effects.toggleAddLogModal = () => acts.toggleAddLogModal();

effects.selectFaults = (faults) => {
  acts.selectFaults(faults);
};
types.selectFaults = P.Array;

effects.removeFaults = (ids) => {
  const onConfirm = guardHandled(async () => {
    try {
      acts.setProcessing(true);
      await decorateWithNotificationsEff(
        {
          id: 'remove-faults',
          failureStyle: 'error',
          loading: msgs.deleting,
          success: ids.length > 1 ? 'Ilmoitukset poistettu' : 'Ilmoitus poistettu',
        },
        Promise.all(ids.map((id) => deleteFault(id))),
      );
      acts.setProcessing(false);
      acts.clearSelection();
    } catch (e) {
      acts.setProcessing(false);
      throw e;
    }

    // refetch
    acts.setVehicleFaultsLoading(true);
    const {data, meta} = await decorateWithNotificationsEff(
      {id: 'get-vehicle-faults', failureStyle: 'warning'},
      getVehicleFaults(sels.queryFetchable()),
    );
    acts.setVehicleFaults({data, pagination: meta});
  });

  confirmerEffs.show({
    message:
      ids.length > 1
        ? `Poistetaanko ${ids.length} ilmoitusta?`
        : 'Poistetaanko ilmoitus?',
    okText: 'Poista',
    okStyle: 'danger',
    cancelText: msgs.cancel,
    onCancel: () => {},
    onOk: onConfirm,
  });
};
types.removeFaults = P.Array;

effects.updateFaultsState = ({ids, state}) => {
  const onConfirm = guardHandled(async () => {
    try {
      acts.setProcessing(true);
      await decorateWithNotificationsEff(
        {
          id: 'update-faults',
          failureStyle: 'error',
          loading: 'Tallennetaan',
          success: 'Tallennettu',
        },
        massUpdateFaults({ids, state}),
      );
      acts.setProcessing(false);
      acts.clearSelection();
    } catch (e) {
      acts.setProcessing(false);
      throw e;
    }

    // refetch
    acts.setVehicleFaultsLoading(true);
    const {data, meta} = await decorateWithNotificationsEff(
      {id: 'get-vehicle-faults', failureStyle: 'warning'},
      getVehicleFaults(sels.queryFetchable()),
    );
    acts.setVehicleFaults({data, pagination: meta});
  });

  confirmerEffs.show({
    message:
      ids.length > 1
        ? `Vaihdetaanko ${ids.length} ilmoitusta tilaan: ${states[state]}?`
        : `Vaihdetaanko ilmoitus tilaan: ${states[state]}?`,
    okText: 'Vahvista',
    cancelText: msgs.cancel,
    onCancel: () => {},
    onOk: onConfirm,
  });
};
types.updateFaultsState = P.Object;

effects.searchFaults = guardHandled(async ({text, callback}) => {
  const activeFault = sels.fault();
  const faults = await decorateWithNotificationsEff(
    {
      id: 'search-faults',
      failureStyle: 'warning',
    },
    getFaults({
      'filter[vehicle_id]': activeFault.vehicle?.id,
      'filter[title]': text,
      'filter[state]': Object.keys(states).filter((s) => s !== 'archived'),
    }),
  );
  callback(
    (faults || []).filter(
      (f) =>
        f.id !== activeFault.id &&
        !f.children?.length &&
        !activeFault.children?.find((child) => child.id === f.id),
    ),
  );
});
types.searchFaults = P.Object;

effects.updateParent = guardHandled(async (data) => {
  const onConfirm = guardHandled(async () => {
    try {
      acts.setProcessing(true);
      const parent = sels.fault();
      await decorateWithNotificationsEff(
        {
          id: 'update-parent',
          failureStyle: 'error',
          success: 'Tallennettu',
        },
        putFault({id: data.id, parent_id: parent.id}),
      );
      const updatedFault = await decorateWithNotificationsEff(
        {id: 'get-fault', failureStyle: 'warning'},
        getFault(parent.id),
      );
      acts.setFault(updatedFault);
      acts.setProcessing(false);
    } catch (e) {
      acts.setProcessing(false);
      throw e;
    }
  });

  confirmerEffs.show({
    message: `Yhdistetäänkö ilmoitus: "${data.title}" tähän ilmoitukseen?`,
    okText: 'Vahvista',
    cancelText: msgs.cancel,
    onCancel: () => {},
    onOk: onConfirm,
  });
});
types.updateParent = P.Object;

effects.removeParent = guardHandled(async () => {
  const onConfirm = guardHandled(async () => {
    try {
      acts.setProcessing(true);
      const fault = sels.fault();
      await decorateWithNotificationsEff(
        {
          id: 'remove-parent',
          failureStyle: 'error',
          success: 'Tallennettu',
        },
        putFault({id: fault.id, parent_id: null}),
      );

      const updatedFault = await decorateWithNotificationsEff(
        {id: 'get-fault', failureStyle: 'warning'},
        getFault(fault.id),
      );
      acts.setFault(updatedFault);
      acts.setProcessing(false);
    } catch (e) {
      acts.setProcessing(false);
      throw e;
    }
  });

  confirmerEffs.show({
    message: 'Poistetaanko ilmoitusten yhdistys?',
    okText: 'Vahvista',
    cancelText: msgs.cancel,
    onCancel: () => {},
    onOk: onConfirm,
  });
});

export default createEffects(namespace, services.get('channel').dispatch, types, effects);
