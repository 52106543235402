import namespace from './namespace';
import {createEffects} from 'utils/events';
import services from 'services';
import _acts from './boundActions';
import _sels from './boundSelectors';
import {setPageTitleMessage, decorateWithNotificationsEff} from 'io/app';
import {guardHandled} from 'io/errors';
import {P, Nullable} from 'utils/types';
import {
  getFolders,
  getDocuments,
  postFolder,
  postDocument,
  getFolder,
  updateFolder,
  updateDocument,
  deleteFolder,
  deleteDocument,
} from './io';
import {uploadFiles} from 'io/files';
import {
  formatFolderEditorOutput,
  formatDocumentEditorOutput,
  formatDocumentUploaderOutput,
} from './utils';
import confirmerEffs from 'modules/confirmer/effects';
import msgs from 'dicts/messages';

let acts, sels;
_acts.then((x) => (acts = x));
_sels.then((x) => (sels = x));

let effects = {};
let types = {};

effects.initialize = guardHandled(async () => {
  setPageTitleMessage('Dokumentit');
  acts.setLoading(true);
  await decorateWithNotificationsEff(
    {id: 'init-docs', failureStyle: 'warning'},
    // get folders and documents in root (folder=0)
    Promise.all([
      getFolders({'filter[folder]': 0}).then((folders) => acts.setRootFolders(folders)),
      getDocuments({'filter[folder]': 0}).then((documents) =>
        acts.setRootDocuments(documents),
      ),
    ]),
  );
  acts.setLoading(false);
});

effects.destroy = async () => {
  acts.reset();
};

effects.setActiveFolder = guardHandled(async (folder) => {
  if (folder) {
    acts.setLoading(true);
    const res = await decorateWithNotificationsEff(
      {id: 'get-folder', failureStyle: 'warning'},
      getFolder(folder.id),
    );
    acts.setActiveFolder(res);
    acts.setLoading(false);
  } else {
    // null if navigating to root
    acts.setActiveFolder(folder);
  }
});
types.setActiveFolder = Nullable(P.Object);

effects.createFolder = guardHandled(async (data) => {
  try {
    acts.setProcessing(true);
    const output = formatFolderEditorOutput({
      ...data,
      folderId: sels.activeFolder()?.id,
    });
    const folder = await decorateWithNotificationsEff(
      {
        id: 'create-folder',
        failureStyle: 'error',
        success: 'Kansio tallennettu',
      },
      postFolder(output),
    );
    acts.addFolder(folder);
    acts.setProcessing(false);
    acts.toggleFolderEditor();
  } catch (e) {
    acts.setProcessing(false);
    throw e;
  }
});
types.createFolder = P.Object;

effects.updateFolder = guardHandled(async (data) => {
  try {
    acts.setProcessing(true);
    const folderInEdit = sels.folderInEdit();
    const output = formatFolderEditorOutput(data);
    const folder = await decorateWithNotificationsEff(
      {
        id: 'update-folder',
        failureStyle: 'error',
        success: 'Kansio tallennettu',
      },
      updateFolder({id: folderInEdit.id, ...output}),
    );
    acts.updateFolder(folder);
    acts.setProcessing(false);
    acts.toggleFolderEditor();
  } catch (e) {
    acts.setProcessing(false);
    throw e;
  }
});
types.updateFolder = P.Object;

effects.removeFolder = (id) => {
  const onConfirm = guardHandled(async () => {
    try {
      acts.setProcessing(true);
      await decorateWithNotificationsEff(
        {
          id: 'remove-folder',
          failureStyle: 'error',
          loading: msgs.deleting,
          success: 'Kansio poistettu',
        },
        deleteFolder(id),
      );
      acts.removeFolder(id);
      acts.setProcessing(false);
    } catch (e) {
      acts.setProcessing(false);
      throw e;
    }
  });

  confirmerEffs.show({
    message: 'Poistetaanko kansio?',
    okText: 'Poista',
    okStyle: 'danger',
    cancelText: msgs.cancel,
    onCancel: () => {},
    onOk: onConfirm,
  });
};
types.removeFolder = P.Number;

effects.createDocument = guardHandled(async (data) => {
  try {
    acts.setProcessing(true);
    const activeFolder = sels.activeFolder();
    const output = formatDocumentEditorOutput({
      ...data,
      folderId: activeFolder?.id,
    });
    const doc = await decorateWithNotificationsEff(
      {
        id: 'create-document',
        failureStyle: 'error',
        success: 'Dokumentti tallennettu',
      },
      postDocument(output),
    );
    acts.addDocument(doc);
    acts.setProcessing(false);
    acts.toggleDocumentEditor();
  } catch (e) {
    acts.setProcessing(false);
    throw e;
  }
});
types.createDocument = P.Object;

effects.updateDocument = guardHandled(async (data) => {
  try {
    acts.setProcessing(true);
    const output = formatDocumentEditorOutput(data);
    const doc = await decorateWithNotificationsEff(
      {
        id: 'update-document',
        failureStyle: 'error',
        success: 'Dokumentti tallennettu',
      },
      updateDocument(output),
    );
    acts.updateDocument(doc);
    acts.setProcessing(false);
    acts.closeDocumentModals();
  } catch (e) {
    acts.setProcessing(false);
    throw e;
  }
});
types.updateDocument = P.Object;

effects.removeDocument = (id) => {
  const onConfirm = guardHandled(async () => {
    try {
      acts.setProcessing(true);
      await decorateWithNotificationsEff(
        {
          id: 'remove-document',
          failureStyle: 'error',
          loading: msgs.deleting,
          success: 'Dokumentti poistettu',
        },
        deleteDocument(id),
      );
      acts.removeDocument(id);
      acts.setProcessing(false);
    } catch (e) {
      acts.setProcessing(false);
      throw e;
    }
  });

  confirmerEffs.show({
    message: 'Poistetaanko dokumentti?',
    okText: 'Poista',
    okStyle: 'danger',
    cancelText: msgs.cancel,
    onCancel: () => {},
    onOk: onConfirm,
  });
};
types.removeDocument = P.Number;

effects.uploadDocuments = guardHandled(async (data) => {
  try {
    acts.setProcessing(true);
    const {files, ...rest} = data;
    const uploadedFiles = await decorateWithNotificationsEff(
      {
        id: 'upload-files',
        failureStyle: 'error',
      },
      uploadFiles(files),
    );
    const activeFolder = sels.activeFolder();
    const output = formatDocumentUploaderOutput({
      ...rest,
      folderId: activeFolder?.id,
      files: uploadedFiles,
    });
    const documents = await decorateWithNotificationsEff(
      {
        id: 'create-documents',
        failureStyle: 'error',
        success:
          uploadedFiles.length > 1 ? 'Dokumentit tallennettu' : 'Dokumentti tallennettu',
      },
      postDocument(output),
    );
    acts.addDocuments(documents);
    acts.setProcessing(false);
    acts.toggleDocumentUploader();
  } catch (e) {
    acts.setProcessing(false);
    throw e;
  }
});
types.uploadDocuments = P.Object;

effects.toggleFolderEditor = (folder = null) => {
  acts.toggleFolderEditor(folder);
};
types.toggleFolderEditor = Nullable(P.Object);

effects.toggleDocumentEditor = (document = null) => {
  acts.toggleDocumentEditor(document);
};
types.toggleDocumentEditor = Nullable(P.Object);

effects.toggleDocumentPreview = (document = null) => {
  acts.toggleDocumentPreview(document);
};
types.toggleDocumentPreview = Nullable(P.Object);

effects.toggleDocumentUploader = (document = null) => {
  acts.toggleDocumentUploader(document);
};
types.toggleDocumentUploader = Nullable(P.Object);

export default createEffects(namespace, services.get('channel').dispatch, types, effects);
