import {mergeLeft} from 'ramda';
import namespace from './namespace';
import {createEffects} from 'utils/events';
import services from 'services';
import _acts from './boundActions';
import _sels from './boundSelectors';
import {setPageTitleMessage, decorateWithNotificationsEff} from 'io/app';
import {getQuery, pushQuery} from 'io/history';
import {guardHandled} from 'io/errors';
import {P, Nullable} from 'utils/types';
import confirmerEffs from 'modules/confirmer/effects';
import msgs from 'dicts/messages';
import {getNews, postNews, updateNews, deleteNews, massUpdateNews} from './io';
import {formatNewsEditorOutput, parseUrlQuery} from './utils';

let acts, sels;
_acts.then((x) => (acts = x));
_sels.then((x) => (sels = x));

let effects = {};
let types = {};

effects.initialize = guardHandled(async () => {
  setPageTitleMessage('Tiedotteet');
  const query = parseUrlQuery(getQuery());
  acts.updateQuery(query);
  const {data, meta} = await decorateWithNotificationsEff(
    {id: 'get-news', failureStyle: 'warning'},
    getNews(sels.queryFetchable()),
  );
  acts.setNews({data, pagination: meta});
  acts.setInitialized(true);
});

effects.destroy = async () => {
  acts.reset();
};

effects.updateQuery = guardHandled(async (query) => {
  acts.updateQuery(query);
  pushQuery(mergeLeft(sels.query()));
  const {data, meta} = await decorateWithNotificationsEff(
    {id: 'get-news', failureStyle: 'warning'},
    getNews(sels.queryFetchable()),
  );
  acts.setNews({data, pagination: meta});
});
types.updateQuery = P.Object;

effects.createNews = guardHandled(async (formData) => {
  try {
    acts.setProcessing(true);
    const output = formatNewsEditorOutput(formData);
    await decorateWithNotificationsEff(
      {
        id: 'create-news',
        failureStyle: 'error',
        success: 'Tiedote tallennettu',
      },
      postNews(output),
    );
    acts.setProcessing(false);
    acts.closeNewsModals();
  } catch (e) {
    acts.setProcessing(false);
    throw e;
  }

  // refetch
  acts.setLoading(true);
  const {data, meta} = await decorateWithNotificationsEff(
    {id: 'get-news', failureStyle: 'warning'},
    getNews(sels.queryFetchable()),
  );
  acts.setNews({data, pagination: meta});
});
types.createNews = P.Object;

effects.updateNews = guardHandled(async (formData) => {
  try {
    acts.setProcessing(true);
    const output = formatNewsEditorOutput(formData);
    await decorateWithNotificationsEff(
      {
        id: 'update-news',
        failureStyle: 'error',
        success: 'Tiedote tallennettu',
      },
      updateNews(output),
    );
    acts.setProcessing(false);
    acts.closeNewsModals();
  } catch (e) {
    acts.setProcessing(false);
    throw e;
  }

  // refetch
  acts.setLoading(true);
  const {data, meta} = await decorateWithNotificationsEff(
    {id: 'get-news', failureStyle: 'warning'},
    getNews(sels.queryFetchable()),
  );
  acts.setNews({data, pagination: meta});
});
types.updateNews = P.Object;

effects.removeNews = (ids) => {
  const onConfirm = guardHandled(async () => {
    try {
      acts.setProcessing(true);
      await decorateWithNotificationsEff(
        {
          id: 'remove-news',
          failureStyle: 'error',
          loading: msgs.deleting,
          success: ids.length > 1 ? 'Tiedotteet poistettu' : 'Tiedote poistettu',
        },
        Promise.all(ids.map((id) => deleteNews(id))),
      );
      acts.setProcessing(false);
      acts.clearSelection();
    } catch (e) {
      acts.setProcessing(false);
      throw e;
    }

    // refetch
    acts.setLoading(true);
    const {data, meta} = await decorateWithNotificationsEff(
      {id: 'get-news', failureStyle: 'warning'},
      getNews(sels.queryFetchable()),
    );
    acts.setNews({data, pagination: meta});
  });

  confirmerEffs.show({
    message:
      ids.length > 1 ? `Poistetaanko ${ids.length} tiedotetta?` : 'Poistetaanko tiedote?',
    okText: 'Poista',
    okStyle: 'danger',
    cancelText: msgs.cancel,
    onCancel: () => {},
    onOk: onConfirm,
  });
};
types.removeNews = P.Array;

effects.archiveNews = (ids) => {
  const onConfirm = guardHandled(async () => {
    try {
      acts.setProcessing(true);
      await decorateWithNotificationsEff(
        {
          id: 'archive-news',
          failureStyle: 'error',
          loading: 'Arkistoidaan',
          success: ids.length > 1 ? 'Tiedotteet arkistoitu' : 'Tiedote arkistoitu',
        },
        massUpdateNews({ids, state: 'archived'}),
      );
      acts.setProcessing(false);
      acts.clearSelection();
    } catch (e) {
      acts.setProcessing(false);
      throw e;
    }

    // refetch
    acts.setLoading(true);
    const {data, meta} = await decorateWithNotificationsEff(
      {id: 'get-news', failureStyle: 'warning'},
      getNews(sels.queryFetchable()),
    );
    acts.setNews({data, pagination: meta});
  });

  confirmerEffs.show({
    message:
      ids.length > 1
        ? `Arkistoidaanko ${ids.length} tiedotetta?`
        : 'Arkistoidaanko tiedote?',
    okText: 'Arkistoi',
    cancelText: msgs.cancel,
    onCancel: () => {},
    onOk: onConfirm,
  });
};
types.archiveNews = P.Array;

effects.toggleNewsEditor = (news = null) => {
  acts.toggleNewsEditor(news);
};
types.toggleNewsEditor = Nullable(P.Object);

effects.toggleNewsPreview = (news = null) => {
  acts.toggleNewsPreview(news);
};
types.toggleNewsPreview = Nullable(P.Object);

effects.selectNews = (news) => {
  acts.selectNews(news);
};
types.selectNews = P.Array;

export default createEffects(namespace, services.get('channel').dispatch, types, effects);
