import namespace from './namespace';
import {scopedStore} from 'utils/redux';

export const initialState = {
  processing: false,
  loading: false,
  activeFolder: null,
  root: {
    folders: [],
    documents: [],
  },
  folderEditorOpen: false,
  folderInEdit: null,
  documentEditorOpen: false,
  documentPreviewOpen: false,
  documentInPreview: null,
  documentInEdit: null,
  documentUploaderOpen: false,
  documentInUploader: null,
};

export const cases = {
  setActiveFolder: (st, all, payload) => {
    return {...st, activeFolder: payload};
  },
  setRootFolders: (st, all, payload) => {
    return {...st, root: {...st.root, folders: payload}};
  },
  setRootDocuments: (st, all, payload) => {
    return {...st, root: {...st.root, documents: payload}};
  },
  toggleFolderEditor: (st, all, payload) => {
    return {...st, folderEditorOpen: !st.folderEditorOpen, folderInEdit: payload};
  },
  toggleDocumentEditor: (st, all, payload) => {
    return {
      ...st,
      documentEditorOpen: !st.documentEditorOpen,
      documentInEdit: payload,
    };
  },
  toggleDocumentPreview: (st, all, payload) => {
    return {
      ...st,
      documentPreviewOpen: !st.documentPreviewOpen,
      documentInPreview: payload,
    };
  },
  toggleDocumentUploader: (st, all, payload) => {
    return {
      ...st,
      documentUploaderOpen: !st.documentUploaderOpen,
      documentInUploader: payload,
    };
  },
  closeDocumentModals: (st) => {
    return {
      ...st,
      documentPreviewOpen: false,
      documentUploaderOpen: false,
      documentEditorOpen: false,
      documentInPreview: initialState.documentInPreview,
      documentInUploader: initialState.documentInUploader,
      documentInEdit: initialState.documentInEdit,
    };
  },
  setProcessing: (st, all, payload) => {
    return {...st, processing: payload};
  },
  setLoading: (st, all, payload) => {
    return {...st, loading: payload};
  },
  addFolder: (st, all, payload) => {
    if (st.activeFolder) {
      return {
        ...st,
        activeFolder: {
          ...st.activeFolder,
          folders: [...st.activeFolder.folders, payload],
        },
      };
    }
    return {
      ...st,
      root: {
        ...st.root,
        folders: [...st.root.folders, payload],
      },
    };
  },
  updateFolder: (st, all, payload) => {
    if (st.activeFolder) {
      return {
        ...st,
        activeFolder: {
          ...st.activeFolder,
          folders: st.activeFolder.folders.map((f) =>
            f.id === payload.id ? {...f, ...payload} : f,
          ),
        },
      };
    }
    return {
      ...st,
      root: {
        ...st.root,
        folders: st.root.folders.map((f) =>
          f.id === payload.id ? {...f, ...payload} : f,
        ),
      },
    };
  },
  removeFolder: (st, all, id) => {
    if (st.activeFolder) {
      return {
        ...st,
        activeFolder: {
          ...st.activeFolder,
          folders: st.activeFolder.folders.filter((f) => f.id !== id),
        },
      };
    }
    return {
      ...st,
      root: {
        ...st.root,
        folders: st.root.folders.filter((f) => f.id !== id),
      },
    };
  },
  addDocument: (st, all, payload) => {
    if (st.activeFolder) {
      return {
        ...st,
        activeFolder: {
          ...st.activeFolder,
          documents: [...st.activeFolder.documents, payload],
        },
      };
    }
    return {
      ...st,
      root: {
        ...st.root,
        documents: [...st.root.documents, payload],
      },
    };
  },
  addDocuments: (st, all, payload) => {
    if (st.activeFolder) {
      return {
        ...st,
        activeFolder: {
          ...st.activeFolder,
          documents: [...st.activeFolder.documents, ...payload],
        },
      };
    }
    return {
      ...st,
      root: {
        ...st.root,
        documents: [...st.root.documents, ...payload],
      },
    };
  },
  updateDocument: (st, all, payload) => {
    if (st.activeFolder) {
      return {
        ...st,
        activeFolder: {
          ...st.activeFolder,
          documents: st.activeFolder.documents.map((d) =>
            d.id === payload.id ? {...d, ...payload} : d,
          ),
        },
      };
    }
    return {
      ...st,
      root: {
        ...st.root,
        documents: st.root.documents.map((d) =>
          d.id === payload.id ? {...d, ...payload} : d,
        ),
      },
    };
  },
  removeDocument: (st, all, id) => {
    if (st.activeFolder) {
      return {
        ...st,
        activeFolder: {
          ...st.activeFolder,
          documents: st.activeFolder.documents.filter((d) => d.id !== id),
        },
      };
    }
    return {
      ...st,
      root: {
        ...st.root,
        documents: st.root.documents.filter((d) => d.id !== id),
      },
    };
  },
  reset: (st) => {
    return initialState;
  },
};

export const {actions, reducer} = scopedStore(namespace, cases, initialState);
