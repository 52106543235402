import ReactDom from 'react-dom';
import {curry} from 'ramda';
import * as Sentry from '@sentry/react';
import nEffs from 'modules/notifications/effects';
import {longerDur} from 'constants/notifications';

const isObj = (x) => x && typeof x === 'object';

const sentryNotify = (level, error) => {
  Sentry.captureException(error, {level});
};

// logError doesn't do full logging because it assumes error won't get caught again afterwards, this just logs everything
export const logErrorFully = (error) => {
  if (isObj(error)) error.wasLogged = true;
  console.error(error);
  sentryNotify('error', error);
  return error;
};

export const logError = (error) => {
  if (isObj(error)) error.wasLogged = true;
  // note: no console.error calls necessary since errors are supposed to always be allowed to be propagated to execution top level where the browser can log them
  sentryNotify('error', error);
  return error;
};

// note: if the error doesn't have a description, consider it unexpected and log it as an error level event instead
export const logWarning = (error) => {
  if (!error || error.description === undefined) {
    return logErrorFully(error);
  }
  if (isObj(error)) error.wasLogged = true;
  console.warn(error);
  sentryNotify('warning', error);
  return error;
};

// note: if the error doesn't have a description, consider it unexpected and log it as an error level event instead
export const logInfo = (error) => {
  if (!error || error.description === undefined) {
    return logErrorFully(error);
  }
  if (isObj(error)) error.wasLogged = true;
  console.warn(error);
  sentryNotify('info', error);
  return error;
};

export const quitWithMessage = (text) => {
  if (window.didQuitDueToError) {
    return;
  }
  window.didQuitDueToError = true;

  // if user navigates away from the error page using history, enforce a page refresh so that the app restarts
  window.addEventListener('popstate', () => {
    window.location.reload();
  });

  const root = document.querySelector('#root');

  root.setAttribute('hidden', '');
  ReactDom.unmountComponentAtNode(root);

  const body = document.querySelector('body');

  const errorPrev = document.querySelector('#app-error-view');
  if (errorPrev) body.removeChild(errorPrev);

  const container = document.createElement('div');
  container.id = 'app-error-view';
  /* prettier-ignore */
  /* eslint-disable max-len */
  container.setAttribute('style', `
    font-family: 'Nunito', -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
    background: #f0f1f2;
    color: #3b3d42;
    font-size: 0.875rem;
    line-height: 1.625;
    height: 100%;
    padding: 1.25rem;
  `);

  const errors = document.createElement('div');

  const header = document.createElement('h1');
  header.textContent = 'Virhe';
  /* prettier-ignore */
  header.setAttribute('style', `
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 800;
  `);
  errors.appendChild(header);

  const message = document.createElement('h2');
  message.textContent = text;
  /* prettier-ignore */
  message.setAttribute('style', `
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 800;
  `);
  errors.appendChild(message);

  const quitInfo = document.createElement('div');
  quitInfo.textContent =
    'Sovelluksen on sulkeuduttava. Kokeile päivittää sivu tai palata edelliselle sivulle.';
  errors.appendChild(quitInfo);

  const frontLink = document.createElement('a');
  frontLink.textContent = 'Etusivu';
  frontLink.setAttribute('href', '/');
  frontLink.style = `
    display: inline-block;
    margin-top: 1rem;
    color: #401B9C;
    font-weight: 800;
  `;
  errors.appendChild(frontLink);

  container.appendChild(errors);

  body.appendChild(container);
};

const handleFatal = (error) => {
  logError(error);
  // Disabled because EditorJS throws random uncaught errors, but everything works fine despite them so we don't want to crash the whole app because of them.
  // quitWithMessage((error && error.description) || 'Odottamaton virhe');
  if (isObj(error)) error._didCrash = true;
  return error;
};

// handle uncaught errors by attaching to global listeners
export const handleUncaught = (error) => {
  if (error && error._didCrash) {
    return;
  }
  handleFatal(error);
};

// handle fatal errors by quitting the app. also re-throw the error to stop any further execution.
export const handleAsFatal = (error) => {
  throw handleFatal(error);
};

// handle non-fatal errors that require notifying the user
export const warnEff = curry((warningEff, warningSpec, error) => {
  logWarning(error);
  const message = error.description || 'Odottamaton virhe';
  warningEff({...warningSpec, message});
  return error;
});

// handle "non-errors" that require notifying the user (like insufficient permissions)
export const informEff = curry((eff, spec, error) => {
  logInfo(error);
  const message = error.description || 'Odottamaton virhe';
  eff({...spec, message});
  return error;
});

// finalize promise or try-catch chains by catching any errors that weren't fatal and have already been handled. errors are considered handled if they've been logged earlier (and hopefully otherwise handled at the same time).
export const catchHandled = (error) => {
  if (!error || error._didCrash || !error.wasLogged) {
    throw error;
  }
};

// finalize promise or try-catch chains by catching any errors that weren't fatal. also, enforce blanket handling for errors that weren't explicitly handled (logged) earlier.
export const catchNonFatal = curry((handleUnhandled, error) => {
  if (!error || error._didCrash) {
    throw error;
  }
  if (!error.wasLogged) {
    logErrorFully(error);
    handleUnhandled(error);
  }
});

// useful as a default argument to catchNonFatal
export const unhandledNotifierEff = () => {
  nEffs.error({
    id: 'unexpected-caught',
    message:
      'Odottamaton virhe. Sovelluksen toiminta saattaa häiriintyä jos et päivitä sivua',
    duration: longerDur,
  });
};

export const catchNonFatalEff = (error) => catchNonFatal(unhandledNotifierEff, error);

// these are useful as wrappers to effect functions

export const guardHandled = (func) => async (...args) => {
  try {
    await func(...args);
  } catch (e) {
    catchHandled(e);
  }
};

export const guardNonFatalEff = (func) => async (...args) => {
  try {
    await func(...args);
  } catch (e) {
    catchNonFatalEff(e);
  }
};
