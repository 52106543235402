import React from 'react';
import {
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalHeader,
  Stack,
  Button,
  HStack,
  FormControl,
  FormLabel,
  Select,
  FormErrorMessage,
} from '@chakra-ui/react';
import {useForm} from 'react-hook-form';
import roles from 'constants/roles';

const RoleMassEditor = ({isOpen, onClose, onSubmit, processing, selection}) => {
  const {handleSubmit, register, errors} = useForm();

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader
          display="flex"
          flexDir="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Text fontWeight="bold" fontSize="sm" textTransform="uppercase">
            Aseta rooli
          </Text>
          <HStack spacing={4}>
            <Button variant="link" textDecor="underline" onClick={() => onClose()}>
              Peruuta
            </Button>
            <Button
              type="submit"
              form="role-mass-editor-form"
              variant="primary"
              isLoading={processing}
            >
              <span>Tallenna</span>
            </Button>
          </HStack>
        </ModalHeader>
        <ModalBody>
          <form onSubmit={handleSubmit(onSubmit)} id="role-mass-editor-form">
            <Text fontSize="sm" mb={4}>
              Käyttäjiä <strong>{`${selection.length} kpl`}</strong>
            </Text>
            <Stack spacing={4}>
              <FormControl isInvalid={errors.role}>
                <FormLabel>Rooli</FormLabel>
                <Select
                  name="role"
                  placeholder="Valitse..."
                  variant="withLabel"
                  isInvalid={errors.role}
                  ref={register({
                    required: 'Pakollinen kenttä',
                  })}
                >
                  {roles.map((r) => (
                    <option key={r.name} value={r.name}>
                      {r.title}
                    </option>
                  ))}
                </Select>
                <FormErrorMessage>{errors.role && errors.role.message}</FormErrorMessage>
              </FormControl>
            </Stack>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default RoleMassEditor;
