/* eslint-disable max-len */
import React from 'react';
import {Icon} from '@chakra-ui/react';

const SearchIcon = (props) => (
  <Icon
    viewBox="0 0 16 16"
    stroke="currentColor"
    fill="currentColor"
    strokeWidth="0"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.6098577,13.7236736 L12.5150918,10.6289077 C14.467383,7.70647909 13.8844326,3.77972621 11.1673462,1.5504951 C8.45025977,-0.678736011 4.48528386,-0.483325151 2.00056426,2.00227306 C-0.484155337,4.48787128 -0.678164391,8.45291602 1.55202719,11.1692141 C3.78221877,13.8855123 7.7091775,14.4670743 10.6309157,12.5137501 L13.7256816,15.6078497 C14.2497246,16.1190365 15.0858147,16.1190365 15.6098577,15.6078497 C16.1300474,15.0875028 16.1300474,14.2440205 15.6098577,13.7236736 Z M6.83924458,2.00687003 C9.50698235,2.00687003 11.6696111,4.1694988 11.6696111,6.83723657 C11.6696111,9.50497434 9.50698235,11.6676031 6.83924458,11.6676031 C4.17150681,11.6676031 2.00887805,9.50497434 2.00887805,6.83723657 C2.01181555,4.1707165 4.17272451,2.00980754 6.83924458,2.00687003 Z"
    />
  </Icon>
);

export default SearchIcon;
