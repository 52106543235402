import namespace from './namespace';
import {scopedStore} from 'utils/redux';

export const initialState = {
  processing: false,
  loading: true,
  shift: null,
  addFaultModalOpen: false,
  shiftPauseModalOpen: false,
  shiftDriveExceptionModalOpen: false,
  shiftExceptionModalOpen: false,
  news: [],
  driveTypeDocuments: [],
  driveTypeDocumentsLoading: true,
  shiftReturnModalOpen: false,
};

export const cases = {
  setShift: (st, all, payload) => {
    return {...st, shift: payload, loading: false};
  },
  openAddFaultModal: (st) => {
    return {...st, addFaultModalOpen: true};
  },
  closeAddFaultModal: (st) => {
    return {...st, addFaultModalOpen: false};
  },
  setNews: (st, all, payload) => {
    return {...st, news: payload};
  },
  setDriveTypeDocuments: (st, all, payload) => {
    return {...st, driveTypeDocuments: payload, driveTypeDocumentsLoading: false};
  },
  toggleShiftPauseModal: (st, all, payload) => {
    return {...st, shiftPauseModalOpen: !st.shiftPauseModalOpen};
  },
  toggleShiftDriveExceptionModal: (st, all, payload) => {
    return {...st, shiftDriveExceptionModalOpen: !st.shiftDriveExceptionModalOpen};
  },
  toggleShiftExceptionModal: (st, all, payload) => {
    return {...st, shiftExceptionModalOpen: !st.shiftExceptionModalOpen};
  },
  toggleShiftReturnModal: (st) => {
    return {...st, shiftReturnModalOpen: !st.shiftReturnModalOpen};
  },
  setProcessing: (st, all, payload) => {
    return {...st, processing: payload};
  },
  reset: (st) => {
    return initialState;
  },
};

export const {actions, reducer} = scopedStore(namespace, cases, initialState);
