import React from 'react';
import {
  Avatar,
  Box,
  Divider,
  HStack,
  IconButton,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  Flex,
  Portal,
} from '@chakra-ui/react';
import {Link as RouterLink} from 'react-router-dom';
import {NavIcon} from './icons';
import commonEffs from 'modules/common/effects';
import {GlobalSearch} from 'components';
import {headerHeight, headerHeightMobile} from 'constants/styles';

const Header = ({openDrawer, user, showUsers, showSettings, showVacations}) => {
  return (
    <Box
      as="header"
      bg="white"
      zIndex="9"
      position="sticky"
      top="0"
      height={{base: headerHeightMobile, md: headerHeight}}
      sx={{
        '@media print': {
          display: 'none',
        },
      }}
    >
      <HStack spacing="2" paddingY={{base: 2, md: 5}} paddingX={{base: 4, md: 8, xl: 16}}>
        <IconButton
          onClick={openDrawer}
          icon={<NavIcon boxSize="6" />}
          display={{md: 'none'}}
          marginLeft={-2}
          size="lg"
          colorScheme="purple"
          variant="ghost"
        />

        <GlobalSearch />

        {/* <IconButton icon={<BellIcon boxSize="6" />} size="lg" colorScheme="purple" variant="ghost" /> */}

        <Menu>
          <MenuButton>
            <Avatar name={user.name} size="md" />
          </MenuButton>
          <Portal>
            <MenuList>
              <MenuItem as={RouterLink} to={`/users/${user.id}?tabIndex=0`}>
                <Flex direction="column">
                  <Box fontSize="sm" fontWeight="bold">
                    {user.name}
                  </Box>
                  <Box textStyle="caption" color="gray.600">
                    Omat tiedot
                  </Box>
                </Flex>
              </MenuItem>
              {showVacations && (
                <>
                  <MenuDivider />
                  <MenuItem as={RouterLink} to={`/users/${user.id}?tabIndex=1`}>
                    <Text fontSize="sm" fontWeight="bold">
                      Vapaatoiveet
                    </Text>
                  </MenuItem>
                </>
              )}
              {showUsers && (
                <>
                  <MenuDivider />
                  <MenuItem as={RouterLink} to="/users">
                    <Text fontSize="sm" fontWeight="bold">
                      Käyttäjät
                    </Text>
                  </MenuItem>
                </>
              )}
              {showSettings && (
                <MenuItem as={RouterLink} to="/settings">
                  <Text fontSize="sm" fontWeight="bold">
                    Asetukset
                  </Text>
                </MenuItem>
              )}
              <MenuDivider />
              <MenuItem onClick={() => commonEffs.logout()}>
                <Text fontSize="sm" fontWeight="bold" color="red.600">
                  Kirjaudu ulos
                </Text>
              </MenuItem>
            </MenuList>
          </Portal>
        </Menu>
      </HStack>

      <Divider marginX={{md: 8, xl: 16}} width="auto" />
    </Box>
  );
};

export default Header;
