import React, {useMemo} from 'react';
import {format, parseISO} from 'date-fns';
import {Link as RouterLink} from 'react-router-dom';
import {
  Stack,
  Tag,
  TagLabel,
  TagLeftIcon,
  Text,
  Link,
  useMediaQuery,
} from '@chakra-ui/react';
import LazyLoad from 'react-lazyload';
import fi from 'date-fns/locale/fi';
import {getDateRangeStr, getPausesStr, getShiftDurationStr} from 'modules/drives/utils';
import {encodeQuery} from 'utils/url';
import {capitalize} from 'utils/strings';
import {
  Card,
  List,
  ListItem,
  PersonIcon,
  PauseIcon,
  Table,
  TableRow,
  TableCell,
  TimeIcon,
  CommentIcon,
} from 'components';

const getDriveTitle = (drive) => {
  const title = drive ? (drive.type === 'order' ? drive.number : drive.title) : null;
  return title || '000000';
};

const getVirtualTitle = (virtualType) => {
  return ['vacation', 'sickLeave'].includes(virtualType)
    ? 'Loma'
    : virtualType === 'offDay'
    ? 'Vapaa'
    : virtualType === 'offDayCancelled'
    ? 'Vapaa (peruttu)'
    : '-';
};

const ShiftRow = ({shift, referrerUrl, isReservation}) => {
  const dateStr =
    shift.virtual || isReservation
      ? capitalize(
          format(parseISO(shift.shift_start_at), 'EEEEEE d.M.yyyy', {
            locale: fi,
          }),
        )
      : getDateRangeStr({start: shift.shift_start_at, end: shift.shift_end_at}) ||
        'Ei ajankohtaa';

  const shiftExceptionDateStr = getDateRangeStr({
    start: shift.exception?.shift_start_at,
    end: shift.exception?.shift_end_at,
  });

  const shiftReturnDateStr = getDateRangeStr({
    start: shift.return?.shift_start_at,
    end: shift.return?.shift_end_at,
  });

  const shiftStartAt = shift.shift_start_at ? parseISO(shift.shift_start_at) : null;
  const shiftEndAt = shift.shift_end_at ? parseISO(shift.shift_end_at) : null;

  const shiftExceptionStartAt = shift.exception?.shift_start_at
    ? parseISO(shift.exception.shift_start_at)
    : null;
  const shiftExceptionEndAt = shift.exception?.shift_end_at
    ? parseISO(shift.exception.shift_end_at)
    : null;

  const shiftReturnStartAt = shift.return?.shift_start_at
    ? parseISO(shift.return.shift_start_at)
    : null;
  const shiftReturnEndAt = shift.return?.shift_end_at
    ? parseISO(shift.return.shift_end_at)
    : null;

  const pausesStr = getPausesStr(shift.pauses);
  const shiftExceptionPausesStr = getPausesStr(shift.exception?.pauses);
  const durationStr = getShiftDurationStr({
    end: shiftExceptionEndAt || shiftReturnEndAt || shiftEndAt,
    start: shiftExceptionStartAt || shiftReturnStartAt || shiftStartAt,
  });

  return (
    <TableRow
      boxShadow="none"
      borderRadius="none"
      borderBottom="1px solid"
      borderColor="gray.100"
      backgroundColor={
        !!shift.deleted_at || shift.virtualType === 'offDayCancelled'
          ? 'red.200'
          : 'white'
      }
      py={5}
      _last={{borderBottom: 'none'}}
      gridTemplateColumns="2fr repeat(2, minmax(0, 1fr)) repeat(2, minmax(0, 2fr))"
      opacity={shift.is_published || shift.virtual ? 1 : 0.7}
    >
      <TableCell width={{base: '100%', md: 'auto'}} marginBottom={{base: 2, md: 0}}>
        {shift.virtual || !!shift.deleted_at || isReservation ? (
          <Text fontWeight="bold" fontSize="sm">
            {dateStr}
          </Text>
        ) : (
          <Link
            as={RouterLink}
            to={`/drives/${shift.id}${encodeQuery({
              referrerUrl,
              referrerTitle: 'Ajot',
            })}`}
          >
            {shift.exception && shiftExceptionDateStr !== dateStr ? (
              <Stack spacing={1}>
                <Text fontWeight="bold" fontSize="sm">
                  {shiftExceptionDateStr}
                </Text>
                <Text textStyle="caption" color="gray.600" textDecor="line-through">
                  {dateStr}
                </Text>
              </Stack>
            ) : shift.return && shiftReturnDateStr !== dateStr ? (
              <Stack spacing={1}>
                <Text fontWeight="bold" fontSize="sm">
                  {shiftReturnDateStr}
                </Text>
                <Text textStyle="caption" color="gray.600" textDecor="line-through">
                  {dateStr}
                </Text>
              </Stack>
            ) : (
              <Text fontWeight="bold" fontSize="sm">
                {dateStr}
              </Text>
            )}
          </Link>
        )}
      </TableCell>
      <TableCell textAlign="right">
        <Tag>
          <TagLabel>
            {shift.virtual
              ? getVirtualTitle(shift.virtualType)
              : isReservation
              ? 'Varaus'
              : getDriveTitle(shift.drive)}
          </TagLabel>
        </Tag>
      </TableCell>
      <TableCell>
        <Tag>
          <TagLeftIcon as={TimeIcon} />
          <TagLabel>{!durationStr || isReservation ? '-' : durationStr}</TagLabel>
        </Tag>
      </TableCell>
      <TableCell width={{base: '100%', md: 'auto'}} marginTop={{base: 2, md: 0}}>
        <Tag variant="unstyled" p={0}>
          <TagLeftIcon as={PauseIcon} />
          <TagLabel whiteSpace="normal">
            {shift.exception?.pauses && shiftExceptionPausesStr !== pausesStr ? (
              <Stack spacing={1}>
                <Text fontWeight="bold">{shiftExceptionPausesStr}</Text>
                <Text
                  textStyle="caption"
                  color="gray.600"
                  textDecor="line-through"
                  fontWeight="normal"
                >
                  {pausesStr}
                </Text>
              </Stack>
            ) : (
              <Text>{!pausesStr || isReservation ? '-' : pausesStr}</Text>
            )}
          </TagLabel>
        </Tag>
      </TableCell>
      <TableCell width={{base: '100%', md: 'auto'}} marginTop={{base: 2, md: 0}}>
        <Tag variant="unstyled" p={0}>
          <TagLeftIcon as={CommentIcon} />
          <TagLabel whiteSpace="normal">{shift.exception?.description || '-'}</TagLabel>
        </Tag>
      </TableCell>
    </TableRow>
  );
};

const ShiftsReport = ({
  reportRows = [],
  fromDate,
  toDate,
  referrerUrl,
  userId,
  showReservations,
}) => {
  const [isSm] = useMediaQuery('(max-width: 48em)');

  const rows = useMemo(
    () => reportRows.filter((u) => (userId ? u.id === userId : !!u.shifts?.length)),
    [reportRows, userId],
  );

  return (
    <Stack spacing={5}>
      {rows.length ? (
        rows.map((user) => (
          <LazyLoad key={user.id} height={90}>
            <Card>
              <List
                isHorizontal={!isSm}
                bg="gray.50"
                px={{base: 1, md: 6}}
                py={{base: 3, md: 5}}
                m={0}
                spacing={{base: 2, md: 10, lg: 24}}
              >
                <ListItem title="Aikaväli" isVertical={!isSm}>
                  <Text fontWeight="bold">
                    {fromDate ? format(parseISO(fromDate), 'd.M.yyyy') : null}
                    {' - '}
                    {toDate ? format(parseISO(toDate), 'd.M.yyyy') : null}
                  </Text>
                </ListItem>
                <ListItem title="Ajojen määrä" isVertical={!isSm}>
                  <Text fontWeight="bold">
                    {user.shifts?.length
                      ? user.shifts.length -
                        user.shifts.filter((s) => s.virtual || !!s.deleted_at).length
                      : 0}
                    {' vuoroa'}
                  </Text>
                </ListItem>
                <ListItem title="Kuljettaja" isVertical={!isSm}>
                  <Tag variant="unstyled" marginLeft="auto" p={0}>
                    <TagLeftIcon as={PersonIcon} />
                    <TagLabel>{user.name}</TagLabel>
                  </Tag>
                </ListItem>
              </List>
              <Table>
                {user.shifts?.map((shift) => (
                  <ShiftRow
                    key={shift.id}
                    shift={shift}
                    referrerUrl={referrerUrl}
                    isReservation={
                      showReservations && !shift.is_published && !shift.virtual
                    }
                  />
                ))}
              </Table>
            </Card>
          </LazyLoad>
        ))
      ) : (
        <Card>
          <Text p={5}>Ei ajoja</Text>
        </Card>
      )}
    </Stack>
  );
};

export default ShiftsReport;
