import React from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {Breadcrumb, BreadcrumbItem, BreadcrumbLink} from '@chakra-ui/react';
import {getReferrerUrl, getReferrerTitle} from 'utils/url';
import {ChevronRightIcon} from 'components';

// Simple breadcrumbs component, max 2 levels (prev + active) based on referrerUrl
const Breadcrumbs = ({location, activeCrumbName}) => {
  const referrerUrl = getReferrerUrl(location.search);
  const referrerTitle = getReferrerTitle(location.search);

  return (
    <Breadcrumb separator={<ChevronRightIcon w="0.7em" h="0.7em" color="violet.700" />}>
      {!!referrerUrl && !!referrerTitle && (
        <BreadcrumbItem>
          <BreadcrumbLink as={RouterLink} to={referrerUrl}>
            {referrerTitle}
          </BreadcrumbLink>
        </BreadcrumbItem>
      )}
      <BreadcrumbItem isCurrentPage>
        <BreadcrumbLink>{activeCrumbName}</BreadcrumbLink>
      </BreadcrumbItem>
    </Breadcrumb>
  );
};

export default Breadcrumbs;
