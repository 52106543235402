import React from 'react';
import {Box, Flex, Heading} from '@chakra-ui/react';

function CardHeader({isMain = false, action, children}) {
  return (
    <Box
      as="header"
      borderBottom={isMain ? '' : '1px solid'}
      borderBottomColor="gray.100"
    >
      <Flex paddingRight="5" alignItems="center">
        <Heading
          size={isMain ? 'sm' : 'xs'}
          flex="1"
          marginY={isMain ? 6 : 4}
          marginX="5"
          textTransform={isMain ? 'none' : 'uppercase'}
        >
          {children}
        </Heading>

        {action}
      </Flex>
    </Box>
  );
}

export default CardHeader;
