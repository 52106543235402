import React from 'react';
import {useForm} from 'react-hook-form';
import {FormControl, FormErrorMessage, FormLabel, Input, Stack} from '@chakra-ui/react';

const ResetPasswordForm = ({onSubmit, id}) => {
  const {handleSubmit, errors, register, watch} = useForm();
  const password = watch('password');

  return (
    <form onSubmit={handleSubmit(onSubmit)} id={id}>
      <Stack spacing={4}>
        <FormControl isInvalid={errors.email}>
          <FormLabel>Käyttäjätunnus</FormLabel>
          <Input
            name="email"
            type="email"
            ref={register({required: 'Pakollinen kenttä'})}
          />
          <FormErrorMessage>{errors.email && errors.email.message}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={errors.password || errors.password_confirmation}>
          <FormLabel>Salasana</FormLabel>
          <Input
            autoComplete="new-password"
            name="password"
            type="password"
            ref={register({required: true, minLength: 8})}
          />
        </FormControl>
        <FormControl isInvalid={errors.password || errors.password_confirmation}>
          <FormLabel>Salasana uudelleen</FormLabel>
          <Input
            autoComplete="new-password"
            name="password_confirmation"
            type="password"
            ref={register({
              validate: (value) => (password ? value === password : true),
            })}
          />
          <FormErrorMessage>
            {errors.password && 'Salasanassa tulee olla vähintään 8 merkkiä'}
          </FormErrorMessage>
          <FormErrorMessage>
            {errors.password_confirmation && 'Salasanat eivät täsmää'}
          </FormErrorMessage>
        </FormControl>
      </Stack>
    </form>
  );
};

export default ResetPasswordForm;
