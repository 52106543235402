/* eslint-disable max-len */
import React from 'react';
import {Icon} from '@chakra-ui/react';

const SymbolIcon = (props) => (
  <Icon viewBox="0 0 80 80" {...props}>
    <g fill="none" fillRule="evenodd" transform="translate(7.868 4.279)">
      <path
        fill="#A67ECC"
        fillRule="nonzero"
        d="M23.791744,8.05125271 C23.9473237,3.56027711 27.6330744,8.8817842e-15 32.126744,8.8817842e-15 C36.6204136,8.8817842e-15 40.3061643,3.56027711 40.461744,8.05125271 L40.461744,30.9112527 L23.791744,21.2812527 L23.791744,8.05125271 Z"
      />
      <path
        fill="#401B9C"
        d="M4.00174399,29.1112527 C0.404915028,26.6450229 -0.684517995,21.8240828 1.50232398,18.0508626 C3.68916595,14.2776425 8.41379088,12.826351 12.341744,14.7212527 L32.131744,26.0912527 L15.461744,35.7212527 L4.00174399,29.1112527 Z"
      />
      <path
        fill="#7841B3"
        fillRule="nonzero"
        d="M12.341744,56.7712527 C8.36709589,58.9441095 3.3847507,57.5470884 1.11922431,53.6245224 C-1.14630207,49.7019563 0.133420171,44.688201 4.00174399,42.3312527 L23.791744,30.9112527 L23.791744,50.1612527 L12.341744,56.7712527 Z"
      />
      <path
        fill="#A67ECC"
        fillRule="nonzero"
        d="M40.461744,63.3812527 C40.5672887,66.4279141 39.0021343,69.2894211 36.3798352,70.8440376 C33.757536,72.3986541 30.495952,72.3986541 27.8736528,70.8440376 C25.2513536,69.2894211 23.6861993,66.4279141 23.791744,63.3812527 L23.791744,40.5312527 L40.461744,50.1612527 L40.461744,63.3812527 Z"
      />
      <path
        fill="#401B9C"
        d="M60.251744,42.3312527 C62.9412413,43.7634613 64.6355935,46.5484161 64.6711382,49.5952757 C64.7066829,52.6421354 63.0777617,55.4658595 60.4224081,56.9604212 C57.7670546,58.4549829 54.5079837,58.3824576 51.921744,56.7712527 L32.131744,45.3412527 L48.801744,35.7212527 L60.251744,42.3312527 Z"
      />
      <path
        fill="#7841B3"
        fillRule="nonzero"
        d="M51.921744,14.7212527 C54.5060839,13.1466287 57.7406199,13.095505 60.3734321,14.5876689 C63.0062443,16.0798328 64.6241598,18.8811158 64.600968,21.9072875 C64.5777763,24.9334593 62.9171167,27.7096161 60.261744,29.1612527 L40.461744,40.5312527 L40.461744,21.2812527 L51.921744,14.7212527 Z"
      />
    </g>
  </Icon>
);

export default SymbolIcon;
