/* eslint-disable max-len */
import React from 'react';
import {Icon} from '@chakra-ui/react';

const CommentIcon = (props) => (
  <Icon
    viewBox="0 0 16 16"
    stroke="currentColor"
    fill="currentColor"
    strokeWidth="0"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.0869565,2 L1.91304348,2 C1.40878349,2 1,2.40878349 1,2.91304348 L1,11.162087 C1.00170569,11.4008471 1.0994802,11.6288631 1.27126479,11.7946924 C1.44304937,11.9605218 1.67437168,12.050194 1.91304348,12.0434783 L3.73913043,12.0434783 L3.73913043,14.4782609 C3.73909018,14.5915334 3.80195968,14.6954567 3.90231006,14.7479968 C4.00266044,14.8005369 4.12388676,14.7930001 4.21695652,14.7284348 L8.09495652,12.0434783 L14.0869565,12.0434783 C14.5912165,12.0434783 15,11.6346948 15,11.1304348 L15,2.91304348 C15,2.40878349 14.5912165,2 14.0869565,2 Z M3.89130435,8.39130435 C3.89130435,8.13917435 4.09569609,7.93478261 4.34782609,7.93478261 L11.6521739,7.93478261 C11.9043039,7.93478261 12.1086957,8.13917435 12.1086957,8.39130435 C12.1086957,8.64343434 11.9043039,8.84782609 11.6521739,8.84782609 L4.34782609,8.84782609 C4.09569609,8.84782609 3.89130435,8.64343434 3.89130435,8.39130435 Z M11.6521739,6.10869565 L4.34782609,6.10869565 C4.09569609,6.10869565 3.89130435,5.90430391 3.89130435,5.65217391 C3.89130435,5.40004392 4.09569609,5.19565217 4.34782609,5.19565217 L11.6521739,5.19565217 C11.9043039,5.19565217 12.1086957,5.40004392 12.1086957,5.65217391 C12.1086957,5.90430391 11.9043039,6.10869565 11.6521739,6.10869565 Z"
    />
  </Icon>
);

export default CommentIcon;
