import {extendTheme} from '@chakra-ui/react';

import global from './global';

import colors from './foundations/color';
import shadows from './foundations/shadows';
import typography from './foundations/typography';
import radii from './foundations/radius';
import spacing from './foundations/spacing';

import textStyles from './styles/text-styles';
import layerStyles from './styles/layer-styles';

import Button from './components/button';
import Tag from './components/tag';
import Input from './components/input';
import Textarea from './components/textarea';
import Tabs from './components/tabs';
import Select from './components/select';
import Popover from './components/popover';
import Modal from './components/modal';
import FormLabel from './components/form-label';
import Menu from './components/menu';

export default extendTheme({
  colors,
  shadows,
  ...typography,
  radii,
  spacing,

  styles: {
    global: {
      ...global,
    },
  },
  textStyles: {
    ...textStyles,
  },
  layerStyles: {
    ...layerStyles,
  },
  components: {
    Button,
    Tag,
    Input,
    Textarea,
    Tabs,
    Select,
    Popover,
    Modal,
    FormLabel,
    Menu,
  },
});
