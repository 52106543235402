import React from 'react';
import {
  HStack,
  IconButton,
  Text,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverBody,
  Stack,
  FormControl,
  FormLabel,
  Portal,
} from '@chakra-ui/react';
import {CalendarIcon, ReactDatepicker} from 'components';

const DateRangePopover = ({
  isActive,
  selectedFrom, // Date
  selectedTo, // Date
  setSelectedFrom,
  setSelectedTo,
  disabled,
  children,
  size = 'lg',
}) => {
  return (
    <Popover>
      <PopoverTrigger>
        <IconButton
          size={size}
          variant={isActive ? 'primary' : 'unselected'}
          isActive={isActive}
          icon={<CalendarIcon />}
        />
      </PopoverTrigger>
      <Portal>
        <PopoverContent
          sx={{
            '@media print': {
              display: 'none',
            },
          }}
        >
          <PopoverArrow />
          <PopoverBody paddingX="5" paddingY="3">
            <Stack spacing="2">
              <Text fontWeight="bold" fontSize="sm">
                Rajaa aikavälillä
              </Text>
              <HStack spacing="2">
                <FormControl>
                  <FormLabel>Alkaen</FormLabel>
                  <ReactDatepicker
                    standalone
                    isClearable
                    selected={selectedFrom}
                    onChange={(date) => setSelectedFrom(date)}
                    disabled={disabled}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Päättyen</FormLabel>
                  <ReactDatepicker
                    standalone
                    isClearable
                    selected={selectedTo}
                    onChange={(date) => setSelectedTo(date)}
                    disabled={disabled}
                  />
                </FormControl>
              </HStack>
            </Stack>
            {children}
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};

export default DateRangePopover;
