/* eslint-disable max-len */
import React from 'react';
import {Icon} from '@chakra-ui/react';

const PenIcon = (props) => (
  <Icon
    viewBox="0 0 16 16"
    stroke="currentColor"
    fill="currentColor"
    strokeWidth="0"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.47289959,10.6637637 C2.57141799,10.639967 2.67522783,10.6690005 2.74710676,10.7404538 L2.74710676,10.7404538 L5.24701429,13.2409449 C5.31875721,13.3128078 5.34797747,13.4168024 5.32416261,13.515515 C5.30034775,13.6142276 5.22692343,13.6934588 5.13030525,13.7247038 L5.13030525,13.7247038 L1.38394523,14.9729069 C1.2789732,15.0067905 1.16386867,14.9795881 1.0851701,14.902298 C1.00711123,14.8240146 0.979999597,14.7083383 1.01514468,14.6035228 L1.01514468,14.6035228 L2.26393136,10.8571628 C2.29526962,10.7607778 2.37438119,10.6875604 2.47289959,10.6637637 Z M10.0297505,3.03824103 C10.145437,2.92864377 10.3266305,2.92864377 10.442317,3.03824103 L10.442317,3.03824103 L12.9398903,5.53698147 C13.0537931,5.65091862 13.0537931,5.83561075 12.9398903,5.94954791 L12.9398903,5.94954791 L6.27988924,12.6083819 C6.16595209,12.7222846 5.98125996,12.7222846 5.86732281,12.6083819 L5.86732281,12.6083819 L3.36974946,10.1108085 C3.25584671,9.99687139 3.25584671,9.81217926 3.36974946,9.6982421 L3.36974946,9.6982421 Z M11.4886135,1.58287937 C12.3032239,0.805706877 13.5847264,0.805706877 14.3993368,1.58287937 C15.2002211,2.38670984 15.2002211,3.68685449 14.3993368,4.49068497 L14.3993368,4.49068497 L13.9826855,4.90908686 C13.8687484,5.02298961 13.6840563,5.02298961 13.5701191,4.90908686 L13.5701191,4.90908686 L11.0731293,2.41151352 C10.9592266,2.29757636 10.9592266,2.11288423 11.0731293,1.99894708 L11.0731293,1.99894708 Z"
    />
  </Icon>
);

export default PenIcon;
