import React from 'react';
import {SimpleGrid, Text, Center, Spinner} from '@chakra-ui/react';
import {Card, CardHeader, File, FileList} from 'components';

const DriveTabAttachments = ({
  shift,
  openDocument,
  editDocument,
  removeDocument,
  hideActions,
  driveTypeDocuments = [],
  driveTypeDocumentsLoading,
}) => {
  return (
    <SimpleGrid columns={{md: 2}} spacing="5">
      <Card>
        <CardHeader>Ohjeistus</CardHeader>
        {driveTypeDocumentsLoading ? (
          <Center p={4}>
            <Spinner size="md" />
          </Center>
        ) : driveTypeDocuments.length ? (
          <FileList>
            {driveTypeDocuments.map((doc) => (
              <File
                key={doc.id}
                type={doc.filetype}
                modified={doc.updated_at}
                hideActions={hideActions}
                onOpen={() =>
                  doc.filetype === 'document'
                    ? openDocument(doc)
                    : window.open(doc.url, '_blank')
                }
                onEdit={() => editDocument(doc)}
                onDelete={() => removeDocument(doc)}
              >
                {doc.title}
              </File>
            ))}
          </FileList>
        ) : (
          <Text p={5}>Ei dokumentteja</Text>
        )}
      </Card>

      <Card>
        <CardHeader>Ajoon liittyvät liitteet</CardHeader>
        {shift.drive?.documents?.length ? (
          <FileList>
            {shift.drive.documents.map((doc) => (
              <File
                key={doc.id}
                type={doc.filetype}
                modified={doc.updated_at}
                hideActions={hideActions}
                onOpen={() =>
                  doc.filetype === 'document'
                    ? openDocument(doc)
                    : window.open(doc.url, '_blank')
                }
                onEdit={() => editDocument(doc)}
                onDelete={() => removeDocument(doc)}
              >
                {doc.title}
              </File>
            ))}
          </FileList>
        ) : (
          <Text p={5}>Ei liitteitä</Text>
        )}
      </Card>
    </SimpleGrid>
  );
};

export default DriveTabAttachments;
