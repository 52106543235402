import namespace from './namespace';
import {createEffects} from 'utils/events';
import services from 'services';
import {guardHandled} from 'io/errors';
import {decorateWithNotificationsEff} from 'io/app';
import {P, Record, Maybe, Union} from 'utils/types';
import msgs from 'dicts/messages';
import _acts from './boundActions';
import _sels from './boundSelectors';
import cache from './cache';
import {formatDocumentEditorOutput} from 'modules/docs/utils';
import {formatNewsEditorOutput} from 'modules/news/utils';
import confirmerEffs from 'modules/confirmer/effects';
import {getDocument, updateDocument, getNews, updateNews, deleteDocument} from './io';

const OpenArgs = Record({
  id: Union(P.String, P.Number),
  type: Maybe(P.String),
  onSave: Maybe(P.Function),
});

const RemoveArgs = Record({
  id: Union(P.String, P.Number),
  onDelete: Maybe(P.Function),
});

let acts, sels;
_acts.then((x) => (acts = x));
_sels.then((x) => (sels = x));

let effects = {};
let types = {};

effects.openPreview = guardHandled(async ({id, type = 'document', onSave = null}) => {
  const doc = await decorateWithNotificationsEff(
    {
      id: 'get-document',
      failureStyle: 'warning',
    },
    type === 'news' ? getNews(id) : getDocument(id),
  );
  cache.update((c) => ({...c, onSave}));
  acts.openPreview({document: doc, type});
});
types.openPreview = OpenArgs;

effects.openEditor = guardHandled(async ({id, type = 'document', onSave = null}) => {
  const doc = await decorateWithNotificationsEff(
    {
      id: 'get-document',
      failureStyle: 'warning',
    },
    type === 'news' ? getNews(id) : getDocument(id),
  );
  cache.update((c) => ({...c, onSave}));
  acts.openEditor({document: doc, type});
});
types.openEditor = OpenArgs;

effects.openUploader = guardHandled(async ({id, onSave = null}) => {
  const doc = await decorateWithNotificationsEff(
    {
      id: 'get-document',
      failureStyle: 'warning',
    },
    getDocument(id),
  );
  cache.update((c) => ({...c, onSave}));
  acts.openUploader(doc);
});
types.openUploader = OpenArgs;

const clearCallbacks = () => {
  cache.update((c) => ({...c, onSave: null}));
};

const onSave = (updatedDocument) => {
  acts.reset();
  const {onSave} = cache.read();
  if (onSave) {
    onSave(updatedDocument);
  }
  clearCallbacks();
};

effects.save = guardHandled(async (formData) => {
  const type = sels.type();
  const data = {id: sels.document().id, ...formData};

  try {
    acts.setProcessing(true);

    const output =
      type === 'news' ? formatNewsEditorOutput(data) : formatDocumentEditorOutput(data);

    const updatedDocument = await decorateWithNotificationsEff(
      {
        id: 'update-document',
        failureStyle: 'error',
        success: 'Tallennettu',
      },
      type === 'news' ? updateNews(output) : updateDocument(output),
    );

    onSave(updatedDocument);
  } catch (e) {
    acts.setProcessing(false);
    throw e;
  }
});
types.save = P.Object;

effects.remove = ({id, onDelete = null}) => {
  const onConfirm = guardHandled(async () => {
    await decorateWithNotificationsEff(
      {
        id: 'remove-document',
        failureStyle: 'error',
        loading: msgs.deleting,
        success: 'Poistettu',
      },
      deleteDocument(id),
    );

    if (onDelete) {
      onDelete();
    }
  });

  confirmerEffs.show({
    message: 'Poistetaanko dokumentti?',
    okText: 'Poista',
    okStyle: 'danger',
    cancelText: msgs.cancel,
    onCancel: () => {},
    onOk: onConfirm,
  });
};
types.remove = RemoveArgs;

effects._openEditor = () => {
  acts._openEditor();
};

effects.close = async () => {
  acts.reset();
  clearCallbacks();
};

export default createEffects(namespace, services.get('channel').dispatch, types, effects);
