import React from 'react';
import {connect} from 'react-redux';
import {pick} from 'ramda';
import {applyState} from 'utils/redux';
import sels from 'modules/documentEditor/selectors';
import effs from 'modules/documentEditor/effects';
import commonEffs from 'modules/common/effects';
import commonSels from 'modules/common/selectors';
import {DocumentPreview, DocumentEditor, DocumentUploader} from 'components';
import {tags} from 'dicts/docs';

const tagOpts = Object.values(tags).map((t) => ({value: t, label: t}));

// Used to open/edit documents outside docs-app
const GlobalDocumentEditor = ({
  previewOpen,
  editorOpen,
  uploaderOpen,
  document,
  documentEditorDefaultVals,
  documentUploaderDefaultVals,
  type,
  vehicles,
  processing,
  isAdmin,
}) => {
  return (
    <>
      {previewOpen && (
        <DocumentPreview
          isOpen={previewOpen}
          document={document}
          onClose={() => effs.close()}
          onEdit={() => effs._openEditor()}
          hideEdit={!isAdmin}
        />
      )}
      {editorOpen && (
        <DocumentEditor
          isOpen={editorOpen}
          document={document}
          defaultValues={documentEditorDefaultVals}
          onClose={() => effs.close()}
          onSubmit={effs.save}
          processing={processing}
          vehicles={vehicles}
          searchClients={commonEffs.searchClients}
          searchDrives={commonEffs.searchDrives}
          tags={type === 'document' ? tagOpts : null}
        />
      )}
      {uploaderOpen && (
        <DocumentUploader
          isOpen={uploaderOpen}
          document={document}
          defaultValues={documentUploaderDefaultVals}
          onClose={() => effs.close()}
          onSubmit={effs.save}
          processing={processing}
          vehicles={vehicles}
          searchClients={commonEffs.searchClients}
          searchDrives={commonEffs.searchDrives}
        />
      )}
    </>
  );
};

export default connect(
  applyState({
    ...pick(
      [
        'previewOpen',
        'editorOpen',
        'uploaderOpen',
        'document',
        'documentEditorDefaultVals',
        'documentUploaderDefaultVals',
        'type',
        'processing',
      ],
      sels,
    ),
    vehicles: commonSels.vehicles,
    isAdmin: commonSels.isAdmin,
  }),
)(GlobalDocumentEditor);
