import React from 'react';
import {
  Center,
  IconButton,
  Link,
  Stack,
  Menu,
  MenuList,
  MenuButton,
  MenuItem,
  MenuDivider,
  Text,
  Grid,
} from '@chakra-ui/react';
import {DotsIcon, FolderIcon} from './icons';

const Folder = ({
  children,
  onOpen = () => {},
  onEdit = () => {},
  onDelete = () => {},
  hideActions,
}) => {
  return (
    <Grid
      gridAutoFlow="column"
      templateColumns="auto 1fr auto"
      alignItems="center"
      role="button"
      onClick={() => onOpen()}
    >
      <Center color="purple.700" boxSize="14" fontSize="2xl">
        <FolderIcon />
      </Center>

      <Stack spacing="1" overflow="hidden">
        <Link
          fontWeight="bold"
          lineHeight="1"
          fontSize="sm"
          display="inline-block"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          overflow="hidden"
        >
          {children}
        </Link>
      </Stack>

      {!hideActions && (
        <Menu>
          <MenuButton
            as={IconButton}
            icon={<DotsIcon />}
            variant="ghost"
            marginRight="1"
            onClick={(e) => e.stopPropagation()}
          />
          <MenuList>
            <MenuItem
              onClick={(e) => {
                e.stopPropagation();
                onEdit();
              }}
            >
              <Text fontSize="sm" fontWeight="bold">
                Muokkaa
              </Text>
            </MenuItem>
            <MenuDivider />
            <MenuItem
              onClick={(e) => {
                e.stopPropagation();
                onDelete();
              }}
            >
              <Text fontSize="sm" fontWeight="bold" color="red.600">
                Poista
              </Text>
            </MenuItem>
          </MenuList>
        </Menu>
      )}
    </Grid>
  );
};

export default Folder;
