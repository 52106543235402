import React from 'react';
import {Text} from '@chakra-ui/react';

const TruncatedText = ({children, maxLines = '2', ...props}) => (
  <Text
    overflow="hidden"
    textOverflow="ellipsis"
    display="-webkit-box"
    sx={{WebkitLineClamp: maxLines, WebkitBoxOrient: 'vertical'}}
    {...props}
  >
    {children}
  </Text>
);

export default TruncatedText;
