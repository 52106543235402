import React from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {
  Button,
  Tag,
  Text,
  Link,
  TagLabel,
  TagLeftIcon,
  HStack,
  Stack,
  Icon,
  Box,
  Tooltip,
} from '@chakra-ui/react';
import {encodeQuery} from 'utils/url';
import {TableRow, TableCell} from 'components';
import {BusIcon, GroupIcon, PersonIcon, InfoIcon, CheckIcon} from 'components/icons';
import {types} from 'dicts/drives';
import {getShiftDateStr} from 'modules/drives/utils';
import colors from 'theme/foundations/color';

const ShiftTitle = ({shift}) => {
  const isOrder = shift.drive?.type === 'order';
  const title = shift.drive ? shift.drive.title : 'Ajo';
  const prevTitle = shift.previous_drive?.title;
  const prevNumber = shift.previous_drive?.number;

  return (
    <Box display="flex" flexDirection="row">
      {isOrder && (
        <Box mr="3" display="flex" flexDirection="row">
          <Text fontWeight="bold" color="violet.700">
            {shift.drive?.number}
          </Text>
          {shift.previous_drive && (
            <Text
              ml="2"
              fontWeight="bold"
              color="gray.600"
              textDecor="line-through"
              title={shift.previous_drive.type === 'order' ? prevTitle : null}
            >
              {shift.previous_drive.type === 'order' ? prevNumber : prevTitle}
            </Text>
          )}
        </Box>
      )}

      <Text
        fontWeight="bold"
        overflow="hidden"
        whiteSpace="nowrap"
        textOverflow="ellipsis"
        className="title"
      >
        {title}
      </Text>

      {!isOrder && shift.previous_drive ? (
        <>
          <Text
            ml="2"
            fontWeight="bold"
            color="gray.600"
            textDecor="line-through"
            title={shift.previous_drive.type === 'order' ? prevTitle : null}
          >
            {shift.previous_drive.type === 'order' ? prevNumber : prevTitle}
          </Text>
        </>
      ) : null}
    </Box>
  );
};

const ShiftRow = ({shift, referrerUrl, isAdmin}) => {
  return (
    <TableRow
      position="relative"
      gridTemplateColumns="minmax(0, 2fr) repeat(3, minmax(0, 1fr)) min-content"
      overflow="hidden"
      sx={{
        '@media print': {
          borderBottom: `1px solid ${colors.gray['500']}`,
          pageBreakInside: 'avoid',
        },
      }}
      opacity={shift.is_published ? 1 : 0.7}
    >
      <TableCell width={{base: '100%', md: 'auto'}} marginBottom={{base: 3, md: 0}}>
        <Text
          fontSize="xs"
          color="gray.500"
          overflow="hidden"
          whiteSpace="nowrap"
          textOverflow="ellipsis"
        >
          {getShiftDateStr(shift, true)}
        </Text>
        <Link
          as={RouterLink}
          to={`/drives/${shift.id}${encodeQuery({
            referrerUrl,
            referrerTitle: 'Ajot',
          })}`}
          _hover={{textDecoration: 'none'}}
          sx={{
            '&:hover .title': {
              textDecoration: 'underline',
            },
          }}
        >
          <ShiftTitle shift={shift} />
        </Link>
      </TableCell>

      <TableCell marginRight={{base: 'auto', md: 0}}>
        {shift.vehicle && (
          <Tag>
            <TagLeftIcon as={BusIcon} />
            <TagLabel>{`${shift.vehicle.number} (${shift.vehicle.registration_number})`}</TagLabel>
          </Tag>
        )}
      </TableCell>

      <TableCell>
        <HStack spacing="2">
          {shift.drive?.type && (
            <Tag variant="outline">
              <TagLabel>{types[shift.drive.type] || shift.drive.type}</TagLabel>
            </Tag>
          )}
          {shift.drive?.person_count && (
            <Tag flexShrink="0" display={{base: 'none', lg: 'inline-flex'}}>
              <TagLeftIcon as={GroupIcon} />
              <TagLabel>{shift.drive.person_count}</TagLabel>
            </Tag>
          )}
        </HStack>
      </TableCell>

      <TableCell width={{base: '100%', md: 'auto'}} marginTop={{base: 3, md: 0}}>
        <Stack spacing={2} overflow="hidden">
          {shift.user && (
            <Button
              as={isAdmin ? RouterLink : undefined}
              to={isAdmin ? `/users/${shift.user.id}` : undefined}
              variant="primary-link"
              leftIcon={<PersonIcon />}
              fontSize="sm"
              width="100%"
              textAlign="left"
              justifyContent="flex-start"
            >
              <Box
                as="span"
                overflow="hidden"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
              >
                {shift.user.name}
              </Box>
            </Button>
          )}
        </Stack>
      </TableCell>

      <TableCell
        textAlign="right"
        position={{base: 'absolute', md: 'static'}}
        top="3"
        right="5"
        sx={{
          '@media print': {
            display: 'none',
          },
        }}
      >
        <HStack spacing={2}>
          <Box visibility={shift.is_returned ? 'visible' : 'hidden'}>
            <Tooltip label="Raportoitu">
              <span>
                <Icon as={CheckIcon} color="#38A169" />
              </span>
            </Tooltip>
          </Box>
          <Tooltip label={shift.read_at ? 'Luettu' : 'Ei luettu'}>
            <span>
              <Icon as={InfoIcon} color={shift.read_at ? '#38A169' : 'yellow.500'} />
            </span>
          </Tooltip>
        </HStack>
      </TableCell>
    </TableRow>
  );
};

export default ShiftRow;
