import React from 'react';
import ReactDOM from 'react-dom';
import {Router} from 'react-router-dom';
import {Provider as ReduxProvider} from 'react-redux';
import * as Sentry from '@sentry/react';
import App from 'views/App';
import reportWebVitals from 'io/reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import services from 'services';
import {createEventLog, injectStack, printLatestEvents} from 'services/createChannel';
import createStoreActionHook from 'services/createStoreActionHook';
import createStore from 'services/createStore';
import effs from 'modules/common/effects';
import _sels from 'modules/common/boundSelectors';
import {inDev} from 'constants/app';
import {eventsConfig} from 'utils/events';
import {assertionsConfig} from 'utils/assertions';
import createApi from 'services/createApi';
import {medDur, longDur} from 'constants/notifications';
import nEffs from 'modules/notifications/effects';
import {isApiTokenError} from 'utils/app';
import {handleUncaught} from 'io/errors';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://9206398f9eca4d3087e3f7b2ae591a20@o198238.ingest.sentry.io/5644313',
    environment: process.env.REACT_APP_NODE_ENV,
  });
}

window.addEventListener('error', (evt) => handleUncaught(evt.error));
window.addEventListener('unhandledrejection', (evt) => handleUncaught(evt.reason));

// configure dependencies

eventsConfig.enable = inDev;
assertionsConfig.enable = inDev;

// configure lazy variables

let sels;
_sels.then((x) => (sels = x));

// configure channel

const channel = services.get('channel');

// this is currently disabled in prod because a) stack traces really don't work with the minified version b) could cause minor performance draw for no visible gain.
if (inDev) {
  channel.addHandler(injectStack);
}

const eventLog = createEventLog();
services.set('eventLog', eventLog);

if (inDev) {
  console.info(
    // eslint-disable-next-line max-len
    'Type "evts" for a full list of events, or "es" for the latest events with nice formatting (from newest to oldest). You can also use e.g. "esn(50)" to control the amount of events to print.',
  );
  window.evts = eventLog.events;
  const logEvents = (n) => printLatestEvents(eventLog.events, n);
  Object.defineProperty(window, 'es', {get: logEvents});
  window.esn = logEvents;
}

channel.addHandler(eventLog.logEvent);

const sentryLogEvent = (evt) => {
  Sentry.addBreadcrumb({
    category: 'event',
    message: evt.type,
    level: Sentry.Severity.Info,
  });
};
channel.addListener(sentryLogEvent);

// init Redux store

const {instance: storeHook, enhancer: actionHookEnhancer} = createStoreActionHook();
const store = createStore({actionHookEnhancer});
services.set('store', store);
services.set('storeHook', storeHook);

// init API
const onApiError = (e) => {
  if (isApiTokenError(e) && sels.apiToken()) {
    effs.logout();
    nEffs.info({
      id: 'api-401',
      message: 'Sessio vanhentunut, uusi kirjautuminen vaaditaan',
      duration: medDur,
    });
  } else if (e.response && e.response.status === 503) {
    nEffs.info({
      id: 'api-503',
      message:
        // eslint-disable-next-line max-len
        'Palvelua päivitetään. Päivitä sivu hetken kuluttua uudelleen. Päivitystoimenpide voi kestää muutaman minuutin.',
      duration: longDur,
    });
  }
};

const api = createApi({
  get: (key) => {
    return {
      apiToken: sels.apiToken(),
      onError: onApiError,
    }[key];
  },
});
services.set('api', api);

// delay starting a bit so that service listeners have time to receive our newly-created services before anything else happens
setTimeout(effs.initialize);

ReactDOM.render(
  <ReduxProvider store={store}>
    <Router history={services.get('history')}>
      <App />
    </Router>
  </ReduxProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
