import React from 'react';
import {FileIcon, PdfIcon, ImageIcon} from 'components/icons';

const FileTypeIcon = ({type, ...props}) => {
  const iconProps = {width: 16, height: 16, color: 'gray.600', ...props};

  if (type === 'application/pdf' || type === 'pdf') {
    return <PdfIcon {...iconProps} />;
  } else if (type.match('image/*') || type === 'image') {
    return <ImageIcon {...iconProps} />;
  }
  return <FileIcon {...iconProps} />;
};

export default FileTypeIcon;
