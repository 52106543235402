export const states = {
  notice: 'Ilmoitus',
  unfixed: 'Ei korjattu',
  unfinished: 'Kesken',
  fixed: 'Korjattu',
  archived: 'Arkistoitu',
};

export const types = {
  damage: 'Vahinkoilmoitus',
  fault: 'Vikailmoitus',
};
