import {mergeLeft, equals, range} from 'ramda';
import namespace from './namespace';
import {createEffects} from 'utils/events';
import services from 'services';
import _acts from './boundActions';
import _sels from './boundSelectors';
import _commonSels from 'modules/common/boundSelectors';
import confirmerEffs from 'modules/confirmer/effects';
import {setPageTitleMessage, decorateWithNotificationsEff} from 'io/app';
import {getQuery, pushQuery} from 'io/history';
import {guardHandled} from 'io/errors';
import {P} from 'utils/types';
import msgs from 'dicts/messages';
import {getShifts, getDrivers, putMassUpdateShifts, getVacations} from './io';
import {parseUrlQuery, filterUnpublishedTrashed} from './utils';
import {
  defaultQuery,
  pageSizeMax,
  adminDefaultQuery,
  repairerDefaultQuery,
} from './constants';
import {initialState} from './store';

let acts, sels, commonSels;
_acts.then((x) => (acts = x));
_sels.then((x) => (sels = x));
_commonSels.then((x) => (commonSels = x));

let effects = {};
let types = {};

// fetches all pages for report/massEditor/print
// or current page (in query) for list
const fetchShifts = () => {
  const {activeTab} = sels.query();
  const fetchableQuery = sels.queryFetchable();
  const filterTrashed = fetchableQuery['filter[trashed]'] === 'with';

  if (activeTab === 'list') {
    return getShifts(fetchableQuery).then((res) => {
      acts.setShifts({data: res.data, pagination: res.meta});
      return res;
    });
  }

  return getShifts({
    ...fetchableQuery,
    'page[number]': 1,
    'page[size]': pageSizeMax,
  }).then((res) => {
    if (res.meta.last_page > 1) {
      const restPages = range(2, res.meta.last_page + 1);

      return Promise.all(
        restPages.map((pageNum) =>
          getShifts({
            ...fetchableQuery,
            'page[number]': pageNum,
            'page[size]': pageSizeMax,
          }),
        ),
      ).then((res2) => {
        const restData = res2
          .map((r) => r.data)
          .reduce((flatten, arr) => [...flatten, ...arr]);

        const allPages = {
          data: [...res.data, ...restData],
          meta: res.meta,
        };

        let data = allPages.data;
        let meta = {
          ...allPages.meta,
          total: allPages.meta.total - data.filter((x) => !!x.deleted_at).length,
        };

        if (filterTrashed) {
          // filter out unpublished trashed shifts
          data = filterUnpublishedTrashed(data);
        }

        acts.setShifts({data, pagination: meta});
        return {data, meta};
      });
    } else {
      let data = res.data;
      let meta = {
        ...res.meta,
        total: res.meta.total - data.filter((x) => !!x.deleted_at).length,
      };

      if (filterTrashed) {
        // filter out unpublished trashed shifts
        data = filterUnpublishedTrashed(data);
      }

      acts.setShifts({data, pagination: meta});
      return {data, meta};
    }
  });
};

// fetch all vacation pages
const fetchVacations = () => {
  const isAdmin = commonSels.isAdmin();
  const user = commonSels.user();

  return getVacations({
    'page[number]': 1,
    'page[size]': pageSizeMax,
    'filter[user_id]': isAdmin ? null : user.id,
  }).then((res) => {
    if (res.meta.last_page > 1) {
      const restPages = range(2, res.meta.last_page + 1);

      return Promise.all(
        restPages.map((pageNum) =>
          getVacations({
            'page[number]': pageNum,
            'page[size]': pageSizeMax,
            'filter[user_id]': isAdmin ? null : user.id,
          }),
        ),
      ).then((res2) => {
        const restData = res2
          .map((r) => r.data)
          .reduce((flatten, arr) => [...flatten, ...arr]);

        const allPages = {
          data: [...res.data, ...restData],
          meta: res.meta,
        };

        acts.setVacations(allPages.data);
        return allPages;
      });
    } else {
      acts.setVacations(res.data);
      return res;
    }
  });
};

effects.initialize = guardHandled(async () => {
  setPageTitleMessage('Ajot');

  const query = parseUrlQuery(getQuery());
  const isAdmin = commonSels.isAdmin();
  const isRepairer = commonSels.isRepairer();

  const _adminDefaultQuery =
    isAdmin && equals(query, initialState.query) ? adminDefaultQuery : {};

  const _repairerDefaultQuery = isRepairer ? repairerDefaultQuery : {};

  acts.updateQuery({
    ...query,
    ..._adminDefaultQuery,
    ..._repairerDefaultQuery,
  });

  await decorateWithNotificationsEff(
    {id: 'init-drives', failureStyle: 'warning'},
    Promise.all([
      fetchShifts().then(({data, meta}) => acts.setShifts({data, pagination: meta})),
      query.activeTab === 'report' ? fetchVacations() : Promise.resolve(null),
      isAdmin
        ? getDrivers().then((drivers) => acts.setDrivers(drivers))
        : Promise.resolve(null).then(() => acts.setDrivers([commonSels.user()])),
    ]),
  );
  acts.setInitialized(true);
});

effects.destroy = async () => {
  acts.reset();
};

effects.updateQuery = guardHandled(async (query) => {
  acts.updateQuery(query);
  pushQuery(mergeLeft(sels.query()));

  await decorateWithNotificationsEff(
    {id: 'get-shifts', failureStyle: 'warning'},
    fetchShifts(),
  );
});
types.updateQuery = P.Object;

effects.setActiveTab = guardHandled(async (tab) => {
  const currQuery = sels.query();

  acts.updateQuery({
    ...currQuery,
    activeTab: tab,
    'page[size]': tab === 'list' ? defaultQuery['page[size]'] : pageSizeMax,
    'page[number]': 1,
  });

  pushQuery(mergeLeft(sels.query()));

  await decorateWithNotificationsEff(
    {id: 'get-shifts', failureStyle: 'warning'},
    Promise.all([
      fetchShifts(),
      tab === 'report' ? fetchVacations() : Promise.resolve(null),
    ]),
  );
});
types.setActiveTab = P.String;

effects.selectShifts = (shifts) => {
  acts.selectShifts(shifts);
};
types.selectShifts = P.Array;

effects.massUpdateShifts = ({ids, is_published}) => {
  const onConfirm = guardHandled(async () => {
    try {
      acts.setProcessing(true);
      await decorateWithNotificationsEff(
        {
          id: 'mass-update-shifts',
          failureStyle: 'error',
          loading: 'Tallennetaan',
          success: 'Tallennettu',
        },
        putMassUpdateShifts({ids, is_published}),
      );
      acts.setProcessing(false);
      acts.clearSelection();
    } catch (e) {
      acts.setProcessing(false);
      throw e;
    }

    // refetch
    await decorateWithNotificationsEff(
      {id: 'get-shifts', failureStyle: 'warning'},
      fetchShifts(),
    );
  });

  confirmerEffs.show({
    message: is_published
      ? `Julkaistaanko ${ids.length} ajoa?`
      : `Muutetaanko ${ids.length} ajoa luonnokseksi?`,
    okText: 'Vahvista',
    cancelText: msgs.cancel,
    onCancel: () => {},
    onOk: onConfirm,
  });
};
types.massUpdateShifts = P.Object;

effects.clearSelection = () => {
  acts.clearSelection();
};

export default createEffects(namespace, services.get('channel').dispatch, types, effects);
