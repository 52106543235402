import {createSelector} from 'reselect';
import {formatISO} from 'date-fns';
import namespace from './namespace';
import {scopedSelectors} from 'utils/redux';
import {
  formatFetchableQuery,
  groupShiftsByUser,
  getFiltersCount,
  getExtraFiltersCount,
  getPeriodStart,
  getPeriodEnd,
  fillVirtualShifts,
} from './utils';
import commonSels from 'modules/common/selectors';

let sels = {};

sels.initialized = (st) => st.initialized;

sels.processing = (st) => st.processing;

sels.loading = (st) => st.loading;

sels.query = (st) => st.query;

sels.filtersCount = createSelector(sels.query, (q) => getFiltersCount(q));

sels.extraFiltersCount = createSelector(sels.query, (q) => getExtraFiltersCount(q));

sels.shifts = (st) => st.shifts;

sels.vacations = (st) => st.vacations;

sels.drivers = (st) => st.drivers;

sels.activeTab = createSelector(sels.query, (q) => q.activeTab);

sels.departure = createSelector(sels.query, (q) => q.departure);

sels.shiftsGroupedByUser = createSelector(
  [sels.shifts, sels.drivers, sels.activeTab],
  (shifts, drivers, activeTab) =>
    activeTab === 'report' ? groupShiftsByUser(shifts, drivers) : [],
);

sels.fromDate = createSelector(sels.query, (query) =>
  query.departure === 'period'
    ? formatISO(getPeriodStart(), {representation: 'date'})
    : query.departure === 'today'
    ? new Date().toISOString().split('T')[0]
    : query.shiftStartAtFrom,
);

sels.toDate = createSelector(sels.query, (query) =>
  query.departure === 'period'
    ? formatISO(getPeriodEnd(), {representation: 'date'})
    : query.departure === 'today'
    ? new Date().toISOString().split('T')[0]
    : query.shiftStartAtTo,
);

sels.reportRows = createSelector(
  [sels.shiftsGroupedByUser, sels.vacations, sels.fromDate, sels.toDate],
  (shiftsGrouped, vacations, fromDate, toDate) =>
    fillVirtualShifts(shiftsGrouped, vacations, fromDate, toDate),
);

sels.pagination = (st) => st.pagination;

sels.selection = (st) => st.selection;

let scoped = scopedSelectors(namespace, sels);

scoped.queryFetchable = createSelector(
  [scoped.query, commonSels.isAdmin],
  (query, isAdmin) => formatFetchableQuery(query, isAdmin),
);

export default scoped;
