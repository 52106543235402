import React from 'react';
import {Box} from '@chakra-ui/react';

function Card({children, ...props}) {
  return (
    <Box
      bg="white"
      borderRadius={{base: 0, md: 'md'}}
      boxShadow="base"
      overflow="auto"
      marginBottom="auto"
      {...props}
    >
      {children}
    </Box>
  );
}

export default Card;
