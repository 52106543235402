import textStyles from '../styles/text-styles';

const FormLabel = {
  baseStyle: {
    pos: 'absolute',
    top: 2,
    left: 4,
    color: 'gray.600',
    zIndex: 2, // to prevent Chrome autofill bg color hiding label
    pointerEvents: 'none',
    whiteSpace: 'nowrap',
    ...textStyles.caption,
  },
  variants: {
    textarea: {
      top: '1px',
      right: 4,
      paddingTop: 2,
      bg: 'gray.50',
    },
  },
};

export default FormLabel;
