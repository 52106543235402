import {evolve} from 'ramda';
import {replace} from 'utils/objects';
import {initialState} from './store';

export const parseUrlQuery = (query) => {
  const vals = evolve(
    {
      'page[number]': Number,
      'page[size]': Number,
    },
    query,
  );
  return replace(vals, initialState.query);
};
