import namespace from './namespace';
import {createEffects} from 'utils/events';
import services from 'services';
import _acts from './boundActions';
import _sels from './boundSelectors';
import {P} from 'utils/types';
import {guardHandled} from 'io/errors';
import {setPageTitleMessage, decorateWithNotificationsEff} from 'io/app';
import {getConfiguration, postConfiguration, putConfiguration, exportXls} from './io';

let acts;
_acts.then((x) => (acts = x));
let sels;
_sels.then((x) => (sels = x));

let effects = {};
let types = {};

effects.initialize = guardHandled(async () => {
  setPageTitleMessage('Asetukset');

  const configuration = await decorateWithNotificationsEff(
    {id: 'get-configuration', failureStyle: 'warning'},
    getConfiguration(),
  );
  acts.setConfiguration(configuration);
});

effects.destroy = async () => {
  acts.reset();
};

effects.saveHolidayConfig = guardHandled(async (formData) => {
  try {
    acts.setProcessing(true);
    const config = sels.configuration();
    await decorateWithNotificationsEff(
      {
        id: 'save-holiday-config',
        failureStyle: 'error',
        success: 'Tallennettu',
      },
      Promise.all(
        Object.entries(formData).map(([key, value]) => {
          const prevConfig = config.find((x) => x.key === key);
          return prevConfig
            ? putConfiguration({id: prevConfig.id, value})
            : postConfiguration({key, value});
        }),
      ),
    );
    acts.setProcessing(false);
  } catch (e) {
    acts.setProcessing(false);
    throw e;
  }
  // refetch config
  const configuration = await decorateWithNotificationsEff(
    {id: 'get-configuration', failureStyle: 'warning'},
    getConfiguration(),
  );
  acts.setConfiguration(configuration);
});
types.saveHolidayConfig = P.Object;

effects.exportXls = guardHandled(async (data) => {
  try {
    acts.setProcessing(true);
    await decorateWithNotificationsEff(
      {
        id: 'export-xls',
        failureStyle: 'error',
      },
      exportXls(data),
    );
    acts.setProcessing(false);
  } catch (e) {
    acts.setProcessing(false);
    throw e;
  }
});
types.exportXls = P.Object;

export default createEffects(namespace, services.get('channel').dispatch, types, effects);
