import {getClientName} from 'utils/clients';

export const getSuggestionGroupTitle = (groupType) => {
  switch (groupType) {
    case 'document':
      return 'Dokumentit';

    case 'client':
      return 'Asiakkaat';

    case 'shift':
      return 'Ajot';

    case 'user':
      return 'Henkilöt';

    default:
      return '';
  }
};

export const getSuggestionItemTitle = (item) => {
  if (!item) return '';

  switch (item.groupType) {
    case 'document':
      return item.title;

    case 'client':
      return getClientName(item);

    case 'shift':
      if (item.drive) {
        return item.drive.type === 'order'
          ? `${item.drive.title} (${item.drive.number})`
          : item.drive.title;
      } else {
        return 'Ajo';
      }

    case 'user':
      return item.name;

    default:
      return '';
  }
};
