export default {
  // The styles all button have in common
  baseStyle: {
    fontWeight: 'extrabold',
  },
  // Two sizes: sm and md
  sizes: {
    sm: {
      fontSize: 'sm',
      borderRadius: 'md',
    },
    lg: {
      fontSize: 'md',
      borderRadius: 'md',
      lineHeight: 4,
    },
  },
  // Two variants: outline and solid
  variants: {
    solid: {
      bg: 'gray.100',
      color: 'gray.900',
      boxShadow: 'base',
      _hover: {
        bg: 'gray.200',
        color: 'gray.900',
        _disabled: {
          bg: 'gray.200',
        },
      },
      _active: {
        bg: 'gray.200',
        color: 'gray.900',
      },
    },
    primary: {
      bg: 'violet.700',
      color: 'white',
      boxShadow: 'base',
      _hover: {
        bg: 'violet.500',
        color: 'white',
        _disabled: {
          bg: 'violet.500',
        },
      },
      _active: {
        bg: 'violet.600',
        color: 'white',
      },
    },
    danger: {
      bg: 'red.500',
      color: 'white',
      boxShadow: 'base',
      _hover: {
        bg: 'red.600',
        color: 'white',
      },
      _active: {
        bg: 'red.700',
        color: 'white',
      },
    },
    'primary-outline': {
      color: 'violet.700',
      border: '1px solid',
      borderColor: 'violet.700',
      _hover: {
        bg: 'violet.100',
      },
      _active: {
        bg: 'violet.200',
      },
    },
    unselected: {
      bg: 'gray.200',
      color: 'gray.600',
      boxShadow: 'base',
      _hover: {
        bg: 'gray.100',
        color: 'gray.900',
        _disabled: {
          bg: 'gray.100',
        },
      },
      _active: {
        bg: 'gray.200',
        color: 'gray.900',
      },
    },
    link: {
      color: 'gray.900',
      _hover: {
        color: 'gray.700',
      },
      _active: {
        color: 'gray.700',
      },
    },
    'primary-link': {
      color: 'violet.700',
      p: 0,
      h: 'auto',
      _hover: {
        color: 'violet.600',
        textDecoration: 'underline',
      },
      _active: {
        color: 'violet.800',
      },
    },
    'danger-link': {
      color: 'red.600',
      p: 0,
      h: 'auto',
      _hover: {
        color: 'red.500',
        textDecoration: 'underline',
      },
      _active: {
        color: 'red.700',
      },
    },
    pagination: {
      bg: 'gray.50',
      color: 'gray.600',
      border: '1px solid',
      borderColor: 'gray.300',
      _hover: {
        borderColor: 'violet.700',
        color: 'violet.700',
      },
      _active: {
        bg: 'violet.700',
        borderColor: 'violet.700',
        color: 'white',
      },
    },
  },
  // The default size and variant values
  defaultProps: {
    size: 'lg',
  },
};
