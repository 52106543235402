export const defaultQuery = {
  'page[size]': 15,
  sort: 'shift_start_at',
  include: 'drive,user,vehicle,pauses,exception.pauses',
};

export const pageSizeMax = 500;

export const adminDefaultQuery = {
  departure: 'today',
  'filter[drive_type]': 'order',
};

export const repairerDefaultQuery = {
  activeTab: 'report',
  'page[size]': pageSizeMax,
};

// this is when the first period started (3.5.2021), we calculate new periods from this point onwards
export const periodStartingPoint = new Date(2021, 4, 3);
