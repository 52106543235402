/* eslint-disable max-len */
import React from 'react';
import {Icon} from '@chakra-ui/react';

const ImageIcon = (props) => (
  <Icon
    viewBox="0 0 16 16"
    stroke="currentColor"
    fill="currentColor"
    strokeWidth="0"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.9426667,3.276 L11.0573333,0.390666667 C10.807343,0.140600834 10.4682598,0 10.1146667,0 L2.33333333,0 C1.59695367,0 1,0.596953667 1,1.33333333 L1,14.6666667 C1,15.4030463 1.59695367,16 2.33333333,16 L13,16 C13.7363797,16 14.3333333,15.4030463 14.3333333,14.6666667 L14.3333333,4.21866667 C14.3333333,3.86507358 14.1927325,3.52599031 13.9426667,3.276 Z M5.66666667,5 C6.40304633,5 7,5.59695367 7,6.33333333 C7,7.069713 6.40304633,7.66666667 5.66666667,7.66666667 C4.930287,7.66666667 4.33333333,7.069713 4.33333333,6.33333333 C4.33333333,5.59695367 4.930287,5 5.66666667,5 Z M12.178,12.914 C12.1487304,12.9672169 12.0927347,13.0002007 12.032,13.0000009 L3.96666667,13.0000009 C3.90597118,13.0000762 3.85003281,12.9671507 3.82063889,12.9140476 C3.79124498,12.8609444 3.79304326,12.7960602 3.82533333,12.7446667 L5.67533333,9.78533333 C5.73624601,9.68787236 5.84306961,9.62866541 5.958,9.62866541 C6.07293039,9.62866541 6.17975399,9.68787236 6.24066667,9.78533333 L6.81666667,10.7066667 C6.84735254,10.7550785 6.90068221,10.7844158 6.958,10.7844158 C7.01531779,10.7844158 7.06864746,10.7550785 7.09933333,10.7066667 L8.71733333,8.11866667 C8.77824601,8.0212057 8.88506961,7.96199874 9,7.96199874 C9.11493039,7.96199874 9.22175399,8.0212057 9.28266667,8.11866667 L12.1733333,12.7446667 C12.2057388,12.7960103 12.2075285,12.8609495 12.178,12.914 L12.178,12.914 Z"
    />
  </Icon>
);

export default ImageIcon;
