/* eslint-disable max-len */
import React from 'react';
import {Icon} from '@chakra-ui/react';

const FileIcon = (props) => (
  <Icon
    viewBox="0 0 16 16"
    stroke="currentColor"
    fill="currentColor"
    strokeWidth="0"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.9426667,3.276 L11.0573333,0.390666667 C10.807343,0.140600834 10.4682598,0 10.1146667,0 L2.33333333,0 C1.59695367,0 1,0.596953667 1,1.33333333 L1,14.6666667 C1,15.4030463 1.59695367,16 2.33333333,16 L13,16 C13.7363797,16 14.3333333,15.4030463 14.3333333,14.6666667 L14.3333333,4.21866667 C14.3333333,3.86507358 14.1927325,3.52599031 13.9426667,3.276 Z M7,13 L4,13 C3.63181017,13 3.33333333,12.7015232 3.33333333,12.3333333 C3.33333333,11.9651435 3.63181017,11.6666667 4,11.6666667 L7,11.6666667 C7.36818983,11.6666667 7.66666667,11.9651435 7.66666667,12.3333333 C7.66666667,12.7015232 7.36818983,13 7,13 Z M10,10.3333333 L4,10.3333333 C3.63181017,10.3333333 3.33333333,10.0348565 3.33333333,9.66666667 C3.33333333,9.29847683 3.63181017,9 4,9 L10,9 C10.3681898,9 10.6666667,9.29847683 10.6666667,9.66666667 C10.6666667,10.0348565 10.3681898,10.3333333 10,10.3333333 Z M10,7.66666667 L4,7.66666667 C3.63181017,7.66666667 3.33333333,7.36818983 3.33333333,7 C3.33333333,6.63181017 3.63181017,6.33333333 4,6.33333333 L10,6.33333333 C10.3681898,6.33333333 10.6666667,6.63181017 10.6666667,7 C10.6666667,7.36818983 10.3681898,7.66666667 10,7.66666667 Z"
    />
  </Icon>
);

export default FileIcon;
