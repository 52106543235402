import React from 'react';
import {SimpleGrid, Text} from '@chakra-ui/react';
import {parseISO, format} from 'date-fns';
import {Card, List, ListItem, CardHeader, File, FileList} from 'components';

const DriveTabVehicle = ({
  shift,
  openDocument,
  editDocument,
  removeDocument,
  hideActions,
}) => {
  return (
    <SimpleGrid columns={{md: 2}} spacing="5">
      <Card>
        <CardHeader isMain>
          {shift.vehicle
            ? `Auto ${shift.vehicle.number} (${shift.vehicle.registration_number})`
            : 'Auto'}
        </CardHeader>

        <List marginBottom="5">
          <ListItem title="Auto vapaana">
            <Text fontWeight="bold" fontSize="sm">
              {shift.drive?.vehicle_free_from &&
                format(parseISO(shift.drive.vehicle_free_from), 'HH:mm')}
              {'-'}
              {shift.drive?.vehicle_free_to &&
                format(parseISO(shift.drive.vehicle_free_to), 'HH:mm')}
            </Text>
          </ListItem>

          <ListItem title="Wifi">
            <Text fontWeight="bold">{shift.drive?.has_wifi ? 'Kyllä' : 'Ei'}</Text>
          </ListItem>
        </List>
      </Card>

      <Card>
        <CardHeader>Auton ohjeistus</CardHeader>
        {shift.vehicle?.documents?.length ? (
          <FileList>
            {shift.vehicle.documents.map((doc) => (
              <File
                key={doc.id}
                type={doc.filetype}
                modified={doc.updated_at}
                hideActions={hideActions}
                onOpen={() =>
                  doc.filetype === 'document'
                    ? openDocument(doc)
                    : window.open(doc.url, '_blank')
                }
                onEdit={() => editDocument(doc)}
                onDelete={() => removeDocument(doc)}
              >
                {doc.title}
              </File>
            ))}
          </FileList>
        ) : (
          <Text p={5}>Ei dokumentteja</Text>
        )}
      </Card>
    </SimpleGrid>
  );
};

export default DriveTabVehicle;
