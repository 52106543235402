import React from 'react';
import EditorJs from 'react-editor-js';
import {Box} from '@chakra-ui/react';
import {EDITOR_JS_TOOLS} from './editor-tools';
import './editor-styles.css';
import translations from './editor-translations';

const Editor = ({instanceRef, data, readOnly = false}) => {
  return (
    <Box
      className={readOnly ? 'editor-readonly' : 'editor-editmode'}
      height={readOnly ? 'auto' : 'calc(100vh - 15.25rem)'}
      overflowY={readOnly ? 'hidden' : 'auto'}
      overflowX="hidden"
      px={10}
    >
      <EditorJs
        instanceRef={(instance) => (instanceRef.current = instance)}
        data={data ? JSON.parse(data) : null}
        tools={EDITOR_JS_TOOLS}
        readOnly={readOnly}
        i18n={translations}
      />
    </Box>
  );
};

export default Editor;
