import {mergeLeft} from 'ramda';
import namespace from './namespace';
import {createEffects} from 'utils/events';
import services from 'services';
import _acts from './boundActions';
import _sels from './boundSelectors';
import {setPageTitleMessage, decorateWithNotificationsEff} from 'io/app';
import {guardHandled} from 'io/errors';
import {P} from 'utils/types';
import {uploadFiles} from 'io/files';
import {getQuery, pushQuery} from 'io/history';
import confirmerEffs from 'modules/confirmer/effects';
import msgs from 'dicts/messages';
import {states} from 'dicts/faults';
import {
  postDocument,
  postFault,
  getFaults,
  massUpdateFaults,
  deleteFault,
  getTags,
} from './io';
import {formatCreateFaultOutput, parseUrlQuery} from './utils';
import {formatTags} from '../utils';

let acts, sels;
_acts.then((x) => (acts = x));
_sels.then((x) => (sels = x));

let effects = {};
let types = {};

effects.initialize = guardHandled(async () => {
  setPageTitleMessage('Vika-/vahinkoilmoitukset');

  const query = parseUrlQuery(getQuery());
  acts.updateQuery(query);

  const {data, meta} = await decorateWithNotificationsEff(
    {id: 'get-faults', failureStyle: 'warning'},
    getFaults(sels.queryFetchable()),
  );
  acts.setFaults({data, pagination: meta});
  acts.setInitialized(true);

  const tags = await decorateWithNotificationsEff(
    {id: 'get-faults-tags', failureStyle: 'warning'},
    getTags(),
  );
  acts.setTags(formatTags(tags));
});

effects.destroy = async () => {
  acts.reset();
};

effects.updateQuery = guardHandled(async (query) => {
  acts.updateQuery(query);
  pushQuery(mergeLeft(sels.query()));
  const {data, meta} = await decorateWithNotificationsEff(
    {id: 'get-faults', failureStyle: 'warning'},
    getFaults(sels.queryFetchable()),
  );
  acts.setFaults({data, pagination: meta});
});
types.updateQuery = P.Object;

effects.addFault = guardHandled(async (formData) => {
  try {
    acts.setProcessing(true);

    // Upload files -> create document for each file -> create fault and link each doc to the fault
    const {files, ...rest} = formData;
    let documents = [];

    if (files.length) {
      const uploadedFiles = await decorateWithNotificationsEff(
        {
          id: 'upload-files',
          failureStyle: 'error',
        },
        uploadFiles(files),
      );
      documents = await decorateWithNotificationsEff(
        {
          id: 'create-documents',
          failureStyle: 'error',
        },
        postDocument({path: uploadedFiles.map((f) => f.path), type: 'fault'}),
      );
    }

    await decorateWithNotificationsEff(
      {
        id: 'create-fault',
        failureStyle: 'error',
        success: 'Ilmoitus tallennettu',
      },
      postFault(formatCreateFaultOutput({...rest, documents})),
    );
    acts.setProcessing(false);
    acts.toggleAddFaultModal();
  } catch (e) {
    acts.setProcessing(false);
    throw e;
  }

  // refetch
  acts.setLoading(true);
  const {data, meta} = await decorateWithNotificationsEff(
    {id: 'get-faults', failureStyle: 'warning'},
    getFaults(sels.queryFetchable()),
  );
  acts.setFaults({data, pagination: meta});
});
types.addFault = P.Object;

effects.removeFaults = (ids) => {
  const onConfirm = guardHandled(async () => {
    try {
      acts.setProcessing(true);
      await decorateWithNotificationsEff(
        {
          id: 'remove-faults',
          failureStyle: 'error',
          loading: msgs.deleting,
          success: ids.length > 1 ? 'Ilmoitukset poistettu' : 'Ilmoitus poistettu',
        },
        Promise.all(ids.map((id) => deleteFault(id))),
      );
      acts.setProcessing(false);
      acts.clearSelection();
    } catch (e) {
      acts.setProcessing(false);
      throw e;
    }

    // refetch
    acts.setLoading(true);
    const {data, meta} = await decorateWithNotificationsEff(
      {id: 'get-faults', failureStyle: 'warning'},
      getFaults(sels.queryFetchable()),
    );
    acts.setFaults({data, pagination: meta});
  });

  confirmerEffs.show({
    message:
      ids.length > 1
        ? `Poistetaanko ${ids.length} ilmoitusta?`
        : 'Poistetaanko ilmoitus?',
    okText: 'Poista',
    okStyle: 'danger',
    cancelText: msgs.cancel,
    onCancel: () => {},
    onOk: onConfirm,
  });
};
types.removeFaults = P.Array;

effects.updateFaultsState = ({ids, state}) => {
  const onConfirm = guardHandled(async () => {
    try {
      acts.setProcessing(true);
      await decorateWithNotificationsEff(
        {
          id: 'update-faults',
          failureStyle: 'error',
          loading: 'Tallennetaan',
          success: 'Tallennettu',
        },
        massUpdateFaults({ids, state}),
      );
      acts.setProcessing(false);
      acts.clearSelection();
    } catch (e) {
      acts.setProcessing(false);
      throw e;
    }

    // refetch
    acts.setLoading(true);
    const {data, meta} = await decorateWithNotificationsEff(
      {id: 'get-faults', failureStyle: 'warning'},
      getFaults(sels.queryFetchable()),
    );
    acts.setFaults({data, pagination: meta});
  });

  confirmerEffs.show({
    message:
      ids.length > 1
        ? `Vaihdetaanko ${ids.length} ilmoitusta tilaan: ${states[state]}?`
        : `Vaihdetaanko ilmoitus tilaan: ${states[state]}?`,
    okText: 'Vahvista',
    cancelText: msgs.cancel,
    onCancel: () => {},
    onOk: onConfirm,
  });
};
types.updateFaultsState = P.Object;

effects.toggleAddFaultModal = () => {
  acts.toggleAddFaultModal();
};

effects.selectFaults = (faults) => {
  acts.selectFaults(faults);
};
types.selectFaults = P.Array;

export default createEffects(namespace, services.get('channel').dispatch, types, effects);
