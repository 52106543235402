import React from 'react';
import {
  Button,
  HStack,
  FormControl,
  FormLabel,
  Flex,
  FormErrorMessage,
} from '@chakra-ui/react';
import {useForm} from 'react-hook-form';
import {List, ListItem, ReactDatepicker} from 'components';

const HolidayExportForm = ({onSubmit, processing}) => {
  const {handleSubmit, control, errors} = useForm({
    defaultValues: {from_date: '', to_date: ''},
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)} id="holiday-export-form">
      <List pt={5}>
        <ListItem title="Tietojen vienti">
          <HStack spacing={2} alignItems="flex-start">
            <FormControl isInvalid={errors.from_date}>
              <FormLabel>Alkaen</FormLabel>
              <ReactDatepicker
                name="from_date"
                control={control}
                dateFormat="dd.MM.yyyy"
                portalId="root"
                rules={{required: 'Pakollinen kenttä'}}
              />
              <FormErrorMessage>
                {errors.from_date && errors.from_date.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors.to_date}>
              <FormLabel>Päättyen</FormLabel>
              <ReactDatepicker
                name="to_date"
                control={control}
                dateFormat="dd.MM.yyyy"
                portalId="root"
                rules={{required: 'Pakollinen kenttä'}}
              />
              <FormErrorMessage>
                {errors.to_date && errors.to_date.message}
              </FormErrorMessage>
            </FormControl>
          </HStack>
        </ListItem>
      </List>
      <Flex justifyContent="flex-end" p={5}>
        <Button
          type="submit"
          form="holiday-export-form"
          variant="primary"
          isLoading={processing}
        >
          <span>Lataa tiedosto</span>
        </Button>
      </Flex>
    </form>
  );
};

export default HolidayExportForm;
