import {differenceInMinutes, parseISO, format} from 'date-fns';
import fi from 'date-fns/locale/fi';
import {capitalize} from 'utils/strings';

export const getShiftDurationStr = ({end, start}) => {
  if (!end || !start) return '';

  const diffInMinutes = differenceInMinutes(end, start);
  const minutes = diffInMinutes % 60;
  const hours = (diffInMinutes - minutes) / 60;

  return `${Math.abs(hours)}h ${Math.abs(minutes)}min`;
};

export const getPausesStr = (pauses = []) => {
  return pauses
    .map(
      (p) =>
        `${p.from_date ? format(parseISO(p.from_date), 'HH:mm') : ''}-${
          p.to_date ? format(parseISO(p.to_date), 'HH:mm') : ''
        }`,
    )
    .join(', ');
};

export const getShiftDateStr = (shift, withPresenceStr) => {
  if (!shift) return '';

  const startDate =
    shift.drive?.type === 'order' ? shift.drive.departure_at : shift.shift_start_at;

  const endDate =
    shift.drive?.type === 'order' ? shift.drive.arrival_at : shift.shift_end_at;

  const startStr = startDate
    ? `${format(parseISO(startDate), 'EEEEEE d.M.yyyy', {
        locale: fi,
      })} klo ${format(parseISO(startDate), 'HH:mm')}`
    : '';

  const endStr = endDate ? `-${format(parseISO(endDate), 'HH:mm')}` : '';

  const presenceStr = shift.drive?.presence_at
    ? ` / Paikalla ${format(parseISO(shift.drive.presence_at), 'HH:mm')}`
    : '';

  return capitalize(startStr) + endStr + (withPresenceStr ? presenceStr : '');
};

export const getDateRangeStr = ({start, end}) => {
  const startStr = start
    ? `${format(parseISO(start), 'EEEEEE d.M.yyyy', {
        locale: fi,
      })} klo ${format(parseISO(start), 'HH:mm')}`
    : '';

  const endStr = end ? `-${format(parseISO(end), 'HH:mm')}` : '';

  return capitalize(startStr) + endStr;
};

export const getDriveNote = (drive) => {
  if (!drive || !drive.note) return '';

  // do not show text after hashtag
  return drive.note.split('#')[0];
};
