export default {
  messages: {
    ui: {
      blockTunes: {
        toggler: {
          'Click to tune': 'Säädä',
          'or drag to move': 'tai raahaa liikuttaaksesi',
        },
      },
      inlineToolbar: {
        converter: {
          'Convert to': 'Muunna',
        },
      },
      toolbar: {
        toolbox: {
          Add: 'Lisää',
        },
      },
    },
    toolNames: {
      Text: 'Teksti',
      Heading: 'Otsikko',
      List: 'Lista',
      Table: 'Taulukko',
      Link: 'Linkki',
      Bold: 'Lihavointi',
      Italic: 'Kursivointi',
      Underline: 'Alleviivaus',
      Image: 'Kuva',
      Attaches: 'Liite',
      Marker: 'Korosta',
    },
    tools: {
      link: {
        'Add a link': 'Lisää linkki',
      },
      list: {
        Unordered: 'Luettelomerkillä varustettu luettelo',
        Ordered: 'Numeroitu luettelo',
      },
      linkTool: {
        Link: 'Linkki',
        "Couldn't fetch the link data": 'Linkin lisääminen epäonnistui',
        "Couldn't get this link data, try the other one": 'Linkin lisääminen epäonnistui',
        'Wrong response format from the server': 'Virheellinen vastaus palvelimelta',
      },
      image: {
        'Couldn’t upload image. Please try another.': 'Kuvan lähetys epäonnistui',
        'With border': 'Lisää reunus',
        'Stretch image': 'Venytä kuvaa',
        'With background': 'Lisää tausta',
        'Select an Image': 'Valitse kuva',
      },
      table: {
        'Insert column before': 'Lisää sarake ennen',
        'Insert column after': 'Lisää sarake jälkeen',
        'Insert row before': 'Lisää rivi ennen',
        'Insert row after': 'Lisää rivi jälkeen',
        'Delete row': 'Poista rivi',
        'Delete column': 'Poista sarake',
      },
    },
    blockTunes: {
      delete: {
        Delete: 'Poista',
      },
      moveUp: {
        'Move up': 'Siirrä ylös',
      },
      moveDown: {
        'Move down': 'Siirrä alas',
      },
    },
  },
};
