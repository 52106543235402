import React from 'react';
import {Tr} from '@chakra-ui/react';

function TableRow({children, ...other}) {
  return (
    <Tr
      as="div"
      display={{base: 'flex', md: 'grid'}}
      gridColumnGap={{base: '3', xl: '5'}}
      paddingY="3"
      paddingX="6"
      flexWrap="wrap"
      gridAutoFlow="column"
      width="full"
      bg="white"
      borderRadius={{md: 'lg'}}
      boxShadow={{md: 'base'}}
      alignItems="center"
      {...other}
    >
      {children}
    </Tr>
  );
}

export default TableRow;
