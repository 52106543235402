import {createSelector} from 'reselect';
import namespace from './namespace';
import {scopedSelectors} from 'utils/redux';
import {
  formatDocumentEditorDefaultVals,
  formatDocumentUploaderDefaultVals,
} from 'modules/docs/utils';
import {formatNewsEditorDefaultVals} from 'modules/news/utils';

let sels = {};

sels.previewOpen = (st) => st.previewOpen;

sels.editorOpen = (st) => st.editorOpen;

sels.uploaderOpen = (st) => st.uploaderOpen;

sels.document = (st) => st.document;

sels.type = (st) => st.type;

sels.processing = (st) => st.processing;

sels.documentEditorDefaultVals = createSelector([sels.document, sels.type], (doc, type) =>
  type === 'news'
    ? formatNewsEditorDefaultVals(doc)
    : formatDocumentEditorDefaultVals(doc),
);

sels.documentUploaderDefaultVals = createSelector(sels.document, (doc) =>
  formatDocumentUploaderDefaultVals(doc),
);

export default scopedSelectors(namespace, sels);
