import {prop} from 'ramda';
import services from 'services';
import {describeThrow} from 'utils/errors';
import msgs from 'dicts/messages';
import {apiUrl} from 'constants/app';
import {encodeQuery} from 'utils/url';
import {format} from 'date-fns';

let httpJson = null;
services.waitFor('api').then((x) => (httpJson = x.httpJson));

export const getConfiguration = (query) =>
  httpJson('get', '/configuration', query)
    .catch(describeThrow(msgs.fetchFailed))
    .then(prop('data'));

export const putConfiguration = (config) =>
  httpJson('put', `/configuration/${config.id}`, {}, {body: config})
    .catch(describeThrow(msgs.saveFailed))
    .then(prop('data'));

export const postConfiguration = (body) =>
  httpJson('post', '/configuration', {}, {body})
    .catch(describeThrow(msgs.saveFailed))
    .then(prop('data'));

export const exportXls = ({from_date, to_date}) => {
  const fromStr = from_date ? format(from_date, 'yyyy-MM-dd') : '';
  const toStr = to_date ? format(to_date, 'yyyy-MM-dd') : '';

  // save response blob to file and download it
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open(
      'GET',
      `${apiUrl}/vacations/export-xls${encodeQuery({
        from_date: fromStr,
        to_date: toStr,
      })}`,
    );
    xhr.responseType = 'blob';
    xhr.send();
    xhr.addEventListener('load', () => {
      if (xhr.status < 400) {
        const blob = xhr.response;
        const a = document.createElement('a');
        a.href = window.URL.createObjectURL(blob);
        a.download = `vuosilomat-${fromStr.replace(/-/g, '')}-${toStr.replace(/-/g, '')}`;
        a.dispatchEvent(new MouseEvent('click'));
        resolve(null);
      } else {
        reject(new Error('Request failed: ' + xhr.status));
      }
    });
    xhr.addEventListener('error', () => {
      reject(new Error('Network error'));
    });
  }).catch(describeThrow('Vienti epäonnistui'));
};
