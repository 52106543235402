/* eslint-disable max-len */
import React from 'react';
import {Icon} from '@chakra-ui/react';

const TimeIcon = (props) => (
  <Icon
    viewBox="0 0 16 16"
    stroke="currentColor"
    fill="currentColor"
    strokeWidth="0"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8,0 C3.581722,0 0,3.581722 0,8 C0,12.418278 3.581722,16 8,16 C12.418278,16 16,12.418278 16,8 C15.9952234,3.58370203 12.416298,0.00477656508 8,0 Z M11.4666667,11.4806667 C11.2153721,11.7466154 10.7982894,11.7649163 10.5246667,11.522 L7.21866667,8.49133333 C7.08002159,8.36552447 7.00066591,8.18721614 7,8 L7,4.33333333 C7,3.9651435 7.29847683,3.66666667 7.66666667,3.66666667 C8.0348565,3.66666667 8.33333333,3.9651435 8.33333333,4.33333333 L8.33333333,7.70666667 L11.4226667,10.5386667 C11.5536562,10.6577488 11.6319044,10.82404 11.6401584,11.0008752 C11.6484123,11.1777104 11.5859943,11.3505674 11.4666667,11.4813333 L11.4666667,11.4806667 Z"
    />
  </Icon>
);

export default TimeIcon;
