import {includes, reject, append, equals} from 'ramda';
import namespace from './namespace';
import {scopedStore} from 'utils/redux';
import {emptyPagination} from 'constants/pagination';

export const initialState = {
  query: {
    'page[number]': 1,
    'page[size]': 15,
    'filter[name]': '',
    'filter[role]': '',
  },
  initialized: false,
  processing: false,
  loading: false,
  addUserModalOpen: false,
  users: [],
  pagination: emptyPagination,
  selection: [],
  roleMassEditorOpen: false,
};

export const cases = {
  updateQuery: (st, all, payload) => {
    return {
      ...st,
      query: {...st.query, ...payload},
      loading: true,
    };
  },
  setUsers: (st, all, payload) => {
    return {
      ...st,
      users: payload.data,
      pagination: payload.pagination,
      loading: false,
    };
  },
  toggleAddUserModal: (st) => {
    return {...st, addUserModalOpen: !st.addUserModalOpen};
  },
  toggleRoleMassEditor: (st) => {
    return {...st, roleMassEditorOpen: !st.roleMassEditorOpen};
  },
  selectUsers: (st, all, users) => {
    let selection = st.selection;

    users.forEach((f) => {
      if (includes(f.id, selection)) {
        selection = !f.selected ? reject(equals(f.id), selection) : selection;
      } else {
        selection = f.selected ? append(f.id, selection) : selection;
      }
    });

    return {...st, selection};
  },
  clearSelection: (st) => {
    return {...st, selection: initialState.selection};
  },
  setProcessing: (st, all, payload) => {
    return {...st, processing: payload};
  },
  setLoading: (st, all, payload) => {
    return {...st, loading: payload};
  },
  setInitialized: (st, all, payload) => {
    return {...st, initialized: payload};
  },
  reset: (st) => {
    return initialState;
  },
};

export const {actions, reducer} = scopedStore(namespace, cases, initialState);
