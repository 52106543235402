import React from 'react';
import {Td} from '@chakra-ui/react';

function TableCell({children, ...other}) {
  return (
    <Td as="div" padding="0" {...other}>
      {children}
    </Td>
  );
}

export default TableCell;
