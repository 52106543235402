import React from 'react';
import {pick} from 'ramda';
import {connect} from 'react-redux';
import {Link as RouterLink} from 'react-router-dom';
import {Flex, Divider, Box, Button, Stack, Text, useMediaQuery} from '@chakra-ui/react';
import {applyState} from 'utils/redux';
import effs from 'modules/login/effects';
import sels from 'modules/login/selectors';
import {Card} from 'components';
import {Logo} from 'components/icons';
import bg from 'assets/bg.jpg';
import ResetPasswordForm from './components/reset-password-form';

const ResetPassword = ({processing}) => {
  const [isMd] = useMediaQuery('(min-width: 48em)');

  return (
    <Flex
      flex="1"
      bgColor="violet.800"
      bgImage={`url(${bg})`}
      bgRepeat="no-repeat"
      bgSize="cover"
      alignItems="center"
      justifyContent="center"
      h="100vh"
      w="100vw"
    >
      <Card mb={0} w="480px">
        <Box my={6} mx={{base: 6, md: 12}}>
          <Logo height="46px" width="158px" mb={2} />
          <Text textStyle="caption" color="gray.600" fontWeight="extrabold">
            Ajohallinta / Aseta salasana
          </Text>
        </Box>
        <Box my={6} mx={{base: 6, md: 12}}>
          <Stack spacing={4}>
            <ResetPasswordForm onSubmit={effs.resetPassword} id="reset-password-form" />
            <Text fontSize="sm" color="gray.600">
              Syötä uusi salasana. Salasanassa tulee olla vähintään 8 merkkiä.
            </Text>
          </Stack>
        </Box>
        <Divider />
        <Stack
          my={6}
          mx={{base: 6, md: 12}}
          spacing={{base: 6, md: 0}}
          isInline={isMd}
          justify={{base: 'center', md: 'space-between'}}
          align="stretch"
        >
          <Button
            type="submit"
            form="reset-password-form"
            variant="primary"
            isLoading={processing}
            order={{base: 1, md: 2}}
          >
            <span>Aseta salasana</span>
          </Button>
          <Button
            as={RouterLink}
            to="/"
            variant="primary-link"
            textDecor="underline"
            fontWeight="bold"
            order={{base: 2, md: 1}}
          >
            Kirjaudu sisään
          </Button>
        </Stack>
      </Card>
    </Flex>
  );
};

export default connect(applyState(pick(['processing'], sels)))(ResetPassword);
