import React, {useEffect, useState, useMemo} from 'react';
import {
  Button,
  HStack,
  IconButton,
  Text,
  Box,
  Flex,
  Spacer,
  Select,
  Center,
  Spinner,
  FormControl,
  FormLabel,
  Checkbox,
  Input,
  InputGroup,
  InputRightElement,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverBody,
  Stack,
  Portal,
  useMediaQuery,
} from '@chakra-ui/react';
import {pick, includes, all} from 'ramda';
import {formatISO, parseISO, format} from 'date-fns';
import {connect} from 'react-redux';
import {sort as fastSort} from 'fast-sort';
import {applyState} from 'utils/redux';
import {useDebounce} from 'utils/react';
import {createReferrerUrl} from 'utils/url';
import effs from 'modules/faults/faultsPage/effects';
import sels from 'modules/faults/faultsPage/selectors';
import commonSels from 'modules/common/selectors';
import {initialState} from 'modules/faults/faultsPage/store';
import {states} from 'dicts/faults';
import {
  PageHeader,
  Table,
  PlusIcon,
  CloseIcon,
  Content,
  FilterIcon,
  TableHeader,
  AddFaultModal,
  Paginator,
  Card,
  SearchIcon,
  DateRangePopover,
  ReactSelect,
} from 'components';
import FaultRow from './components/fault-row';
import MassEditButton from './components/mass-edit-button';

const stateOpts = Object.entries(states).map(([value, label]) => ({value, label}));

const Faults = ({
  initialized,
  processing,
  loading,
  query,
  faults,
  pagination,
  addFaultModalOpen,
  vehicles,
  selection,
  location,
  isRepairer,
  isAdmin,
  tags,
}) => {
  useEffect(() => {
    effs.initialize();
    return () => {
      effs.destroy();
    };
  }, []);

  const referrerUrl = createReferrerUrl(location);

  /* keep title filter in local state too and use it to update query debounced */
  const queryFilterTitle = query['filter[title]'];
  const [filterTitle, setFilterTitle] = useState(queryFilterTitle);
  const debouncedFilterTitle = useDebounce(filterTitle, 500);

  useEffect(() => {
    setFilterTitle(queryFilterTitle);
  }, [queryFilterTitle]);

  useEffect(() => {
    if (initialized && debouncedFilterTitle !== queryFilterTitle) {
      effs.updateQuery({
        'filter[title]': debouncedFilterTitle,
        'page[number]': 1,
      });
    }
  }, [debouncedFilterTitle]);

  const createdAtFrom = query['filter[created_at_from]'];
  const createdAtTo = query['filter[created_at_to]'];

  const vehicleOpts = useMemo(
    () =>
      vehicles.map((v) => ({
        value: v.id,
        label: `${v.number} (${v.registration_number})`,
      })),
    [vehicles],
  );

  const tagOpts = useMemo(
    () =>
      fastSort(tags.map((t) => ({label: t, value: t}))).asc((t) =>
        t.value?.toLowerCase(),
      ),
    [tags],
  );

  const [isSm] = useMediaQuery('(max-width: 48em)');

  const renderDateRange = () => (
    <DateRangePopover
      isActive={createdAtFrom || createdAtTo ? true : false}
      selectedFrom={createdAtFrom ? parseISO(createdAtFrom) : null}
      selectedTo={createdAtTo ? parseISO(createdAtTo) : null}
      setSelectedFrom={(date) =>
        effs.updateQuery({
          'page[number]': 1,
          'filter[created_at_from]': date
            ? formatISO(date, {representation: 'date'})
            : null,
        })
      }
      setSelectedTo={(date) =>
        effs.updateQuery({
          'page[number]': 1,
          'filter[created_at_to]': date
            ? formatISO(date, {representation: 'date'})
            : null,
        })
      }
    />
  );

  const renderFilters = () => (
    <>
      <FormControl>
        <FormLabel>Otsikko</FormLabel>
        <InputGroup>
          <Input
            value={filterTitle}
            placeholder="Hakusana"
            onChange={(e) => setFilterTitle(e.target.value)}
          />
          <InputRightElement pointerEvents="none" color="gray.600">
            <SearchIcon />
          </InputRightElement>
        </InputGroup>
      </FormControl>
      <FormControl minW="15rem">
        <FormLabel>Tarkenne</FormLabel>
        <ReactSelect
          standalone
          inline
          value={query['filter[tags]']}
          onChange={(val) =>
            effs.updateQuery({
              'filter[tags]': val,
              'page[number]': 1,
            })
          }
          options={tagOpts}
          variant="withLabel"
          placeholder="Valitse..."
          isClearable
        />
      </FormControl>
      <FormControl>
        <FormLabel>Tyyppi</FormLabel>
        <Select
          value={query['filter[type]']}
          onChange={(e) =>
            effs.updateQuery({
              'filter[type]': e.target.value,
              'page[number]': 1,
            })
          }
          variant="withLabel"
          placeholder="Valitse..."
        >
          <option value="fault">Vikailmoitus</option>
          <option value="damage">Vahinkoilmoitus</option>
        </Select>
      </FormControl>
    </>
  );

  const renderSelects = () => (
    <>
      <FormControl minW="12rem">
        <FormLabel>Tila</FormLabel>
        <ReactSelect
          standalone
          isMulti
          inline
          value={query['filter[state]']}
          onChange={(val) =>
            effs.updateQuery({
              'filter[state]': val,
              'page[number]': 1,
            })
          }
          options={
            isRepairer ? stateOpts.filter((opt) => opt.value !== 'notice') : stateOpts
          }
          variant="withLabel"
          placeholder="Valitse..."
          isClearable
        />
      </FormControl>

      <FormControl minW="10rem">
        <FormLabel>Kiireisyys</FormLabel>
        <Select
          value={query['filter[affects_drivability]']}
          onChange={(e) =>
            effs.updateQuery({
              'filter[affects_drivability]': e.target.value,
              'page[number]': 1,
            })
          }
          variant="withLabel"
          placeholder="Valitse..."
        >
          <option value="false">Ei vaikuta ajoon</option>
          <option value="true">Vaikuttaa ajoon</option>
        </Select>
      </FormControl>
    </>
  );

  return (
    <>
      <PageHeader
        title="Vika- / vahinkoilmoitukset"
        actions={
          <Button
            onClick={() => effs.toggleAddFaultModal()}
            leftIcon={<PlusIcon />}
            variant="primary-link"
          >
            Lisää uusi
          </Button>
        }
      >
        <Flex>
          <HStack
            spacing="1"
            sx={{
              '> *': {flexBasis: 0},
              '> :nth-of-type(2)': {flexBasis: 'max-content'},
            }}
          >
            <FormControl minW="12rem">
              <FormLabel>Auto</FormLabel>
              <ReactSelect
                standalone
                value={query['filter[vehicle_id]']}
                onChange={(val) =>
                  effs.updateQuery({
                    'filter[vehicle_id]': val,
                    'page[number]': 1,
                  })
                }
                options={vehicleOpts}
                variant="withLabel"
                placeholder="Valitse..."
                isClearable
                menuPosition="fixed"
              />
            </FormControl>
            {!isSm && renderSelects()}
          </HStack>

          <Spacer />

          <Flex marginLeft="1">
            <HStack display={{base: 'none', xl: 'flex'}} spacing="3">
              <HStack spacing="1">
                {renderDateRange()}
                {renderFilters()}
              </HStack>
              <Button
                onClick={() => effs.updateQuery(initialState.query)}
                size="lg"
                leftIcon={<CloseIcon />}
                variant="link"
              >
                Tyhjennä
              </Button>
            </HStack>

            <Box display={{xl: 'none'}}>{renderDateRange()}</Box>

            <Popover>
              <PopoverTrigger>
                <Box display={{xl: 'none'}} marginLeft="1">
                  <IconButton size="lg" icon={<FilterIcon />} variant="unselected" />
                </Box>
              </PopoverTrigger>
              <Portal>
                <PopoverContent>
                  <PopoverArrow />
                  <PopoverBody p={5}>
                    <Stack spacing={2}>
                      {isSm && renderSelects()}
                      {renderFilters()}
                    </Stack>
                    <Button
                      display={{base: 'block', xl: 'none'}}
                      onClick={() => effs.updateQuery(initialState.query)}
                      size="lg"
                      leftIcon={<CloseIcon />}
                      variant="link"
                      mt={5}
                    >
                      Tyhjennä
                    </Button>
                  </PopoverBody>
                </PopoverContent>
              </Portal>
            </Popover>
          </Flex>
        </Flex>
      </PageHeader>

      <Content>
        <TableHeader
          actions={
            <>
              <Text fontSize="xs">Järjestä:</Text>
              <Select
                width="10.5rem"
                value={query.sort}
                onChange={(e) =>
                  effs.updateQuery({
                    sort: e.target.value,
                  })
                }
                size="sm"
                variant="filled"
              >
                <option value="-created_at">Viimeksi lisätty</option>
                <option value="-updated_at">Viimeksi muokattu</option>
                <option value="title">Otsikko</option>
              </Select>
              <Box>
                <MassEditButton
                  selection={selection}
                  updateFaults={effs.updateFaultsState}
                  removeFaults={effs.removeFaults}
                />
              </Box>
            </>
          }
        >
          <HStack spacing={4}>
            <Checkbox
              size="lg"
              colorScheme="gray"
              isChecked={!!faults.length && all((f) => includes(f.id, selection), faults)}
              onClick={(e) => e.stopPropagation()}
              onChange={(e) =>
                effs.selectFaults(
                  faults.map((f) => ({
                    id: f.id,
                    selected: e.target.checked,
                  })),
                )
              }
            />
            <Text fontWeight="bold" fontSize="sm" textTransform="uppercase">
              <span>{`${pagination.total} ilmoitusta`}</span>
              <span>
                {createdAtFrom || createdAtTo
                  ? ` / ${
                      createdAtFrom ? format(parseISO(createdAtFrom), 'd.M.yyyy') : ''
                    } - ${createdAtTo ? format(parseISO(createdAtTo), 'd.M.yyyy') : ''}`
                  : null}
              </span>
            </Text>
          </HStack>
        </TableHeader>

        {loading ? (
          <Center>
            <Spinner size="xl" />
          </Center>
        ) : !faults.length ? (
          <Card>
            <Text p={5}>Ei ilmoituksia</Text>
          </Card>
        ) : (
          <>
            <Table>
              {faults.map((f) => (
                <FaultRow
                  key={f.id}
                  fault={f}
                  isSelected={includes(f.id, selection)}
                  select={effs.selectFaults}
                  referrerUrl={referrerUrl}
                  isAdmin={isAdmin}
                />
              ))}
            </Table>
            <Paginator
              totalPages={pagination.last_page}
              currentPage={pagination.current_page}
              perPage={pagination.per_page}
              onPageSelect={(pageNum) => effs.updateQuery({'page[number]': pageNum})}
              disabled={loading}
            />
          </>
        )}
      </Content>

      {addFaultModalOpen && (
        <AddFaultModal
          isOpen={addFaultModalOpen}
          onClose={() => effs.toggleAddFaultModal()}
          onSubmit={effs.addFault}
          processing={processing}
          vehicles={vehicles}
          defaultValues={{type: 'fault', affectsDrivability: 'no'}}
        />
      )}
    </>
  );
};

export default connect(
  applyState({
    ...pick(
      [
        'initialized',
        'processing',
        'loading',
        'query',
        'faults',
        'pagination',
        'addFaultModalOpen',
        'selection',
        'tags',
      ],
      sels,
    ),
    vehicles: commonSels.vehicles,
    isRepairer: commonSels.isRepairer,
    isAdmin: commonSels.isAdmin,
  }),
)(Faults);
