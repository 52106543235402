import React from 'react';
import {
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalHeader,
  Stack,
  Button,
  HStack,
  FormControl,
  FormLabel,
  Input,
  Select,
  FormErrorMessage,
} from '@chakra-ui/react';
import {useForm} from 'react-hook-form';
import roles from 'constants/roles';

const UserEditor = ({
  isOpen,
  onClose,
  onSubmit,
  processing,
  defaultValues,
  showPassword = true,
  isNew = true,
}) => {
  const {handleSubmit, register, errors, watch} = useForm({defaultValues});
  const password = watch('password');

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader
          display="flex"
          flexDir="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Text fontWeight="bold" fontSize="sm" textTransform="uppercase">
            {isNew ? 'Lisää käyttäjä' : 'Muokkaa käyttäjää'}
          </Text>
          <HStack spacing={4}>
            <Button variant="link" textDecor="underline" onClick={() => onClose()}>
              Peruuta
            </Button>
            <Button
              type="submit"
              form="add-user-form"
              variant="primary"
              isLoading={processing}
            >
              <span>{isNew ? 'Lisää' : 'Tallenna'}</span>
            </Button>
          </HStack>
        </ModalHeader>
        <ModalBody>
          <form onSubmit={handleSubmit(onSubmit)} id="add-user-form">
            <Stack spacing={4}>
              <FormControl isInvalid={errors.name}>
                <FormLabel>Nimi</FormLabel>
                <Input
                  name="name"
                  type="text"
                  ref={register({
                    required: 'Pakollinen kenttä',
                  })}
                />
                <FormErrorMessage>{errors.name && errors.name.message}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors.email}>
                <FormLabel>Sähköposti</FormLabel>
                <Input
                  name="email"
                  type="email"
                  ref={register({required: 'Pakollinen kenttä'})}
                />
                <FormErrorMessage>
                  {errors.email && errors.email.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl>
                <FormLabel>Puhelinnumero</FormLabel>
                <Input name="phone" type="text" ref={register} />
              </FormControl>
              <FormControl isInvalid={errors.role}>
                <FormLabel>Rooli</FormLabel>
                <Select
                  name="role"
                  placeholder="Valitse..."
                  variant="withLabel"
                  isInvalid={errors.role}
                  ref={register({
                    required: 'Pakollinen kenttä',
                  })}
                >
                  {roles.map((r) => (
                    <option key={r.name} value={r.name}>
                      {r.title}
                    </option>
                  ))}
                </Select>
                <FormErrorMessage>{errors.role && errors.role.message}</FormErrorMessage>
              </FormControl>

              {showPassword && (
                <HStack spacing={2} align="flex-start">
                  <FormControl
                    isInvalid={errors.password || errors.password_confirmation}
                  >
                    <FormLabel>Salasana</FormLabel>
                    <Input
                      autoComplete="new-password"
                      name="password"
                      type="password"
                      ref={register({required: 'Pakollinen kenttä'})}
                    />
                    <FormErrorMessage>
                      {errors.password && errors.password.message}
                    </FormErrorMessage>
                    <FormErrorMessage>
                      {errors.password_confirmation && 'Salasanat eivät täsmää'}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    isInvalid={errors.password || errors.password_confirmation}
                  >
                    <FormLabel>Salasana uudelleen</FormLabel>
                    <Input
                      autoComplete="new-password"
                      name="password_confirmation"
                      type="password"
                      ref={register({
                        validate: (value) => (password ? value === password : true),
                      })}
                    />
                  </FormControl>
                </HStack>
              )}
            </Stack>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default UserEditor;
