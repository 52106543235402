import React, {useEffect} from 'react';
import {
  SimpleGrid,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Spinner,
  Center,
} from '@chakra-ui/react';
import {pick} from 'ramda';
import {connect} from 'react-redux';
import {applyState} from 'utils/redux';
import effs from 'modules/settings/effects';
import sels from 'modules/settings/selectors';
import {Card, CardHeader, Content, PageHeader} from 'components';
import HolidayConfigurationForm from './components/holiday-configuration-form';
import HolidayExportForm from './components/holiday-export-form';

const Settings = ({processing, configurationLoading, holidayConfigFormDefaultVals}) => {
  useEffect(() => {
    effs.initialize();

    return () => {
      effs.destroy();
    };
  }, []);

  return (
    <Tabs>
      <PageHeader title="Asetukset">
        <TabList>
          <Tab>Vuosilomat</Tab>
        </TabList>
      </PageHeader>
      <Content>
        <TabPanels>
          <TabPanel>
            <SimpleGrid columns={{md: 2}} spacing="5">
              <Card>
                <CardHeader isMain>Lomake</CardHeader>
                {configurationLoading ? (
                  <Center p={5}>
                    <Spinner size="xl" />
                  </Center>
                ) : (
                  <HolidayConfigurationForm
                    onSubmit={effs.saveHolidayConfig}
                    defaultValues={holidayConfigFormDefaultVals}
                    processing={processing}
                  />
                )}
              </Card>
              <Card>
                <CardHeader>Lomien vienti</CardHeader>
                <HolidayExportForm onSubmit={effs.exportXls} processing={processing} />
              </Card>
            </SimpleGrid>
          </TabPanel>
        </TabPanels>
      </Content>
    </Tabs>
  );
};

export default connect(
  applyState({
    ...pick(['processing', 'configurationLoading', 'holidayConfigFormDefaultVals'], sels),
  }),
)(Settings);
