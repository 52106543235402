import React, {useEffect} from 'react';
import {
  Avatar,
  Button,
  HStack,
  SimpleGrid,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tag,
  Text,
  Wrap,
  WrapItem,
  Link,
} from '@chakra-ui/react';
import {pick} from 'ramda';
import {connect} from 'react-redux';
import {applyState} from 'utils/redux';
import {getReferrer} from 'utils/url';
import effs from 'modules/users/userPage/effects';
import sels from 'modules/users/userPage/selectors';
import commonSels from 'modules/common/selectors';
import roles from 'dicts/roles';
import {
  Card,
  CardHeader,
  Content,
  List,
  ListItem,
  PageHeader,
  Breadcrumbs,
  PenIcon,
  PageLoader,
  TabsHorizontalScroll,
} from 'components';
import ChangePasswordForm from '../components/change-password-form';
import UserEditor from '../components/user-editor';
import UserTabHolidays from '../components/user-tab-holidays';
import UserTabAnnualHolidays from '../components/user-tab-annual-holidays';

const User = ({
  location,
  processing,
  loading,
  user,
  match: {
    params: {userId},
  },
  location: {search},
  isAdmin,
  currUser,
  userEditorOpen,
  userEditorDefaultVals,
  query,
  showAnnualHolidays,
  annualHolidaysReserveFrom,
  annualHolidaysReserveTo,
  isSubcontractor,
}) => {
  useEffect(() => {
    effs.initialize(Number(userId));
    return () => {
      effs.destroy();
    };
  }, [userId]);

  useEffect(() => {
    // if changing tab via user menu, queries are out of sync
    effs.recheckQuery();
  }, [search]);

  const handleTabsChange = (index) => {
    effs.updateQuery({tabIndex: index});
  };

  const referrer = getReferrer(search);

  if (loading) return <PageLoader />;

  return (
    <Tabs index={query.tabIndex} onChange={handleTabsChange}>
      <PageHeader
        title={
          referrer === 'users' ? (
            <Breadcrumbs location={location} activeCrumbName={user.name} />
          ) : user.id === currUser.id ? (
            'Omat tiedot'
          ) : (
            user.name
          )
        }
      >
        <TabsHorizontalScroll>
          <TabList>
            <Tab>Perustiedot</Tab>
            <Tab hidden={currUser.id !== user.id || isSubcontractor}>Vapaatoiveet</Tab>
            <Tab
              hidden={currUser.id !== user.id || !showAnnualHolidays || isSubcontractor}
            >
              Vuosilomat
            </Tab>
          </TabList>
        </TabsHorizontalScroll>
      </PageHeader>

      <Content>
        <TabPanels>
          <TabPanel>
            <SimpleGrid columns={{md: 2}} spacing="5">
              <Card>
                <CardHeader isMain>{user.name}</CardHeader>

                <List marginBottom="5">
                  <ListItem title="Profiilikuva" alignItems="center">
                    <HStack spacing="5">
                      <Avatar name={user.name} size="md" />
                      {/* <Button variant="primary-link">Lataa uusi</Button> */}
                    </HStack>
                  </ListItem>

                  <ListItem title="Sähköposti">
                    <Link href={`mailto:${user.email}`} fontWeight="bold">
                      {user.email}
                    </Link>
                  </ListItem>

                  <ListItem title="Puhelinnumero">
                    <Link href={`tel:${user.phone}`} fontWeight="bold">
                      {user.phone}
                    </Link>
                  </ListItem>

                  {user.driver_number && (
                    <ListItem title="Kuljettajanumero">
                      <Text fontWeight="bold">{user.driver_number}</Text>
                    </ListItem>
                  )}

                  <ListItem title="Rooli">
                    <Wrap
                      spacing={2}
                      direction="row"
                      align="flex-start"
                      justify="space-between"
                    >
                      <WrapItem>
                        <Tag variant="outline">{roles[user.role] || user.role}</Tag>
                      </WrapItem>
                      {isAdmin && (
                        <WrapItem>
                          <Button
                            onClick={() => effs.openUserEditor()}
                            variant="primary-link"
                            leftIcon={<PenIcon />}
                          >
                            Muokkaa
                          </Button>
                        </WrapItem>
                      )}
                    </Wrap>
                  </ListItem>
                </List>
              </Card>

              <Card>
                <CardHeader>Vaihda salasana</CardHeader>
                <ChangePasswordForm onSubmit={effs.updateUser} processing={processing} />
              </Card>
            </SimpleGrid>
          </TabPanel>

          <TabPanel hidden={currUser.id !== user.id}>
            <UserTabHolidays sendRequest={effs.addVacation} processing={processing} />
          </TabPanel>

          <TabPanel hidden={currUser.id !== user.id}>
            <UserTabAnnualHolidays
              sendRequest={effs.addVacation}
              processing={processing}
              show={showAnnualHolidays}
              reserveFrom={annualHolidaysReserveFrom}
              reserveTo={annualHolidaysReserveTo}
            />
          </TabPanel>
        </TabPanels>
      </Content>

      {userEditorOpen && (
        <UserEditor
          isOpen={userEditorOpen}
          onClose={() => effs.closeUserEditor()}
          onSubmit={effs.updateUser}
          processing={processing}
          defaultValues={userEditorDefaultVals}
          showPassword={false}
          isNew={false}
        />
      )}
    </Tabs>
  );
};

export default connect(
  applyState({
    ...pick(
      [
        'processing',
        'loading',
        'user',
        'userIsSelf',
        'userEditorOpen',
        'userEditorDefaultVals',
        'query',
        'showAnnualHolidays',
        'annualHolidaysReserveFrom',
        'annualHolidaysReserveTo',
      ],
      sels,
    ),
    isAdmin: commonSels.isAdmin,
    currUser: commonSels.user,
    isSubcontractor: commonSels.isSubcontractor,
  }),
)(User);
