import React from 'react';
import {
  Stack,
  HStack,
  Text,
  Box,
  Link,
  useDisclosure,
  IconButton,
  Spacer,
} from '@chakra-ui/react';
import {format, parseISO} from 'date-fns';
import {Card, BellIcon, CloseIcon} from 'components';

const DriveNewsRow = ({news, openDocument}) => {
  const {isOpen, onClose} = useDisclosure({defaultIsOpen: true});

  if (!isOpen) return null;

  return (
    <Card px={4} py={2} role="button" onClick={() => openDocument(news)} _last={{mb: 6}}>
      <HStack spacing={4}>
        <BellIcon color="yellow.500" boxSize={6} />
        <Box>
          <Text fontSize="xs" color="gray.500">
            {news.release_at ? format(parseISO(news.release_at), 'd.M.yyyy HH:mm') : null}
          </Text>
          <Link>
            <Text fontSize="sm" fontWeight="bold">
              {news.title}
            </Text>
          </Link>
        </Box>
        <Spacer />
        <IconButton
          icon={<CloseIcon />}
          variant="ghost"
          onClick={(e) => {
            e.stopPropagation();
            onClose(e);
          }}
        />
      </HStack>
    </Card>
  );
};

const DriveNews = ({news, openDocument}) => {
  if (!news || !news.length) return null;

  return (
    <Stack spacing={4}>
      {news.map((n) => (
        <DriveNewsRow key={n.id} news={n} openDocument={openDocument} />
      ))}
    </Stack>
  );
};

export default DriveNews;
