import React, {memo} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {
  Button,
  Tag,
  TagLabel,
  Text,
  Link,
  TagLeftIcon,
  Checkbox,
  HStack,
  Box,
} from '@chakra-ui/react';
import {parseISO, format} from 'date-fns';
import {encodeQuery} from 'utils/url';
import {TableRow, TableCell} from 'components';
import {BusIcon, WarningIcon, PersonIcon} from 'components/icons';
import {states, types} from 'dicts/faults';

const FaultRow = memo(function FaultRow({
  fault,
  showSelect = true,
  showVehicle = true,
  showId = false,
  isSelected,
  select = () => {},
  onClick = () => {},
  referrerUrl,
  isAdmin,
}) {
  return (
    <TableRow
      position="relative"
      overflow="hidden"
      gridTemplateColumns={
        !showVehicle
          ? 'minmax(0, 2fr) repeat(3, minmax(0, 1fr))'
          : showSelect
          ? 'min-content minmax(min-content, 7.5rem) minmax(0, 2fr) repeat(3, minmax(0, 1fr))'
          : 'minmax(min-content, 7.5rem) minmax(0, 2fr) repeat(3, minmax(0, 1fr))'
      }
    >
      {showSelect && (
        <TableCell>
          <Checkbox
            size="lg"
            colorScheme="gray"
            isChecked={isSelected}
            onClick={(e) => e.stopPropagation()}
            onChange={(e) => select([{id: fault.id, selected: e.target.checked}])}
          />
        </TableCell>
      )}

      {showVehicle && (
        <TableCell>
          <HStack spacing="1">
            <Tag>
              <TagLeftIcon as={BusIcon} />
              <TagLabel>{fault.vehicle?.number}</TagLabel>
            </Tag>
            <Tag>
              <TagLeftIcon as={WarningIcon} />
              <TagLabel>
                {fault.vehicle?.faults?.filter((f) => f.state !== 'archived').length}
              </TagLabel>
            </Tag>
          </HStack>
        </TableCell>
      )}

      <TableCell width={{base: '100%', md: 'auto'}} marginY={{base: 3, md: 0}}>
        <Text
          fontSize="xs"
          color="gray.500"
          overflow="hidden"
          whiteSpace="nowrap"
          textOverflow="ellipsis"
        >
          {format(parseISO(fault.created_at), 'd.M.yyyy HH:mm')}
          {` / ${types[fault.type]}`}
        </Text>

        <Link
          as={RouterLink}
          to={`/faults/${fault.id}${encodeQuery({
            referrerUrl,
            referrerTitle: 'Vika- / vahinkoilmoitukset',
          })}`}
          onClick={onClick}
        >
          <Text
            fontWeight="bold"
            overflow="hidden"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
          >
            {showId ? `#${fault.id} ` : ''}
            {fault.title}
          </Text>
        </Link>
      </TableCell>

      <TableCell marginRight={{base: 'auto', md: 0}}>
        <Tag variant="outline">
          <TagLabel>
            {fault.affects_drivability ? 'Vaikuttaa ajoon' : 'Ei vaikuta ajoon'}
          </TagLabel>
        </Tag>
      </TableCell>

      <TableCell
        order={{base: 1, md: 0}}
        width={{base: '100%', md: 'auto'}}
        marginTop={{base: 3, md: 0}}
      >
        {fault.user && (
          <Button
            as={isAdmin ? RouterLink : undefined}
            to={isAdmin ? `/users/${fault.user.id}` : undefined}
            variant="primary-link"
            leftIcon={<PersonIcon />}
            fontSize="sm"
            width="100%"
            textAlign="left"
            justifyContent="flex-start"
          >
            <Box as="span" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
              {fault.user.name}
            </Box>
          </Button>
        )}
      </TableCell>

      <TableCell textAlign="right">
        <Tag>
          <TagLabel>{states[fault.state] || fault.state}</TagLabel>
        </Tag>
      </TableCell>
    </TableRow>
  );
});

export default FaultRow;
