import React from 'react';
import {Stack, Tag, TagLabel, TagLeftIcon, Divider, Flex, Text} from '@chakra-ui/react';
import {parseISO, format} from 'date-fns';
import {sort} from 'ramda';
import {PersonIcon} from 'components/icons';
import roles from 'dicts/roles';

const ActivityLog = ({logs}) => {
  if (!logs || !logs.length) {
    return <Text p={5}>Ei tapahtumia</Text>;
  }

  const sorted = sort((a, b) => parseISO(b.created_at) - parseISO(a.created_at), logs);

  return (
    <Stack spacing="0" divider={<Divider />}>
      {sorted.map((log) => (
        <Stack key={log.id} padding="5" spacing="3">
          <Flex>
            <Text fontSize="sm" color="gray.500" marginRight="5">
              {format(parseISO(log.created_at), 'd.M.yyyy HH:mm')}
            </Text>
            <Tag>{log.user ? roles[log.user.role] || log.user.role : ''}</Tag>
            <Tag variant="unstyled" marginLeft="auto">
              <TagLeftIcon as={PersonIcon} />
              <TagLabel>{log.user?.name}</TagLabel>
            </Tag>
          </Flex>
          <Text whiteSpace="pre-wrap">{log.comment}</Text>
        </Stack>
      ))}
    </Stack>
  );
};

export default ActivityLog;
