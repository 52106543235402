import {createSelector} from 'reselect';
import namespace from './namespace';
import {scopedSelectors} from 'utils/redux';
import {
  formatFolderEditorDefaultVals,
  formatDocumentEditorDefaultVals,
  formatDocumentUploaderDefaultVals,
} from './utils';

let sels = {};

sels.processing = (st) => st.processing;

sels.loading = (st) => st.loading;

sels.activeFolder = (st) => st.activeFolder;

sels.rootFolders = (st) => st.root.folders;

sels.rootDocuments = (st) => st.root.documents;

sels.folderEditorOpen = (st) => st.folderEditorOpen;

sels.folderInEdit = (st) => st.folderInEdit;

sels.folderEditorDefaultVals = createSelector(sels.folderInEdit, (folder) =>
  formatFolderEditorDefaultVals(folder),
);
sels.documentEditorOpen = (st) => st.documentEditorOpen;

sels.documentInEdit = (st) => st.documentInEdit;

sels.documentEditorDefaultVals = createSelector(sels.documentInEdit, (doc) =>
  formatDocumentEditorDefaultVals(doc),
);

sels.documentPreviewOpen = (st) => st.documentPreviewOpen;

sels.documentInPreview = (st) => st.documentInPreview;

sels.documentUploaderOpen = (st) => st.documentUploaderOpen;

sels.documentInUploader = (st) => st.documentInUploader;

sels.documentUploaderDefaultVals = createSelector(sels.documentInUploader, (doc) =>
  formatDocumentUploaderDefaultVals(doc),
);

export default scopedSelectors(namespace, sels);
